import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
    TreeItemsStateContext,
    TokenContext,
    BimProfContext,
    TreeIsLoadedContext,
    ProfilIsLoadedContext,
    SiteColorContext,
    TreeClickedContext,
    ProfilClickedContext,
    ProjectIsLoadedContext,
    ProjectClickedContext,
    BimProjectsContext,
    BimUserProjectsContext,
    UserProjectIsLoadedContext,
    DataobjectsIsLoadedContext,
    DataobjectsClickedContext,
    DataobjectsContext,
    DatacomponentsClickedContext
} from "../Store";
// import axios from "axios";
import * as s from "./Sidebar.styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";

import { connect } from "react-redux";
import UserService from "../../services/UserService";

// import RolesRoute from "../RolesRoute";
import RenderOnRole from "../RenderOnRole";
import { /*MENU1,*/MENU2, MENU3, MENU4, MENU5, MENU6, MENU7, MENU8, MENU9, MENU10 } from "../../modules/config";
import http from "../../shared/http-common";
import {trackPromise} from "react-promise-tracker";
import BimProfileService from "../../services/BimProfileService";
import TreeComponentTypeService from "../../services/TreeComponentTypeService";
import ProjectService from "../../services/ProjectService";
// import {SET_THE_REALM, SET_THE_TOKEN} from "../../app/start/actions";

// Sidebar-Definition

const Sidebar = ({ theRealm, keycloakToken, ...props }) => {
    // ----- Token-Aufruf nur während Entwicklung
    const [token, setToken] = useContext(TokenContext);
    // const [token, setToken] = useState(useContext(TokenContext));
    const [treeItemState, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [, setBimProf] = useContext(BimProfContext);
    const [, setBimProjects] = useContext(BimProjectsContext);
    const [, setBimUserProjects] = useContext(BimUserProjectsContext);
    const [, setTreeClicked] = useContext(TreeClickedContext);
    const [, setProfilClicked] = useContext(ProfilClickedContext);
    const [, setProjectClicked] = useContext(ProjectClickedContext);
    const [, setDataobjectsClicked] = useContext(DataobjectsClickedContext);
    const [, setDatacomponentsClicked] = useContext(DatacomponentsClickedContext);
    const [, setTreeIsLoaded] = useContext(TreeIsLoadedContext);
    const [profilIsLoaded, setProfilIsLoaded] = useContext(ProfilIsLoadedContext);
    const [, setProjectIsLoaded] = useContext(ProjectIsLoadedContext);
    const [, setUserProjectIsLoaded] = useContext(UserProjectIsLoadedContext);
    const [, setDataobjectsIsLoaded] = useContext(DataobjectsIsLoadedContext);
    const [, setDataobjects] = useContext(DataobjectsContext);

    const GetTreeData = async () => {
        try {
            return TreeComponentTypeService.getTreeData(token, theRealm)

        } catch (e) {
            console.log(e);
        }
    };

    const GetProfilesData = async () => {
        try {

            BimProfileService.getBimProfiles(token, theRealm)
                .then((res)=>{
                    console.log(res)
                    setBimProf(res.data);
                });

        } catch (e) {
            console.log(e);
        }
    };

    const GetProjectsData = async () => {

        try {
            let formData = new FormData();
            formData.append("realm", theRealm);

            return http.post(`/project/listAll`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });


        } catch (e) {
            console.log(e);
        }

    };

    const GetObjectData = async () => {
        try {
            let formData = new FormData();
            formData.append("realm", theRealm);
            // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
            return http.post(`/administrativeobject/economicunit/list`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    // --------------------
    const {
        backgroundImage = "",
        sidebarHeader = {
            fullName: "",
            shortName: "",
        },
        menuItems = [],
        fonts = {
            header: "",
            menu: "",
        },
        colorPalette = {
            bgColor1: "rgba(11, 171, 100, 0.8)",
            bgColor2: "rgba(59, 183, 143, 0.8)",
            fontColor: "rgba(22, 46, 39)",
            fontColorSelected: "rgba(255, 255, 255)",
            dividerColor: "rgba(122, 204, 178)",
            selectedBackgroundCollapsedMode: "dark",
        },
    } = props;

    // State
    const [selected, setSelectedMenuItem] = useState(menuItems[0].name);
    const [isSidebarOpen, setSidebarState] = useState(true);
    const [header, setHeader] = useState(sidebarHeader.fullName);
    const [subMenusStates, setSubmenus] = useState({});
    const [SiteColor, setSiteColor] = useContext(SiteColorContext);

    // Effects

    // Set selected menu item based on URL pathname
    useLayoutEffect(() => {
        const path = window.location.pathname;
        const parts = path.split("/");

        if (
            path !== "/" &&
            parts[1].charAt(0).toUpperCase() !== menuItems[0].name
        ) {
            const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
            setSelectedMenuItem(selectedItem);
        }
    }, [menuItems]);

    // Update of header state
    useEffect(() => {
        isSidebarOpen
            ? setTimeout(() => setHeader(sidebarHeader.fullName), 200)
            : setHeader(sidebarHeader.shortName);
    }, [isSidebarOpen, sidebarHeader]);

    // Update of sidebar state
    useEffect(() => {
        const updateWindowWidth = () => {
            if (window.innerWidth < 1280) setSidebarState(false);
            else setSidebarState(true);
        };

        window.addEventListener("resize", updateWindowWidth);

        return () => window.removeEventListener("resize", updateWindowWidth);
    }, [isSidebarOpen]);

    //Nur bei Sub-Menu Punkten notwendig, führt sonst zu Absturz
    // Add index of items that contain sub menu items
    useEffect(() => {
        const newSubmenus = {};

        menuItems.forEach((item, index) => {
            const hasSubmenus = !!item.subMenuItems.length;

            if (hasSubmenus) {
                newSubmenus[index] = {};
                newSubmenus[index]["isOpen"] = false;
                newSubmenus[index]["selected"] = null;
            }
        });

        // Set selected submenu if user landed on one
        const path = window.location.pathname;
        const parts = path.split("/");

        // @ToDo, quick & dirty, nicht schön, Mandant wird als Parameter übergeben und dann geht das so nicht, fm
        if (parts.length === 3 && (parts[1] !== "benutzer")) {
            const selectedItem = parts[1].toLowerCase();
            const selectedSubItem = parts[2].toLowerCase();
            const selectedItemIndex = menuItems.findIndex(
                (item) => item.name.toLowerCase() === selectedItem
            );
            const selectedSubItemIndex = menuItems[
                selectedItemIndex
                ]?.subMenuItems.findIndex(
                (subItem) => subItem.name.toLowerCase() === selectedSubItem
            );

            if (selectedItemIndex !== -1)
                newSubmenus[selectedItemIndex]["isOpen"] = true;
            if (selectedItemIndex !== -1 && selectedSubItemIndex !== -1)
                newSubmenus[selectedItemIndex]["selected"] = selectedSubItemIndex;
        }

        Object.keys(subMenusStates).length === 0 && setSubmenus(newSubmenus);
    }, [menuItems, subMenusStates]);

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        } else {
            // console.log('Hello')
            // console.log(UserService.hasRole(['TWIN_ADMIN_MAP']))
            // console.log(UserService.hasRole(['USER_MANDANT']))
        }
    }, [])

    // console.log('Hello')
    // console.log(UserService.hasRole(['TWIN_ADMIN_MAP']))
    // console.log(UserService.hasRole(['USER_MANDANT']))

    const handleMenuItemClick = async(name, index) => {
        const tk = UserService.getToken()
        setToken(tk);
        setSelectedMenuItem(name);

        // Katalog laden
        if (name === MENU2) {
            setProfilClicked(false);
            setTreeClicked(true);
            setDatacomponentsClicked(false);
            GetTreeData().then((result) => {
                // console.log(result);
                setTreeItemsState(result.data);
                setTreeIsLoaded(true);
            });
        }

        // Profile laden
        if (name === MENU3) {
            if(!UserService.isLoggedIn()) {
                UserService.doLogout();
            }
            setTreeClicked(false);
            setProjectClicked(false);
            setProfilClicked(true);
            setDatacomponentsClicked(false);
            GetProfilesData().then(() => {
                setProfilIsLoaded(true);
            });
            // console.log(treeItemState);
            if(!treeItemState || treeItemState.length === 0) {
                GetTreeData().then((result) => {
                    // console.log(result);
                    setTreeItemsState(result.data);
                    setTreeIsLoaded(false);
                });
            }
        }

        // Projekte laden
        if (name === MENU4) {
            if(!UserService.isLoggedIn()) {
                UserService.doLogout();
            }
            setTreeClicked(false);
            setProfilClicked(false);
            setProjectClicked(true)
            setDatacomponentsClicked(false);
            GetProjectsData().then((result) => {
                // console.dir(result);
                setProjectIsLoaded(true);
                setBimProjects(result.data);

            });
            if(!profilIsLoaded) {
                GetProfilesData().then(() => {
                    setProfilIsLoaded(true);
                });
            }
        }

        // Projekte laden
        if (name === MENU8) {
            if(!UserService.isLoggedIn()) {
                UserService.doLogout();
            }
            setTreeClicked(false);
            setProfilClicked(false);
            setProjectClicked(true);
            setDatacomponentsClicked(false);
            await trackPromise(
                ProjectService.listmyprojects(token, theRealm)
                    .then((result) => {
                    // console.dir(result);
                    setUserProjectIsLoaded(true);
                    setBimUserProjects(result?.data);

                })
            );
            /*if(!profilIsLoaded) {
                GetProfilesData().then(() => {
                    setProfilIsLoaded(true);
                });
            }*/
        }

        if(name === MENU6) {
            // console.log('sidebar 1');
            setDataobjectsClicked(true);
            setProfilClicked(false);
            setProjectClicked(false)
            setDatacomponentsClicked(false);
            trackPromise(
            GetObjectData().then((result) => {
                // console.log('sidebar');
                setDataobjectsIsLoaded(true);
                setDataobjects(result?.data);
            }));

            if(!treeItemState || treeItemState.length === 0) {
                await trackPromise(
                    GetTreeData().then((res) => {
                        setTreeItemsState(res?.data);
                        setTreeIsLoaded(true);
                    })
                );
            }
        }

        if(name === MENU7) {
            // console.log('sidebar 1');
            setDataobjectsClicked(false);
            setProfilClicked(false);
            setProjectClicked(false);
            setDatacomponentsClicked(true);
            if (!treeItemState || treeItemState.length === 0) {
                await trackPromise(
                    GetTreeData().then((res) => {
                        setTreeItemsState(res?.data);
                        setTreeIsLoaded(true);
                    })
                );
            }
        }
        if(name === MENU10) {
            // console.log('sidebar 1');
            setDataobjectsClicked(false);
            setProfilClicked(false);
            setProjectClicked(false);
            setDatacomponentsClicked(false);
            if (!treeItemState || treeItemState.length === 0) {
                await trackPromise(
                    GetTreeData().then((res) => {
                        setTreeItemsState(res?.data);
                        setTreeIsLoaded(true);
                    })
                );
            }
        }
        // Ausloggen
        if (name === MENU5) {
            await UserService.doLogout();
            window.location.href = '/logout'
        }

        const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

        if (subMenusStates.hasOwnProperty(index)) {
            subMenusCopy[index]["isOpen"] = !subMenusStates[index]["isOpen"];
            setSubmenus(subMenusCopy);
        } else {
            for (let item in subMenusStates) {
                subMenusCopy[item]["isOpen"] = false;
                subMenusCopy[item]["selected"] = null;
            }
            setSubmenus(subMenusCopy);
        }
    };

    const handleSubMenuItemClick = (menuItemIdx, subMenuItemIdx) => {
        const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

        subMenusCopy[menuItemIdx]["selected"] = subMenuItemIdx;
        setSubmenus(subMenusCopy);
    };

    const menuItemsJSX = menuItems.map((item, index) => {
        const isItemSelected = selected === item.name;

        const hasSubmenus = !!item.subMenuItems.length;
        const isOpen = subMenusStates[index]?.isOpen;

        const subMenusJSX = item.subMenuItems.map(
            (subMenuItem, subMenuItemIndex) => {
                const isSubmenuItemSelected =
                    subMenusStates[index]?.selected === subMenuItemIndex;

                return (
                    <RenderOnRole
                        key={`blup_` + index + "_" + subMenuItemIndex}
                        roles={subMenuItem.rollen}
                    >
                        <Link
                            to={`${item.to}${subMenuItem.to}`}
                            style={{ textDecoration: "none" }}
                            key={subMenuItemIndex}
                        >
                            <s.SubMenuItem
                                onClick={() => handleSubMenuItemClick(index, subMenuItemIndex)}
                                selected={isSubmenuItemSelected}
                                colorPalette={colorPalette}
                            >
                                {subMenuItem.name}
                            </s.SubMenuItem>
                        </Link>
                    </RenderOnRole>
                );
            }
        );

        return (
            <RenderOnRole key={`bla_` + index} roles={item.rollen}>
                <s.ItemContainer key={index}>
                    <Link to={item.to} style={{ textDecoration: "none" }}>
                        <s.MenuItem
                            font={fonts.menu}
                            selected={isItemSelected}
                            onClick={() => handleMenuItemClick(item.name, index)}
                            isSidebarOpen={isSidebarOpen}
                            isOpen={isOpen}
                            colorPalette={colorPalette}
                        >
                            <s.Icon isSidebarOpen={isSidebarOpen} src={item.icon} />
                            <s.Text isSidebarOpen={isSidebarOpen}>{item.name}</s.Text>
                            {hasSubmenus && isSidebarOpen && (
                                <s.DropdownIcon
                                    selected={isItemSelected}
                                    isOpen={isOpen}
                                    colorPalette={colorPalette}
                                />
                            )}
                        </s.MenuItem>
                    </Link>

                    {/* Display submenus if they exist  */}
                    <AnimatePresence>
                        {hasSubmenus && isOpen && (
                            <motion.nav
                                initial={{ opacity: 0, y: -15 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.35 }}
                                exit={{ opacity: 0, x: -30 }}
                            >
                                <s.SubMenuItemContainer
                                    isSidebarOpen={isSidebarOpen}
                                    colorPalette={colorPalette}
                                >
                                    {subMenusJSX}
                                </s.SubMenuItemContainer>
                            </motion.nav>
                        )}
                    </AnimatePresence>
                </s.ItemContainer>
            </RenderOnRole>
        );
    });

    return (
        <s.SidebarContainer
            backgroundImage={backgroundImage}
            isSidebarOpen={isSidebarOpen}
            colorPalette={colorPalette}
        >
            <s.SidebarHeader font={fonts.header}>{header}</s.SidebarHeader>
            <s.MenuItemContainer>{menuItemsJSX}</s.MenuItemContainer>
            <s.TogglerContainer>
                <s.Toggler onClick={() => setSidebarState(!isSidebarOpen)} />
                {/*<s.NightShift onClick={() => setSiteColor(!SiteColor)}>
                    <Brightness4Icon />
                </s.NightShift>*/}
            </s.TogglerContainer>

            <p className="navbar-text navbar-right" style={{ marginRight: 15 }}>
                Signed in as {UserService.getUsername()}
            </p>
        </s.SidebarContainer>
    );
};
//export default Sidebar;
function mapStateToProps({ theRealm, keycloakToken }) {
    return {
        theRealm: theRealm,
        keycloakToken: keycloakToken,
    };
}

export default connect(mapStateToProps)(Sidebar);
