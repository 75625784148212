import React, {useState, useContext, useEffect, useLayoutEffect} from "react";
import { BIMDialogOpenContext, RealmContext, TokenContext } from "../../Store";
// import { BROKER_URL } from "../../../modules/config";
// import axios from "axios";

import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import ListComp from "./ListComp";
import {MyTextInput} from "../../MyTextInput";

import {ErrorMessage, Form, Formik} from "formik";

import http from "../../../shared/http-common";
import {MySelectInput} from "../../MySelectInput";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import BimProfilesComponents from "./BimProfilesComponents";
import RenderOnRole from "../../RenderOnRole";

import {useStylesDialog} from "../../../shared/useStyleDialog";
import {SelectChangeEvent} from "@mui/material";
import {Select} from "@mui/material";

// Fenster zum Anlegen eines Profils

const useStyles = makeStyles((theme) => ({
    itemText: {
        margin: "2px",
        paddingLeft: "20px",
    },
    ListComp: {
        padding: "5px",
        margin: "2px",
    },
}));

const Label = styled.label`
  left: 26px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;
  background-color: #ffffff;
  color: #888888;  
  padding-left: 4px;
  padding-right: 4px;
  top: ${(props) => (props.isFloating ? `-2px` : `35%`)};
  font-size: ${(props) => (props.isFloating ? `0.75rem` : `1rem`)};
`;

const initialValues = {
    name: '',
    description: '',
    mark: '',
    category: '',
    componenttypes: [],
    guid: '',
    id: null
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function BimCreate({bimProfileSelected, GetProfilesData}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();

    const [BIMDialogOpen, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [BIMDialogOpen, setBIMDialogOpen] = useState(false);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [, setName] = useState("");
    const [, setDescription] = useState("");
    const [, setMark] = useState("");
    const [category, setCategory] = useState([]);
    const [guid, setGuid] = useState("");
    const [, setId] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [componenttypes, setComponenttypes] = useState([]);
    const [componenttypeItems, setComponenttypeItems] = useState([]);
    const [attributes, setAttributes] = useState([]);

    useLayoutEffect(()=>{
        console.log('hello BimCreate');
        // console.dir(bimProfileData);
        console.dir(bimProfileSelected);
        // console.log(bimProfileData.category);
        setComponenttypes([]);
        setAttributes([]);
        setComponenttypeItems([]);
        if(bimProfileSelected &&  bimProfileSelected.id) {
            const myCategory = []
            if(bimProfileSelected.category) {
                bimProfileSelected.category.forEach((item) => {
                    myCategory.push(item.bezeichnung)
                })
            }
            setCategory(myCategory);
            setMark(bimProfileSelected.mark);
            setName(bimProfileSelected.name);
            setDescription(bimProfileSelected.description);
            setGuid(bimProfileSelected.guid);
            setId(bimProfileSelected.id);

            if(bimProfileSelected.componenttypes) {
                const comTypes = [];
                const comTypeItems = [];
                const attributts = [];
                bimProfileSelected.componenttypes.forEach((item, index) => {
                    comTypes.push(item.objecttype_id);
                    if(item.attribute) {
                        item.attribute.forEach((attr, idx) => {
                            // attributts.push(item.objecttype_id + '_' + attr.attribute_id);
                            attributts.push(attr);
                        })
                    }
                    comTypeItems.push(item)
                });
                // setComponenttypes(comTypes);
                setComponenttypes(comTypeItems);
                setAttributes(attributts);
                setComponenttypeItems(comTypeItems);
            }
            // setBIMDialogOpen(true);

            const savedValues = {
                category: myCategory,
                mark: bimProfileSelected.mark,
                name: bimProfileSelected.name,
                description: bimProfileSelected.description,
                guid: bimProfileSelected.guid,
                id: bimProfileSelected.id
                // realm: ''
            }
            setFormValues(savedValues);

        } else {
            setCategory([]);
            setMark("");
            setName("");
            setDescription("");
            setGuid("");
            setId(null);
            setComponenttypes([]);
            setAttributes([]);
            const savedValues = {
                category: [],
                mark: "",
                name: "",
                description: "",
                guid: "",
                id: null
                // realm: ''
            }
            setFormValues(savedValues);
        }



    }, [bimProfileSelected, setBIMDialogOpen])

    const Component_Create = async (formData) => {
        await http.post(`/bimProfile/save`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

        await GetProfilesData();
        setBIMDialogOpen(false);
    };



    const handleChangeMultiple = (event: SelectChangeEvent<typeof category>) => {
        const {
            target: { value },
        } = event;
        setCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Dialog
            className={classesDialog.dialog}
            open={BIMDialogOpen}
            onClose={() => setBIMDialogOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <Formik
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                    console.dir(values);
                    console.dir(componenttypes);
                    console.dir(attributes);

                    const componenttypesToSave = [];
                    for (const component of componenttypes) {
                        const compAttr = [];
                        for(const attr of attributes) {
                            if(component.attribute?.includes(attr)) {
                                compAttr.push({id: attr.attribute_id});
                            }
                            // const attr = attrString.split('_');
                            // if(attr[0] === component.toString()) {
                            //     compAttr.push({id: attr[1]});
                            //     // break;
                            // }
                        }
                        componenttypesToSave.push({id: component.objecttype_id, attributes: compAttr})
                    }

                    const profile = {
                        name: values.name,
                        description: values.description,
                        mark: values.mark,
                        category: typeof values.category == 'string' ? values.category?.split(',') : values.category,
                        componenttypes: componenttypesToSave,
                        guid: guid
                    };

                    let formData = new FormData();
                    formData.append("realm", realm);
                    formData.append("data", JSON.stringify(profile));

                    await Component_Create(formData);

                    setSubmitting(false);

                }}

            >
                <Form>

                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setBIMDialogOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                {bimProfileSelected?.id ? `Profil bearbeiten`: `Profil hinzufügen`}
                            </Typography>
                            {/*{name != "" && name != null ? (*/}
                            <RenderOnRole roles={['MAP_ADMIN', 'ADMIN_MAP', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_ADMIN']}>
                                <Button
                                    type="submit"
                                    color="inherit"
                                >
                                    Speichern
                                </Button>
                            </RenderOnRole>
                            {/*) : null}*/}
                        </Toolbar>
                    </AppBar>

                    <List>
                        <ListItem>
                            <MyTextInput
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                required
                            />
                            <ErrorMessage name="name"/>
                        </ListItem>
                        <ListItem>
                            <MyTextInput
                                margin="dense"
                                name="mark"
                                label="Kennzeichen"
                                type="text"
                                fullWidth
                            />
                        </ListItem>
                        <ListItem>
                            <Label isFloating={true}>Lebensphasen des Bauwerks</Label>
                            <MySelectInput
                                margin="dense"
                                name="category"
                                label="Kategorie"
                                fullWidth
                                variant="outlined"
                                multiple

                            >
                                <MenuItem key="m01" value=""></MenuItem>
                                <MenuItem key="m02" value="Bauen">Bauen</MenuItem>
                                <MenuItem key="m03" value="Betreiben">Betreiben</MenuItem>
                                <MenuItem key="m04" value="Planen">Planen</MenuItem>

                            </MySelectInput>
                           {/* <MyTextInput
                                margin="dense"
                                name="category"
                                label="Kategorie"
                                type="text"
                                fullWidth
                            />*/}
                        </ListItem>
                        <ListItem>
                            <MyTextInput
                                margin="dense"
                                name="description"
                                label="Beschreibung"
                                type="text"
                                fullWidth
                            />
                        </ListItem>
                        <hr/>
                        <div className={classes.itemText}>
                            <p>Komponenten wählen, die dem Profil zugeordnet werden sollen: </p>
                        </div>
                        <div className={classes.ListComp}>
                            {/*<ListComp setComponenttypes={setComponenttypes}
                                      setAttributes={setAttributes}
                                      attributes={attributes}
                                      componenttypes={componenttypes}
                                      componenttypeItems={componenttypeItems}
                            />*/}
                            <BimProfilesComponents
                                setComponenttypes={setComponenttypes}
                                setAttributes={setAttributes}
                                attributes={attributes}
                                componenttypes={componenttypes}
                                theRealm={realm}
                                />
                        </div>
                    </List>

                </Form>
            </Formik>
        </Dialog>
    );
}

export default BimCreate;
