import axios from "axios";
import UserService from "./UserService";
import http from '../shared/http-common';


const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
};

// const _axios = axios.create();

const configure = () => {
    http.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    });
};

const getAxiosClient = () => http;

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
};

export default HttpService;