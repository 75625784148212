import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import ItemDisplay from "./ItemDisplay";
import ItemComponenttype from "./ItemComponenttype";



function ListComponenttype({items, currentObjectTypeId, setCurrentObjectTypeId, showAttributes, setShowAttributes, setCurrentObjectType, checkedAttributes, setCheckedAttributes, theRealm}) {
    console.log(items);
    // const [currentObjectTypeId, setCurrentObjectTypeId] = useState(null);
    // const [showAttributes, setShowAttributes] = useState(false);

    useEffect(()=>{
        console.log(checkedAttributes);
        const localCheckedAttributes = checkedAttributes;
        if(items) {
            items.forEach(c => {
                let localAttributes = c.attribute;
                localAttributes.forEach(a => {
                    if (a.data_type === 'standard') {
                        localCheckedAttributes.push(a.attribute_id);
                    } else if(a.data_type === 'p_referencevalue' && (a.reference_field === 'realestateobject' || a.reference_field === 'component')) {
                        localCheckedAttributes.push(a.attribute_id);
                    }
                });
            });
            setCheckedAttributes(localCheckedAttributes)
        }
    }, [items])

    const [showAttributesSub, setShowAttributesSub] = useState(false);

    const handleClick = (index, data) => {
        console.log(index, data);
        // handleToggle(index, idPath, data)
        // childObjects = data.children_objecttypes;
        setCurrentObjectTypeId(index);
        setCurrentObjectType(data);
        setShowAttributes(false)
        setShowAttributesSub(false);
    }

    return (
        <div>
            {items?.map((item) => {

                return (
                    <ItemComponenttype
                        key={item?.objecttype_id}
                        data={item}
                        index={item?.objecttype_id}
                        labelId={item?.objecttype_id}
                        currentObjectTypeId={currentObjectTypeId}
                        setCurrentObjectTypeId={setCurrentObjectTypeId}
                        setCurrentObjectType={setCurrentObjectType}
                        handleClick={handleClick}
                        showAttributes={showAttributes}
                        setShowAttributes={setShowAttributes}
                        showAttributesSub={showAttributesSub}
                        setShowAttributesSub={setShowAttributesSub}
                        checkedAttributes={checkedAttributes}
                        setCheckedAttributes={setCheckedAttributes}
                        theRealm={theRealm}
                         />
                );
            })}
        </div>
    );


}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListComponenttype);