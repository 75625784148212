const SET_THE_REALM = 'SET_THE_REALM';
const SET_THE_TOKEN = 'SET_THE_TOKEN';

export function setTheRealm(theRealm='') {
    return {
        type: SET_THE_REALM,
        theRealm
    };
}

export function setTheToken(keycloakToken='') {
    return {
        type: SET_THE_TOKEN,
        keycloakToken
    };
}

export {SET_THE_REALM, SET_THE_TOKEN};