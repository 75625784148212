import React, {useContext, useEffect, useState} from "react";
import {DatacomponentsContext, DataobjectsContext, TokenContext} from "../../Store";
import {trackPromise} from "react-promise-tracker";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import {connect} from "react-redux";


import {makeStyles} from "@mui/styles";
// import TreeView from "@material-ui/lab/TreeView";
// import TreeItem from "@material-ui/lab/TreeItem";
import DatacomponentTableView from "./DatacomponentTableView";
import http from "../../../shared/http-common";
import UserService from "../../../services/UserService";
import {useNavigate} from "react-router-dom";

// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        width: "100%",
        height: "100%",
        /*margin: "15px",
        padding: "20px",*/
        float: "left",
        /*border: "2px solid #B4AF03",
        borderRadius: "25px",*/
    },
    btListe: {
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

    table: {
        minWidth: 700,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const GetObjectDataByType = async (objecttype, type, theRealm, token) => {
    try {
        if(token && token !== 'Kein Token generiert!') {
            console.log('Hello GetObjectDataByType ' + type);
            let formData = new FormData();
            formData.append("realm", theRealm);
            // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
            return http.post(`/` + objecttype + `/` + type + `/list`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
};


function Datacomponents({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);

    const [dataObjects, setDataObjects] = useContext(DataobjectsContext);
    const [Datacomponents, setDatacomponents] = useContext(DatacomponentsContext);
    const [administrativeObjects, setAdministrativeObjects] = useState([]);
    // const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
    const [, setIsLoading] = useState(false);
    let navigate = useNavigate();

    const classes = useStyles();

    useEffect(()=>{
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
            navigate('/')
        }
    }, []);

/*    useEffect(()=>{
        setIsLoading(true);
        GetObjectDataByType('realestateobject', 'site', theRealm, token)
            .then((result) => {
                setDataObjects(result.data);
                setIsLoading(false);
            })
    }, [theRealm, token, GetObjectDataByType]);


    useEffect(()=>{
        setIsLoading(true);
        GetObjectDataByType('administrativeobject', 'economicunit', theRealm, token)
            .then((result) => {
                setAdministrativeObjects(result.data);
                setIsLoading(false);
            })
    }, [theRealm, token, GetObjectDataByType]);*/

    useEffect(()=>{
        setIsLoading(true);
        async function fetchData() {
            const dataObjects2 = await getMyData('realestateobject', 'site', theRealm, token);
            const administrativeObjects2 = await getMyData('administrativeobject', 'economicunit', theRealm, token)

            setDataObjects(dataObjects2);
            setAdministrativeObjects(administrativeObjects2)
        }
        fetchData().then(()=>{
            setIsLoading(false);
        });


    }, [theRealm, token, setDataObjects]);

    const getMyData =  async (objecttype, type, theRealm, token) => {
        const result =  await GetObjectDataByType(objecttype, type, theRealm, token);
        return result?.data;
    }

    return (
        <div>
            <div className={classes.katalogView}>
                <LoadingIndicator/>
                <div className={classes.btListe}>

                        <DatacomponentTableView
                            dataComponents={Datacomponents}
                            setDatacomponents={setDatacomponents}
                            theRealm={theRealm}
                            token={token}
                            dataObjects={dataObjects}
                            administrativeObjects={administrativeObjects}

                        />

                </div>

            </div>

        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(Datacomponents);