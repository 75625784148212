import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import {MyTextInput} from "../../MyTextInput";
import {ErrorMessage} from "formik";
import Select from "react-select";
import {ListItemText} from "@mui/material";
import React, {useEffect, useState, useContext} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@mui/styles";
import {MyCheckbox} from "../../MyCheckbox";
import ComponentTypeAutoComplete from "../../../shared/ComponentTypeAutoComplete";
import DataobjectService from "../../../services/DataobjectService";

import {TokenContext} from "../../Store";
import ParentObjectEditComponent from "../../../shared/ParentObjectEditComponent";

const useStyles = makeStyles((theme) => ({
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));

// const initialValues = {
//     name: '',
//     description: '',
//     componenttypes: [],
//     guid: '',
//     id: null,
//     instanceTypeId: null,
//
//     deactivated: 0
// }
//
// const validationSchema = Yup.object({
//     name: Yup.string()
//         .required('Pflichtfeld'),
//     description: Yup.string(),
//     guid: Yup.string()
// })


function DataObjectFormItems({dataObjectSelected, options, attributeList, instanceTypeId, onChangeInstanceType, realestateobjectParentValue, setRealestateobjectParentValue, administrativeobjectParentValue, setAdministrativeobjectParentValue, theRealm}) {
    const classes = useStyles();
    // const [DataobjectsDialogOpen, setDataobjectsDialogOpen] = useContext(DataobjectsDialogOpenContext);
    const [token] = useContext(TokenContext);
    // const [realm] = useContext(RealmContext);


    // Autocomplete-Liegenschaftlist
    // const [realestateobjectValue, setRealestateobjectValue] = useState(null);
    // const [realestateobjectParentValue, setRealestateobjectParentValue] = useState(null);
    // const liegenschaftFlatList = [];
    const [liegenschaftSubFlatList, setLiegenschaftSubFlatList] = useState([]);
    const [weSubFlatList, setWeSubFlatList] = useState([]);
    const lsfl = [];
    const wefl = [];

    function convert3(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        // myItem.title = item.number + ' - ' + item.name;
        myItem.title = item.name;
        myItem.key = item.guid;
        lsfl.push(myItem);

    }

    function convertWe(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        // myItem.title = item.number + ' - ' + item.name;
        myItem.title = item.name;
        myItem.key = item.guid;
        wefl.push(myItem);

    }

    const getRealestateobjectParents = async(guid) => {
        const objs = await DataobjectService.listrealestateobjectparents(token, theRealm, guid);
        console.log(objs?.data)
        console.log(objs?.data instanceof Array)
        return objs?.data

    }

    const getAdministrativeobjectParents = async(guid) => {
        const objs = await DataobjectService.listadministrativeobjectparents(token, theRealm, guid);
        console.log(objs?.data)
        console.log(objs?.data instanceof Array)
        return objs?.data

    }

    const setRealestateobjectsValues = async(guid) => {
        console.log(guid)
        if (guid) {
            const objs = await DataobjectService.listrealestateobjectchildren(token, theRealm, guid);
            console.log(objs?.data)
            console.log(objs?.data instanceof Array)
            if (objs?.data && objs.data instanceof Array) {
                const objects = objs.data
                objects.forEach(item => {
                    convert3(item)
                });
            }

            setLiegenschaftSubFlatList(lsfl)
        }
    }

    const setAdministrativeobjectsValues = async(guid) => {
        console.log(guid)
        if (guid) {
            const objs = await DataobjectService.listadministrativeobjectchildren(token, theRealm, guid);
            console.log('setAdministrativeobjectsValues - children: ');
            console.log(objs?.data)
            console.log(objs?.data instanceof Array)
            if (objs?.data && objs.data instanceof Array) {
                const objects = objs.data
                objects.forEach(item => {
                    convertWe(item)
                });
            }

            setWeSubFlatList(wefl)
        }
    }

    useEffect(() => {
        if(dataObjectSelected.realestateobjectType && dataObjectSelected.guid) {
            getRealestateobjectParents(dataObjectSelected.guid)
                .then((result) => {
                    console.log(result);
                    if(result && result instanceof Array && result.length > 0) {
                        setRealestateobjectsValues(result[0].guid);
                    }
                });

        } else if(dataObjectSelected.administrativeobjectType && dataObjectSelected.guid) {
            getAdministrativeobjectParents(dataObjectSelected.guid)
                .then((result) => {
                    console.log('ao parents: ')
                    console.log(result);
                    if(result && result instanceof Array && result.length > 0) {
                        console.log(result[0].guid);
                        setAdministrativeobjectsValues(result[0].guid);
                    }
                });

        }
    }, []);

    return (
        <List>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Bezeichnung</InputLabel>
                <MyTextInput
                    autoFocus
                    margin="dense"
                    name="name"
                    type="text"
                    fullWidth
                    required
                />
                <ErrorMessage name="name"/>
            </ListItem>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Typ</InputLabel>
                <ListItemText>{dataObjectSelected.realestateobjectType ? dataObjectSelected.realestateobjectType : dataObjectSelected.administrativeobjectType}</ListItemText>
            </ListItem>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Guid</InputLabel>
                <MyTextInput
                    disabled
                    margin="dense"
                    name="guid"
                    type="text"
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Beschreibung</InputLabel>
                <MyTextInput
                    margin="dense"
                    name="description"
                    type="text"
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Nummer</InputLabel>
                <MyTextInput
                    margin="dense"
                    name="number"
                    type="text"
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <InputLabel className={classes.InputLabel}>Kennzeichen</InputLabel>
                <MyTextInput
                    margin="dense"
                    name="mark"
                    type="text"
                    fullWidth
                />
            </ListItem>
            { dataObjectSelected.realestateobjectType ?(

                <ParentObjectEditComponent
                    name={dataObjectSelected.parentRealestateobjectName}
                    guid={dataObjectSelected.parentRealestateobjectGuid}
                    flatList={liegenschaftSubFlatList}
                    setValue={setRealestateobjectParentValue}
                    value={realestateobjectParentValue}
                    dataObjectSelected={dataObjectSelected}
                />
                ) : (
            null
            )}

            { dataObjectSelected.administrativeobjectType ?(

                <ParentObjectEditComponent
                    name={dataObjectSelected.parentAdministrativeobjectName}
                    guid={dataObjectSelected.parentAdministrativeobjectGuid}
                    flatList={weSubFlatList}
                    setValue={setAdministrativeobjectParentValue}
                    value={administrativeobjectParentValue}
                    dataObjectSelected={dataObjectSelected}
                />
            ) : (
                null
            )}

            { dataObjectSelected.realestateobjectType ? (
                <ListItem>
                    <InputLabel className={classes.InputLabel}>Instanztyp</InputLabel>
                    <Select
                        className={classes.selectbox}
                        value = {
                            options.filter(option =>
                                option.value === instanceTypeId)
                        }
                        onChange={(option) => onChangeInstanceType(option)}
                        options={options}
                    />


                </ListItem>
            ) : null}

            <ListItem>
                <InputLabel className={classes.InputLabel}>deaktiviert</InputLabel>
                <ListItemText>{dataObjectSelected.deactivated ? 'ja': 'nein'}</ListItemText>
            </ListItem>

            <ListItem>
                <InputLabel className={classes.InputLabel}>geändert am</InputLabel>
                <ListItemText>{ new Date(dataObjectSelected.last_changed).toLocaleString()}</ListItemText>
            </ListItem>

            {attributeList && attributeList.map((attr)=> (
                <ListItem key={attr.guid}>
                    <InputLabel className={classes.InputLabel}>{attr.label}</InputLabel>
                    {attr.type === 'number' || attr.type === 'real' || attr.type === 'integer' ? (
                        <MyTextInput
                            margin="dense"
                            name={"attr_" + attr.guid}
                            type="number"
                            fullWidth
                        />
                    ) : null }
                    {attr.type === 'date' ? (
                        <MyTextInput
                            margin="dense"
                            name={"attr_" + attr.guid}
                            type="date"
                            fullWidth
                        />
                    ) : null }
                    {attr.type === 'boolean' ? (
                        <MyCheckbox
                               name={"attr_" + attr.guid}
                        />

                    ) : null
                    }
                    {!(attr.type === 'number' || attr.type === 'real' || attr.type === 'integer' || attr.type === 'boolean' || attr.type === 'date') ? (
                        <MyTextInput
                            margin="dense"
                            name={"attr_" + attr.guid}
                            type="text"
                            fullWidth
                        />

                    ) : null
                    }
                </ListItem>
            ))}
        </List>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataObjectFormItems);