import RenderOnRole from "../../RenderOnRole";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import {TabPanel} from "@mui/lab";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import { withStyles} from "@mui/styles";
import {header} from "../../../shared/headerStyle";
import ProjectObjects from "./ProjectObjects";
import ProjectObjectsSelect from "./ProjectObjectsSelect";
import PropTypes from "prop-types";
import React from "react";
// import {useStylesDialog} from "../../../shared/useStyleDialog";
import Checkbox from "@mui/material/Checkbox";
import ProjectUsers from "./ProjectUsers";
import BimProjectFormList from "./BimProjectFormList";

/*
const useStyles = makeStyles(() => ({

    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "120px"
    },
    selectbox: {
        width: "100%"
    },
    TabBorderStyle: {
        padding: "3px",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    }
}));
*/


/*const StyledTableCell = withStyles((theme) => ({
    head: header,
    body: {
        fontSize: 12,
    },
}))(TableCell);*/

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/*function FormRow({username, handleChange, checkedRights}) {
    console.dir(checkedRights);
    let checkRead = false;
    let checkWrite = false;
    checkedRights.rights?.forEach((r) => {
        if(r.username === username) {
            checkRead = r.read;
            checkWrite = r.write;
        }
    })

    return (
        <React.Fragment>

            <TableRow>
                <StyledTableCell>{username}</StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="read" onChange={handleChange} checked={checkRead} /></StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="write" onChange={handleChange} checked={checkWrite}/></StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}*/

/*const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));*/

function BimProjectTabs({users, bimProjectData, checkedRights, handleChange, realm,
                            setProfileId, profileId, optionsRealestateobject,
                            setSelectedRealestateobject, selectedRealestateobject, setHideSaveButton}) {
    // const classesDialog = useStylesDialog();
    // const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const styles = {
        TabBorderStyle: {
            padding: "3px",
            borderRight: "1px solid black",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        if(newValue > 1) {
            setHideSaveButton(true);
        } else {
            setHideSaveButton(false);
        }
    };

    return (
        <RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}>
            {bimProjectData.guid ? (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px'}}>
                        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" sx={{marginLeft: '5px' }}>
                            <Tab label="Projekt" {...a11yProps(0)} sx={styles.TabBorderStyle}/>
                            <Tab label="Benutzer" {...a11yProps(1)} sx={styles.TabBorderStyle}/>
                            <Tab label="Objekte" {...a11yProps(2)} sx={styles.TabBorderStyle} />
                            <Tab label="Objekte zuordnen" {...a11yProps(3)} sx={styles.TabBorderStyle}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <BimProjectFormList
                            bimProjectData={bimProjectData}
                            setProfileId={setProfileId}
                            profileId={profileId}
                            optionsRealestateobject={optionsRealestateobject}
                            setSelectedRealestateobject={setSelectedRealestateobject}
                            selectedRealestateobject={selectedRealestateobject}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProjectUsers users={users}
                                      checkedRights={checkedRights}
                                      handleChange={handleChange}
                                      realm={realm}
                                      />
                        {/*<Box sx={{ width: '100%' }}>

                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Leserecht</StyledTableCell>
                                        <StyledTableCell>Schreibrecht</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users?.map((user) => (
                                        <>
                                            <FormRow username={user.username} handleChange={handleChange} checkedRights={checkedRights} />
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>*/}
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <ProjectObjects projectGuid={bimProjectData.guid} realm={realm}
                                        projectId={bimProjectData.id}/>

                    </TabPanel>

                    <TabPanel value={value} index={3} >
                        <ProjectObjectsSelect projectGuid={bimProjectData.guid} realm={realm}
                                              projectId={bimProjectData.id}/>

                    </TabPanel>
                </>
            ) : (
                <BimProjectFormList
                    bimProjectData={bimProjectData}
                    setProfileId={setProfileId}
                    profileId={profileId}
                    optionsRealestateobject={optionsRealestateobject}
                    setSelectedRealestateobject={setSelectedRealestateobject}
                    selectedRealestateobject={selectedRealestateobject}
                />
            )
            }
        </RenderOnRole>
    )
}
export default BimProjectTabs;