import UserService from "../services/UserService";

const RenderOnAnonymous = ({ realmId, children }) => {
    // console.log(realmId);
    UserService.setRealm(realmId)
    return (
        (!UserService.isLoggedIn()) ? children : null
    )
}

export default RenderOnAnonymous;