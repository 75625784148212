import React, { useContext, useEffect } from "react";
import {
    TreeItemsStateContext,
    CompIsSelectedContext,
    SubDialogOpenContext,
    SearchValueContext,
    SiteColorContext,
    TreeParentContext,
} from "../../Store";

// Unterkomponente zum ausgewählten Bauteil anlegen
import CreateSubCompDialog from "./Dialog/CreateComp/CreateSubCompDialog";
// gefilterte Tree-Elemente
import { SearchList } from "./CatalogItems/SearchList";
// Bauteil Editor
import EditComp from "./EditComp";

import TreeView from "@material-ui/lab/TreeView";
// import TreeView from "@mui/lab/TreeView";
import { withStyles, makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShortTextIcon from "@mui/icons-material/ShortText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import UserService from "../../../services/UserService";
import RenderOnRole from "../../RenderOnRole";

// Baumdarstellung Bauteilliste

const useStyles = makeStyles({
    treeView: {
        textAlign: "left",
    },
    buttonLight: {
        float: "right",
        fontWeight: "bold",
    },
    buttonDark: {
        float: "right",
        fontWeight: "bold",
        color: "#e5e5e5",
    },
    gesamtView: {
        display: "flex",
        alignItems: "flex-start",
        flexGrow: "5",
        border: "1px solid #4D6F8C",
        borderRadius: "0px",
        height: "95vh",
        margin: "20px",
    },
    katalogView: {
        display: "flex",
        flexDirection: "column",
    },
    btSearch: {
        /*margin: "15px",*/
        /*width: "80%",*/
        padding: "0px 10px 0px 10px",
        float: "left",
        display: "flex",
        flexWrap: "wrap",
    },
    btListe: {
        maxHeight: "75vh",
        width: "400px",
        /*overflowY: "scroll",*/
        /*margin: "0px 15px 15px 15px",
        padding: "10px",*/
        float: "left",
    },
    showParent: {
        display: "flex",
        marginLeft: "30px"
    },
    btNeu: {
        margin: "2px 0px 0px 15px",
        float: "right",
    },
    darkColor: {
        color: "#e5e5e5",
    },

});

function DataTreeView({ treeItems }) {
    const classes = useStyles();

    return (
        <TreeView
            className={classes.treeView}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<ShortTextIcon />}
        >
            {SearchList(treeItems)}
        </TreeView>
    );
}

// Suchfeld wenn Seite hell
const CssTextFieldLight = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#633437",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#2D3A42",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#2D3A42",
            },
            "&:hover fieldset": {
                borderColor: "#2D3A42",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#2D3A42",
            },
        },
    },
})(TextField);

// Suchfeld wenn Seite dunkel
const CssTextFieldDark = withStyles({
    root: {
        "& label": {
            color: "#e5e5e5",
        },
        "& label.Mui-focused": {
            color: "#e5e5e5",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#e5e5e5",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#e5e5e5",
            },
            "&:hover fieldset": {
                borderColor: "#e5e5e5",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#e5e5e5",
            },
        },
    },
})(TextField);

const ShowParent = (x) => {

    var y = [];

    if (x) {
        y = x.values();
    }

    for (const value of y) {
        return (<div>Zugehörig zu: {value.name}</div>);
    }
};

const TreeComponent = () => {
    const [treeItemsState] = useContext(TreeItemsStateContext);
    const [CompIsSelected] = useContext(CompIsSelectedContext);
    const [TreeParent] = useContext(TreeParentContext);
    const [SiteColor] = useContext(SiteColorContext);
    const [SearchValue, setSearchValue] = useContext(SearchValueContext);
    const [, setSubDialogOpen] = useContext(SubDialogOpenContext);
    const classes = useStyles();

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    },[])

    return (
        <div>
            <div className={classes.gesamtView}>
                <div className={classes.katalogView}>
                    <RenderOnRole  roles={['MAP_ADMIN', 'ADMIN_MAP', 'TWIN_DATA_ADMIN']}>
                        <div className={classes.btNeu}>
                            {SiteColor ? (
                                <Button
                                    className={classes.buttonLight}
                                    onClick={() => setSubDialogOpen(true)}
                                >
                                    <AddIcon /> Neu
                                </Button>
                            ) : (
                                <Button
                                    className={classes.buttonDark}
                                    onClick={() => setSubDialogOpen(true)}
                                >
                                    <AddIcon /> Neu
                                </Button>
                            )}
                        </div>
                        </RenderOnRole>
                    <div>
                        {SiteColor ? (
                            <form className={classes.btSearch}>
                                <CssTextFieldLight
                                    autoFocus
                                    margin="dense"
                                    id="SearchCata"
                                    variant="outlined"
                                    label="Katalog durchsuchen"
                                    type="text"
                                    fullWidth
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </form>
                        ) : (
                            <form className={classes.btSearch}>
                                <CssTextFieldDark
                                    autoFocus
                                    margin="dense"
                                    id="SearchCata"
                                    variant="outlined"
                                    label="Katalog durchsuchen"
                                    type="text"
                                    fullWidth
                                    // Textfarbe auf weiß
                                    InputProps={{
                                        className: classes.darkColor,
                                    }}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </form>
                        )}
                    </div>
                    {/** Darstellen der Eltern-Bauteils nur wenn Filterung gesetzt ist */}
                    {SearchValue ? <div className={classes.showParent}>{ShowParent(TreeParent)}</div> : null}
                    <div className={classes.btListe}>
                        <DataTreeView treeItems={treeItemsState} />
                    </div>
                    {/*<div className={classes.btNeu}>
                        {SiteColor ? (
                            <Button
                                className={classes.buttonLight}
                                onClick={() => setSubDialogOpen(true)}
                            >
                                <AddIcon /> Neu
                            </Button>
                        ) : (
                            <Button
                                className={classes.buttonDark}
                                onClick={() => setSubDialogOpen(true)}
                            >
                                <AddIcon /> Neu
                            </Button>
                        )}
                    </div>*/}
                </div>
                {/*Fenster zum Bauteil-Anlegen*/}
                <CreateSubCompDialog />
                {/*Bauteil-Bearbeitung darstellen wenn Element aus Baum gewählt ist*/}
                {CompIsSelected ? <EditComp /> : null}
            </div>
        </div>
    );
};

export default TreeComponent;
