import React, {useState, useContext, useEffect} from "react";
import {RealmContext, TokenContext, TreeItemsStateContext} from "../../Store";

import {makeStyles} from "@mui/styles";

import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {TreeItem} from "@material-ui/lab";

import {connect} from "react-redux";


import DataComponentService from "../../../services/DataComponentService";

import {trackPromise} from "react-promise-tracker";

import {Form, Formik} from "formik";

import UploadService from "../../../services/upload-files.service";
import UploadDialog from "./UploadDialog";
import {confirmWrapper} from "../../../shared/confirm";
import TreeComponentTypeService from "../../../services/TreeComponentTypeService";


import styled from 'styled-components';
import DataobjectService from "../../../services/DataobjectService";
import AttachmentList from '../../../shared/AttachmentList';
import DataComponentButtonToolbar from '../Datacomponents/DataComponentButtonToolbar';
import DataComponentList from "../Datacomponents/DataComponentList";

import {useStylesDialog} from "../../../shared/useStyleDialog";
import UserService from "../../../services/UserService";
import ComponentFormAppBar from "../Datacomponents/ComponentFormAppBar";

const useStyles = makeStyles((theme) => ({

    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));



const initialValues = {
    name: '',
    number: '',
    mark: '',
    description: '',
    componentAttributeList: [],
    guid: '',
    id: null,
    position: '',
    deactivated: 0,
    geom: ''
}

const Label = styled.label`
  left: 26px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;
  background-color: #ffffff;
  color: #888888;  
  padding-left: 4px;
  padding-right: 4px;
  top: ${(props) => (props.isFloating ? `-2px` : `35%`)};
  font-size: ${(props) => (props.isFloating ? `0.75rem` : `1rem`)};
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function ComponentFormView({dataObjectSelected, setDataComponentFormDialogOpen, DataComponentFormDialogOpen, componentSelected, componentType, attributeList, setAttributeList, realestateobjectGuid, handleActivateClick, handleDeactivateClick, saveObject, reloadComponentList, userProject, theRealm}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();

    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [, setName] = useState('');
    const [, setNumber] = useState('');
    const [, setMark] = useState('');
    // const [geom, setGeom] = useState('');
    const [, setPosition] = useState(dataObjectSelected?.guid);
    const [, setDescription] = useState("");
    // const [, setDeactivated] = useState(0);
    // const [guid] = useState(componentSelected?.guid);
    // const [id] = useState(componentSelected?.id);
    const [formValues, setFormValues] = useState(null);

    const [myComponentType,setMyComponentType] = useState({});

    const [treeItemsState, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [value, setValue] = React.useState(null);
    const [parentComponentValue, setParentComponentValue] = React.useState(null);
    const [, setExpanded] = React.useState([]);

    const [, setMessage] = useState("");
    const [fileInfos, setFileInfos] = useState([]);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const [newRealestateobjectGuid, setNewRealestateobjectGuid] = useState(null);
    const [realestateobjectSubValue, setRealestateobjectSubValue] = useState(null);

    // const [componentValues, setComponentValues] = useState([]);
    const [componentFlatListValues, setComponentFlatListValues] = useState([]);
    let myAttributList = componentType?.attribute;
    let attributes = componentSelected.componentAttributeList;

    const componentFlatList = [];
    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    useEffect(() => {

        // setFormValues([]);
        // setValue(null);
        console.log('hello DataObject');
        console.log(componentSelected);
        console.log(dataObjectSelected);
        console.log(formValues);
        if(realestateobjectGuid) {
            setNewRealestateobjectGuid(realestateobjectGuid);
        } else {
            setNewRealestateobjectGuid(componentSelected.realestateobjectGuid);
        }

        console.log('treeItemsState')
        console.log(!treeItemsState || treeItemsState.length === 0)
        if ((!treeItemsState || treeItemsState.length === 0) && (token && token !== 'Kein Token generiert!')) {

            TreeComponentTypeService.getTreeData(token, theRealm)
                .then((res) => {
                    setTreeItemsState(res.data);
                })
        }

        if(componentSelected &&  componentSelected.id) {
            // setAttributeList(componentSelected.componentAttributeList);
            const savedValues = {
                name: componentSelected.name,
                number: componentSelected.number ? componentSelected.number : '',
                mark: componentSelected.mark ? componentSelected.mark : '',
                description: componentSelected.description ? componentSelected.description : '',
                guid: componentSelected.guid,
                id: componentSelected.id,
                deactivated: componentSelected.deactivated,
                position: componentSelected.position ? componentSelected.position : '',
                geom: componentSelected.geom ? componentSelected.geom : ''

            }
            if(componentSelected.parentComponent) {
                const myParentComponent = {};
                myParentComponent.name = componentSelected.parentComponent;
                myParentComponent.id = componentSelected.parentComponentId;
                myParentComponent.guid = componentSelected.parentComponentGuid;
                myParentComponent.title = componentSelected.parentComponent + ' - ' + componentSelected.realestateobjectName;
                setParentComponentValue(myParentComponent);
            } else {
                setParentComponentValue(null);
            }

            if(myAttributList) {
                myAttributList.forEach((item, index) => {
                    // console.log('Schleife attributList')
                    // console.log(item);
                    // Attribute vom merkmalserver
                    savedValues['attr_' + item.attribute_id] = item.value ? item.value : '';

                    /*if (item.name === 'Bezeichnung') {
                        savedValues['attr_' + item.attribute_id] = componentSelected?.name ? componentSelected.name : componentType?.name;

                    }
                    if (item.name === 'Nummer') {
                        savedValues['attr_' + item.attribute_id] = componentSelected?.number ? componentSelected?.number : '';
                    }
                    if (item.name === 'Kennzeichen') {
                        savedValues['attr_' + item.attribute_id] = componentSelected?.mark ? componentSelected?.mark : '';
                    }
                    if (item.name === 'Ort') {
                        savedValues['attr_' + item.attribute_id] = componentSelected?.position ? componentSelected?.position : '';
                    }
                    if (item.name === 'Beschreibung') {
                        savedValues['attr_' + item.attribute_id] = componentSelected?.description ? componentSelected?.description: '';
                    }*/
                })
            }

            if(componentSelected.componentAttributeList) {
                // setAttributes(componentSelected.componentAttributeList);
                componentSelected.componentAttributeList.forEach((item, index) => {
                    const myAttribute = {};
                    if(item.mmsAttributeId) {
                        // Attribute vom merkmalserver
                        savedValues['attr_' + item.mmsAttributeId] = item.value;

                        /*if (item.label === 'Bezeichnung') {
                            savedValues['attr_' + item.mmsAttributeId] = componentSelected?.name ? componentSelected.name : componentType?.name;

                        }
                        if (item.label === 'Nummer') {
                            savedValues['attr_' + item.mmsAttributeId] = componentSelected?.number ? componentSelected?.number : "";
                        }
                        if (item.label === 'Kennzeichen') {
                            savedValues['attr_' + item.mmsAttributeId] = componentSelected?.mark ? componentSelected?.mark : "";
                        }
                        if (item.label === 'Ort') {
                            savedValues['attr_' + item.mmsAttributeId] = componentSelected?.position ? componentSelected?.position : "";
                        }
                        if (item.label === 'Beschreibung') {
                            savedValues['attr_' + item.mmsAttributeId] = componentSelected?.description ? componentSelected?.description : "";
                        }*/
                        // myAttribute.name = 'attr_' + item.mmsAttributeId;
                        // myAttribute.key = "key_" + item.mmsAttributeId
                    } else {
                        // Kundenspezfische Attribute
                        savedValues['attribute_tenant_' + item.id] = item.value ? item.value : "";
                        // myAttribute.name = 'attribute_tenant_' + item.id;
                        // myAttribute.key = "key_" + item.id
                    }

                });
                // console.log(myAttributeListToDisplay)
                if(!dataObjectSelected || (dataObjectSelected instanceof Array && dataObjectSelected.length === 0)) {
                    DataobjectService.getRealestateObject(token, theRealm, componentSelected.realestateobjectGuid)
                        .then(result => {

                            console.log(result);
                            const item = result.data
                            setRealestateobjectsValues(item)
                            const myItem = {};
                            myItem.name = item.name;
                            myItem.id = item.id;
                            myItem.guid = item.guid;
                            myItem.title = item.number + ' - ' + item.name;
                            myItem.key = item.guid;
                            setRealestateobjectSubValue(myItem);
                        });
                }
                DataComponentService.getAllComponentsToRealestateobject(token, theRealm, componentSelected.realestateobjectGuid)
                    .then(async(result) => {
                        // wir holen alle im RO vorhandenen Bauteile
                        console.log(result.data)
                        await setRealestateComponents(result.data);
                        // console.log(componentFlatList);
                        setComponentFlatListValues(componentFlatList)
                    });


            }

            const myTempComponentType = {};
            myTempComponentType.objecttypeId = componentType.objecttype_id;
            myTempComponentType.name = componentType.name;
            myTempComponentType.mmsCode = componentType.code;
            myTempComponentType.mmsCafmId = componentType.global_id;
            myTempComponentType.mmsGlobalId = componentType.global_id;
            setMyComponentType(myTempComponentType);


            console.log(savedValues);
            setFormValues(savedValues);
            setMessage('');
            if(componentSelected.componentAttachmentList?.length > 0) {
                setFileInfos(componentSelected.componentAttachmentList)
            } else {
                setFileInfos([]);
            }
        } else {
            setName('');
            setNumber('');
            setDescription('');
            setMark('');
            setPosition('');
            setFormValues(null);
            if(componentType?.length == 0) {
                console.log(myAttributList)
                console.log('No Bauteiltyp found: ' + treeItemsState.length);
                    if (!treeItemsState || treeItemsState.length === 0) {
                        TreeComponentTypeService.getTreeData(token, theRealm)
                            .then((res) => {
                                setTreeItemsState(res.data);
                            })
                    }
                // setName('');
                // setMark('');
                // setNumber('');
                // setDescription('');
                // setPosition(dataObjectSelected?.guid);
            }
            setFileInfos([]);
            setMessage('');
            if((dataObjectSelected.realestateobjectType || dataObjectSelected.realestateobjectTypeGuid) && dataObjectSelected.guid) {
                // console.log(dataObjectSelected)
                DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                    .then(async (result) => {
                        // wir holen alle im RO vorhandenen Bauteile
                        // console.log(result.data)
                        await setRealestateComponents(result.data);
                        // console.log(componentFlatList);
                        setComponentFlatListValues(componentFlatList)
                    });
            }
            // setFormValues([]);
        }
        if((dataObjectSelected && dataObjectSelected.guid) && !componentSelected?.realestateobjectGuid) {
            const item = dataObjectSelected;
            setRealestateobjectsValues(item)
                .then(()=> {
                    const myItem = {};
                    myItem.name = item.name;
                    myItem.id = item.id;
                    myItem.guid = item.guid;
                    myItem.title = item.number + ' - ' + item.name;
                    myItem.key = item.guid;
                    setRealestateobjectSubValue(myItem);
                })
        } else if(componentSelected?.realestateobjectGuid){
            DataobjectService.getRealestateObject(token, theRealm, componentSelected.realestateobjectGuid)
                .then(result => {

                    console.log(result);
                    const item = result.data
                    // setRealestateobjectsValues(item)
                    const myItem = {};
                    myItem.name = item.name;
                    myItem.id = item.id;
                    myItem.guid = item.guid;
                    myItem.title = item.number + ' - ' + item.name;
                    myItem.key = item.guid;
                    setRealestateobjectSubValue(myItem);
                });
        }

    }, [componentSelected, componentType, attributeList, realm, token, treeItemsState, setTreeItemsState, setNewRealestateobjectGuid, realestateobjectGuid]);


    // Autocomplete-Liegenschaftlist

    // const liegenschaftFlatList = [];
    const [liegenschaftSubFlatList, setLiegenschaftSubFlatList] = useState([]);
    const lsfl = [];


    function convert3(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        myItem.title = item.number + ' - ' + item.name;
        myItem.key = item.guid;
        lsfl.push(myItem);

    }

    const setRealestateobjectsValues = async(val) => {
        console.log(val)
        if (val && val.guid) {
            const objsParents = await DataobjectService.listrealestateobjectparents(token, theRealm, val.guid);
            const objsSiblings = await DataobjectService.listrealestateobjectsiblings(token, theRealm, val.guid);
            const objsChildren = await DataobjectService.listrealestateobjectchildren(token, theRealm, val.guid);
            // console.log(objsParents?.data)
            // console.log(objsParents?.data instanceof Array);
            let objects = [];
            if(objsParents?.data && objsParents.data instanceof Array) {
                objects = [...objsParents.data]
                // objects.forEach(item => {
                //     convert3(item)
                // });
            }
            if(objsSiblings?.data && objsSiblings.data instanceof Array) {
                objects = [...objects, ...objsSiblings.data];

            }
            // console.log(objects)
            if(objsChildren?.data && objsChildren.data instanceof Array) {
                objects = [...objects, ...objsChildren.data];

            }

            const uniqueIds = [];

            const uniqueObjects = objects.filter(element => {
                const isDuplicate = uniqueIds.includes(element.guid);

                if (!isDuplicate) {
                    uniqueIds.push(element.guid);

                    return true;
                }

                return false;
            });

            // console.log(uniqueObjects);

            uniqueObjects.forEach(item => {
                convert3(item)
            });

            setLiegenschaftSubFlatList(lsfl)
            // console.log(liegenschaftSubFlatList)
            // setTitle(val.title);

        }
        // setRealestateobjectSubValue(val);
        // setRealestateobjectGuid(val.guid);
    }

    const setRealestateobjectSubValues = async(val) => {
        console.log(val);
        setRealestateobjectSubValue(val)
        if(val && val.guid) {
            // console.log(realestateobjectGuid)
            setNewRealestateobjectGuid(val.guid)
            // console.log(realestateobjectGuid)
        }
        // hier müssen wir alle Bauteile des ROs setzen nicht Bauteiltypen
        // await setRealestateComponents(val);
    }



    const setRealestateComponents = async(val) => {
        // console.log(val)
        if(val && val instanceof Array) {
            val.forEach(item => {
                // console.log(item)
                convertComponentList(item)
            });

        }
        // console.log(componentFlatList)
    }

    function convertComponentList(item) {
        const myItem = {};
        let num = item.number ? item.number : '';
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        myItem.title = item.name + ' - ' + item.realestateobjectName;
        myItem.key = item.guid;
        componentFlatList.push(myItem);
    }

    const setComponentTypeValue = async(val) => {
        setValue(val);
        console.log(val)
        if(val) {
            const myTempComponentType = {};
            myTempComponentType.objecttypeId = val.id;
            myTempComponentType.name = val.name;
            myTempComponentType.mmsCode = val.code;
            myTempComponentType.mmsCafmId = val.cafmId;
            myTempComponentType.mmsGlobalId = val.globalId;
            setMyComponentType(myTempComponentType);
            if(userProject && userProject.componenttypes?.length > 0) {
                userProject.componenttypes.forEach(item => {
                    console.log(item.objecttype_id, val.id, item.objecttype_id === val.id)
                        if(item.objecttype_id === val.id) {
                            myAttributList = item.attribute;
                            const myArray = DataComponentService.fillEmptyAttributList(myAttributList)
                            setAttributeList(myArray);
                            console.log(myArray)

                            const initialFormValues = {
                                name: '',
                                number: '',
                                mark: '',
                                description: '',
                                guid: '',
                                id: null,
                                deactivated: 0,
                                position: '',
                                geom: ''
                            }
                            myArray.forEach((item, index) => {
                                initialFormValues[item.name] = ''
                            });
                            setFormValues(initialFormValues);

                        }
                    }
                )
            } else {
                await trackPromise(
                    DataComponentService.getComponentTypeByCode(token, realm, val.code)
                        .then((result) => {
                            console.log(result.data[0]);
                            myAttributList = result.data[0].attribute;
                            const myArray = DataComponentService.fillEmptyAttributList(myAttributList)
                            setAttributeList(myArray);
                            console.log(myArray)

                            const initialFormValues = {
                                name: '',
                                number: '',
                                mark: '',
                                description: '',
                                guid: '',
                                id: null,
                                deactivated: 0,
                                position: '',
                                geom: ''
                            }
                            myArray.forEach((item, index) => {
                                initialFormValues[item.name] = ''
                            });
                            setFormValues(initialFormValues);
                        })
                );
            }
        } else {
            setExpanded([]);
        }

    }


    const deactivateClick = async (event) => {
        // setOpen(false);
        if (await confirmWrapper('Soll das Bauteil wirklich deaktiviert werden?')) {
            handleDeactivateClick(componentSelected, event);
        }
    }

    const activateClick = (event) => {
        // setOpen(false);
        handleActivateClick(componentSelected, event);
    }



    const afterUploadWork = () => {
        UploadService.getFiles(componentSelected.id, token, theRealm, 'component')
            .then(async(files) => {
                console.dir(files)
                setFileInfos(files.data);
                await reloadComponentList();
            })
    }

    const closeWindow = () => {
        // setFormValues([]);
        setName('');
        setNumber('');
        setDescription('');
        setMark('');
        setPosition('');
        setDataComponentFormDialogOpen(false);
    }

        return (
        <>
        <Dialog
            className={classesDialog.dialog}
            open={DataComponentFormDialogOpen}
            onClose={() => closeWindow()}
            TransitionComponent={Transition}
            fullScreen
        >
            <Formik
                enableReinitialize
                initialValues={formValues || initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log("Speichern");
                    console.dir(values);
                    console.log(myComponentType);
                    console.log(componentType);
                    console.log(formValues);
                    console.log(attributeList);

                    const objectAttributes = [];
                    let myName = values.name;
                    let myNumber = values.number;
                    let myMark = values.mark;
                    let myDescription = values.description;
                    let myPosition = values.position;
                    let myGeom = values.geom;
                    Object.entries(values).map(([key, value], i) => {
                        if(key.substring(0, 5) === 'attr_') {
                            const arr = key.split('_');
                            const mmsAttributeId = arr[1];
                            const attr = {};

                            attributeList.map((item) => {
                                // console.log(item);
                                // console.log(item.attribute_id, mmsAttributeId, (item.attribute_id.toString() == mmsAttributeId));
                                if(item.name === key) {
                                    attr.name = item.label;
                                    attr.label = item.label;
                                    attr.attributeType = item.attributeType ? item.attributeType : item.type;
                                    attr.dataType = item.dataType ? item.dataType : item.data_type;
                                    attr.mms_attribut_id = mmsAttributeId;
                                    attr.value = value ? value : "";
                                    objectAttributes.push(attr);
                                }

                            });

                        } else if(key.substring(0, 16) === 'attribute_tenant') {
                            const arr = key.split('_');
                            const attributeId = arr[2];
                            const attr = {}
                            attr.id = attributeId;
                            attr.value = value ? value : "";

                            attributes.forEach((item, index) => {
                                console.log(index + ' # ' + item.name, attributeId)
                                if(item.id.toString() === attributeId.toString()) {
                                    attr.name = item.name;
                                }
                            });
                            objectAttributes.push(attr);
                        }

                    });


                    const data = {
                        name: myName,
                        description: myDescription,
                        // componenttypes: componenttypesToSave,
                        guid: values.guid,
                        id: values.id,
                        mark: myMark,
                        number: myNumber,
                        position: myPosition,
                        geom: myGeom,
                        realestateobjectGuid: newRealestateobjectGuid,
                        parentComponentGuid: parentComponentValue?.guid,
                        componentType: {
                            objecttypeId: myComponentType?.objecttypeId,
                            name: myComponentType?.name,
                            mmsCode: myComponentType?.mmsCode,
                            mmsCafmId: myComponentType?.mmsCafmId,
                            mmsGlobalId: myComponentType?.mmsGlobalId,
                            guid: myComponentType?.guid
                        }
                    };

                    await saveObject(data, objectAttributes);

                    setSubmitting(false);
                    closeWindow();
                }}



            >
                <Form>
                    <ComponentFormAppBar
                        closeWindow={closeWindow}
                        objectId={componentSelected?.id}
                        />

                    <DataComponentList
                        componentSelected={componentSelected}
                        attributeList={attributeList}
                        treeItemsState={treeItemsState}
                        setComponentTypeValue={setComponentTypeValue}
                        value={value}
                        liegenschaftSubFlatList={liegenschaftSubFlatList}
                        setRealestateobjectSubValues={setRealestateobjectSubValues}
                        realestateobjectSubValue={realestateobjectSubValue}
                        componentFlatListValues={componentFlatListValues}
                        setParentComponentValue={setParentComponentValue}
                        parentComponentValue={parentComponentValue}
                        userProject={userProject}
                    />

                    <DataComponentButtonToolbar
                        componentSelected={componentSelected}
                        deactivateClick={deactivateClick}
                        activateClick={activateClick}
                        setUploadDialogOpen={setUploadDialogOpen}
                        theRealm={theRealm}
                        />
                   <AttachmentList
                        fileInfos={fileInfos}
                        setFileInfos={setFileInfos}
                        componentSelected={componentSelected}
                        reloadComponentList={reloadComponentList}
                        theRealm={theRealm}
                    />

                </Form>
            </Formik>

        </Dialog>
    <UploadDialog
        dataObjectSelected={componentSelected}
        setUploadDialogOpen={setUploadDialogOpen}
        uploadDialogOpen={uploadDialogOpen}
        callbackFunc={afterUploadWork}
    />
        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ComponentFormView);

function TreeViewKrams({treeItem}) {
    return (
        <TreeItem nodeId={treeItem.objecttype_id} label={treeItem.code + ' - ' +treeItem.name} >
            {treeItem.children_objecttypes?.map((item) => (
                <TreeViewKrams treeItem={item} />
            ))}
        </TreeItem>
    );
}