import React, { useContext } from "react";
import { TreeClickedContext, ProfilClickedContext, ProjectClickedContext } from "../../Store";

import {makeStyles} from "@mui/styles"
import ReactLoading from "react-loading";

// Ladebildschirm wenn Katalog/Profil lädt oder nach Aktualisierung keine Seite geladen ist

const useStyles = makeStyles({
  LoadingSymbol: {
    marginTop: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  LoadingText: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const LoadingText = () => {
  const [TreeClicked] = useContext(TreeClickedContext);
  const [ProfilClicked] = useContext(ProfilClickedContext);
  const [ProjectClicked] = useContext(ProjectClickedContext);

  if (TreeClicked) {
    return <p>Katalog wird geladen...</p>;
  } else if (ProfilClicked) {
    return <p>Profile werden geladen...</p>;
  } else if (ProjectClicked) {
    return <p>Projekte werden geladen...</p>;
  } else {
    return <p>Menü-Punkt auswählen...</p>;
  }
};

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.LoadingSymbol}>
        <ReactLoading
          type={"spinningBubbles"}
          color={"#D5CD7E"}
          height={100}
          width={100}
        />
      </div>
      <div className={classes.LoadingText}>{LoadingText()}</div>
    </div>
  );
};

export default LoadingScreen;
