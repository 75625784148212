import {connect} from "react-redux";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import React from "react";
import {makeStyles} from "@mui/styles";

// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },

});

function DetailItemInfo({item, openDetailView, theRealm}) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const type = item.administrativeobjectType ? item.administrativeobjectType : item.realestateobjectType;
    return (
        <>
            <div >{item.name}{bull}{item.number}{bull}{item.mark}</div>
            <div >{type}</div>
            <CardActions>
                <Button size="small" onClick={()=>openDetailView(item.guid)}>Details</Button>
            </CardActions>
        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DetailItemInfo);