import React from "react";
import { useField } from "formik";
import {TextField} from "@mui/material";

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field] = useField(props);
    return (
        /*<React.Fragment>
            <label htmlFor={props.id  || props.name}>{label}</label>
            <input className="text-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </React.Fragment>*/
        <TextField
            label={label}
            {...field} {...props}
           />
    );
};

export {MyTextInput};