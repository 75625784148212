import http from "../shared/http-common";

const WuDataService = {
    getWuList(token, theRealm) {

        let formData = new FormData();
        formData.append("realm", theRealm);
        // formData.append("profileId", profileId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wu/list/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getWuDatenList(token, theRealm) {

        let formData = new FormData();
        formData.append("realm", theRealm);
        // formData.append("profileId", profileId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wuDaten/${theRealm}/list/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getWuObjectList(token, theRealm, type) {

        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("type", type);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wuDaten/${theRealm}/objectList/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getWuVeList(token, theRealm, wuId) {

        let formData = new FormData();
        formData.append("realm", theRealm);
        // formData.append("wuId", wuId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wu/${wuId}/list/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importWuData(token, theRealm, baGuid, hauseingangGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("baGuid", baGuid);
        formData.append("hauseingangGuid", hauseingangGuid);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wuDaten/${theRealm}/importdata/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    /*
    importWuData(token, theRealm, wuId, veId) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("veId", veId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wu/${wuId}/importeconmicunit/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }
     */


    importWuAllData(token, theRealm) {
        let formData = new FormData();
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/wuDaten/${theRealm}/importalldata/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importWuParcelData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        return http.post(`/wutwinimport/${theRealm}/importparcel/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importWuLocationData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        return http.post(`/wutwinimport/${theRealm}/importlocation/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuTerritorialdivisionData(token, theRealm, guid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        return http.post(`/wutwinimport/${theRealm}/importterritorialdivision/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuEconomicunitData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        return http.post(`/wutwinimport/${theRealm}/importeconomicunit/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuRentalunitAppartmentData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importRentalunitAppartment/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuRentalunitParkingData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importRentalunitParking/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuRentalunitCommercialData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importRentalunitCommercial/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuRentalunitNotletData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importRentalunitNotlet/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuSiteData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importsite/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuStructureData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importstructure/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },
    importWuBuildingData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importbuilding/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importWuStoreyData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importstorey/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importWuRoomData(token, theRealm, guid=null) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);
        return http.post(`/wutwinimport/${theRealm}/importroom/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }

}

export default WuDataService;