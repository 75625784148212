// Baumdarstellung Komponentenliste
import React, {useContext} from "react";
import {
    RealmContext, TokenContext
} from "../../Store";
import MaterialTable from "material-table";

import {connect} from "react-redux";

import UserService from "../../../services/UserService";

import { header } from'../../../shared/headerStyle';

function WuDbDataList({ dataList, addNew, addStorey, addRoom, importAll, title }) {
    // const classes = useStyles();
    const tableRef = React.createRef();
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    // const [, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    // const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [BimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    /*const handleClickOnRow = (profile) => {
        console.log(profile)
        setBimProfileSelected(profile);
        setBIMDialogOpen(true);
    }*/




    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;

    const getNeedsUpadte = (data) => {
        const blup = new Date(data.last_updated)
        const bla = new Date(data.last_changed)
        return blup > bla ? 'ja' : 'nein'
        // return data.haus_last_updated > data.last_changed;
    }


    return (
        <>
        <MaterialTable
            tableRef={tableRef}
            title={"WU-Daten: " + title}
            columns={[
                /*{ title: 'Liegenschaft', field: 'liegenschaft_nummer' },*/
                { title: 'Name', field: 'name' },
                { title: 'Nummer', field: 'number'},
                { title: 'Kennzeichen', field: 'label'},
                { title: 'importiert', field: 'imported',  lookup: { false: 'nein', true: 'ja' }},

                { title: 'WU geändert am',
                    field: 'last_updated',
                    type: "date",
                    dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' },

                },
                /*{ title: 'importiert am',
                    field: 'date_imported',
                    type: "date",
                    dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' },

                },*/
                { title: 'TWIN geändert am',
                    field: 'last_changed',
                    type: "date",
                    dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' },

                },
                { title: 'Update?', field: 'last_updated', render: (rowData) => {
                        return getNeedsUpadte(rowData)}
                },
            ]}
            data={dataList}
            options={{
                headerStyle: header,
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,

            }}
            actions={[

                {
                    icon: 'input',
                    tooltip: 'Datensatz importieren',
                    onClick: (event,rowData) => addNew(rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_ADMIN'])
                },
                {
                    icon: 'input',
                    tooltip: 'Importieren',
                    isFreeAction: true,
                    onClick: (event) => importAll(token, realm)
                }
                /*{
                    icon: InsertLink,
                    tooltip: 'Geschoss importieren',
                    onClick: (event,rowData) => addStorey(rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'BROKER_ADMIN'])
                },
                {
                    icon: InsertPhoto,
                    tooltip: 'Raum importieren',
                    onClick: (event,rowData) => addRoom(rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'BROKER_ADMIN'])
                }*/
            ]}
            // onRowClick={(event, rowData) => handleClickOnRow(rowData)}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} von {count}',
                    labelRowsSelect: 'Zeilen',
                    labelRowsPerPage: 'Zeilen pro Seite'
                },
                toolbar: {
                    nRowsSelected: '{0} Zeile(n) ausgewählt',
                    searchTooltip: 'Suchen',
                    searchPlaceholder: 'Suchbegriff eingeben'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: 'Keine Einträge gefunden',
                    filterRow: {
                        filterTooltip: 'Filter'
                    },
                    editRow: {
                        deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                        saveTooltip: 'Speichern',
                        cancelTooltip: 'Abbrechen'
                    }
                }
            }}

        />

        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(WuDbDataList);