import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
// import ListDisplay from "./ListDisplay";
// import EditComp from "../TreeComponent/EditComp";
// import {makeStyles} from "@mui/styles";
import ListDisplayAttributes from "./ListDisplayAttributes";
// import {MyCheckbox} from "../../MyCheckbox";

// const useStyles = makeStyles({
//     root: {
//         textAlign: "left",
//         fontFamily: "poppins",
//     },
//     selected: {
//         backgroundColor: "rgba(240,178,175)",
//         fontWeight: "bold",
//     }
// });

function ItemDisplayRight({data, index, checkedItems, labelId, handleToggleRight, handleToggleAttributes, checkedAttributes, currentAttributeId}) {
    // const classes = useStyles();
    // console.log('ItemDisplayRight: ' +checkedAttributes);
    // const handleClick = (index) => {
    //     handleToggleRight(index);
    //     // childObjects = data.children_objecttypes;
    // }
    let showChildren = checkedItems.indexOf(index) > -1;

    console.log(checkedItems);

    return (
        <>
            <ListItem key={index} role="listitem" button onClick={()=>handleToggleRight(index)}>
                <ListItemIcon>
                    <Checkbox
                        key={index}
                        value={index}
                        checked={checkedItems.indexOf(index) > -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${data.code} - ${data.name}`} />
            </ListItem>
            {showChildren ? <ListDisplayAttributes
                items={data.attribute}
                componentTypeId={index}
                handleToggleAttributes={handleToggleAttributes}
                checkedAttributes={checkedAttributes}
                currentAttributeId={currentAttributeId}/> : null}
        </>
    );
}

export default ItemDisplayRight;