import {connect} from "react-redux";
import React from "react";
import ItemDisplayRight from "./ItemDisplayRight";


function ListDisplayRight({items, currentRightObjectTypeId, checkedItems, handleToggleRight, handleToggleAttributes, checkedAttributes, currentAttributeId, theRealm}) {
    console.log('ListDisplayRight: ' +checkedAttributes);
    console.log('ListDisplayRight2: ' + items);
    return (
        <div>
            {items.map((data) => {
                const labelId = `transfer-list-item-${data.objecttype_id}-label`;
                return (
                    <ItemDisplayRight
                        key={data.objecttype_id}
                        data={data}
                        index={data.objecttype_id}
                        labelId={labelId}
                        checkedItems={checkedItems}
                        handleToggleRight={handleToggleRight}
                        handleToggleAttributes={handleToggleAttributes}
                        checkedAttributes={checkedAttributes}
                        currentAttributeId={currentAttributeId}
                        />
                );
            })}
        </div>
    );

}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListDisplayRight);