// Baumdarstellung Komponentenliste
import React, {useContext} from "react";
import {
    BIMDialogOpenContext,
    RealmContext, TokenContext
} from "../../Store";
import MaterialTable from "material-table";
import {confirmWrapper} from "../../../shared/confirm";
import BimProfileService from "../../../services/BimProfileService";
import {connect} from "react-redux";
import BimCreate from "./BimCreate";
import UserService from "../../../services/UserService";


import { header } from'../../../shared/headerStyle';

function ProfilesListView({ profileList, GetProfilesData, setBimProfileSelected, bimProfileSelected, addNew, showBimetaProfiles }) {
    // const classes = useStyles();
    const tableRef = React.createRef();
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    // const [, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [BimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    const handleClickOnRow = (profile) => {
        console.log(profile)
        setBimProfileSelected(profile);
        setBIMDialogOpen(true);
    }


    // Gewähltes Bim-Profil löschen
    const Profile_Delete = async (profile) => {
        try {
            if (await confirmWrapper('Soll das BimProfil wirklich gelöscht werden?')) {
                console.log("Delete");
                console.log(profile);
                // let formData = new FormData();
                // formData.append("realm", realm);
                // formData.append("profileId", BimProfileSelectedData.id);
                // formData.append("guid", BimProfileSelectedData.guid);
                // return http.post(`/bimProfile/delete`, formData,{
                //     headers: {
                //         "Content-Type": "application/json",
                //         "Authorization": "Bearer " + token
                //     }
                // });
                await BimProfileService.deleteBimProfile(token, realm, profile);
                await GetProfilesData();
            } else {
                console.log('Heute wird nicht gelöscht');
            }

        } catch (e) {
            console.log(e);
        }
    };

    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;




    return (
        <>
        <MaterialTable
            tableRef={tableRef}
            title="Profile"
            columns={[
                { title: 'Bezeichnung', field: 'name' },
                { title: 'Beschreibung', field: 'description'},
                { title: 'deaktiviert', field: 'deactivated',  lookup: { 0: 'nein', 1: 'ja' } },
            ]}
            data={profileList}
            options={{
                headerStyle: header,
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,

            }}
            actions={[
                {
                    icon: 'wysiwyg',
                    tooltip: 'Details',
                    onClick: (event, rowData) => handleClickOnRow(rowData)
                },{
                    icon: 'delete',
                    tooltip: 'löschen',
                    onClick: (event, rowData) => Profile_Delete(rowData),
                    disabled: (rowData) => {
                        return (rowData.isGeneralProfile && !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN']))
                         || (!rowData.isGeneralProfile && !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']))
                    }
                },
                {
                    icon: 'add',
                    tooltip: 'Neues Profil anlegen',
                    isFreeAction: true,
                    onClick: (event) => addNew(),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                },
                {
                    icon: 'post_add',
                    tooltip: 'Bimeta-Profile anzeigen',
                    isFreeAction: true,
                    onClick: (event) => showBimetaProfiles(),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_ADMIN'])
                }
            ]}
            onRowClick={(event, rowData) => handleClickOnRow(rowData)}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} von {count}',
                    labelRowsSelect: 'Zeilen',
                    labelRowsPerPage: 'Zeilen pro Seite'
                },
                toolbar: {
                    nRowsSelected: '{0} Zeile(n) ausgewählt',
                    searchTooltip: 'Suchen',
                    searchPlaceholder: 'Suchbegriff eingeben'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: 'Keine Einträge gefunden',
                    filterRow: {
                        filterTooltip: 'Filter'
                    },
                    editRow: {
                        deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                        saveTooltip: 'Speichern',
                        cancelTooltip: 'Abbrechen'
                    }
                }
            }}

        />
            <BimCreate
                bimProfileSelected={bimProfileSelected}
                GetProfilesData={GetProfilesData}

            />
        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ProfilesListView);