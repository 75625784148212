import {connect} from "react-redux";
import {useParams} from "react-router";
import UploadService from "../services/upload-files.service";
import Select from 'react-select';

import React, {useCallback, useEffect, useState} from "react";
// import {Link} from "react-router-dom";

import { withStyles, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import {Formik} from "formik";
import {Form} from "formik";
import SaveIcon from "@mui/icons-material/Save";

import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import {ThreeDots} from 'react-loader-spinner';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
         promiseInProgress &&
        <div
            style={{
                width: "60%",
                    height: "60",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
              }}
        >
          <ThreeDots  color="#2BAD60" height="60" width="60" />
        </div>
    );
}


const optionsImportType = [
    { value: 'administrativeobject', label: 'Verwaltungsobjekte' },
    { value: 'realestateobject', label: 'Immobilienobjekt' },
    { value: 'component', label: 'Bauteile' },
    { value: 'relation', label: 'Relation' },
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    scrollListe: {
        overflowY: "scroll",
        minHeight: 600,
        height:"90vH",
    },
});

const initialValues = {
    importType: '',
    objectType: '',
    // realm: ''
}

function FileMapping({theRealm}) {
    const classes = useStyles();

    const {file} = useParams();
    const [fields, setFields] = useState([]);
    const [header, setHeader] = useState([]);
    const [objectTypes, setObjectTypes] = useState([]);
    const [selectedObjectType, setSelectedObjectType] = useState('');
    const [selectedObjectTypeGuid, setSelectedObjectTypeGuid] = useState('');
    const [selectedObjectTypeName, setSelectedObjectTypeName] = useState('');
    const [selectedImportType, setSelectedImportType] = useState('');
    const [formValues] = useState(null);
    const [mapping, setMapping] = useState([]);

    const token = sessionStorage.getItem('kctoken');

    useEffect(()=>{
        getFile(file, token, theRealm)
    }, [file, token, theRealm]);

    function getFile(filename, token, theRealm) {
        trackPromise(
        UploadService.getFile(filename, token, theRealm)
            .then((response) => {
                // console.dir(response.data);
                setFields(response.data.fields2);
                setHeader(response.data.header);
                setObjectTypes(response.data.objectTypes);
            })
            .catch(() => {
                console.log('Fehler bei CSV-File auslesen')
            }));
    }

    const onChangeImportType = useCallback((selectedOption) => {
        setSelectedImportType(selectedOption.value);
        console.log(`Option selected:`, selectedOption);
        // mapping.push({field: 'importType', value: selectedOption.value});
        // setMapping(mapping);
        if(selectedOption.value === 'relation') {
            setFields([
                {value: 'realestateobjectGuid', label: 'realestateobjectGuid'},
                {value: 'administrativeobjectGuid', label: 'administrativeobjectGuid'}
            ]);
        } else if(selectedOption.value === 'component') {
            trackPromise(
            UploadService.getComponentData(token, theRealm)
                .then((response) => {
                    // console.dir(response.data);
                    setFields(response.data.fields);
                    setObjectTypes(response.data.objectTypes);
                })
                .catch(() => {
                    console.log('Fehler bei CSV-File auslesen')
                }));
        } else {
            trackPromise(
            UploadService.getObjectData(selectedOption.value, token, theRealm)
                .then((response) => {
                    // console.dir(response.data);
                    setFields(response.data.fields2);

                })
                .catch(() => {
                    console.log('Fehler bei CSV-File auslesen')
                }));
        }
    }, [token, theRealm]);

    const onChangeObjectType = selectedOption => {
        setSelectedObjectType(selectedOption.label);
        setSelectedObjectTypeGuid(selectedOption.guid);
        setSelectedObjectTypeName(selectedOption.id);
        console.log(`Option selected:`, selectedOption);
        // mapping.push({field: 'objecttype', value: selectedOption.label});
        // setMapping(mapping);
    };

    const onChangeGedoens = (selectedOption, index, field) => {
        console.log(`Option selected:`, selectedOption);
        console.log(`Option index:`, index);
        console.log(`Option field:`, field);
        let wumms = {field: field, value: selectedOption.value, index: index};
        // mapping.push(wumms);
        mapping[index] = wumms;
        setMapping(mapping);
    }

    return (
        <React.Fragment>
            <Formik
                initialValues = {formValues || initialValues}
                onSubmit={
                    async (values, {setSubmitting}) => {
                        // console.dir(values);
                        // console.dir(mapping);
                        trackPromise(
                        UploadService.import(theRealm, mapping, token, file, selectedImportType, selectedObjectType, selectedObjectTypeGuid, selectedObjectTypeName)
                            .then(response => {
                                // console.dir(response);
                                setMapping([]);
                            }));
                    }
                }
                enableReinitialize
            >
                <Form style={{textAlign: "left"}}>
                    <LoadingIndicator/>
                    <TableContainer component={Paper} className={classes.scrollListe}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableBody>
                            <StyledTableRow ><StyledTableCell colSpan={3}>{file}</StyledTableCell></StyledTableRow>
                            <StyledTableRow><StyledTableCell colSpan={3} className="list-group-flush">
                                <Select
                                    defaultValue={selectedImportType}
                                    onChange={onChangeImportType}
                                    options={optionsImportType}
                                />
                            </StyledTableCell></StyledTableRow>
                            <StyledTableRow><StyledTableCell colSpan={3}>
                                <Select
                                    defaultValue={selectedObjectType}
                                    onChange={onChangeObjectType}
                                    options={objectTypes}
                                />
                            </StyledTableCell></StyledTableRow>

                            {header &&
                            header.map((column, index) => (
                                <StyledTableRow className="list-group-item" key={index}>
                                    <StyledTableCell className="list-group-flush">{index}</StyledTableCell>
                                    <StyledTableCell >{column}</StyledTableCell>
                                    <StyledTableCell >
                                        <Select
                                            onChange={(option) => onChangeGedoens(option, index, column)}
                                        options={fields}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <div>
                            <Button type="submit" startIcon={<SaveIcon />}  color="primary" variant="outlined" size="medium">Import</Button>
                            <Button type="button" href={`/Import/uploadfiles/${theRealm}`} color="primary" variant="outlined" size="medium" >Datei-Liste</Button>
                        </div>
                    </TableContainer>
                </Form>
            </Formik>
        </React.Fragment>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(FileMapping);