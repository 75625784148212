import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ListSelectedComponenttype from "./ListSelectedComponenttype";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 400,
        height: 300,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function ListSelectedComponenttypeBox({selectedComponenttypes, setSelectedComponenttypes, selectedAttributes, setSelectedAttributes, counter, deleteAttribute, deleteComponenttype, theRealm}) {
    const classes = useStyles();
    // console.log(counter);
    const [, setGedoens] = useState(null)
    useEffect(()=>{
        setGedoens(selectedComponenttypes)
    }, [selectedComponenttypes]);

    return (
        <Paper className={classes.paper}>
            <ListSelectedComponenttype
                selectedComponenttypes={selectedComponenttypes}
                setSelectedComponenttypes={setSelectedComponenttypes}
                selectedAttributes={selectedAttributes}
                setSelectedAttributes={setSelectedAttributes}
                counter={counter}
                deleteAttribute={deleteAttribute}
                deleteComponenttype={deleteComponenttype}
                theRealm={theRealm}
            />
        </Paper>
    );

}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListSelectedComponenttypeBox);