import {Form} from "formik";
import {MyTextInput} from "./MyTextInput";
import {MyCheckbox} from "./MyCheckbox";
// import {Link} from "react-router-dom";
import React from "react";

import SaveIcon from '@mui/icons-material/Save';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {Button} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        }

    },
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
}));

const MyUserForm = ({roles, realmId, groups}) => {
    const classes = useStyles();
    console.log(realmId)
    console.log(groups);
    return (
        <Form className={classes.root} style={{textAlign: "left", backgroundColor: "#ffffff"}}>

            <MyTextInput
                label="Benutzername"
                name="username"
                type="text"
                placeholder="Benutzernamen eingeben"
                required
                margin="dense"
            />
            <MyTextInput
                label="E-Mail"
                name="email"
                type="email"
                placeholder="E-Mail-Adresse eingeben"
                required
                margin="dense"
            />
            <br/>
            <MyTextInput
                label="Vorname"
                name="firstname"
                type="text"
                placeholder="Vornamen eingeben"
                margin="dense"
            />
            <MyTextInput
                label="Nachname"
                name="lastname"
                type="text"
                placeholder="Nachnamen eingeben"
                margin="dense"
            />
            <br/>
            <MyCheckbox
                name="enableUser"
                margin="dense"
             >aktivieren</MyCheckbox>
            <br/>
            <MyTextInput
                label="Passwort"
                name="password"
                type="password"
                placeholder="Passwort eingeben"
                margin="dense"
            />
            <MyTextInput
                label="Passwort bestätigen"
                name="confirm_password"
                type="password"
                placeholder="Passwort erneut eingeben"
                margin="dense"
            />
            <br/>


            <br/>
                <Button type="submit" startIcon={<SaveIcon />}  color="primary" variant="outlined" size="medium">Speichern</Button>

                <Link to={`/MandantUserList/${realmId}`}><Button type="button" color="primary" variant="outlined" size="medium" >Benutzer-Liste</Button></Link>
        </Form>
    );
}

export {MyUserForm};