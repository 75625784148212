import React, { useContext } from "react";
import { TreeItemsFromData } from "./CatalogItems";
import {
    SearchValueContext,
    TreeSelectedContext,
} from "../../../Store";

// Filterung der Daten wenn Suchfeld verwendet wird

export const SearchList = (treeItems) => {
    const [SearchValue] = useContext(SearchValueContext);
    const [TreeSelected] = useContext(TreeSelectedContext);
    // const [TreeParent, setTreeParent] = useContext(TreeParentContext);

    // Kind-Elemente
    var childs = [];
    // Array mit Suchergebnissen
    var searchResult = [];
    // Kombiniertes Kind-Elemente-Array
    var filteredItems = [];
    // Eltern-Elemente
    var pa = [];

    // Wenn Kind-Elemente vorhanden, dann füge diese children hinzu
    const check = (i) => {
        for (let m = 0; m < i.length; m++) {
            let x = i[m].children_objecttypes;
            if (x) {
                childs.indexOf(i[m]) === -1 ? childs.push(i[m]) : childs.push();
                check(i[m].children_objecttypes);
            }
        }

        // Arrays zusammenführen
        filteredItems = [].concat.apply([], childs);

        // Suchergebnisse in serachval pushen wenn dieses dort nicht bereits vorkommt
        for (let n = 0; n < filteredItems.length; n++) {
            let x = filteredItems[n].name.toLowerCase();
            let w = filteredItems[n].code;
            let y = filteredItems[n].global_id;
            let z = filteredItems[n].objecttype_id;
            if (x) {
                if (x.includes(SearchValue.toLowerCase()) || w.includes(SearchValue)) {
                    searchResult.indexOf(filteredItems[n]) === -1
                        ? searchResult.push(filteredItems[n])
                        : searchResult.push();
                }
                // Elternkomponente des ausgewählten Bauteils darzustellen (als Ersatz für vollständigen Baum)
                if (
                    y === TreeSelected.parent_global_id &&
                    z === TreeSelected.parent_objecttype_id
                ) {
                    pa.indexOf(filteredItems[n]) === -1
                        ? pa.push(filteredItems[n])
                        : pa.push();
                }
            }
        }
    };

    // Wenn Suche einen Wert enthält, gib gefilterte Ergebnisse zurück & setzte TreeParent-Variable
    if (SearchValue) {
        return (
            <div>
                <div>
                    {check(treeItems)}
                    {TreeItemsFromData(searchResult, pa)}
                </div>
            </div>
        );
        // wenn nicht, gib vollständigen Baum zurück
    } else {
        return <div>{TreeItemsFromData(treeItems)}</div>;
    }
};
