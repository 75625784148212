import React, {useEffect, useState, useCallback, useContext} from "react";
import http from "../../../shared/http-common";
import {Box, TableHead, Card, CardContent, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";

import {TokenContext} from "../../Store";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";


function UserProjects({username, userId, realm}) {

    const [token] = useContext(TokenContext);
    const [projects, setProjects] = useState([])

    const getUserProjects = useCallback(async () => {

            try {
                let formData = new FormData();
                formData.append("realm", realm);
                formData.append('username', username)
                formData.append('userId', userId)

                return http.post(`/project/userprojectlist`, formData,{
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });


            } catch (e) {
                console.log(e);
            }

    }, [realm, token, username, userId]);

    const saveProjectUserRight = (projectId, projectGuid, tenantId, userId, right, val) => {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append('projectId', projectId)
            formData.append('projectGuid', projectGuid)
            formData.append('tenantId', tenantId)
            formData.append('userId', userId)
            formData.append('username', username)
            formData.append('right', right)
            formData.append('value', val)

            return http.post(`/project/saveprojectright`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });


        } catch (e) {
            console.log(e);
        }

    }


    useEffect(()=> {
        trackPromise (
            getUserProjects()
                .then((result) => {
                    console.log(result);
                    setProjects(result.data);
                })
        )
    }, []);

    const checkUserProjectRight = async (projectId, projectGuid, tenantId, userId, right, val) => {
        saveProjectUserRight(projectId, projectGuid, tenantId, userId, right, val)
            .then((res)=>{
                console.log('checkUserProjectRight:')
                console.dir(res);
                getUserProjects()
                    .then((result) => {
                        console.log(result);
                        setProjects(result.data);
                    });
            })
    }


    return (
        <div >
            <LoadingIndicator />
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Projekte
                        </Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Projekt</TableCell>
                                    <TableCell>Leserecht</TableCell>
                                    <TableCell>Schreibrecht</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projects.map(function (proj, index) {

                                    return <TableRow key={proj.id}>
                                        <TableCell>{proj.name} </TableCell>
                                        <TableCell>{proj.read ? (<CheckBox onClick={() => checkUserProjectRight(proj.id, proj.guid, proj.tenantId, userId, 'read', 0)} style={{cursor: "Pointer"}}/>) : (<CheckBoxOutlineBlankOutlined onClick={() => checkUserProjectRight(proj.id, proj.guid, proj.tenantId, userId, 'read', 1)} style={{cursor: "Pointer"}}/>)}</TableCell>
                                        <TableCell>{proj.write ? (<CheckBox onClick={() => checkUserProjectRight(proj.id, proj.guid, proj.tenantId, userId, 'write', 0)} style={{cursor: "Pointer"}}/>) : (<CheckBoxOutlineBlankOutlined onClick={() => checkUserProjectRight(proj.id, proj.guid, proj.tenantId, userId, 'write', 1)} style={{cursor: "Pointer"}}/>)}</TableCell>
                                        {/*<TableCell align="right"><Button onClick={() => alert(role)} size="small"
                                                                         className={classes.link}
                                                                         color="primary">hinzufügen</Button></TableCell>*/}
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default UserProjects;
