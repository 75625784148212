import React, { useContext, useState, useCallback} from "react";
import {connect} from "react-redux";
import Button from "@mui/material/Button";

import {TokenContext} from "../../../components/Store";
import Toolbar from "@mui/material/Toolbar";


function DataComponentButtonToolbar({componentSelected, deactivateClick, activateClick, setUploadDialogOpen, theRealm}) {
    const [token] = useContext(TokenContext);

    return (
        <>
            <Toolbar>

                {/*<Button
                            type="submit"
                            color="inherit"
                        >
                            Speichern
                        </Button>*/}
                {componentSelected && componentSelected.id ? (
                    componentSelected?.deactivated ? (
                        <>
                            <Button
                                onClick={(event)=>activateClick(event)}
                                color="inherit"
                                variant="outlined"
                                size="medium"
                                key="20"
                            >
                                Aktivieren
                            </Button>
                            <Button
                                onClick={()=>setUploadDialogOpen(true)}
                                color="inherit"
                                variant="outlined"
                                size="medium"
                                key="21"
                            >Upload</Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={(event)=>deactivateClick(event)}
                                color="inherit"
                                variant="outlined"
                                size="medium"
                                key="20"
                            >
                                Deaktivieren
                            </Button>
                            <Button
                                onClick={()=>setUploadDialogOpen(true)}
                                color="inherit"
                                variant="outlined"
                                size="medium"
                                key="21"
                            >Upload</Button>
                        </>
                    )
                ):null}

            </Toolbar>
        </>
    )
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataComponentButtonToolbar);