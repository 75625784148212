import React, {useContext, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Link, useNavigate} from "react-router-dom";

import { makeStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import MandantUserAdminService from "../../../services/MandantUserAdminService";
import {TokenContext} from "../../Store";
import Button from "@mui/material/Button";
import { EXTERN_USERADMINISTRATION_URL } from "../../../modules/config";
import MaterialTable from "material-table";

import { header } from'../../../shared/headerStyle';


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

function MandantUserList({theRealm}) {

    // const classes = useStyles();
    const [token] = useContext(TokenContext);
    // const [realm] = useContext(RealmContext);
    const [users, setUsers] = useState([]);

    const [filtering] = useState(false);
    const [isLoading] = useState(false);
    const tableRef = React.createRef();
    let navigate  = useNavigate();

    useEffect(() => {
        MandantUserAdminService.getKeycloakUser(token, theRealm)
            .then(result => {
                // console.log(result.data);
                if(result?.data && result?.data instanceof Array) {
                    setUsers(result.data);
                }
            });
    }, [theRealm, token]);

    const handleEditClick = async (event, rowData) => {
        navigate(`/mandantuseredit/${theRealm}/${rowData.id}`);
    }

    const getGroupString = (data) => {
        const gruppen = []
        if(data.groups?.length > 0) {
            const gr = data.groups;
            for(let i=0; i<gr.length; i++) {
                gruppen.push(gr[i].name);
            }
        }
        return gruppen.join(', ');
    }
    const tableHeight =(window.innerHeight - 66 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <TableContainer component={Paper}>
            <div className="row">
                <div className="col-sm-12">
                    <h3>{theRealm}</h3>
                    <div>
                        <Link to={`/mandantusercreate/${theRealm}`}><Button type="button" color="primary" variant="outlined" size="medium" >Benutzer hinzufügen</Button></Link>
                        <a href={`${EXTERN_USERADMINISTRATION_URL}/${theRealm}`} target="extUV"><Button type="button" color="primary" variant="outlined" size="medium" >externe Benutzerverwaltung</Button></a>
                    </div>

                </div>
            </div>

            <MaterialTable
                tableRef={tableRef}
                title="Benutzer"
                columns={[
                    { title: 'Username', field: 'username' },
                    { title: 'Vorname', field: 'firstName'},
                    { title: 'Nachname', field: 'lastName' },
                    { title: 'E-Mail', field: 'email'},
                    { title: 'Gruppen', field: 'type', render: (rowData) => {
                            return getGroupString(rowData)}
                    }

                ]}
                data={users}

                onRowClick={(event, rowData) => handleEditClick(event, rowData)}
                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : null,
                        fontSize: "0.8em"
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    filtering,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}
                actions={[

                    {
                        icon: 'edit',
                        tooltip: 'Editieren',
                        onClick: (event, rowData) => handleEditClick(event, rowData)
                    },

                ]}
                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}

            />
        </TableContainer>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(MandantUserList);