import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    TokenContext
} from "../../Store";

import { makeStyles } from "@mui/styles";


import {connect} from "react-redux";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import UserService from "../../../services/UserService";
import WuDataService from "../../../services/WuDataService";
import WuDbDataList from "./WuDbDataList";
import {List, ListItem} from "@mui/material";
import ObjecttypeSelectlist from "../../../shared/ObjecttypeSelectlist";


// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        /*width: "95%",
        height: "90%",
        margin: "15px",
        padding: "20px",
        float: "left",
        border: "2px solid #B4AF03",
        borderRadius: "25px",*/
        width: "100%",
        height: "100%",
        float: "left",
    },
    btListe: {
        /*width: "20vw",
        maxHeight: "65vh",*/
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

});


function WuDbData({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [dataList , setDataList] = useState([]);

    const classes = useStyles();
    // console.dir(bimProf);

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);


    const GetWuData = useCallback(async (type) => {
        try {
            await trackPromise(
               /* WuDataService.getWuDatenList(token, theRealm)
                    .then((res) => {
                        setDataList(res.data);
                    })*/
                WuDataService.getWuObjectList(token, theRealm, type)
                .then((res) => {
                    setDataList(res.data);
                })
            )
            /*
            WuDataService.getWuList(token, theRealm)
                .then((res) => {
                    console.log(res.data);
                    if(res.data) {
                        const tempArray = res.data;
                        tempArray.forEach(async (item) => {
                            if(item.Kürzel === 'Bille') {
                                console.log(item.ID)
                                await trackPromise(
                                WuDataService.getWuVeList(token, theRealm, item.ID)
                                    .then(result => {
                                        console.log(result.data)
                                        setDataList(result.data);
                                    })
                                )
                            }
                        })

                    }
                })
            */
        } catch (e) {
            console.log(e);
        }
    }, []);


    const addNew = async (rowData) => {
        try {
            console.log(rowData);
            // const data = [];
            // data.push(rowData)
            if(selected === 'parcel') {
                await trackPromise(
                    WuDataService.importWuParcelData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'buildingentrance') {
                await trackPromise(
                    WuDataService.importWuLocationData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'territorialdivision') {
                await trackPromise(
                    WuDataService.importWuTerritorialdivisionData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'economicunit') {
                await trackPromise(
                    WuDataService.importWuEconomicunitData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'rentalunit') {
                await trackPromise(
                    WuDataService.importWuRentalunitAppartmentData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'commercialunit') {
                await trackPromise(
                    WuDataService.importWuRentalunitCommercialData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'parkingspace') {
                await trackPromise(
                    WuDataService.importWuRentalunitParkingData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'rentalunitnotlet') {
                await trackPromise(
                    WuDataService.importWuRentalunitNotletData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'site') {
                await trackPromise(
                    WuDataService.importWuSiteData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'structure') {
                await trackPromise(
                    WuDataService.importWuStructureData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'building') {
                await trackPromise(
                    WuDataService.importWuBuildingData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'storey') {
                await trackPromise(
                    WuDataService.importWuStoreyData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'room') {
                await trackPromise(
                    WuDataService.importWuRoomData(token, theRealm, rowData.guid)
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            }

        } catch (e) {
            console.log(e);
        }
    }

    const addStorey = async (rowData) => {
        try {
            console.log(rowData)

            await trackPromise(
                WuDataService.importWuStoreyData(token, theRealm, rowData.wuid, rowData.veid)
                    .then((res) => {
                        console.log(res.data);
                        if(res.data) {

                            // setBimetaProfiles(res.data);
                        }

                    })
            )

        } catch (e) {
            console.log(e);
        }
    }

    const addRoom = async (rowData) => {
        try {
            console.log(rowData)

            await trackPromise(
                WuDataService.importWuRoomData(token, theRealm, rowData.wuid, rowData.veid)
                    .then((res) => {
                        console.log(res.data);
                        if(res.data) {

                            // setBimetaProfiles(res.data);
                        }

                    })
            )

        } catch (e) {
            console.log(e);
        }
    }

    const importAll = async () => {
        try {
            // console.log()
            if(selected === 'parcel') {
                await trackPromise(
                    WuDataService.importWuParcelData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'buildingentrance') {
                await trackPromise(
                    WuDataService.importWuLocationData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'territorialdivision') {
                await trackPromise(
                    WuDataService.importWuTerritorialdivisionData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'economicunit') {
                await trackPromise(
                    WuDataService.importWuEconomicunitData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'rentalunit') {
                await trackPromise(
                    WuDataService.importWuRentalunitAppartmentData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'commercialunit') {
                await trackPromise(
                    WuDataService.importWuRentalunitCommercialData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'parkingspace') {
                await trackPromise(
                    WuDataService.importWuRentalunitParkingData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'rentalunitnotlet') {
                await trackPromise(
                    WuDataService.importWuRentalunitNotletData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'site') {
                await trackPromise(
                    WuDataService.importWuSiteData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'structure') {
                await trackPromise(
                    WuDataService.importWuStructureData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'building') {
                await trackPromise(
                    WuDataService.importWuBuildingData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'storey') {
                await trackPromise(
                    WuDataService.importWuStoreyData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            } else if(selected === 'room') {
                await trackPromise(
                    WuDataService.importWuRoomData(token, theRealm, '')
                        .then((res) => {
                            console.log(res.data);
                        })
                )
            }

        } catch (e) {
            console.log(e);
        }
    }


    const options = [
        {value: '', text: '-- Bitte wählen Sie einen Objekttypen --'},
        {value: 'territorialdivision', text: 'Gebietsgliederung'},
        {value: 'site', text: 'Liegenschaft'},
        {value: 'economicunit', text: 'Wirtschaftseinheit'},
        {value: 'parcel', text: 'Flurstück'},
        {value: 'structure', text: 'Bauwerk'},
        {value: 'building', text: 'Gebäude'},
        {value: 'storey', text: 'Geschoß'},
        {value: 'buildingentrance', text: 'Hauseingang'},
        {value: 'rentalunit', text: 'Mieteinheit'},
        {value: 'commercialunit', text: 'Gewerbeeinheit'},
        {value: 'room', text: 'Raum'},
        {value: 'parkingspace', text: 'Stellplatz'},
        {value: 'rentalunitnotlet', text: 'unvermietet'},
    ];

    const [selected, setSelected] = useState(options[0].value);
    const [title, setTitle] = useState('Wirtschaftseinheiten');
    const [fieldType, setFieldType] = useState('administrativeobjectType');
    const [bauteilAction, setBauteilAction] = useState('');

    let hideDeactivatedColumn = true


    const handleChange = async(event) => {
        await putChangesInMotion(event.target.value);
    }

    const putChangesInMotion = (val) => {
        console.log(val);
        setSelected(val);
        let objecttype, type
        switch (val) {
            case 'site':
                objecttype = 'realestateobject';
                type = 'site';
                setTitle('Liegenschaften');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;
            case 'structure':
                objecttype = 'realestateobject';
                type = 'structure';
                setTitle('Bauwerke');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;
            case 'building':
                objecttype = 'realestateobject';
                type = 'building';
                setTitle('Gebäude');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;
            case 'room':
                objecttype = 'realestateobject';
                type = 'room';
                setTitle('Räume');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;
            case 'storey':
                objecttype = 'realestateobject';
                type = 'storey';
                setTitle('Etagen');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;
            /*case 'playground':
                objecttype = 'realestateobject';
                type = 'playground';
                setTitle('Spielplätze');
                setFieldType('realestateobjectType');
                // setBauteilAction(bauteilActionRO);
                break;*/
            case 'economicunit':
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschafteinheiten')
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            /*case 'apartment':
                objecttype = 'administrativeobject';
                type = 'apartment';
                setTitle('Wohnungen');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;*/
            case 'rentalunit':
                objecttype = 'administrativeobject';
                type = 'rentalunit';
                setTitle('Mieteinheiten');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'commercialunit':
                objecttype = 'administrativeobject';
                type = 'commercialunit';
                setTitle('Gewerbeeinheiten');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'parkingspace':
                objecttype = 'administrativeobject';
                type = 'parkingspace';
                setTitle('Stellplatzanlagen');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'location':
                objecttype = 'administrativeobject';
                type = 'location';
                setTitle('Belegenheiten');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'buildingentrance':
                objecttype = 'administrativeobject';
                type = 'buildingentrance';
                setTitle('Hauseingänge');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'parcel':
                objecttype = 'administrativeobject';
                type = 'parcel';
                setTitle('Flurstücke');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'territorialdivision':
                objecttype = 'administrativeobject';
                type = 'territorialdivision';
                setTitle('Gebietsgliederungen');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            /*case 'washhouse':
                objecttype = 'administrativeobject';
                type = 'washhouse';
                setTitle('Waschhäuser');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;*/
            case 'parcel':
                objecttype = 'administrativeobject';
                type = 'parcel';
                setTitle('Flurstück');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            case 'rentalunitnotlet':
                objecttype = 'administrativeobject';
                type = 'rentalunitnotlet';
                setTitle('unvermietet');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
                break;
            default:
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschaftseinheiten');
                setFieldType('administrativeobjectType');
                // setBauteilAction(bauteilActionAO);
        }


        return GetWuData(type);
    };


    return (
        <div>
            <LoadingIndicator />
            <div className={classes.katalogView}>
                <List style={{backgroundColor: "#FFFFFF"}}>
                    <ListItem>
                        <ObjecttypeSelectlist
                            options={options}
                            selected={selected}
                            handleChange={handleChange}
                        />
                    </ListItem>
                </List>
                <div className={classes.btListe}>
                    <WuDbDataList
                        dataList={dataList}
                        addNew={addNew}
                        addStorey={addStorey}
                        addRoom={addRoom}
                        importAll={importAll}
                        title={title}
                    />
                </div>

            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(WuDbData);