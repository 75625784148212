import http from "../shared/http-common";
import UserService from "./UserService";

const ProjectService = {
    getKeycloakUser(token, realm) {

        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/keycloakUser/getUserToRealm/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getProjectUserRights(token, realm, projectId, guid) {

        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("projectId", projectId);
        formData.append("guid", guid);

        return http.post(`/project/getUserRights/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveProjectObject(token, realm, projectId, projectGuid, objectId, objectGuid, objectType) {

        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("projectId", projectId);
        formData.append("projectGuid", projectGuid);
        formData.append("objectId", objectId);
        formData.append("objectGuid", objectGuid);
        formData.append("objectType", objectType);

        return http.post(`/project/saveobject/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteProjectObject(token, realm,projectId, projectGuid, objectGuid) {

        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("projectId", projectId);
        formData.append("projectGuid", projectGuid);
        formData.append("objectGuid", objectGuid);

        return http.post(`/project/deleteobject/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listprojectobjects(token, realm,projectId, projectGuid) {

        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("projectId", projectId);
        formData.append("projectGuid", projectGuid);

        return http.post(`/project/listprojectobjects/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    async listmyprojects(token, realm) {

        console.log('listmyprojects')
        try {
            const c = await UserService.userInfo();


            const username = UserService.getUsername();
            const userId = c.sub;
            console.log(userId);
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append('username', username)
            formData.append('userId', userId)

            return http.post(`/project/userprojectlist`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });


        } catch (e) {
            console.log(e);
        }

    },

    async listUserProjectInfoObjects(token, realm) {
        try {
            const c = await UserService.userInfo();


            const username = UserService.getUsername();
            const userId = c.sub;
            console.log(userId);
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append('username', username)
            formData.append('userId', userId)

            return http.post(`/project/listUserProjectInfoObjects`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });


        } catch (e) {
            console.log(e);
        }
    }

}

export default ProjectService;