import React, {useContext, useEffect, useState} from "react";
import {
    TreeSelectedContext,
    TreeSelectedItemContext,
    AttrSelectedContext,
    UpdateCompDialogOpenContext,
    DeleteCompDialogOpenContext,
    DialogOpenContext,
    AttrDialogOpenContext,
    AttrIsSelectedContext,
    SiteColorContext, TokenContext, RealmContext,
} from "../../Store";

// Attr-Merkmale zeigen
import EditAttr from "./EditAttr";
// Bauteil anlegen Dialog
import CreateCompDialog from "./Dialog/CreateComp/CreateSubCompDialog";
// Bauteil updaten Dialog
import UpdateCompDialog from "./Dialog/UpdateComp/UpdateCompDialog";
// Bauteil löschen Dialog
import DeleteCompDialog from "./Dialog/DeleteComp/DeleteCompDialog";
// Attribut anlegen Dialog
import CreateAttrDialog from "./Dialog/CreateAttr/CreateAttrDialog";
// Attribut updaten Dialog
import UpdateAttrDialog from "./Dialog/UpdateAttr/UpdateAttrDialog";
// Attribut löschen Dialog
import DeleteAttrDialog from "./Dialog/DeleteAttr/DeleteAttrDialog";

import {makeStyles} from "@mui/styles"
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import TreeComponentTypeService from "../../../services/TreeComponentTypeService";
import RenderOnRole from "../../RenderOnRole";
import http from "../../../shared/http-common";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Bauteil-Informationen darstellen, inkl. aller Dialoge zum Bearbeiten

const useStyles = makeStyles({
    root: {
        textAlign: "left",
    },
    buttonLight: {
        float: "right",
        fontWeight: "bold",
        marginRight: 20,
    },
    buttonDark: {
        float: "right",
        fontWeight: "bold",
        marginRight: 20,
        color: "#e5e5e5",
    },
    create: {
        float: "right",
        textAlign: "right",
    },
    btEdit: {
        width: "70%",
        height: "90%",
        margin: "15px",
        padding: "20px",
        float: "left",
        border: "1px solid #D5CD7E",
        borderRadius: "25px",
    },
    btEditBtn: {
        float: "right",
        textAlign: "right",
        flexGrow: "1",
    },
    btInfo: {
        display: "flex",
    },
    btDesc: {
        flexGrow: "7",
    },
    btUeber: {
        float: "left",
        textAlign: "left",
        fontSize: "18px",
        fontWeight: "bold",
        width: "100%",
    },
    btDetail: {
        float: "left",
        textAlign: "left",
        padding: "5px",
        width: "100%",
    },
    attrView: {
        width: "1 rem",
        minHeight: "fit-content",
        maxHeight: "80vh",
        display: "flex",
        alignItems: "left",
        padding: "5px",
        border: "1px solid #D5CD7E",
        borderRadius: "25px",
    },
    attrListe: {
        width: "20vw",
        maxHeight: "65vh",
        overflowY: "scroll",
        float: "left",
    },
    headlineBearbeiten: {
        textAlign: "left",
        fontWeight: "bold",
        margin: "5px",
    },
    lightIcons: {
        color: "grey",
    },
    darkIcons: {
        color: "#e5e5e5",
    },
});

// Attribute als TreeItem definieren
const TreeItemsFromDataAttr = (arrayItems) => {
    const [, setAttrSelected] = useContext(AttrSelectedContext);
    const [, setAttrIsSelected] = useContext(AttrIsSelectedContext);

    return arrayItems.map((treeItemData) => {
        return (
            <div>
                <TreeItem
                    key={treeItemData.name}
                    nodeId={treeItemData.name}
                    label={treeItemData.name}
                    onLabelClick={() => {
                        setAttrSelected(treeItemData);
                        setAttrIsSelected(true);
                    }}
                />
            </div>
        );
    });
};

// Baumdarstellung Attributliste
function DataTreeViewAttr({ arrayItems }) {
    const classes = useStyles();
    console.log(arrayItems);
    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            key={1}
        >
            {TreeItemsFromDataAttr(arrayItems)}
        </TreeView>
    );
}

export default function EditComp() {
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [TreeSelected] = useContext(TreeSelectedContext);
    const [TreeSelectedItem, setTreeSelectedItem] = useContext(TreeSelectedItemContext);
    const [, setUpdateCompDialogOpen] = useContext(UpdateCompDialogOpenContext);
    const [, setDeleteCompDialogOpen] = useContext(DeleteCompDialogOpenContext);
    const [, setDialogOpen] = useContext(DialogOpenContext);
    const [, setAttrDialogOpen] = useContext(AttrDialogOpenContext);
    const [AttrIsSelected] = useContext(AttrIsSelectedContext);
    const [SiteColor] = useContext(SiteColorContext);
    const classes = useStyles();

    const [message, setMessage] = useState("");

    useEffect(() => {

       setMessage('')

    }, []);

    const reloadTreeItem = () => {
        if(TreeSelected.code) {
            return TreeComponentTypeService.getTreeComponent(token, realm, TreeSelected.code)
                .then((result) => {
                    // setTreeSelected(result.data);
                    if(result?.data instanceof Array && result.data.length > 0) {
                        setTreeSelectedItem(result.data[0].attribute);
                        console.log(result.data)
                        return result.data
                    }
                });
        }
    }

    const downloadTemplate = async () => {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("guid", TreeSelected.global_id);

        const fetchData = await http.post("/componentType/exportComponenttypeTemplate", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                responseType: 'blob'
            }
        );

        const url = window.URL.createObjectURL(new Blob([fetchData.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', TreeSelected.name + '.xlsx'); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast('Die Template-Datei wurde in Ihrem Download-Ordner gespeichert.')
    }

    return (
        <div className={classes.btEdit}>
            <div className={classes.btInfo}>
                <div className={classes.btDesc}>
                    <div className={classes.btUeber}>{TreeSelected.name}</div>
                    <div className={classes.btDetail}>
                        <p>
                            <b>Beschreibung:</b> {TreeSelected.description}
                        </p>
                        <p>
                            <b>Bauteil-Code:</b> {TreeSelected.code}
                        </p>
                    </div>
                </div>
                <RenderOnRole  roles={['MAP_ADMIN', 'ADMIN_MAP', 'TWIN_DATA_ADMIN']}>
                {TreeSelected.twin_only ? SiteColor ? (
                    <div className={classes.btEditBtn}>
                        <IconButton
                            className={classes.lightIcons}
                            onClick={() => setUpdateCompDialogOpen(true)}
                        >
                            <CreateIcon />
                        </IconButton>
                        <IconButton
                            className={classes.lightIcons}
                            onClick={() => {
                                setDeleteCompDialogOpen(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ) : (
                    <div className={classes.btEditBtn}>
                        <IconButton
                            className={classes.darkIcons}
                            onClick={() => setUpdateCompDialogOpen(true)}
                        >
                            <CreateIcon />
                        </IconButton>
                        <IconButton
                            className={classes.darkIcons}
                            onClick={() => {
                                setDeleteCompDialogOpen(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ) : null }
                </RenderOnRole>
            </div>
            <div className={classes.attrView}>
                <div className={classes.attrListe}>
                    <div className={classes.headlineBearbeiten}>Merkmale:</div>
                    <DataTreeViewAttr arrayItems={TreeSelectedItem} />
                    <div>
                        <RenderOnRole  roles={['MAP_ADMIN', 'ADMIN_MAP', 'TWIN_DATA_ADMIN']}>
                            <div>
                                <button onClick={() => setDialogOpen(true)} hidden>
                                    Komponente hinzufügen
                                </button>
                                {SiteColor ? (
                                    <Button
                                        className={classes.buttonLight}
                                        onClick={() => setAttrDialogOpen(true)}
                                    >
                                        <AddIcon /> Neu
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.buttonDark}
                                        onClick={() => setAttrDialogOpen(true)}
                                    >
                                        <AddIcon /> Neu
                                    </Button>
                                )}
                            </div>
                        </RenderOnRole>
                    </div>
                </div>
                {/*Attribut-Bearbeiten nur darstellen, wenn Attr ausgewählt ist*/}
                {AttrIsSelected ? <EditAttr /> : null}
                {/*Dialog-Implementierung, Darstellung, wenn entsprechende "Open"-Variable verändert wird*/}
                <CreateCompDialog />
                <UpdateCompDialog />
                <DeleteCompDialog />
                <CreateAttrDialog reloadTreeItem={reloadTreeItem}/>
                <UpdateAttrDialog reloadTreeItem={reloadTreeItem}/>
                <DeleteAttrDialog reloadTreeItem={reloadTreeItem}/>
            </div>
            <div style={{textAlign: "left", marginTop:"10px"}}>
                <Button type="button" color="primary" variant="outlined" size="large" onClick={()=>downloadTemplate()}>Export Bauteiltyp-Template</Button>
            </div>
            <ToastContainer />
        </div>

    );
}
