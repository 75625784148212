import React, { useState, useContext } from "react";

import {
  UpdateCompDialogOpenContext,
  TreeItemsStateContext,
  TokenContext,
  RealmContext,
  TreeSelectedContext,
  BTNameContext,
  BTCodeContext,
  BTDescContext,
} from "../../../../Store";

// import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import {trackPromise} from "react-promise-tracker";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Fenster zum Bearbeiten des ausgewählten Bauteils

/*const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "70%",
    marginRight: 0,
  },
  dialogAlert: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "30%",
  },
  appBar: {
    position: "relative",
    background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));*/

// Transition legt fest, wie das Fenster erscheint
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function UpdateCompDialog() {
  const classesDialog = useStylesDialog();
  const [UpdateCompDialogOpen, setUpdateCompDialogOpen] = useContext(
    UpdateCompDialogOpenContext
  );
  const [token] = useContext(TokenContext);
  const [realm] = useContext(RealmContext);
  const [TreeSelected, setTreeSelected] = useContext(TreeSelectedContext);
  const [, setTreeItemsState] = useContext(TreeItemsStateContext);
  const [BTName, setBTName] = useContext(BTNameContext);
  const [BTCode, setBTCode] = useContext(BTCodeContext);
  const [BTDesc, setBTDesc] = useContext(BTDescContext);
  const [alertOpen, setAlertOpen] = useState(false);

  const Component_Update = async () => {
    try {

      const data = {
        name: BTName,
            description: BTDesc,
            cafmId: TreeSelected.cafm_id,
            globalId: TreeSelected.global_id,
            parentComponent: TreeSelected.parent_objecttype_id,
            parentCafmId: TreeSelected.parent_cafm_id,
            parentGlobalId: TreeSelected.parent_global_id,
            code: BTCode,
      };

      await trackPromise(
        TreeComponentTypeService.saveComponentType(token, realm, data)
            .then((res) => {
              TreeComponentTypeService.getTreeData(token, realm)
                  .then((result) => {
                    setTreeItemsState(result.data);
                    // console.log(res);
                    const selItem = {...TreeSelected, name: data.name, description: data.description};
                    setTreeSelected(selItem);
                  });
            })
      );

    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div>
        <Dialog
          className={classesDialog.dialog}
          open={UpdateCompDialogOpen}
          onClose={() => {
            setUpdateCompDialogOpen(false);
            setBTName("");
            setBTCode("");
            setBTDesc("");
          }}
          TransitionComponent={Transition}
          fullScreen
        >
          <AppBar className={classesDialog.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setUpdateCompDialogOpen(false);
                  setBTName("");
                  setBTCode("");
                  setBTDesc("");
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classesDialog.title}>
                Bauteil <b> {TreeSelected.name} </b> bearbeiten
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  setAlertOpen(true);

                    BTName === ""
                      ? setBTName(TreeSelected.name)
                      : setBTName(BTName);

                    BTCode === ""
                      ? setBTCode(TreeSelected.code)
                      : setBTCode(BTCode);

                    BTDesc === ""
                      ? setBTDesc(TreeSelected.description)
                      : setBTDesc(BTDesc);

                }}
              >
                Bestätigen
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <TextField
                autoFocus
                required
                defaultValue={TreeSelected.name}
                margin="dense"
                id="Comp_name"
                label="Name"
                type="text"
                fullWidth
                onChange={(e) => setBTName(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                autoFocus
                required
                disabled
                defaultValue={TreeSelected.code}
                margin="dense"
                id="Comp_code"
                label="Code"
                type="text"
                fullWidth
                onChange={(e) => setBTCode(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                autoFocus
                defaultValue={TreeSelected.description}
                margin="dense"
                id="Comp_description"
                label="Beschreibung"
                type="text"
                fullWidth
                onChange={(e) => setBTDesc(e.target.value)}
              />
            </ListItem>
            <Divider />
          </List>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={alertOpen}
          className={classesDialog.dialogAlert}
          onClose={() => setAlertOpen(false)}
        >
          <AppBar className={classesDialog.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setAlertOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classesDialog.title}>
                Bauteil <b>{BTName}</b> bearbeiten
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>Eingaben korrekt?</DialogContentText>
            <Alert variant="outlined" severity="info">
              <DialogContentText>Name: {BTName}</DialogContentText>
              <DialogContentText>Beschreibung: {BTDesc}</DialogContentText>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen(false)} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={() => {
                setAlertOpen(false);
                Component_Update();
                setUpdateCompDialogOpen(false);
              }}
              color="primary"
              autoFocus
            >
              Bestätigen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}