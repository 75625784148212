import React, { useState, useContext } from "react";

import {
    SubDialogOpenContext,
    SubDialogConfirmOpenContext,
    TokenContext,
    RealmContext,
    TreeSelectedContext,
    TreeItemsStateContext,
} from "../../../../Store";

import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import {trackPromise} from "react-promise-tracker";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Anlegen-Fenster für Bauteil unterhalb ddes gewählten Bauteils

const useStyles = makeStyles((theme) => ({
    alert: {},
/*    dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "70%",
        marginRight: 0,
    },
    dialogConfirm: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },
    appBar: {
        position: "relative",
        background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },*/
}));

// Transition legt fest, wie das Fenster erscheint
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function CreateSubCompDialog() {
    const classesDialog = useStylesDialog();
    const [SubDialogOpen, setSubDialogOpen] = useContext(SubDialogOpenContext);
    const [SubDialogConfirmOpen, setSubDialogConfirmOpen] = useContext(
        SubDialogConfirmOpenContext
    );
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [TreeSelected] = useContext(TreeSelectedContext);
    const [, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [compName, setCompName] = useState("");
    const [compDesc, setCompDesc] = useState("");
    const [compCode, setCompCode] = useState("");
    const [compCafmId] = useState("");
    const [compGlobalId] = useState("");

    const Component_Create = async () => {
        try {

            const data = {
                name: compName,
                    description: compDesc,
                    cafmId: compCafmId,
                    globalId: compGlobalId,
                    code: compCode,
                    //sortNumber: 0,
                    parentComponent: TreeSelected.objecttype_id,
                    parentCafmId: TreeSelected.cafm_id,
                    parentGlobalId: TreeSelected.global_id,
                //cafmConnectVersion: "v3",
                //applicableEntity: "IfcWall",
                //location: "",
                //attributes:
            };

            await trackPromise(
                TreeComponentTypeService.saveComponentType(token, realm, data)
                    .then((res) => {
                        TreeComponentTypeService.getTreeData(token, realm)
                            .then((result) => {
                                setTreeItemsState(result.data);

                            });
                    })
            );

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <Dialog
                className={classesDialog.dialog}
                open={SubDialogOpen}
                onClose={() => setSubDialogOpen(false)}
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setSubDialogOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Katalogelement hinzufügen
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => {
                                setSubDialogConfirmOpen(true);
                            }}
                        >
                            Speichern
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Comp_name"
                            label="Name"
                            type="text"
                            fullWidth
                            required="true"
                            onChange={(e) => setCompName(e.target.value)}
                        />
                    </ListItem>
                    {/*<ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Comp_cafmid"
                            label="Cafm-ID"
                            type="hidden"
                            fullWidth
                            onChange={(e) => setCompCafmId(e.target.value)}
                        />
                    </ListItem>*/}
                    {/*<ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Comp_globalid"
                            label="Global-ID"
                            type="hidden"
                            fullWidth
                            onChange={(e) => setCompGlobalId(e.target.value)}
                        />
                    </ListItem>*/}
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Comp_code"
                            label="Code"
                            type="text"
                            fullWidth
                            required="true"
                            onChange={(e) => setCompCode(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="Comp_cafmid"
                            type="hidden"
                        />
                        <TextField
                            margin="dense"
                            id="Comp_globalid"

                            type="hidden"

                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Comp_description"
                            label="Beschreibung"
                            type="text"
                            fullWidth
                            onChange={(e) => setCompDesc(e.target.value)}
                        />
                    </ListItem>
                    <Divider />
                </List>
            </Dialog>
            {/**Unterscheidung ob Eingaben ausreichend um Bauteil anzulegen -> 2 verschiedene Fenster*/}
            {compName !== "" && compCode !=="" ? (
                <Dialog
                    className={classesDialog.dialogConfirm}
                    open={SubDialogConfirmOpen}
                    onClose={() => setSubDialogConfirmOpen(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setSubDialogConfirmOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                Bauteil <b>{compName}</b> anlegen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Alert variant="outlined" severity="success">
                            Das Bauteil <b>{compName}</b> kann angelegt werden! Das
                            übergeordnete Bauteil ist {TreeSelected.name}.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setSubDialogConfirmOpen(false);
                            }}
                            color="primary"
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={async() => {
                                await Component_Create();
                                setSubDialogOpen(false);
                                setSubDialogConfirmOpen(false);
                            }}
                            color="primary"
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog
                    className={classesDialog.dialogConfirm}
                    open={SubDialogConfirmOpen}
                    onClose={() => setSubDialogConfirmOpen(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setSubDialogConfirmOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                Bauteil <b>{compName}</b> anlegen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Alert variant="outlined" severity="warning">
                            <b>Bitte tragen Sie einen Namen und einen Code ein!</b>
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setSubDialogConfirmOpen(false);
                            }}
                            color="primary"
                        >
                            Eingaben ändern
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
