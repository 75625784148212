import React, { useContext, useEffect, useState} from "react";
import {
    TokenContext, TreeItemsStateContext
} from "../../Store";
import {connect} from "react-redux";
import { makeStyles } from '@mui/styles';
import ObjecttypeService from "../../../services/ObjecttypeService";
import {List, ListItem} from "@mui/material";
import ObjecttypeSelectlist from "../../../shared/ObjecttypeSelectlist";
import MaterialTable from "material-table";

import { header } from'../../../shared/headerStyle';
import ComponentTypeAutoComplete from "../../../shared/ComponentTypeAutoComplete";
import DataComponentService from "../../../services/DataComponentService";

// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        width: "100%",
        height: "100%",
        /*margin: "15px",
        padding: "20px",*/
        float: "left",
        /*border: "2px solid #B4AF03",
        borderRadius: "25px",*/
    },
    btListe: {
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "hidden",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

    table: {
        minWidth: 700,
    },
});


function ObjectFields({theRealm}) {
    // const {theRealm, theme} = props;
    // console.dir(props)
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);

    // const [SiteColor] = useContext(SiteColorContext);
    const [optionsRO, setOptionsRO] = useState([]);
    const [optionsAO, setOptionsAO] = useState([]);
    const [selectedRO, setSelectedRO] = useState('');
    const [selectedAO, setSelectedAO] = useState('');
    const classes = useStyles();

    const [treeItemsState] = useContext(TreeItemsStateContext);
    const [value, setValue] = useState(null);

    const [arrTitle, setArrTitle] = useState([]);
    // let tableTitle = '';
    useEffect(()=> {
        async function fetchData() {
            const bla = await ObjecttypeService.getRealestateobjecttypes(token, theRealm);
            const myOptions = [];
            const myTitles = [];
            if (bla.data && bla.data instanceof Array) {
                myOptions[0] = {value: '', text: ' - Bitte wählen -'}
                bla.data?.forEach((val) => {
                    const item = {value: val.guid, text: val.label}
                    myOptions.push(item);
                    myTitles[val.guid] = val.label;
                })
            }
            setOptionsRO(myOptions);
            setSelectedRO('');
            const bla2 = await ObjecttypeService.getAdministrativeobjecttypes(token, theRealm);
            const myOptions2 = [];
            if (bla2.data && bla2.data instanceof Array) {
                myOptions2[0] = {value: '', text: ' - Bitte wählen -'}
                bla2.data?.forEach((val) => {
                    const item = {value: val.guid, text: val.label}
                    myOptions2.push(item);
                    myTitles[val.guid] = val.label;
                })
            }
            setOptionsAO(myOptions2);
            setSelectedAO('');
            setArrTitle(myTitles);
        }
        fetchData()
    }, []);



    const [title, setTitle] = useState('');
    const [fieldType, setFieldType] = useState('realestateobjectType');
    const [columnData, setColumnData] = useState([]);

    const handleChange = async(event) => {
        console.log(event.target.value);
        const myRoValue = event.target.value;

        // let objecttype, type
        const listFields = await ObjecttypeService.getRealestateobjectFields(myRoValue, token, theRealm);
        console.log(listFields);
        const sortedFields = await listFields?.data?.sort((a, b) => a.label.localeCompare(b.label))
        // setColumnData(listFields?.data);
        setColumnData(sortedFields);
        setSelectedRO(myRoValue);
        setSelectedAO('');
        setTitle(arrTitle[myRoValue]);
        setFieldType('realestateobjectType');
        // setTitle(tableTitle);
        // handleObjecttypeChange(objecttype, type)
    };

    const handleChangeAO = async(event) => {
        console.log(event.target.value);
        const myAoValue = event.target.value;

        // let objecttype, type
        const listFields = await ObjecttypeService.getAdministrativeobjectFields(myAoValue, token, theRealm);
        console.log(listFields);
        const sortedFields = await listFields?.data?.sort((a, b) => a.label.localeCompare(b.label))
        // setColumnData(listFields?.data);
        setColumnData(sortedFields);
        setSelectedAO(myAoValue);
        setSelectedRO('');
        // handleObjecttypeChange(objecttype, type)
        setFieldType('administrativeobjectType');
        setTitle(arrTitle[myAoValue]);
    };


    const treeFlatList = [];

    treeItemsState.forEach(item => {
        convert(item)
    });

    const changedComponenttypeValue = async (val) => {
        console.log(val);
        if(val) {
            const myBtValue = val;
            const listFields = await DataComponentService.getComponentTypeAttributeFieldsByGuid(token, theRealm, val.globalId);
            console.log(listFields);
            const sortedFields = await listFields?.data?.sort((a, b) => a.name.localeCompare(b.name))
            // setColumnData(listFields?.data);
            setColumnData(sortedFields);
            setSelectedAO('');
            setSelectedRO('');
            // handleObjecttypeChange(objecttype, type)
            setFieldType('componentType');
            setTitle(val.code + ' - ' + val.name + ' (' + val.globalId + ')');
            setValue(val)
        }
    }

    function convert(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.objecttype_id;
        myItem.code = item.code;
        myItem.title = item.code + ' - ' + item.name;
        myItem.cafmId = item.cafm_id;
        myItem.globalId = item.global_id;
        myItem.key = item.global_id;
        treeFlatList.push(myItem);
        item.children_objecttypes?.forEach(item2 => {
            convert(item2)
        });
    }

    const tableHeight =(window.innerHeight - 88 - 74 - 52 - 1) / window.innerHeight * 100;
    let columns = [];
    let addRow = undefined;
    if(fieldType == 'realestateobjectType' || fieldType == 'administrativeobjectType') {
        columns = [
            {title: 'guid', field: 'guid', hidden: true},
            {title: 'Bezeichnung', field: 'label'},
            {
                title: 'Typ',
                field: 'type',
                lookup: {
                    "text": 'Text',
                    "number": 'Nummer',
                    "integer": 'Integer',
                    "real": "Real",
                    "boolean": 'Boolean',
                    "date": 'Datum'
                },
            },
            {title: 'Cockpit-Feldname', field: 'mappedField'},
            {title: 'Sortierung', field: 'position', type: 'numeric'},
        ];

        addRow = async (newData) => {
            if (fieldType === 'administrativeobjectType') {
                await ObjecttypeService.saveAdministrativeobjectTypeAttribute(selectedAO, newData, token, theRealm);
                const listFields = await ObjecttypeService.getAdministrativeobjectFields(selectedAO, token, theRealm);
                console.log(listFields);
                setColumnData(listFields?.data);
            } else if (fieldType === 'realestateobjectType') {
                await ObjecttypeService.saveRealestateobjectTypeAttribute(selectedRO, newData, token, theRealm);
                const listFields = await ObjecttypeService.getRealestateobjectFields(selectedRO, token, theRealm);
                console.log(listFields);
                setColumnData(listFields?.data);
            }
        }

    } else if(fieldType == 'componentType') {
        columns = [
            {title: 'guid', field: 'guid', hidden: true},
            {title: 'cafm_property_guid', field: 'cafm_property_guid', hidden: true},
            {title: 'Bezeichnung', field: 'name', editable: 'never'},
            {
                title: 'Typ',
                field: 'type',
                lookup: {
                    "969d56de-6206-4cfe-b095-35db210ec54c": 'Text',
                    "8241577e-b646-4068-83db-2ca1fde28c77": 'Integer',
                    "7e408b5c-4bf3-4213-b314-5d975551c00a": "Real",
                    "9073bfbe-3645-4fc4-b3b9-09d411325750": 'Boolean',
                    "d2fd3692-dde6-47f2-afca-86c282092d51": 'Datum',
                    "6d8c13ff-eeb4-4992-ab38-bd63ff8eac0e": 'Längenmaß',
                    "0bf919fb-1569-40cc-804f-38366374037c": 'Identifikator',
                    "c593e9dc-8bfc-4ae3-902f-89ad349c33fe": 'Ja/Nein',
                    "559ed3f6-596e-43ad-bf2a-263ef7388a0a": 'Text (max 255)',
                    "de4f8f6d-3bc1-4edf-a31e-3c696c8d5ee7": 'Positives Längenmaß',

                },
                editable: 'never'
            },
            {title: 'Cockpit-Feldname', field: 'mappedField'},

        ];
    } else {
        columns = [
            {title: 'guid', field: 'guid', hidden: true},
            {title: 'Bezeichnung', field: 'label'},
            {
                title: 'Typ',
                field: 'type',
                lookup: {
                    "text": 'Text',
                    "number": 'Nummer',
                    "integer": 'Integer',
                    "real": "Real",
                    "boolean": 'Boolean',
                    "date": 'Datum'
                },
            },
            {title: 'Cockpit-Feldname', field: 'mappedField'},
            {title: 'Sortierung', field: 'position', type: 'numeric'},
        ];
    }




    return (
        <div>

            <div className={classes.katalogView}>
                <List style={{backgroundColor: "#FFFFFF"}}>
                    <ListItem>
                        <ObjecttypeSelectlist
                            options={optionsRO}
                            selected={selectedRO}
                            handleChange={handleChange}
                            label="Immobilienobjekt"
                        />
                        <ObjecttypeSelectlist
                            options={optionsAO}
                            selected={selectedAO}
                            handleChange={handleChangeAO}
                            label="Verwaltungsobjekt"
                        />
                        <div style={{width: "11px"}}></div>
                        <ComponentTypeAutoComplete
                            flatList={treeFlatList}
                            setValue={changedComponenttypeValue}
                            value={value}
                            label="Bauteiltypen wählen oder Text eingeben"
                        />
                    </ListItem>
                </List>
                <div className={classes.btListe}>
                    <MaterialTable
                        title={title}
                        columns={columns}
                        options={{
                            headerStyle: header,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, 100],
                            search: false,
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportAllData: true,
                            maxBodyHeight: `${tableHeight}vh`,
                            minBodyHeight: `${tableHeight}vh`,
                        }}
                        data={columnData}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} von {count}',
                                labelRowsSelect: 'Zeilen',
                                labelRowsPerPage: 'Zeilen pro Seite'
                            },
                            toolbar: {
                                nRowsSelected: '{0} Zeile(n) ausgewählt',
                                searchTooltip: 'Suchen',
                                searchPlaceholder: 'Suchbegriff eingeben'
                            },
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Keine Einträge gefunden',
                                filterRow: {
                                    filterTooltip: 'Filter'
                                },
                                editRow: {
                                    deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                                    saveTooltip: 'Speichern',
                                    cancelTooltip: 'Abbrechen'
                                }
                            }
                        }}
                        editable={{
                            isDeletable: rowData => !rowData.cafm_property_guid,
                            onRowAdd: addRow,
                            onRowUpdate: async(newData, oldData) => {
                                if(fieldType === 'administrativeobjectType') {
                                    await ObjecttypeService.saveAdministrativeobjectTypeAttribute(selectedAO, newData, token, theRealm);
                                    const listFields = await ObjecttypeService.getAdministrativeobjectFields(selectedAO, token, theRealm);
                                    console.log(listFields);
                                    setColumnData(listFields?.data);
                                } else if(fieldType === 'realestateobjectType') {
                                    await ObjecttypeService.saveRealestateobjectTypeAttribute(selectedRO, newData, token, theRealm);
                                    const listFields = await ObjecttypeService.getRealestateobjectFields(selectedRO, token, theRealm);
                                    console.log(listFields);
                                    setColumnData(listFields?.data);
                                } else if(fieldType === 'componentType') {
                                    await ObjecttypeService.saveComponentTypeAttribute(value.globalId, newData, token, theRealm);
                                    const listFields = await DataComponentService.getComponentTypeAttributeFieldsByGuid(token, theRealm, value.globalId);
                                    setColumnData(listFields?.data);
                                }
                            },
                            onRowDelete: async (oldData) => {
                                if(fieldType === 'administrativeobjectType') {
                                    await ObjecttypeService.deleteAdministrativeobjectTypeAttribute(selectedAO, oldData.guid, token, theRealm);
                                    const listFields = await ObjecttypeService.getAdministrativeobjectFields(selectedAO, token, theRealm);
                                    console.log(listFields);
                                    setColumnData(listFields?.data);
                                } else if(fieldType === 'realestateobjectType') {
                                    await ObjecttypeService.deleteRealestateobjectTypeAttribute(selectedRO, oldData.guid, token, theRealm);
                                    const listFields = await ObjecttypeService.getRealestateobjectFields(selectedRO, token, theRealm);
                                    console.log(listFields);
                                    setColumnData(listFields?.data);
                                } else if(fieldType === 'componentType') {
                                    // await ObjecttypeService.deleteComponentTypeAttribute(value, oldData.guid, token, theRealm);
                                    // const listFields = await DataComponentService.getComponentTypeAttributeFieldsByGuid(token, theRealm, value);
                                    // setColumnData(listFields?.data);
                                }
                            },
                        }}

                        />
                </div>

            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ObjectFields);