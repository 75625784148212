import PropTypes from 'prop-types'
import { Route } from "react-router-dom";
import UserService from "../services/UserService";
import NotAllowed from "./NotAllowed";
import {useParams} from "react-router";

const RealmsRoute = ({ realms, children, ...rest }) => {
    const {realm} = useParams();
    console.log(realm)
    console.dir(realms)

    const checkRealmInRealms = (realm) => {
        let check = false;
        realms.forEach((item) => {
            if(item === realm) {
                check = true;
            }
        })
        return check;
    }

    return (
        <>
            {checkRealmInRealms(realm) ? children : <NotAllowed/>}
        </>
    )
}

RealmsRoute.propTypes = {
    realms: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RealmsRoute