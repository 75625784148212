import http from "../shared/http-common";

const MapService = {

    getSiteFeatureList(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        const data = {}
        formData.append('data', JSON.stringify(data));
        return http.post(`realestateobject/site/getFeatureList`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }
}
export default MapService;