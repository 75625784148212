import http from "../shared/http-common";

const DataComponentService = {
    getComponentsToRealestateobject(token, realm, realestateobjectGuid) {
        console.log('getComponentsToRealestateobject: ' + realestateobjectGuid);
        try {

            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("realestateobjectGuid", realestateobjectGuid);
            // return http.post(`/component/listrealestatecomponents`, formData,{
            return http.post(`/component/simplelist`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getAllComponentsToRealestateobject(token, realm, realestateobjectGuid) {
        console.log('getAllComponentsToRealestateobject: ' + realestateobjectGuid);
        try {

            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("realestateobjectGuid", realestateobjectGuid);
            return http.post(`/component/listallrealestatecomponentstorealestate`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getComponentTypeByCode(token, realm, code) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("code", code);
            return http.post(`/componentType/get`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getComponentsByComponentTypeCode(token, realm, code, realestateobjectGuid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("componentType", code);
            if(realestateobjectGuid) {
                formData.append("realestateobjectGuid", realestateobjectGuid);
                return http.post(`/component/listrealestatecomponents`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });
            } else {
                return http.post(`/component/simplelist`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });
            }
        } catch (e) {
            console.log(e);
        }
    },

    getComponentTypeById(token, realm, id) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("componentTypeId", id);
            return http.post(`/componentType/getComponentTypeById`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getComponentTypeByGuid(token, realm, guid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("componentTypeGuid", guid);
            return http.post(`/componentType/getComponentTypeByGuid`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getComponentTypeAttributeFieldsByGuid(token, realm, guid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("componentTypeGuid", guid);
            return http.post(`/componentType/getComponentTypeAttributeFieldsByGuid`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },


    getComponentByGuid(token, realm, guid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("guid", guid);
            return http.post(`/component/get`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    async saveComponent(token, theRealm, properties, attributes) {
        try {
            // properties['realestateobjectType'] = realestateobjectTypeGuid;
            const data = {type: 'component', properties: properties, attributes: attributes}
            let formData = new FormData();
            formData.append("realm", theRealm);
            // console.log(JSON.stringify(data))
            formData.append("data", JSON.stringify(data));
            // console.log(data)

            return await http.post(`/component/update`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log('Errör');
            console.dir(e)
        }
    },

    getComponentTypeParents(token, realm, componentTypeId) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("componentTypeId", componentTypeId);
            return http.post(`/componentType/getComponentTypeParents`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    deactivateComponent(token, realm, componentGuid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            const data = {componentGuid: componentGuid};
            formData.append("data", JSON.stringify(data));

            return http.post(`/component/deactivate`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    deleteComponent(token, realm, componentGuid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            const data = {componentGuid: componentGuid};
            formData.append("data", JSON.stringify(data));

            return http.post(`/component/delete`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    activateComponent(token, realm, componentGuid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            const data = {componentGuid: componentGuid};
            formData.append("data", JSON.stringify(data));
            return http.post(`/component/activate`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    fillEmptyAttributList(attributArray) {
        const myAttributeListToDisplay= [];
        console.log('attributArray');
        console.log(attributArray);
        if(attributArray) {
            attributArray.forEach((item, index) => {
                const myAttribute = {};
                if (item.attribute_id) {
                    // Attribute vom merkmalserver
                    myAttribute.id = item.attribute_id;
                    myAttribute.name = 'attr_' + item.attribute_id;
                    myAttribute.key = "key_" + item.attribute_id;
                    myAttribute.mms = 1;
                    myAttribute.pos = item.attribute_id;
                    myAttribute.type = item.attribute_type;
                    myAttribute.data_type = item.data_type;
                    myAttribute.enumerators = item.enumerators;
                    myAttribute.reference_field = item.reference_field;
                } else {
                    // Kundenspezfische Attribute
                    myAttribute.id = item.id;
                    myAttribute.name = 'attribute_tenant_' + item.id;
                    myAttribute.key = "key_" + item.id;
                    myAttribute.mms = 0;
                    myAttribute.pos = item.id;
                    myAttribute.type = item.type;
                }
                myAttribute.label = item.name;
                myAttribute.value = item.value ? item.value: '';
                myAttributeListToDisplay.push(myAttribute);
            });
        }

        const myArray = myAttributeListToDisplay.sort((a, b) => (a.mms > b.mms) || (a.mms === b.mms && a.pos < b.pos) ? -1 : 1);
        return myArray;
    },

    fillAttributList(attributArray:[], componentTypeAttributes:[]) {
        const myAttributeListToDisplay= [];
        const usedMmsAttributes = [];
        console.log('fillAttributList')
        console.dir(attributArray);
        console.dir(componentTypeAttributes);
        if(attributArray) {

            // setAttributes(componentSelected.componentAttributeList);
            attributArray.forEach((item, index) => {
                const myAttribute = {};
                if(item.mmsAttributeId) {
                    // Attribute vom merkmalserver
                    myAttribute.name = 'attr_' + item.mmsAttributeId;
                    myAttribute.key = "key_" + item.mmsAttributeId
                    myAttribute.mms = 1;
                    myAttribute.pos = item.mmsAttributeId;
                    myAttribute.attributeType = item.attributeType;
                    myAttribute.dataType = item.dataType;
                    myAttribute.enumerators = item.enumerators;
                    myAttribute.reference_field = item.reference_field;
                    myAttribute.guid = item.guid
                    myAttribute.value = item.value;
                    componentTypeAttributes.forEach((typ, idx) => {
                        if(item.mmsAttributeId.toString() === typ.attribute_id.toString()) {
                            myAttribute.enumerators = typ.enumerators;
                            myAttribute.dataType = typ.data_type;
                            myAttribute.attributeType = typ.attribute_type;
                            myAttribute.reference_field = typ.reference_field;
                            myAttribute.global_id = typ.guid;
                            myAttribute.unit = typ.unit;
                            myAttribute.unit_id = typ.unit_id;
                            myAttribute.definition_de = typ.definition_de;
                            myAttribute.dimension = typ.dimension;
                            myAttribute.description = typ.description;
                            myAttribute.example_de = typ.example_de;
                            myAttribute.identifier = typ.identifier;
                            myAttribute.value = typ.value;
                            usedMmsAttributes.push(typ.attribute_id);
                        }
                    });
                } else {
                    // Kundenspezfische Attribute
                    myAttribute.name = 'attribute_tenant_' + item.id;
                    myAttribute.key = "key_" + item.id;
                    myAttribute.mms = 0;
                    myAttribute.pos = item.id;
                    myAttribute.type = item.type;
                    myAttribute.value = item.value;
                }
                myAttribute.id = item.id;
                myAttribute.label = item.name;

                myAttributeListToDisplay.push(myAttribute);
            });

        }
        componentTypeAttributes.forEach((typ, index) => {
            if(!usedMmsAttributes.includes(typ.attribute_id)) {
                const myAttribute = {};

                myAttribute.name = 'attr_' + typ.attribute_id;
                myAttribute.key = "key_" + typ.attribute_id
                myAttribute.id = typ.id;
                myAttribute.label = typ.name;
                myAttribute.value = "";
                myAttribute.mms = 1;
                myAttribute.pos = typ.attribute_id;
                myAttribute.attributeType = typ.attribute_type;
                myAttribute.data_type = typ.data_type;
                myAttribute.enumerators = typ.enumerators;
                myAttribute.reference_field = typ.reference_field;
                myAttribute.global_id = typ.guid;
                myAttribute.unit = typ.unit;
                myAttribute.unit_id = typ.unit_id;
                myAttribute.definition_de = typ.definition_de;
                myAttribute.dimension = typ.dimension;
                myAttribute.description = typ.description;
                myAttribute.example_de = typ.example_de;
                myAttribute.identifier = typ.identifier;
                myAttributeListToDisplay.push(myAttribute);
            }
        });
        const myArray = myAttributeListToDisplay.sort((a, b) => (a.mms > b.mms) || (a.mms === b.mms && a.pos < b.pos) ? -1 : 1);
        return myArray;
    },

    /**
     * nur die Attribute die im Projekt erlaubt sind
     * @param userProject
     * @param attributeList
     * @param componentSelected
     * @returns {*}
     */
    fillUserprojectAttributList(userProject, attributeList, componentSelected) {
        if(userProject) {
            let myLocalAttributeList = attributeList;
            userProject.componenttypes.forEach(ct => {
                if(ct.global_id === componentSelected?.componentTypeGlobalId || ct.global_id === componentSelected?.componentType?.mmsGlobalId) {
                    myLocalAttributeList = [];
                    ct.attribute.forEach(a => {
                        // console.log('a.attribute_id:' + a.attribute_id);
                        attributeList.forEach(attr => {
                            // console.log('attr.attribute_id:' + attr.attribute_id, attr.attribute_id === 'attr_' + a.attribute_id);
                            if(attr.attribute_id === a.attribute_id) {
                                myLocalAttributeList.push(attr);
                            }
                        })
                    });

                }
            })
            return myLocalAttributeList
        } else {
            return attributeList;
        }

    }
}

export default DataComponentService;
