import React from "react";
import { useField } from "formik";
import {Select} from "@mui/material";

const MySelectInput = ({ label, children, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field] = useField(props);
    return (

        <Select
            label={label}
            {...field} {...props}
           >{children}</Select>
    );
};

export {MySelectInput};