import React, {useState, useContext, useEffect} from "react";
import {TokenContext} from "../../Store";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {connect} from "react-redux";

import DataComponentService from "../../../services/DataComponentService";

import MaterialTable from "material-table";
import {trackPromise} from "react-promise-tracker";
import ComponentFormView from "./ComponentFormView";
import {confirmWrapper} from "../../../shared/confirm";

import FileCopy from "@mui/icons-material/FileCopy";
import DataComponentDialog from "../Datacomponents/DataComponentDialog";
import LoadingIndicator from "../../../shared/LoadingIndicator";

import { header } from'../../../shared/headerStyle';
import {useStylesDialog} from "../../../shared/useStyleDialog";
import UserService from "../../../services/UserService";

const useStyles = makeStyles((theme) => ({
/*    dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "80%",
        marginRight: 0,
    },
    appBar: {
        position: "relative",
        background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },*/
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function DataComponentEdit({dataObjectSelected, setDataComponentEditDialogOpen, DataComponentEditDialogOpen, handleUploadClick, setDataComponentFormDialogOpen, DataComponentFormDialogOpen, userProject, theRealm}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();

    const [token] = useContext(TokenContext);

    const [components, setComponents] = useState([]);
    const [componentType, setComponentType] = useState(null);

    const [componentSelected, setComponentSelected] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [DataComponentCopyFormDialogOpen, setDataComponentCopyFormDialogOpen] = useState(false);

    const [, setFormValues] = useState(null);


    useEffect(() => {
        // console.log(dataObjectSelected.guid);
        if(dataObjectSelected.realestateobjectType || dataObjectSelected.realestateobjectTypeGuid) {
            console.log('DataComponentEdit');
            setIsLoading(true);
            async function doSomething() {
                console.log('doSomething: ' + dataObjectSelected.guid);
                await DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                    .then((result) => {
                        setComponents(result.data);
                        console.log(result.data);
                        setIsLoading(false);
                    });
            }
            trackPromise(
                doSomething()
            )
        }
    }, [dataObjectSelected, theRealm, token]);


    const handleRowClick = async (event, rowData) => {
        // event.preventDefault();
        console.log(rowData);
        setFormValues([]);
        if(rowData.componentType?.mmsCode) {
            await trackPromise(
                DataComponentService.getComponentByGuid(token, theRealm, rowData.guid)
                    .then((res) => {
                        console.log(res);

                        DataComponentService.getComponentTypeByCode(token, theRealm, rowData.componentType?.mmsCode)
                            .then((result) => {
                                if (result && result.data && result.data.length > 0 && result.data[0].attribute) {
                                    console.log('Hello 1');
                                    const componentTypeAttributes = result.data[0].attribute;
                                    // const usedMmsAttributes = [];
                                    const myArray = DataComponentService.fillAttributList(res.componentAttributeList, componentTypeAttributes);
                                    setComponentType(result.data[0]);
                                    setComponentSelected(res.data);
                                    setAttributeList(myArray);
                                    setDataComponentFormDialogOpen(true);
                                }
                            })
                    })
            );
        } else if(rowData.componentTypeObjecttypeId || rowData.componentType?.objecttypeId) {
            const cTypeId = rowData.componentTypeObjecttypeId ? rowData.componentTypeObjecttypeId : rowData.componentType?.objecttypeId;
            await trackPromise(
                DataComponentService.getComponentByGuid(token, theRealm, rowData.guid)
                    .then((res) => {
                        console.log(res);
                        DataComponentService.getComponentTypeById(token, theRealm, cTypeId)
                            .then((result) => {
                                if (result && result.data && result.data.length > 0 && result.data[0].attribute) {
                                    const componentTypeAttributes = result.data[0].attribute;
                                    let componentTypeAttributesModified
                                    if(userProject) {
                                        componentTypeAttributesModified = DataComponentService.fillUserprojectAttributList(userProject, componentTypeAttributes, res.data)
                                    } else {
                                        componentTypeAttributesModified = componentTypeAttributes;
                                    }
                                    const myArray = DataComponentService.fillAttributList(rowData.componentAttributeList, componentTypeAttributesModified);

                                    setComponentType(result.data[0]);
                                    setComponentSelected(res.data);
                                    setAttributeList(myArray);
                                    setDataComponentFormDialogOpen(true);
                                }
                            })
                    })
            )
        } else {
            console.log('handleRowClick');
            console.log(rowData);
            const myArray = DataComponentService.fillEmptyAttributList(rowData.componentAttributeList);

            setComponentType([]);
            setComponentSelected(rowData);
            setAttributeList(myArray);
            // setOpen(true);
            // setModalFormKey(modalFormKey + 1);
            setDataComponentFormDialogOpen(true);
        }

    }

    const copyComponentClick =  async (event, rowData) => {
        // event.preventDefault();
        console.log(rowData);
        setFormValues([]);
        if(rowData.componentTypeCode) {
            await trackPromise(
                DataComponentService.getComponentByGuid(token, theRealm, rowData.guid)
                    .then((res) => {
                        console.log(res);

                        DataComponentService.getComponentTypeByCode(token, theRealm, rowData.componentTypeCode)
                            .then((result) => {
                                if (result && result.data && result.data.length > 0 && result.data[0].attribute) {
                                    console.log('Hello 1');
                                    const componentTypeAttributes = result.data[0].attribute;
                                    // const usedMmsAttributes = [];
                                    const myArray = DataComponentService.fillAttributList(res.componentAttributeList, componentTypeAttributes);
                                    setComponentType(result.data[0]);

                                    const dataCopy = res.data;
                                    dataCopy.id = null;
                                    dataCopy.guid = null;
                                    dataCopy.mark = dataCopy.mark + ' (Kopie)'
                                    setComponentSelected(dataCopy);
                                    setAttributeList(myArray);
                                    setDataComponentCopyFormDialogOpen(true);
                                }
                            })
                    })
            );
        }

    }


    const saveObject = async(formData, attributeData) => {
        console.log(formData);
        console.log(attributeData);
        // console.log(token);
        await trackPromise(
            DataComponentService.saveComponent(token, theRealm, formData, attributeData)
                .then(() => {
                    setIsLoading(true);
                    // setDataComponentFormDialogOpen(false)
                    DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                        .then((result) => {
                            setComponents(result.data);
                            setIsLoading(false);
                        });
                })
        );
    }

    const reloadComponentList = async () => {
        console.log('reloadComponentList - wtf');
        if(dataObjectSelected.realestateobjectType || dataObjectSelected.realestateobjectTypeGuid) {
            setIsLoading(true);
            DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                .then((result) => {
                    setComponents(result.data);
                    setIsLoading(false);
                });
        }
    }

    const handleDeactivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);

        DataComponentService.deactivateComponent(token, theRealm, object.guid)
            .then((result) => {
                setIsLoading(true);
                setDataComponentFormDialogOpen(false)
                DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                    .then((result) => {
                        setComponents(result.data);
                        setIsLoading(false);
                    });
            });
    }

    const handleActivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        DataComponentService.activateComponent(token, theRealm, object.guid)
            .then((result) => {
                setIsLoading(true);
                setDataComponentFormDialogOpen(false)
                DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                    .then((result) => {
                        setComponents(result.data);
                        setIsLoading(false);
                    });
            });
    }

    const handleDeleteClick = async(event, rowData ) => {
        if (await confirmWrapper('Soll das Bauteil wirklich gelöscht werden?')) {
            event.preventDefault();
            setIsLoading(true);

            DataComponentService.deleteComponent(token, theRealm, rowData.guid)
                .then((result) => {
                    setIsLoading(true);
                    DataComponentService.getComponentsToRealestateobject(token, theRealm, dataObjectSelected.guid)
                        .then((result) => {
                            setComponents(result.data);
                            setIsLoading(false);
                        });
                });
        }
    }

    const tableHeight =(window.innerHeight -65 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>
            <LoadingIndicator />
        <Dialog
            className={classesDialog.dialog}
            open={DataComponentEditDialogOpen}
            onClose={() => setDataComponentEditDialogOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <AppBar className={classesDialog.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setDataComponentEditDialogOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classesDialog.title}>
                        Bauteile bearbeiten oder hinzufügen
                    </Typography>
                    {UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']) ? (
                        <Button autoFocus
                            color="inherit"
                            onClick={(e) => {handleRowClick(e, [])}}>Neues Bauteil anlegen</Button>
                        ) : null
                    }
                </Toolbar>
            </AppBar>
            <LoadingIndicator />
            <MaterialTable

                title={"Bauteile - " + dataObjectSelected.name}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark'},
                    { title: 'Bauteiltyp', field: 'componentTypeName'},
                    { title: 'Code', field: 'componentTypeCode'},
                    { title: 'deaktiviert', field: 'deactivated',  lookup: { 0: 'nein', 1: 'ja' } },
                ]}
                data={components}
                options={{
                    headerStyle: header,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}
                isLoading={isLoading}
                onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Bauteil bearbeiten',
                        onClick: (event, rowData) => handleRowClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                    {
                        icon: FileCopy,
                        tooltip: 'Bauteil kopieren',
                        onClick: (event, rowData) => copyComponentClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                    {
                        icon: 'upload',
                        tooltip: 'Upload',
                        onClick: (event, rowData) => handleUploadClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => handleDeleteClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
            />

        </Dialog>
            <ComponentFormView
                dataObjectSelected={dataObjectSelected}
                setDataComponentFormDialogOpen={setDataComponentFormDialogOpen}
                DataComponentFormDialogOpen={DataComponentFormDialogOpen}
                componentSelected={componentSelected}
                componentType={componentType}
                attributeList={attributeList}
                setAttributeList={setAttributeList}
                realestateobjectGuid={dataObjectSelected.guid}
                handleActivateClick={handleActivateClick}
                handleDeactivateClick={handleDeactivateClick}
                saveObject={saveObject}
                reloadComponentList={reloadComponentList}
                userProject={userProject}
            />
            <DataComponentDialog
                dataObjectSelected={dataObjectSelected}
                setDataComponentCopyFormDialogOpen={setDataComponentCopyFormDialogOpen}
                DataComponentCopyFormDialogOpen={DataComponentCopyFormDialogOpen}
                componentSelected={componentSelected}
                componentType={componentType}
                attributeList={attributeList}
                setAttributeList={setAttributeList}
                realestateobjectGuid={dataObjectSelected.guid}
                handleActivateClick={handleActivateClick}
                handleDeactivateClick={handleDeactivateClick}
                saveObject={saveObject}
                reloadComponentList={reloadComponentList}
                userProject={userProject}
            />
        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataComponentEdit);