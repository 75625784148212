import React from 'react';
import {createRoot} from 'react-dom/client'
import ReactDOM from 'react-dom';
import App from './App';
//import { BrowserRouter } from 'react-router-dom';
import './Global.scss'
import 'material-icons/iconfont/material-icons.css';

import HttpService from "./services/HttpService";
import UserService from "./services/UserService";

// ReactDOM.render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//   document.getElementById('root')
// );

// const renderApp = () => ReactDOM.render(<App />, document.getElementById("root"));
const renderApp = () => {
    const root = createRoot(document.getElementById("root"));
    root.render(<App />);
}
//
UserService.initKeycloak(renderApp);
HttpService.configure();
