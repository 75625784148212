import {connect} from "react-redux";
import Typography from "@mui/material/Typography";
import DetailItemInfo from "./DetailItemInfo";
import React, {useContext, useEffect, useState} from "react";
// import {makeStyles} from "@mui/styles";
import {useLocation, useNavigate} from "react-router";
import http from "../../../shared/http-common";
import {trackPromise} from "react-promise-tracker";
import {DataobjectsDialogOpenContext, TokenContext} from "../../Store";
import MaterialTable from "material-table";
import {header} from "../../../shared/headerStyle";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DataobjectService from "../../../services/DataobjectService";
import DataObjectsEdit from "./DataObjectEdit";
import DataComponentEdit from "./DataComponentEdit";
import UploadDialog from "./UploadDialog";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserService from "../../../services/UserService";

// Style für DataTreeView
const styles = {
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    table: {
        minWidth: 700,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}

const GetObjectSubData = async (guid, objecttype, theRealm, token) => {
    try {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        // return http.post(`/brokerData/getAllToObject`, formData,{
        return http.post(`/` + objecttype + `/getAllObjects`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }

}





function Dataobject({theRealm}) {
    // const classes = useStyles();
    // const theRealm = props.theRealm;
    const [token] = useContext(TokenContext);

    const location = useLocation();

    const dataObject = location.state.subObjects;
    const title = location.state.title;
    const selectedObjectType = location.state.objectType;

    // const openDetailView = location.openDetailView;
    const [, setMyRow] = useState([]);
    const [myRealestate, setMyRealestate] = useState([]);
    const [myAdministrativeobjects, setMyAdministrativeobjects] = useState([]);

    const [DataComponentEditDialogOpen, setDataComponentEditDialogOpen] = useState(false);
    const [DataComponentFormDialogOpen, setDataComponentFormDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const [fieldType, setFieldType] = useState('administrativeobjectType');
    const [filtering, setFiltering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [, setDataobjectsDialogOpen] = useContext(DataobjectsDialogOpenContext);
    const [dataObjectSubSelected, setDataObjectSubSelected] = useState([]);

    const [myTableData, setMyTableData] = useState([]);

    let hideDeactivatedColumn = true

    const navigate = useNavigate()
    // const myTableData = [];
    useEffect(()=>{
        if(!location?.state?.subObjects) {
            navigate('/DataobjectsList/'+ theRealm, {state: {objectType: selectedObjectType}});
        }
    },[])

    useEffect(() => {
        const objecttype = dataObject.realestateobjectTypeGuid ? 'realestateobject' : 'administrativeobject';
        async function fetchData() {
            setIsLoading(true);
            await trackPromise(
                GetObjectSubData(dataObject.guid, objecttype, theRealm, token)
                    .then(result => {
                        console.log('SubDataRowTreeView');
                        console.log(result);
                        setDataObjectSubSelected(result.data)
                        if(objecttype === 'realestateobject') {
                            setMyRow(result.data);
                            setMyRealestate(result.data.subordinated);
                            setMyAdministrativeobjects(result.data.administrativeobjects);

                            const tempData = []
                            result?.data?.subordinated?.forEach((item)=>{
                                tempData.push({guid: item.guid, name: item.name, number: item.number, mark: item.mark, type: item.realestateobjectType, deactivated: item.deactivated, realestateobjectTypeGuid: item.realestateobjectTypeGuid});
                            })
                            result?.data?.administrativeobjects?.forEach((item)=>{
                                tempData.push({guid: item.guid, name: item.name, number: item.number, mark: item.mark, type: item.administrativeobjectType, deactivated: item.deactivated});
                            })
                            setMyTableData(tempData);
                        } else {
                            setMyRow(result.data);
                            setMyRealestate(result.data.realestateobjects);
                            setMyAdministrativeobjects(result.data.subordinated);
                            const tempData = []
                            result?.data?.subordinated?.forEach((item)=>{
                                tempData.push({guid: item.guid, name: item.name, number: item.number, mark: item.mark, type: item.administrativeobjectType, deactivated: item.deactivated});
                            })
                            result?.data?.realestateobjects?.forEach((item)=>{
                                tempData.push({guid: item.guid, name: item.name, number: item.number, mark: item.mark, type: item.realestateobjectType, deactivated: item.deactivated, realestateobjectTypeGuid: item.realestateobjectTypeGuid});
                            })
                            setMyTableData(tempData);
                        }
                        setIsLoading(false);
                    }));
        }
        fetchData();
    },[dataObject, theRealm, token]);

    const getColumnType = () => {
        return fieldType;
    }

    if(filtering) {
        hideDeactivatedColumn = false;
    }

    const goBack = () => {
        navigate('/DataobjectsList/' + theRealm, {state: {objectType: selectedObjectType}});
    }

    const handleDeactivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            DataobjectService.deactivateAdministrativeobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectSubData(object.guid, theRealm, token).then((bla) => {
                        // setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        } else if(object.realestateobjectTypeGuid) {
            DataobjectService.deactivateRealestateobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectSubData(object.guid, theRealm, token).then((bla) => {
                        // setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }
    }

    const handleActivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            DataobjectService.activateAdministrativeobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectSubData(object.guid, theRealm, token).then((bla) => {
                        // setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }else if(object.realestateobjectTypeGuid) {
            DataobjectService.activateRealestateobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectSubData(object.guid, theRealm, token).then((bla) => {
                        // setDataObjects([...bla.data]);
                        // setDataObjectSelected(result.data);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }
    }

    const handleEditClick = (object) => {
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid || checkObjectType(object.type) === 'administrativeobject') {
            DataobjectService.getAdministrativeObject(token, theRealm, object.guid)
                .then((result) => {
                    console.log(result)
                    setDataObjectSubSelected(result.data);
                    setDataobjectsDialogOpen(true);
                    setIsLoading(false);
                });
        }else if(object.realestateobjectTypeGuid || checkObjectType(object.type) === 'realestateobject') {
            DataobjectService.getRealestateObject(token, theRealm, object.guid)
                .then((result) => {
                    console.log(result)
                    setDataObjectSubSelected(result.data);
                    setDataobjectsDialogOpen(true);
                    setIsLoading(false);
                });
        } else {
            console.log('war wohl nix! ')
            console.log(object)
            setIsLoading(false);
        }
    }

    const handleUploadClick = (event, item) => {
        console.log(item);
        event.preventDefault();
        setDataObjectSubSelected(item);
        // setDataobjectsDialogOpen(false);
        setDataComponentEditDialogOpen(false);
        setUploadDialogOpen(true);
    }

    const handleComponentClick = (event, rowData) => {
        setDataObjectSubSelected(rowData);
        setDataComponentEditDialogOpen(true);
    }

    const reloadObjects = () => {}

    const checkObjectType = (type) => {
        let objecttype = '';
        switch (type) {
            case 'Liegenschaft':
            case 'Bauwerk':
            case 'Gebäude':
            case 'Raum':
            case 'Geschoss':
            case 'Spielplatz':
                objecttype = 'realestateobject';
                break;
            case 'Wirtschaftseinheit':
            case 'Wohnung':
            case 'Mieteinheit':
            case 'Gewerbeeinheit':
            case 'Stellplatz':
            case 'Hauseingang':
            case 'Belegenheit':
            case 'Flurstück':
            case 'Gebietsgliederung':
            case 'Waschhaus':
                objecttype = 'administrativeobject';
                break;

        }
        return objecttype;
    }


    const tableHeight =(window.innerHeight -57 - 74 - 52) / window.innerHeight * 100;

    return (
        <>
            <div onClick={goBack} className="backline" >
                <Button style={{color: '#ffffff'}}><ChevronLeftIcon />Zurück</Button>
            </div>
            <Typography variant="body2" component="p">
               <MaterialTable
                data={myTableData}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: 'type'},
                    { title: 'deaktiviert', field: 'deactivated', lookup: { 0: 'nein', 1: 'ja' }, hidden: hideDeactivatedColumn }
                ]}
                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF',
                        display: (hideDeactivatedColumn && rowData.deactivated) ? 'none': ''
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    filtering,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}
                isLoading={isLoading}
                onRowClick={(event, rowData) => {
                    navigate('/Dataobject', {state: {subObjects: rowData, title: rowData.name, objectType: selectedObjectType}})

                }
                }
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite',
                        firstTooltip: "Erste Seite",
                        previousTooltip: "Vorherige Seite",
                        nextTooltip: "Nächste Seite",
                        lastTooltip: "Letzte Seite",
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: 'Aktionen'
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Editieren',
                        onClick: (event, rowData) => handleEditClick(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                    rowData => ({
                        icon:  rowData.deactivated ? 'taskalt' : 'cancel',
                        tooltip: rowData.deactivated ? 'Aktivieren': 'Deaktivieren',
                        onClick: rowData.deactivated ? (event, rowData) => handleActivateClick(rowData, event) :(event, rowData) => handleDeactivateClick(rowData, event),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    }),
                    rowData => (checkObjectType(rowData.type) === 'realestateobject') ? {
                        icon: 'hardware',
                        tooltip: 'Bauteile',
                        onClick: (event, rowData) => {
                            console.log(rowData.guid)
                            handleComponentClick(event, rowData)

                        }
                    } : '',
                    {
                        icon: 'upload',
                        tooltip: 'Upload',
                        onClick: (event, rowData) => handleUploadClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    }

                ]}
               />
            </Typography>
            <DataObjectsEdit dataObjectSelected={dataObjectSubSelected}
                             handleDeactivateClick={handleDeactivateClick}
                             handleActivateClick={handleActivateClick}
                             reloadObjects={reloadObjects}
            />
            <DataComponentEdit
                dataObjectSelected={dataObjectSubSelected}
                setDataComponentEditDialogOpen={setDataComponentEditDialogOpen}
                DataComponentEditDialogOpen={DataComponentEditDialogOpen}
                handleUploadClick={handleUploadClick}
                setDataComponentFormDialogOpen={setDataComponentFormDialogOpen}
                DataComponentFormDialogOpen={DataComponentFormDialogOpen}
            />
            <UploadDialog
                dataObjectSelected={dataObjectSubSelected}
                setUploadDialogOpen={setUploadDialogOpen}
                uploadDialogOpen={uploadDialogOpen}
            />
        </>
    );
}

function mapState(props) {
    return {
        theRealm: props.theRealm
    }
}

export default connect(
    mapState,
    null
)(Dataobject);