import React, {useState, useContext, useEffect, useCallback} from "react";
import {
    BimProfileSelectedContext,
    BimProfileSelectedDataContext,
    RealmContext,
    TokenContext, TreeItemsStateContext
} from "../../Store";
import {makeStyles} from "@mui/styles";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ComponentTypeAutoComplete from "../../../shared/ComponentTypeAutoComplete";
import {trackPromise} from "react-promise-tracker";
import DataComponentService from "../../../services/DataComponentService";
import {connect} from "react-redux";
import ListComponenttype from "./ListComponenttype";
// import ListSelectedComponenttype from "./ListSelectedComponenttype";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListSelectedComponenttypeBox from "./ListSelectedComponenttypeBox";
import RenderOnRole from "../../RenderOnRole";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 400,
        height: 300,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function BimProfilesComponents({setComponenttypes, setAttributes, attributes, componenttypes, theRealm}) {
    const classes = useStyles();

    const [token] = useContext(TokenContext);
    // const [realm] = useContext(RealmContext);
    // const [BimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    const [treeItemsState] = useContext(TreeItemsStateContext);

    const [value, setValue] = React.useState(null);
    const [, setExpanded] = React.useState([]);
    const [myComponentType, setMyComponentType] = useState([]);
    const [selectedComponenttypes, setSelectedComponenttypes] = useState([]);
    const [checkedAttributes, setCheckedAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);

    useEffect(()=>{
        setSelectedComponenttypes(componenttypes);
        setSelectedAttributes(attributes)
        // console.dir(BimProfileSelectedData)
    }, [componenttypes, attributes]);



    const treeFlatList = [];

    treeItemsState.forEach(item => {
        convert(item)
    });

    function convert(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.objecttype_id;
        myItem.code = item.code;
        myItem.title = item.code + ' - ' + item.name;
        myItem.cafmId = item.cafm_id;
        myItem.globalId = item.global_id;
        myItem.key = item.global_id;
        treeFlatList.push(myItem);
        item.children_objecttypes?.forEach(item2 => {
            convert(item2)
        });
    }

    const setComponentTypeValue = async(val) => {
        setValue(val);
        console.log(val);

        if(val) {

            await trackPromise(
                DataComponentService.getComponentTypeByCode(token, theRealm, val.code)
                    .then((result) => {
                        console.log(result.data);
                        setMyComponentType(result.data)
                        setCheckedAttributes([])
                        // myAttributList = result.data[0].attribute;
                        // const myArray = DataComponentService.fillEmptyAttributList(myAttributList)
                        // setAttributeList(myArray);

                    })
            );
        } else {
            setExpanded([]);
        }

    }

    const [counter, setCounter] = useState(0)

    const setComponentAndAttributes = useCallback(async() => {
        console.log(myComponentType);
        console.log(currentObjectTypeId);
        console.log(currentObjectType);
        console.log(checkedAttributes);
        let cnt = new Date();
        // currentObjectType.forEach(item => {
            if(currentObjectType?.objecttype_id === currentObjectTypeId) {
                let arr = selectedComponenttypes;
                if(!arr.includes(currentObjectType)) {
                    arr.push(currentObjectType);
                    setSelectedComponenttypes(arr);
                    console.log(selectedComponenttypes);
                    cnt++;
                }
                currentObjectType.attribute?.forEach((attr) => {
                    // console.log(attr.attribute_id + ' - ' + id);
                    // if(attr.attribute_id === id) {
                        let arrAttributes = selectedAttributes;
                        if(!arrAttributes.includes(attr)) {
                            arrAttributes.push(attr);
                            setSelectedAttributes(arrAttributes);
                        }
                    // }
                });
                // setSelectedAttributes(currentObjectType.attribute)
                // setAttributes(currentObjectType.attribute);
                // Es werden alle Attribute des gewählten Bauteiltyps dem Profil hinzugefügt
                /*if(checkedAttributes?.length > 0) {
                    checkedAttributes.forEach(id => {
                        console.log(id)
                        currentObjectType.attribute?.forEach((attr) => {
                            console.log(attr.attribute_id + ' - ' + id);
                            if(attr.attribute_id === id) {
                                let arrAttributes = selectedAttributes;
                                if(!arrAttributes.includes(attr)) {
                                    arrAttributes.push(attr);
                                    setSelectedAttributes(arrAttributes);
                                }
                            }
                        });
                    })
                }*/
            }

            setAttributes(selectedAttributes);
            setComponenttypes(selectedComponenttypes);

        // });
        setCounter(cnt)

    });

    const deleteComponenttype = (id) => {
        console.log(id);
        let cnt = new Date();
        let arr = selectedComponenttypes;
        arr?.forEach((attr, index) => {
            if(attr.objecttype_id === id) {
                arr.splice(index, 1);
                cnt++;
            }
        });
        setSelectedComponenttypes(arr);
        setComponenttypes(arr);
        setCounter(cnt)
    }

    const deleteAttribute = (id) => {
        console.log(id);
        let cnt = new Date();
        let arr = selectedAttributes;
        arr?.forEach((attr, index) => {
            if(attr.attribute_id === id) {
                arr.splice(index, 1);
                cnt++;
            }
        });
        setSelectedAttributes(arr);
        setAttributes(arr);
        setCounter(cnt)
     }

    const [currentObjectTypeId, setCurrentObjectTypeId] = useState(null);
    const [currentObjectType, setCurrentObjectType] = useState(null);
    const [showAttributes, setShowAttributes] = useState(false);

    return (
        <>
            <List style={{backgroundColor: "#FFFFFF"}}>
                <ListItem>
                    <ComponentTypeAutoComplete
                        flatList={treeFlatList}
                        setValue={setComponentTypeValue}
                        value={value}
                        label="Bauteiltypen wählen oder Text eingeben"
                    />
                </ListItem>
                {/*<ListItem>
                    <Button onClick={setComponentAndAttributes}  startIcon={<SaveIcon />}  color="primary" variant="outlined" size="large" style={{"width": "500px"}}>ausgewählten Bauteiltyp und Merkmale übernehmen </Button>
                </ListItem>*/}
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <ListComponenttype
                                items={myComponentType}
                                currentObjectTypeId={currentObjectTypeId}
                                setCurrentObjectTypeId={setCurrentObjectTypeId}
                                showAttributes={showAttributes}
                                setShowAttributes={setShowAttributes}
                                setCurrentObjectType={setCurrentObjectType}
                                checkedAttributes={checkedAttributes}
                                setCheckedAttributes={setCheckedAttributes}
                                theRealm={theRealm}
                            />
                        </Paper>
                    </Grid>
                    <RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}>
                        <Button onClick={setComponentAndAttributes}
                                startIcon={<SaveIcon />}
                                color="primary" variant="outlined"
                                style={{"marginLeft": "5px"}}
                        >&gt;&gt;</Button>
                    </RenderOnRole>
                    <Grid item>
                        <ListSelectedComponenttypeBox
                            selectedComponenttypes={selectedComponenttypes}
                            setSelectedComponenttypes={setSelectedComponenttypes}
                            selectedAttributes={selectedAttributes}
                            setSelectedAttributes={setSelectedAttributes}
                            counter={counter}
                            deleteAttribute={deleteAttribute}
                            deleteComponenttype={deleteComponenttype}
                            theRealm={theRealm}
                            />

                    </Grid>
                </Grid>
            </List>
        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimProfilesComponents);
