import {usePromiseTracker} from "react-promise-tracker";
import {ThreeDots} from "react-loader-spinner";
import React from "react";

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div
            style={{
                width: "20vw",
                height: "15vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex:999,
                position: "absolute",
                top: "25vh",
                left: "40vw",
                backgroundColor: "#b8b8b8",
                border: "2px solid #333333",
            }}
        >
            {/*<ThreeDots  color="#CDC57F" height="50" width="50" />*/}
            <ThreeDots  color="#D5CD7E" height="50" width="50" />
        </div>
    );
}

export default LoadingIndicator;