import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {withStyles} from "@mui/styles";
import {header} from "../../../shared/headerStyle";
import Checkbox from "@mui/material/Checkbox";

const StyledTableCell = withStyles((theme) => ({
    head: header,
    body: {
        fontSize: 12,
    },
}))(TableCell);

function FormRow({username, handleChange, checkedRights}) {
    console.dir(checkedRights);
    let checkRead = false;
    let checkWrite = false;
    checkedRights.rights?.forEach((r) => {
        if(r.username === username) {
            checkRead = r.read;
            checkWrite = r.write;
        }
    })

    return (
        <React.Fragment>

            <TableRow>
                <StyledTableCell>{username}</StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="read" onChange={handleChange} checked={checkRead} /></StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="write" onChange={handleChange} checked={checkWrite}/></StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}

function ProjectUsers({users, checkedRights, handleChange, realm}) {
    return (
        <Box sx={{ width: '100%' }}>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Leserecht</StyledTableCell>
                        <StyledTableCell>Schreibrecht</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                        <>
                            <FormRow username={user.username} handleChange={handleChange} checkedRights={checkedRights} />
                        </>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

}

export default ProjectUsers;