import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
// import ItemDisplay from "./ItemDisplay";
// import ItemComponenttype from "./ItemComponenttype";
import {ListItem} from "@mui/material";
// import ListItemText from "@mui/material/ListItemText";
// import {Label} from "@mui/icons-material";
import List from "@mui/material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import RenderOnRole from "../../RenderOnRole";



function ListSelectedComponenttype({selectedComponenttypes, setSelectedComponenttypes, selectedAttributes, setSelectedAttributes, counter, deleteAttribute, deleteComponenttype, theRealm}) {
    console.log(selectedComponenttypes);
    console.log(selectedAttributes);
    // const [currentObjectTypeId, setCurrentObjectTypeId] = useState(null);
    // const [showAttributes, setShowAttributes] = useState(false);

    const [, setGedoens] = useState(null)
    useEffect(()=>{
        setGedoens(selectedComponenttypes)
    }, [selectedComponenttypes]);

    // const [showAttributesSub, setShowAttributesSub] = useState(false);


    let cnt = Math.random();
    return (
        <>
            {selectedComponenttypes?.map((item) => {
                cnt++;
                // console.log(cnt);
                return (

                    <List key={'idxc_' + cnt}>
                        <ListItem style={{paddingTop: "0px", paddingBottom: "0px", alignItems:"normal"}}><div style={{float: "left", width:"30px", height: "25px"}}><RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}><DeleteIcon onClick={() => deleteComponenttype(item.objecttype_id)}/></RenderOnRole></div>{item.code} - {item.name}</ListItem>
                {
                    // selectedAttributes?.map((attr) => {
                    item.attribute?.map((attr) => {
                        if(item.attribute?.includes(attr)) {
                            cnt++;

                            return (

                                <div key={'a_' + cnt} style={{marginLeft: "2em", paddingTop: "0px", paddingBottom: "0px", alignItems:"normal"}}>
                                    {/*<div style={{float: "right", width:"25px", height: "25px"}}><RenderOnRole roles={['BROKER_ADMIN', 'ADMIN_MAP', 'TWIN_ADMIN_MAP']}><DeleteIcon onClick={() => deleteAttribute(attr.attribute_id)} /></RenderOnRole></div>*/}
                                    <div style={{float:"left", marginLeft: "2em"}}>{attr.name}</div>
                                    <div style={{clear:"both"}}></div>
                                </div>

                            )
                        }
                    })

                }

                    </List>
                );
            })}
        </>
    );


}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListSelectedComponenttype);