import React, { useContext } from "react";

import {
  TokenContext,
  RealmContext,
  DeleteAttrDialogOpenContext,
  TreeSelectedContext,
  AttrSelectedContext,
  TreeItemsStateContext, AttrIsSelectedContext, TreeSelectedItemContext
} from "../../../../Store";

import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import {trackPromise} from "react-promise-tracker";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Fenster zum Löschen des ausgewählten Attributes

// const useStyles = makeStyles((theme) => ({
//   dialog: {
//     display: "flex",
//     flexDirection: "column",
//     margin: "auto",
//     width: "50%",
//   },
//   dialogAlert: {
//     display: "flex",
//     flexDirection: "column",
//     margin: "auto",
//     width: "30%",
//   },
//   appBar: {
//     position: "relative",
//     background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1,
//   },
//   highlight: {
//     display: "inline",
//     color: "#B4AF03",
//   },
// }));

// Transition legt fest, wie das Fenster erscheint
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function DeleteAttrDialog({reloadTreeItem}) {
  const classesDialog = useStylesDialog();
  const [DeleteAttrDialogOpen, setDeleteAttrDialogOpen] = useContext(
    DeleteAttrDialogOpenContext
  );
  const [TreeSelected, setTreeSelected] = useContext(TreeSelectedContext);
  const [, setTreeItemsState] = useContext(TreeItemsStateContext);
  // const [, setTreeSelectedItem] = useContext(TreeSelectedItemContext);
  const [token] = useContext(TokenContext);
  const [realm] = useContext(RealmContext);
  const [AttrSelected] = useContext(AttrSelectedContext);
  const [, setAttrIsSelected] = useContext(AttrIsSelectedContext);

  const Attr_Delete = async () => {

    try {

      await trackPromise(
      TreeComponentTypeService.deleteComponentTypeAttribute(token, realm, AttrSelected.global_id)
          .then((res) => {
            TreeComponentTypeService.getTreeData(token, realm)
                .then((result) => {
                  setTreeItemsState(result.data);
                  console.log(res);
                  setAttrIsSelected(false);
                  reloadTreeItem().then((newItem) => {
                    if (newItem instanceof Array && newItem.length > 0) {
                      console.log(newItem[0])
                      setTreeSelected(newItem[0]);

                    }
                  })
                });
          })
    );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Dialog
        className={classesDialog.dialog50}
        open={DeleteAttrDialogOpen}
        onClose={() => setDeleteAttrDialogOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classesDialog.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDeleteAttrDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classesDialog.title}>
              Merkmal <b>{AttrSelected.name}</b> löschen
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
        <Alert variant="outlined" severity="error">
          <DialogContentText id="alert-dialog-slide-description">
            <b>Möchten Sie das Merkmal{" "}
            <div className={classesDialog.highlight}>{AttrSelected.name}</div> des
            Bauteiles{" "}
            <div className={classesDialog.highlight}>{TreeSelected.name}</div>{" "}
            wirklich löschen?</b>
          </DialogContentText>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteAttrDialogOpen(false);
            }}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              Attr_Delete();
              setDeleteAttrDialogOpen(false);
            }}
            color="primary"
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
