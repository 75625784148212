import http from "../shared/http-common";

const UploadFilesService = {
    upload(file, token, realm, onUploadProgress) {
        console.dir(file);
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("file", file);

        return http.post("/uploadfile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token
            },
            onUploadProgress,
        });
    },

    getLocaleFiles(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        // console.log('getLocaleFiles - token: ' + token);
        return http.post("/files", formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }

        );
    },

    getFile(file, token, theRealm) {
        console.dir(file);
        let formData = new FormData();

        formData.append("file", file);

        return http.get(`/getfile/${theRealm}/${file}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    import(theRealm, mapping, token, file, importType, importObjectType, importObjectTypeGuid, importObjectTypeName) {
        console.dir(mapping);
        let formData = new FormData();
        // console.log(importType);
        // console.log(importObjectType);

        //formData.append("mapping", mapping);
        formData.append("realm", theRealm);
        formData.append("filename", file);
        formData.append("importType", importType);
        formData.append("importObjectType", importObjectType);
        formData.append("importObjectTypeGuid", importObjectTypeGuid);
        formData.append("importObjectTypeName", importObjectTypeName);

        mapping.map((field) => {
            formData.append('feld_' + field.index, field.value);
            formData.append('column_' + field.index, field.field);
        });

        return http.post(`/importcsv`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteLocaleFile(file, token, realm) {
        console.dir(file);
        let formData = new FormData();

        formData.append("file", file);
        formData.append("realm", realm);

        return http.post(`/deletefile/${file}`,formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getComponentData(token, theRealm) {
        return http.get(`/getcomponentdata/${theRealm}`, {
            headers: {
                "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
            }
        });
    },

    getObjectData(type, token, theRealm) {
        let formData = new FormData();

        formData.append("type", type);
        return http.get(`/getobjectdata/${theRealm}/${type}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    insertAttachment(id, currentfile, token, theRealm, type, guid) {

        let formData = new FormData();
        formData.append("filename", currentfile.name);
        formData.append("mimetype", currentfile.type);
        formData.append("realm", theRealm);
        if(type === 'realestateobject') {
            formData.append("realestateobjectId", id);
            formData.append("realestateobjectGuid", guid);
            return http.post(`/realestateobjectAttachment/insertRealestateobjectAttachment`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'administrativeobject') {
            formData.append("administrativeobjectId", id);
            formData.append("administrativeobjectGuid", guid);
            return http.post(`/administrativeobjectAttachment/insertAdministrativeobjectAttachment`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'component') {
            formData.append("componentId", id);
            formData.append("componentGuid", guid);
            return http.post(`/attachment/insertComponentAttachment`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    },

    countAttachmentByObject(id, token, theRealm, type) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        if(type === 'realestateobject') {
            formData.append("realestateobjectId", id);
            return http.post(`/realestateobjectAttachment/countAttachmentByRealestateobject`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'administrativeobject') {
            formData.append("administrativeobjectId", id);
            return http.post(`/administrativeobjectAttachment/countAttachmentByAdministrativeobject`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'component') {
            formData.append("componentId", id);
            return http.post(`/attachment/countAttachmentByComponent`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    },

    getFiles(id, token, realm, type) {
        let formData = new FormData();
        formData.append("realm", realm);
        if(type === 'realestateobject') {
            formData.append("realestateobjectId", id);
                return http.post("/realestateobjectAttachment/listAttachmentByRealestateobject", formData, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                        }
                    }

                );
        } else if(type === 'administrativeobject') {
            formData.append("administrativeobjectId", id);
            return http.post(`/administrativeobjectAttachment/listAttachmentByAdministrativeobject`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'component') {
            formData.append("componentId", id);
            return http.post(`/attachment/listAttachmentByComponent`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    },


    deleteAttachment(guid, token, realm, type) {
        let formData = new FormData();
        formData.append("realm", realm);
        if(type === 'realestateobject') {
        formData.append("realestateobjectGuid", guid);
            return http.post("/realestateobjectAttachment/deleteAttachment", formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }
            );
        } else if(type === 'administrativeobject') {
            formData.append("administrativeobjectGuid", guid);
            return http.post(`/administrativeobjectAttachment/deleteAttachment`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } else if(type === 'component') {
            formData.append("componentGuid", guid);
            return http.post(`/attachment/deleteAttachment`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    }

}

export default UploadFilesService;

