import React, {useContext, useState} from 'react';
import axios from 'axios';
import http from "../../../shared/http-common";
import {RealmContext, TokenContext} from "../../Store";
import {trackPromise} from "react-promise-tracker";
import LoadingIndicator from "../../../shared/LoadingIndicator";

const ExcelUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [jsonResult, setJsonResult] = useState(null);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {

        try {

            await trackPromise(uploadExcelFile());

        } catch (error) {
            console.error('Fehler beim Hochladen der Datei:', error);
            setJsonResult('Fehler beim Hochladen der Datei: ' + error.toString());
        }
    };

    const uploadExcelFile = async() => {
        if (!selectedFile) {
            alert('Bitte wählen Sie eine Datei aus, bevor Sie hochladen.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await http.post(`/uploadexcel/${realm}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + token
            },
        });
        if (response.data && (response.data.error || response.data[0]?.error)) {
            if (response.data[0].error) {
                setJsonResult(response.data[0].error);
            } else if (response.data.error) {
                setJsonResult(response.data.error);
            }
        } else {
            if (response.data && response.data.guid && response.data.id) {
                setJsonResult('Die Datei wurde hochgeladen und verarbeitet');
            }
        }
    }

    return (
        <>
            <LoadingIndicator />
            <div style={{marginTop: "10px"}}>
                <h3>Excel-Datei hochladen</h3>
                <p style={{margin: "10px"}}>
                <input type="file" onChange={handleFileChange} accept=".xlsx" />
                <button onClick={handleUpload}>Hochladen</button>
                </p>
                {jsonResult && (
                    <div style={{textAlign: 'left', margin:"25px"}}>
                        <h2>Ergebnis:</h2>
                        <pre>{JSON.stringify(jsonResult, null, 2)}</pre>
                    </div>
                )}
            </div>
        </>
    );
};

export default ExcelUploader;
