import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    TokenContext,
} from "../../Store";

import { makeStyles } from "@mui/styles";

import {connect} from "react-redux";

import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import UserService from "../../../services/UserService";
import BimetaDataService from "../../../services/BimetaDataService";
import MaterialTable from "material-table";
import {header} from "../../../shared/headerStyle";


// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        /*width: "95%",
        height: "90%",
        margin: "15px",
        padding: "20px",
        float: "left",
        border: "2px solid #B4AF03",
        borderRadius: "25px",*/
        width: "100%",
        height: "100%",
        float: "left",
    },
    btListe: {
        /*width: "20vw",
        maxHeight: "65vh",*/
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

});


function BimetaData({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [bimData , setBimData] = useState('');
    const [classificationsystems, setClassificationsystems] = useState([]);
    // // const [SiteColor] = useContext(SiteColorContext);
    // const [bimProfileSelected, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);
    const [, setIsLoading] = useState(false);

    const classes = useStyles();
    // console.dir(bimProf);

    const cafmIdentifier = 'CAFMConnect.DE'

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);


    const GetBimetaDataClassificationSystems = useCallback(async (identifier) => {
        try {

            BimetaDataService.getClassificationsytem(token, theRealm, identifier)
                .then((res) => {
                    console.log(res)
                    if(res.data) {
                        const csData = []
                        for(const cs of res.data) {

                            for(const csVersion of cs.classificationSystemVersions) {
                                const myCSystem = [];
                                myCSystem['id'] = cs.id;
                                myCSystem['name'] = cs.name;
                                myCSystem['identifier'] = cs.identifier;
                                myCSystem['version'] = cs.version;
                                myCSystem['publicationDate'] = cs.publicationDate;
                                myCSystem['versionCreateDate'] = csVersion.createDate
                                myCSystem['versionName'] = csVersion.name;
                                myCSystem['versionId'] = csVersion.id;
                                myCSystem['versionPublishDate'] = csVersion.publishDate;
                                csData.push(myCSystem);
                            }
                        }
                        setClassificationsystems(csData);
                    } else {
                        // setBimData(res.data);
                    }
                    // setBimProfileSelected('');
                    // setBimProfileSelectedData([]);
                })

        } catch (e) {
            console.log(e);
        }
    }, [setBimData, theRealm, token]);

    useEffect( () => {
        async function fetchData() {
            // await trackPromise (
                await GetBimetaDataClassificationSystems(cafmIdentifier)
            // )
        }
        // trackPromise (
        setIsLoading(true)
            fetchData().then(()=>{
                setIsLoading(false)
            })
        // )
    }, []);


    return (
        <div>
            <LoadingIndicator />
            <div className={classes.katalogView}>
                <div className={classes.btListe}>
                    <BimetaClassifivationsystemListView
                        classificationsystems={classificationsystems}
                        realm={theRealm}
                        />

                </div>
                {/*<div className={classes.editView}>
                    <BimEdits GetProfilesData={GetProfilesData}/>
                </div>*/}

            </div>
        </div>
    );
};

function BimetaClassifivationsystemListView({ classificationsystems, realm }) {
    // const classes = useStyles();

    const [token] = useContext(TokenContext);
    // const [realm] = useContext(RealmContext);
    // const [, setBimProjectSelected] = useContext(BimProjectSelectedContext);
   //  const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [BimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    /*const handleClickOnRow = (profile) => {
        console.log(profile)
        setBimProfileSelected(profile);
        setBIMDialogOpen(true);
    }*/

    const tableRef = React.createRef();
    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;
    const addClasses = async (rowData) => {
        await trackPromise(
            /*BimetaDataService.getClasses(token, realm, rowData.id)
                .then(res => {
                    console.log('addClasses');
                    console.log(res);
                })*/
            BimetaDataService.getClassesVersioned(token, realm, rowData.id, rowData.versionId)
                .then(res => {
                    console.log('addClassesVersioned');
                    console.log(res);
                })
        )
    }

    const addClassificationSystem = async (rowData) => {
        await trackPromise(
            BimetaDataService.saveClassificationsytem(token, realm, rowData)
                .then(res => {
                    console.log('addClassificationSystem');
                    console.log(res);
                })
        )
    }

    const addProperties = async (rowData) => {
        await trackPromise(
            BimetaDataService.getProperties(token, realm, rowData.id)
                .then(res => {
                    console.log('addProperties');
                    console.log(res);
                })
        )
    }

    const addClassToProperty = async (classId) => {
        await trackPromise(
            BimetaDataService.get(token, realm, classId)
                .then(res => {
                    console.log('addProperties');
                    console.log(res);
                })
        )
    }

    const addEnumerations = async(rowData) => {
        await trackPromise(
            BimetaDataService.getEnumerators(token, realm, rowData.id)
                .then(res => {
                    console.log('addEnumerations');
                    console.log(res);
                })
        )
    }

    const addUnitcodes = async(rowData) => {
        await trackPromise(
            BimetaDataService.getUnitCodes(token, realm)
                .then(res => {
                    console.log('addUnitcodes');
                    console.log(res);
                })
        )
    }

    const addPropertyTypes = async(rowData) => {
        await trackPromise(
            BimetaDataService.getPropertyTypes(token, realm)
                .then(res => {
                    console.log('addPropertyTypes');
                    console.log(res);
                })
        )
    }

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title="Bimeta Classificationsystems zu CAFMConnect.DE"
                columns={[
                    { title: 'Name', field: 'name' },
                    // { title: 'Beschreibung', field: 'definition'},
                    { title: 'Version', field: 'versionName' },
                    { title: 'VersionId', field: 'versionId' },
                    { title: 'Datum CS', field: 'publicationDate', type: "date",
                        dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' }, },
                    { title: 'Version Date', field: 'versionCreateDate', type: "date",
                        dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' }, },
                    { title: 'Publish Date', field: 'versionPublishDate', type: "date",
                        dateSetting: { locale: "de-DE", format: 'dd.MM.yyyy' }, },
                    { title: 'id', field: 'id' },

                ]}
                data={classificationsystems}
                options={{
                    headerStyle: header,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    exportButton: false,
                    exportAllData: false,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,

                }}
                actions={[
                    {
                        icon: 'keyboard',
                        tooltip: 'Classificatiosystem importieren',
                        onClick: (event,rowData) => addClassificationSystem(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                    },
                    {
                        icon: 'iso',
                        tooltip: 'Classes importieren',
                        onClick: (event,rowData) => addClasses(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                    },
                    /*{
                        icon: 'keyboard',
                        tooltip: 'Properties importieren',
                        onClick: (event,rowData) => addProperties(rowData),
                        disabled: !UserService.hasRole(['TWIN_ADMIN_MAP', 'ADMIN_MAP', 'BROKER_ADMIN'])
                    },*/
                    {
                        icon: 'gradient',
                        tooltip: 'Enumeratoren importieren',
                        onClick: (event,rowData) => addEnumerations(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                    },
                    {
                        icon: 'input',
                        tooltip: 'Unitcodes importieren',
                        onClick: (event,rowData) => addUnitcodes(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                    },
                    {
                        icon: 'memory',
                        tooltip: 'Propertytypes importieren',
                        onClick: (event,rowData) => addPropertyTypes(rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                    }


                ]}
                // onRowClick={(event, rowData) => addNew(rowData)}
                // onRowClick={(event, rowData) => handleClickOnRow(rowData)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                        editRow: {
                            deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                            saveTooltip: 'Speichern',
                            cancelTooltip: 'Abbrechen'
                        }
                    }
                }}

            />

        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimetaData);