import React, {useContext, useEffect} from 'react';
import { makeStyles } from "@mui/styles";
import {TokenContext} from "../../Store";
import UserService from "../../../services/UserService";

// Home Seite

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    marginTop: "30px"
  }
});

const Home = () => {
    const [token] = useContext(TokenContext);
    const classes = useStyles();
    useEffect(()=>{
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, []);
    return (
        <div className={classes.root}>
            <h1>Map Topomatik</h1>
            <h2>ImmoSpector twin</h2>
        </div>
    )

}

export default Home