// Baumdarstellung Komponentenliste
import React, {useContext} from "react";
import {
    BIMDialogOpenContext, BimProfContext,
    RealmContext, TokenContext
} from "../../Store";
import MaterialTable from "material-table";

import {connect} from "react-redux";
import UserService from "../../../services/UserService";

import { header } from'../../../shared/headerStyle';
import {useNavigate} from "react-router-dom";
import BimProfileService from "../../../services/BimProfileService";

function BimetaProfilesListView({ profileList, GetProfilesData, addNew }) {
    // const classes = useStyles();
    const tableRef = React.createRef();
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [ , setBimProf] = useContext(BimProfContext);
    // const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [BimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    /*const handleClickOnRow = (profile) => {
        console.log(profile)
        setBimProfileSelected(profile);
        setBIMDialogOpen(true);
    }*/

    let navigate = useNavigate();

    const showProfileList = async () => {
        await GetLocalProfileData()
        navigate('/BimProfile');
    }

    const GetLocalProfileData = async () => {
        try {

            BimProfileService.getBimProfiles(token, realm)
                .then((res)=>{
                    console.log(res)
                    setBimProf(res.data);
                });

        } catch (e) {
            console.log(e);
        }
    };

    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;




    return (
        <>
        <MaterialTable
            tableRef={tableRef}
            title="Profile"
            columns={[
                { title: 'Bezeichnung', field: 'name' },
                // { title: 'Beschreibung', field: 'definition'},
                { title: 'Kategory', field: 'lifeCyclePhase' },
                { title: 'Kennzeichen', field: 'identifier' },
            ]}
            data={profileList}
            options={{
                headerStyle: header,
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,

            }}
            actions={[

                {
                    icon: 'input',
                    tooltip: 'BiMeta-Profil importieren',
                    onClick: (event,rowData) => addNew(rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN'])
                },
                {
                    icon: 'list',
                    tooltip: 'zurück zur Profil-Liste',
                    isFreeAction: true,
                    onClick: (event) => showProfileList(),
                    // disabled: !UserService.hasRole(['ADMIN_MAP', 'BROKER_ADMIN'])
                }
            ]}
            // onRowClick={(event, rowData) => handleClickOnRow(rowData)}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} von {count}',
                    labelRowsSelect: 'Zeilen',
                    labelRowsPerPage: 'Zeilen pro Seite'
                },
                toolbar: {
                    nRowsSelected: '{0} Zeile(n) ausgewählt',
                    searchTooltip: 'Suchen',
                    searchPlaceholder: 'Suchbegriff eingeben'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: 'Keine Einträge gefunden',
                    filterRow: {
                        filterTooltip: 'Filter'
                    },
                    editRow: {
                        deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                        saveTooltip: 'Speichern',
                        cancelTooltip: 'Abbrechen'
                    }
                }
            }}

        />

        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimetaProfilesListView);