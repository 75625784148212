import React, { useContext, useState, useCallback} from "react";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import {confirmWrapper} from "./confirm";
import UploadService from "../services/upload-files.service";
import http from "./http-common";
import {TokenContext} from "../components/Store";


function AttachmentList({fileInfos, setFileInfos, componentSelected, reloadComponentList, theRealm}) {
    const [token] = useContext(TokenContext);
    const [message, setMessage] = useState("");

    const deleteAttachment = useCallback(async(guid) => {
        if (await confirmWrapper('Soll die Datei wirklich gelöscht werden?')) {
            UploadService.deleteAttachment(guid, token, theRealm, 'component')
                .then((response) => {
                    console.dir(response);
                    if (response.data?.message) {
                        setMessage('Die Datei wurde gelöscht.');
                    } else if (response.data?.message === 'not found') {
                        setMessage('Die Datei wurde nicht gefunden.');
                    } else {
                        setMessage(response.data.message);
                    }
                    console.log(componentSelected.id);
                    return UploadService.getFiles(componentSelected.id, token, theRealm, 'component');
                })
                .then(async (files) => {
                    console.dir(files)
                    setFileInfos(files.data);
                    await reloadComponentList();
                })
                .catch(() => {

                });
        }
    },[token, componentSelected, theRealm, setFileInfos, reloadComponentList]);

    const downloadFile = async (file) => {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("attachmentId", file.id);
        formData.append("filename", file.name);
        let tableName = 'bauteil'
        // if(type === 'component') {
        //     tableName ='bauteil'
        // } else {
        //     tableName = type;
        // }
        formData.append("attachmentTable", tableName);

        const fetchData = await http.post("/attachment/download", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        );
        // console.dir(fetchData);
        let fetchDataModified = `data:${file.mime_type};base64,${fetchData.data.base64FileContent}`;
        console.log(fetchDataModified)
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = file.name;
        a.click();
    }

    const previewFile = async (file) => {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("attachmentId", file.id);
        formData.append("filename", file.name);
        formData.append("previewSize", 600);
        let tableName = 'bauteil'
        // if(type === 'component') {
        //     tableName ='bauteil'
        // } else {
        //     tableName = type;
        // }
        formData.append("attachmentTable", tableName);

        const fetchData = await http.post("/attachment/getPreviewFileContentById", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        );
        console.dir(fetchData);
        let fetchDataModified = `data:${file.mime_type};base64,${fetchData.data.message.base64FileContent}`;
        console.log(fetchDataModified)
        let img = document.createElement("img");
        img.src = fetchDataModified;
        // img.onclick = document.getElementById('myPreview').removeChild(img)
        if(document.getElementById('myPreview').hasChildNodes()) {
            document.getElementById('myPreview').firstChild.remove();
        }
        document.getElementById('myPreview').appendChild(img);

        // a.popover = file.name
        // a.download = file.name;
        // a.click();
    }

    return (
        <>
            {fileInfos.length > 0 ? (
                <div className="card">
                    <div className="card-header">Liste der Dateien auf dem Server</div>
                    <div id="myPreview" style={{margin:"20px"}}></div>
                    <ul className="list-group list-group-flush">
                        {fileInfos &&
                            fileInfos.map((file, index) => (
                                <li className="list-group-item" key={index}>
                                    <img key={'i' + index} src={`data:${file.mime_type};base64,${file.previewBase64content}`} alt=""/>
                                    <div key={'d' + index}>{file.name} ({file.sizeFormatted})</div>
                                    <Button key={'b1' + index} onClick={()=>downloadFile(file)}>Download</Button>
                                    <Button key={'b2' + index} onClick={()=>deleteAttachment(file.guid)}>Löschen</Button>
                                    {file.mime_type==='image/jpeg' ? (
                                    <Button key={'b3' + index} onClick={()=>previewFile(file)}>Preview</Button>
                                    ) : null
                                    }
                                </li>
                            ))}
                    </ul>
                </div>
            ) : null }
            <div className="alert alert-light" role="alert">
                {message}
            </div>
        </>
    )
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(AttachmentList);