import http from "../shared/http-common";

const ObjecttypeService = {

    getRealestateobjecttypes(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        return http.post(`/realestateobjectType/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },


    getRealestateobjectFields(realestateobjectTypeGuid, token, realm)  {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('realestateobjectTypeGuid', realestateobjectTypeGuid);
        return http.post(`/realestateobjectType/listAttributes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getAdministrativeobjecttypes(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        return http.post(`/administrativeobjectType/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getAdministrativeobjectFields(administrativeobjectTypeGuid, token, realm)  {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('administrativeobjectTypeGuid', administrativeobjectTypeGuid);
        return http.post(`/administrativeobjectType/listAttributes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveRealestateobjectTypeAttribute(realestateobjectTypeGuid, newData, token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('realestateobjectTypeGuid', realestateobjectTypeGuid);
        formData.append('guid', newData.guid);
        formData.append('label', newData.label);
        formData.append('position', newData.position ? newData.position : 0);
        formData.append('type', newData.type);
        formData.append('mappedField', newData.mappedField);
        return http.post(`/realestateobjectType/save`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveAdministrativeobjectTypeAttribute(administrativeobjectTypeGuid, newData, token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('administrativeobjectTypeGuid', administrativeobjectTypeGuid);
        formData.append('guid', newData.guid);
        formData.append('label', newData.label);
        formData.append('position', newData.position  ? newData.position : 0);
        formData.append('type', newData.type);
        formData.append('mappedField', newData.mappedField);
        return http.post(`/administrativeobjectType/save`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveComponentTypeAttribute(componentTypeGuid, newData, token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('componentTypeGuid', componentTypeGuid);
        formData.append('guid', newData.guid);
        formData.append('name', newData.name);
        // formData.append('position', newData.position);
        formData.append('type', newData.type);
        formData.append('mappedField', newData.mappedField);
        return http.post(`/componentType/saveattribute`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteRealestateobjectTypeAttribute(realestateobjectTypeGuid, guid, token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('realestateobjectTypeGuid', realestateobjectTypeGuid);
        formData.append('guid', guid);

        return http.post(`/realestateobjectType/delete`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteAdministrativeobjectTypeAttribute(administrativeobjectTypeGuid, guid, token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append('administrativeobjectTypeGuid', administrativeobjectTypeGuid);
        formData.append('guid', guid);

        return http.post(`/administrativeobjectType/delete`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }


}

export  default ObjecttypeService;