import React, { useState, useEffect, useCallback } from "react";

// Styles
import * as s from "./App.styles";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ThemeProvider as SCThemeProvider} from 'styled-components';
// Routing
import { Provider } from "react-redux";
import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";

// Components
import Sidebar from "./components/Sidebar/Sidebar";
import MainView from "./components/MainView/MainView";
import Store from "./components/Store";
import RenderOnAnonymous from "./components/RenderOnAnonymous";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";

// Services
import StoreService from "./services/StoreService";
import Welcome from "./app/Welcome";
import Redirect from "./app/Redirect";
import UserService from "./services/UserService";

import {KEYCLOAK_ALLOWED_REALMS} from './modules/config';

// Var
import { MENU1, MENU2, MENU3, MENU4, MENU5, MENU8, MENU9, MENU10 } from "./modules/config";
import RealmsRoute from "./components/RealmsRoute";


let realmId = "";
if (sessionStorage.getItem("realm_stuff")) {
    realmId = sessionStorage.getItem("realm_stuff");
}

const store = StoreService.setup();


const theme = createTheme({
    typography: {
        fontFamily: "poppins",
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                position: 'static'
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    "&.MuiTablePagination-toolbar": {
                        width: 'auto'
                    }
                }
            }

        }
    }
});

const App = () => {
    const [, setTenant] = useState(realmId);

    // console.log(realmId);
    // console.log(tenant);
    const getUserInfo = useCallback(async()=>{
        const userInfo = await UserService.userInfo();
    }, [])

    useEffect(() => {

        const tenantName = UserService.userTenant();
        if (tenantName !== realmId) {
            setTenant(tenantName);
        } else {
            setTenant(realmId);
        }
        // getUserInfo();

    }, []);
    // console.log(tenant);
    const backgroundImage = "/images/HamburgHafen.jpg";
    const sidebarHeader = {
        fullName: "ImmoSpector twin",
        shortName: "twin",
    };

    // Menü-Items im linken Menü
    const menuItems = [
        {
            name: MENU1,
            to: "/",
            icon: "/icons/home_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'MANDANT_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },
        {
            name: MENU2,
            to: "/TreeComponent",
            icon: "/icons/account_tree_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },
        {
            name: MENU3,
            to: "/BimProfile",
            icon: "/icons/contact_page_white_48dp.svg",
            subMenuItems: [
                /*{
                    name: "BiMeta-Profile",
                    to: "/bimetaprofile",
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ["ADMIN_MANDANT", 'ADMIN_MAP', 'TWIN_ADMIN_MANDANT', 'TWIN_ADMIN_MAP'],
                },*/
            ],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },
        {
            name: MENU4,
            to: "/BimProjects",
            icon: "/icons/category_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },
        /*{
            name: MENU8,
            to: "/BimUserProjects",
            icon: "/icons/category_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },*/

        {
            name: 'Objekte',
            to: `/DataobjectsList/${realmId}`,
            icon: '/icons/category_white_48dp.svg',
            subMenuItems: [

            ],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']
        },
        {
            name: 'Bauteile',
            to: `/DataComponents/${realmId}`,
            icon: '/icons/category_white_48dp.svg',
            subMenuItems: [

            ],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']
        },
/*        {
            name: 'Karte',
            to: `/MapObjects/${realmId}`,
            icon: '/icons/category_white_48dp.svg',
            subMenuItems: [

            ],
            rollen: ['ADMIN_MANDANT']
        },*/
        {
            name: 'Import',
            to: `/Import`,
            icon: '/icons/category_white_48dp.svg',
            subMenuItems: [
                {
                    name: "CSV/Excel-Dateien",
                    to: `/uploadfiles/${realmId}`,
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'],
                },
                {
                    name: "WU-DB",
                    to: "/wuimport",
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_DATA_ADMIN'],
                },
                {
                    name: MENU9,
                    to: "/BimetaData",
                    icon: "/icons/services.svg",
                    subMenuItems: [],
                    rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'],
                }
            ],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']
        },
        /*        {
                    name: 'Token-Request',
                    to: '/TokenGenerator',
                    icon: '/icons/vpn_key_white_48dp.svg',
                    subMenuItems: [],
                    rollen: ['ADMIN_MANDANT', 'BROKER_ADMIN']
                },*/
        /*{
            name: "Benutzer-Verwaltung",
            to: "/",
            icon: "/icons/shield_white_48dp.svg",
            subMenuItems: [
                {
                    name: "Mandanten",
                    to: "mandantlist",
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN'],
                },
                {
                    name: "Benutzer",
                    to: `benutzer/${tenant}`,
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ["BROKER_ADMIN"],
                },
                {
                    name: "Rollen",
                    to: "/",
                    icon: "/icons/person_add_white_48dp.svg",
                    subMenuItems: [],
                    rollen: ["BROKER_ADMIN"],
                },
            ],
            rollen: ["BROKER_ADMIN"],
        },*/
        {
            name: MENU10,
            to: `/ObjectFields/${realmId}`,
            icon: "/icons/vpn_key_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'],
        },
        {
            name: "Benutzer",
            to: `/MandantUserList/${realmId}`,
            icon: "/icons/person_add_white_48dp.svg",
            subMenuItems: [],
            rollen: ["USER_ADMIN_MANDANT", 'ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN'],
        },
        {
            name: "API-Doku",
            to: `/SwaggerDoku/${realmId}`,
            icon: "/icons/blog.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN'],
        },
        /*{
            name: "Benutzer-Extern",
            to: `/Administration/User/${realmId}`,
            icon: "/icons/person_add_white_48dp.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MANDANT', "USER_ADMIN_MANDANT"],
        },*/
        {
            name: MENU5,
            to: "/logout",
            icon: "/icons/logout_white.svg",
            subMenuItems: [],
            rollen: ['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN'],
        },
    ];

    const fonts = {
        header: "Poppins",
        menu: "Poppins",
    };
    // console.log('Hello')
    // console.log(UserService.hasRole(['TWIN_ADMIN_MAP']))
    // console.log(UserService.hasRole(['USER_MANDANT']))
    // console.log(realmId);
    if(realmId === '') {
        realmId = '';
    }
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Store>
                    <ThemeProvider theme={theme}>
                        <SCThemeProvider theme={theme}>
                        <s.App>
                            <RenderOnAnonymous realmId={realmId}>
                                <Routes>
                                    <Route path="/:realm" element={<RealmsRoute realms={KEYCLOAK_ALLOWED_REALMS}><Redirect /></RealmsRoute>}></Route>
                                    <Route path="*" element={<Welcome />}></Route>
                                </Routes>

                            </RenderOnAnonymous>
                            <RenderOnAuthenticated realmId={realmId}>
                                <Sidebar
                                    backgroundImage={backgroundImage}
                                    sidebarHeader={sidebarHeader}
                                    menuItems={menuItems}
                                    fonts={fonts}
                                    colorPalette={s.blacksilver}
                                />
                                <MainView theme={theme}/>
                            </RenderOnAuthenticated>
                        </s.App>
                        </SCThemeProvider>
                    </ThemeProvider>
                </Store>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
