//import {makeStyles} from "@mui/styles";
import {makeStyles} from "@mui/styles";

export const useStylesDialog = makeStyles((theme) => ({
    dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "75%",
        marginRight: 0,
    },
    dialog50: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },
    dialogAlert: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "30%",
    },
    dialogConfirm: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },
    appBar: {
        position: "inherit",
        background: "linear-gradient(45deg, #0B0E10 50%, #D5CD7E 90%)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    highlight: {
        display: "inline",
        color: "#D5CD7E",
    },
    formControl: {
        width: "100%",
    },
}));