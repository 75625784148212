import { combineReducers } from "redux";
// import toastsReducer from '../app/toasts/reducer';
// import fetchReducer from '../app/fetch/reducer'
import realmsReducer from '../app/mandant/mandantReducer';
import usersReducer from "../app/user/userReducer";

import brokerReducer, {keycloakTokenReducer, theRealmReducer} from "../app/start/reducer";

export default combineReducers({
  realms: realmsReducer,
  users: usersReducer,
  requestId: brokerReducer,
  theRealm: theRealmReducer,
  keycloakToken: keycloakTokenReducer
});