import http from "../shared/http-common";

const BimmetaDataService = {

    auth(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);
        // formData.append("profileId", profileId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/bimetaData/auth/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getClassificationsytem(token, realm, identifier) {
        console.log(realm, identifier);
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("identifier", identifier);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/bimetaData/classificationsystem/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveClassificationsytem(token, realm, rowdata) {
        console.log(realm, rowdata);
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("id", rowdata.id);
        formData.append("name", rowdata.name);
        formData.append("identifier", rowdata.identifier);
        formData.append("publicationDate", rowdata.publicationDate);
        formData.append("lastChange", rowdata.lastChange);
        formData.append("version", rowdata.version);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/bimetaData/saveclassificationsystem/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getClasses(token, realm, csId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("csId", csId);

        return http.post(`/bimetaData/classestop`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getClassesVersioned(token, realm, csId, versionId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("csId", csId);
        formData.append("versionId", versionId);

        return http.post(`/bimetaData/classesversioned`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getEnumerators(token, realm, csId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("csId", csId);

        return http.post(`/bimetaData/enumerators`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getProperties(token, realm, csId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("csId", csId);

        return http.post(`/bimetaData/properties`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getClassToProperty(token, realm, classId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("classId", classId);

        return http.post(`/bimetaData/classestoproperties`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getPropertyTypes(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/bimetaData/propertytypes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getUnitCodes(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/bimetaData/unitcodes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importBimetaProfiles(token, realm, id) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("id", id);

        return http.post(`/bimetaData/saveprofile`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }
}

export default BimmetaDataService;