import React, {useContext, useState} from "react";
import {TreeItemsStateContext} from "../../Store";
import DataobjectService from "../../../services/DataobjectService";
import MaterialTable from "material-table";

import {connect} from "react-redux";
import ComponentTypeAutoComplete from "../../../shared/ComponentTypeAutoComplete";
import {trackPromise} from "react-promise-tracker";
import DataComponentService from "../../../services/DataComponentService";
import {ListItem} from "@mui/material";
import List from "@mui/material/List";

import ComponentFormView from "../Dataobjects/ComponentFormView";
import Button from "@mui/material/Button";

import { header } from'../../../shared/headerStyle';
// import http from "../../../shared/http-common";
// import {fetchUserById} from "../../../app/user/api";
import UserService from "../../../services/UserService";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*const GetObjectDataByType = async (objecttype, type, theRealm, token) => {
    try {
        if(token) {
            console.log('Hello GetObjectDataByType ' + type);
            let formData = new FormData();
            formData.append("realm", theRealm);
            // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
            return http.post(`/` + objecttype + `/` + type + `/list`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
};*/

function DatacomponentTableView({dataComponents, setDatacomponents, theRealm, token, dataObjects, administrativeObjects}) {

    // const [, setDataobjectsDialogOpen] = useContext(DataobjectsDialogOpenContext);
    const [dataObjectSelected, setDataObjectSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [componentType, setComponentType] = useState(null);
    // const [attributes, setAttributes] = useState([]);
    // const [bimProf /*, setBimProf*/] = useContext(BimProfContext);
    const [componentSelected, setComponentSelected] = useState([]);
    const [attributeList, setAttributeList] = useState([]);

    // const [, setDataComponentEditDialogOpen] = useState(false);
    const [DataComponentFormDialogOpen, setDataComponentFormDialogOpen] = useState(false);
    // const [, setUploadDialogOpen] = useState(false);

    const [, setFormValues] = useState(null)

    const [filtering, setFiltering] = useState(false);
    let hideDeactivatedColumn = true;

    const tableRef = React.createRef();

    // const handleIconClick = async (guid) => {
    //         openDetailView(guid).then(()=> {
    //             setIsDetailViewOpen(true);
    //         })
    //
    // }

    const[realestateobjectGuid, setRealestateobjectGuid] = useState('');
    const [treeItemsState] = useContext(TreeItemsStateContext);



    /*useEffect(()=>{
        setIsLoading(true);

        setIsLoading(false);
    }, [theRealm, token, GetObjectDataByType]);*/


    const handleEditClick = async (event, rowData) => {
        event.preventDefault();
        console.log(rowData);
        setFormValues([]);
        setDataObjectSelected([]);
        setComponentSelected([])
        let code = rowData.componentType?.mmsCode ? rowData.componentType?.mmsCode : rowData.componentTypeCode;
        if(code) {
            await trackPromise(
                DataComponentService.getComponentByGuid(token, theRealm, rowData.guid)
                    .then((res) => {
                        console.log(res);
                        setRealestateobjectGuid(res.data?.realestateobjectGuid);
                        DataComponentService.getComponentTypeByCode(token, theRealm, code)
                            .then((result) => {
                                console.log(result);
                                if(result && result.data && result.data.length > 0 &&  result.data[0].attribute) {
                                    const componentTypeAttributes = result.data[0].attribute;
                                    // const usedMmsAttributes = [];
                                    const myArray = DataComponentService.fillAttributList(res.componentAttributeList, componentTypeAttributes);
                                    setComponentType(result.data[0]);
                                    setComponentSelected(res.data);
                                    setAttributeList(myArray);
                                    setDataComponentFormDialogOpen(true);
                                }
                            });
                    })

            );
        } else if(rowData.componentTypeObjecttypeId || rowData.componentType?.objecttypeId) {
            const cTypeId = rowData.componentTypeObjecttypeId ? rowData.componentTypeObjecttypeId : rowData.componentType?.objecttypeId;
            await trackPromise(
                DataComponentService.getComponentTypeById(token, theRealm, cTypeId)
                    .then((result) => {
                        if(result && result.data && result.data.length > 0 && result.data[0].attribute) {
                            const componentTypeAttributes = result.data[0].attribute;
                            const myArray = DataComponentService.fillAttributList(rowData.componentAttributeList, componentTypeAttributes);
                            setComponentType(result.data[0]);
                            setComponentSelected(rowData);
                            setAttributeList(myArray);
                            setDataComponentFormDialogOpen(true);
                        }
                    })
            )
        }
    }

    const handleDeactivateClick = async (object, event) => {
        event.preventDefault();
        setIsLoading(true);

        await DataComponentService.deactivateComponent(token, theRealm, object.guid)
            .then(async(result) => {
                if(result.data?.error) {
                    toast('Fehler beim Speichern: ' + result.data.error.message);
                } else {
                    setIsLoading(true);
                    setDataComponentFormDialogOpen(false)
                    await reloadComponentList();
                    setIsLoading(false);
                }
            });
    }

    const handleActivateClick = async(object, event) => {
        event.preventDefault();
        setIsLoading(true);
        await DataComponentService.activateComponent(token, theRealm, object.guid)
            .then(async(result) => {
                if(result.data?.error) {
                    toast('Fehler beim Speichern: ' + result.data.error.message);
                } else {
                    setIsLoading(true);
                    setDataComponentFormDialogOpen(false)
                    await reloadComponentList();
                    setIsLoading(false);
                }
            });
    }

    const handleSearchClick = async() => {
        console.log(realestateobjectValue);
        console.log(realestateobjectSubValue);
        console.log(value);
        await setComponentTypeValue(value, realestateobjectSubValue);
    }

    // const [, setSelected] = useState('');
    // const [selected, setSelected] = useState('');
    const [title, setTitle] = useState('');
    // const [fieldType, setFieldType] = useState('administrativeobjectType');


    // Autocomplete-Liegenschaftlist
    const [realestateobjectValue, setRealestateobjectValue] = useState(null);
    const [administrativeobjectValue, setAdministrativeobjectValue] = useState(null);
    const [realestateobjectSubValue, setRealestateobjectSubValue] = useState(null);
    const liegenschaftFlatList = [];
    const wirtschafteinheitFlatList = [];
    const [liegenschaftSubFlatList, setLiegenschaftSubFlatList] = useState([]);
    const lsfl = []
    if(dataObjects && dataObjects instanceof Array) {
        dataObjects.forEach(item => {
            convert2(item)
        });
    }

    function convert2(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        myItem.title = item.number + ' - ' + item.name;
        myItem.key = item.guid;
        myItem.type = 'realestateobject'
        liegenschaftFlatList.push(myItem);

    }

    if(administrativeObjects && administrativeObjects instanceof Array) {
        administrativeObjects.forEach(item => {
            convertAdministrativeObjects(item)
        });
    }

    function convertAdministrativeObjects(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        myItem.title = item.number + ' - ' + item.name;
        myItem.key = item.guid;
        myItem.type = 'administrativeobject'
        wirtschafteinheitFlatList.push(myItem);

    }


    function convert3(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.id;
        myItem.guid = item.guid;
        myItem.title = item.number + ' - ' + item.name;
        myItem.key = item.guid;
        lsfl.push(myItem);

    }

    const setRealestateobjectsValues = async(val) => {
        console.log(val)
        if (val) {
            setIsLoading(true);
            // wenn val ein AO ist, wird das in der Funktion im Broker behandelt und die dazugehörigen ROs geholt.
            const objs = await DataobjectService.listrealestateobjectchildren(token, theRealm, val.guid);
            // console.log(objs?.data)
            // console.log(objs?.data instanceof Array)
            if(objs?.data && objs.data instanceof Array) {
                const objects = objs.data
                objects.forEach(item => {
                    convert3(item)
                });
            }

            setLiegenschaftSubFlatList(lsfl)
            // console.log(liegenschaftSubFlatList)
            setTitle(val.title);

            if(val.type === 'administrativeobject') {
                setAdministrativeobjectValue(val);
                setRealestateobjectValue(null);
                setRealestateobjectSubValues(val)
                // setDatacomponents(null)
            } else {
                setRealestateobjectValue(val);
                setAdministrativeobjectValue(null);
                setRealestateobjectSubValues(val);
            }

            // if(value) {
            // await setComponentTypeValue(value, val);
            // }
        } else {
            setAdministrativeobjectValue(null);
            setRealestateobjectValue(null);
            setRealestateobjectSubValues(null)
            setTitle('')
        }
        setIsLoading(false)
    }

    const setRealestateobjectSubValues = async(val) => {
        setRealestateobjectSubValue(val)
        console.log(val);

        await setComponentTypeValue(value, val);

    }

    // Autocomplete-BauteiltypListe

    const [value, setValue] = React.useState(null);
    const [, setExpanded] = React.useState([]);
    const [,setMyComponentType] = useState({});

    const treeFlatList = [];

    treeItemsState.forEach(item => {
        convert(item)
    });

    function convert(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.objecttype_id;
        myItem.code = item.code;
        myItem.title = item.code + ' - ' + item.name;
        myItem.cafmId = item.cafm_id;
        myItem.globalId = item.global_id;
        myItem.key = item.global_id;
        treeFlatList.push(myItem);
        item.children_objecttypes?.forEach(item2 => {
            convert(item2)
        });
    }


    const setComponentTypeValue = async(val, myRealestateobjectValue=null) => {
        setValue(val);
        console.log(val);
        console.log(realestateobjectValue);
        console.log(realestateobjectSubValue);
        console.log(myRealestateobjectValue);
        console.log(administrativeobjectValue);

        if(!myRealestateobjectValue && realestateobjectValue) {
            myRealestateobjectValue = realestateobjectValue;
            setAdministrativeobjectValue(null);
        } else if(!myRealestateobjectValue && administrativeobjectValue) {
            myRealestateobjectValue = administrativeobjectValue;
            setRealestateobjectValue(null);
        }
        if(val) {
            const myTempComponentType = {};
            myTempComponentType.objecttypeId = val.id;
            myTempComponentType.name = val.name;
            myTempComponentType.mmsCode = val.code;
            myTempComponentType.mmsCafmId = val.cafmId;
            myTempComponentType.mmsGlobalId = val.globalId;
            setMyComponentType(myTempComponentType);
            await trackPromise(
                DataComponentService.getComponentsByComponentTypeCode(token, theRealm, val.code, myRealestateobjectValue?.guid)
                    .then((result) => {
                        console.log(result.data);
                        setDatacomponents(result.data)

                    })
            );
        } else if(!val && myRealestateobjectValue?.type === 'administrativeobject') {
            setDatacomponents([])
        } else if(!val && (myRealestateobjectValue?.guid)) {
            await trackPromise(
                DataComponentService.getComponentsToRealestateobject(token, theRealm, myRealestateobjectValue?.guid)
                    .then((result) => {
                        console.log(result.data);
                        setDatacomponents(result.data)

                    })
            );
        } else {
            setExpanded([]);

        }

    }

    const changedComponenttypeValue = (val) => {
        setComponentTypeValue(val, realestateobjectSubValue);
    }

    const reloadComponentList = async () => {
        // console.dir(tableRef.current);
        setIsLoading(true);
        let roGuid = ''
        if(realestateobjectSubValue) {
            roGuid = realestateobjectSubValue.guid
        } else {
            roGuid =realestateobjectValue?.guid
        }
        DataComponentService.getComponentsByComponentTypeCode(token, theRealm, value?.code, roGuid)
            .then((result) => {
                console.log(result.data);
                setDatacomponents(result.data);
                setIsLoading(false);
            })
    }

    const saveObject = async(formData, attributeData) => {
        console.log(formData);
        console.log(attributeData);
        // console.log(token);
        await trackPromise(
            DataComponentService.saveComponent(token, theRealm, formData, attributeData)
                .then((resp) => {
                    console.log(resp)
                    if(resp.data?.error) {
                        toast('Fehler beim Speichern: ' + resp.data.error.message);
                    } else {

                        //if(value?.code) {
                            setIsLoading(true);
                            setDataComponentFormDialogOpen(false);
                            let roGuid = ''
                            if(realestateobjectSubValue) {
                                roGuid = realestateobjectSubValue.guid
                            } else {
                                roGuid =realestateobjectValue?.guid
                            }
                            DataComponentService.getComponentsByComponentTypeCode(token, theRealm, value?.code, roGuid)
                                .then((result) => {
                                    setDatacomponents(result.data);
                                    setIsLoading(false);
                                });
                        //}
                    }
                }).catch((err) => {
                    console.log('Error:')
                    console.log(err)
                    toast('Error: ' + err?.message);
                    setIsLoading(false);
                })
        );
    }

    const addNew = () => {
        setComponentSelected([]);
        setDataComponentFormDialogOpen(true);
    }

    if(filtering) {
        hideDeactivatedColumn = false;
    }

    const tableHeight =(window.innerHeight - 162 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>
            <ToastContainer />
            <List style={{backgroundColor: "#FFFFFF"}}>
                <ListItem>
                    <ComponentTypeAutoComplete
                        flatList={liegenschaftFlatList}
                        setValue={setRealestateobjectsValues}
                        value={realestateobjectValue}
                        label="Liegenschaft wählen oder Text eingeben"
                        width={350}
                    />
                    <div style={{width: "11px"}}></div>
                    <ComponentTypeAutoComplete
                        flatList={wirtschafteinheitFlatList}
                        setValue={setRealestateobjectsValues}
                        value={administrativeobjectValue}
                        label="Wirtschaftseinheit wählen oder Text eingeben"
                        width={350}
                    />

                    <div style={{width: "88px"}}></div>
                    <Button variant="outlined"  onClick={() => {setFiltering(currentFilter => !currentFilter)}}>nach Spalten filtern</Button>
                    <div style={{width: "25px"}}></div>
                    <Button variant="outlined"  onClick={() => {handleSearchClick()}}>Suchen</Button>
                </ListItem>
                <ListItem>
                    <ComponentTypeAutoComplete
                        flatList={liegenschaftSubFlatList}
                        setValue={setRealestateobjectSubValues}
                        value={realestateobjectSubValue}
                        label="Immobilienobjekte in der ausgewählten Liegenschaft"
                    />
                    <ComponentTypeAutoComplete
                        flatList={treeFlatList}
                        setValue={changedComponenttypeValue}
                        value={value}
                        label="Bauteiltypen wählen oder Text eingeben"
                    />

                </ListItem>

            </List>
            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Immobilienobjekt', field: 'realestateobjectName'},
                    { title: 'deaktiviert', field: 'deactivated', lookup: { 0: 'nein', 1: 'ja' }, hidden: hideDeactivatedColumn }
                ]}
                data={dataComponents}

                onRowClick={(event, rowData) => {
                    if(UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])) {
                        handleEditClick(event, rowData);
                    }

                }}
                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF',
                        display: (hideDeactivatedColumn && rowData.deactivated) ? 'none': ''
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    filtering,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}
                actions={[

                    {
                        icon: 'edit',
                        tooltip: 'Editieren',
                        onClick: (event, rowData) => handleEditClick(event, rowData),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    },
                    rowData => ({
                        icon:  rowData.deactivated ? 'taskalt' : 'cancel',
                        tooltip: rowData.deactivated ? 'Aktivieren': 'Deaktivieren',
                        onClick: rowData.deactivated ? (event, rowData) => handleActivateClick(rowData, event) :(event, rowData) => handleDeactivateClick(rowData, event),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    }),
                    {
                        icon: 'add',
                        tooltip: 'Neues Bauteil anlegen',
                        isFreeAction: true,
                        onClick: (event) => addNew(),
                        disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                    }
                ]}
                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}

            />
            <ComponentFormView
                dataObjectSelected={dataObjectSelected}
                setDataComponentFormDialogOpen={setDataComponentFormDialogOpen}
                DataComponentFormDialogOpen={DataComponentFormDialogOpen}
                componentSelected={componentSelected}
                componentType={componentType}
                attributeList={attributeList}
                setAttributeList={setAttributeList}
                realestateobjectGuid={realestateobjectGuid}
                handleActivateClick={handleActivateClick}
                handleDeactivateClick={handleDeactivateClick}
                saveObject={saveObject}
                reloadComponentList={reloadComponentList}
            />

        </>
    )
}


function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DatacomponentTableView);