import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Typography} from "@mui/material";
import RenderOnRole from "../../RenderOnRole";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";

import {useStylesDialog} from "../../../shared/useStyleDialog";


function UploadDialogAppBar({setUploadDialogOpen}) {
    const classesDialog = useStylesDialog();

    return (
        <AppBar className={classesDialog.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setUploadDialogOpen(false)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classesDialog.title}>
                    Datei-Upload
                </Typography>
                <Button
                    type="submit"
                    color="inherit"
                >
                    Upload
                </Button>

            </Toolbar>
        </AppBar>
    )
}
export default UploadDialogAppBar;