import React, {useContext, useEffect} from "react";
import { useState } from 'react'
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    useMapEvents,
    LayersControl,
    ZoomControl,
    useMap
} from 'react-leaflet'
import Layers from './Layers'
import 'leaflet/dist/leaflet.css';
import {TokenContext} from "../../Store";
import {connect} from "react-redux";
import MapService from "../../../services/MapService";
import * as L from 'leaflet/dist/leaflet'

function MapObjects({theRealm}) {
    const [token] = useContext(TokenContext);
    const [siteLayer, setSiteLayer] = useState(null);
    const [myMarkers, setMyMarkers] = useState( L.layerGroup());
    const [map, setMap] = useState(null);

    // const map1 = useMap()

    useEffect(() => {

        async function fetchData() {
            const what = await MapService.getSiteFeatureList(token, theRealm);
            console.log(what);
            const myLayer = what?.data;
            setSiteLayer(myLayer)
            console.log(myLayer);
            // let geojson = L.geoJSON(myLayer).addTo(map1);
            // const b = siteLayer.getBounds();
            /*let mapData = L.featureGroup();
            let blup = L.geoJSON(myLayer, {
                local: true,
                style: {
                    weight: 5,
                    color: '#c0392b',
                },
                onEachFeature: function(feature, layer) {
                    // if (feature.properties.id === id) {
                    //     // Polyline
                    //     layer.addTo(mapData);
                    //
                    //     // Map marker
                    //     L.marker([
                    //         feature.geometry.coordinates[feature.geometry.coordinates.length - 1][1],
                    //         feature.geometry.coordinates[feature.geometry.coordinates.length - 1][0],
                    //     ], { icon: newIcon }).addTo(mapData);
                    // }
                }
            });*/
            // mapData.addTo(map);
            //
            // blup.on('data:loaded', function () {
            //     map.fitBounds(layers.getBounds());
            // });
        }
        fetchData();
        console.log(siteLayer);

    }, [token, theRealm]);

    const setMapReference = (map) => {
        setMap(map);

        myMarkers.addTo(map);
        setMyMarkers(myMarkers)

        map.on('zoomend', function() {
            let radius = 8;
            const zoom = map.getZoom();
            console.log(zoom);

            if (zoom < 18) {
                radius = 7;
            }else if(zoom < 17) {
                radius = 6;
            }else if(zoom < 16) {
                radius = 5;
            }else if(zoom < 15) {
                radius = 4;
            }else if(zoom < 14) {
                radius = 1;
            }
            myMarkers.eachLayer(function (marker) {
                marker.setRadius(radius);
            });
        });
    }

    const center = {
        lat: 58.9809976,
        lng: 16.2129849,
    };

    return (
        <>
            <MapContainer
                center={center}
                zoom={11}
                zoomControl={false}
                style={{ height: '100vh', width: '100%' }}
                whenCreated={setMapReference}
                scrollWheelZoom={true}
                preferCanvas={true}
                renderer={L.canvas()}
            >
                <Layers featureCollection={siteLayer}/>
                <ZoomControl position='topright'/>
            </MapContainer>
        </>
    )
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(MapObjects);