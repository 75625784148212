import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    TokenContext,
    BimProfileSelectedContext,
    BimProfileSelectedDataContext,
    BIMDialogOpenContext,
} from "../../Store";

import { makeStyles } from "@mui/styles";


import {connect} from "react-redux";
import BimProfileService from "../../../services/BimProfileService";

import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import UserService from "../../../services/UserService";
import BimetaProfilesListView from "./BimetaProfilesListView";


// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        /*width: "95%",
        height: "90%",
        margin: "15px",
        padding: "20px",
        float: "left",
        border: "2px solid #B4AF03",
        borderRadius: "25px",*/
        width: "100%",
        height: "100%",
        float: "left",
    },
    btListe: {
        /*width: "20vw",
        maxHeight: "65vh",*/
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

});


function BimetaProfiles({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [bimetaProfiles , setBimetaProfiles] = useState([]);
    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [SiteColor] = useContext(SiteColorContext);
    const [bimProfileSelected, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    const classes = useStyles();
    // console.dir(bimProf);

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);


    const GetProfilesData = useCallback(async () => {
        try {
            await trackPromise(
            BimProfileService.getBimetaProfiles(token, theRealm)
                .then((res) => {
                    console.log(res.data);
                    if(res.data) {
                       setBimetaProfiles(res.data);
                    }
                    setBimProfileSelected('');
                    setBimProfileSelectedData([]);
                })
            )
        } catch (e) {
            console.log(e);
        }
    }, [setBimetaProfiles, setBimProfileSelected, setBimProfileSelectedData, theRealm, token]);

    useEffect( () => {
        async function fetchData() {

                await GetProfilesData();

        }
        trackPromise (
            fetchData()
        )
    }, []);

    const addNew = async (rowData) => {
        try {
        await trackPromise(
            BimProfileService.importBimetaProfiles(token, theRealm, rowData.id)
                .then((res) => {
                    // console.log(res.data);
                    if(res.data) {
                        setBimetaProfiles(res.data);
                    }
                    // setBimProfileSelected('');
                    // setBimProfileSelectedData([]);
                })
        )

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <LoadingIndicator />
            <div className={classes.katalogView}>
                <div className={classes.btListe}>
                    {/*<DataTreeView treeItems={bimProf}  />*/}
                    <BimetaProfilesListView
                        profileList={bimetaProfiles}
                        GetProfilesData={GetProfilesData}
                        // setBimProfileSelected={setBimProfileSelected}
                        // bimProfileSelected={bimProfileSelected}
                        addNew={addNew}
                    />
                </div>
                {/*<div className={classes.editView}>
                    <BimEdits GetProfilesData={GetProfilesData}/>
                </div>*/}

            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimetaProfiles);