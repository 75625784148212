import React, {useState, useContext, useEffect, useRef} from "react";
import {
    TokenContext,
    DataobjectsDialogOpenContext
} from "../../Store";

import Button from "@mui/material/Button";

import { trackPromise } from 'react-promise-tracker';

import {connect} from "react-redux";

import http from "../../../shared/http-common";

import MaterialTable from "material-table"


import LoadingIndicator from "../../../shared/LoadingIndicator";
import {List, ListItem} from "@mui/material";
import DataObjectsEdit from "./DataObjectEdit";
import DataobjectService from "../../../services/DataobjectService";
import DataComponentEdit from "./DataComponentEdit";
import UploadDialog from "./UploadDialog";

import ObjecttypeSelectlist from "../../../shared/ObjecttypeSelectlist";

import UserService from "../../../services/UserService";

import { header } from'../../../shared/headerStyle';
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

// Style für DataTreeView
const styles = {
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        width: "100%",
        height: "100%",
        /*margin: "15px",
        padding: "20px",*/
        float: "left",
        /*border: "2px solid #B4AF03",
        borderRadius: "25px",*/
    },
    btListe: {
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

    table: {
        minWidth: 700,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};


const GetObjectSubData = async (guid, objecttype, theRealm, token) => {
    try {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);

        // return http.post(`/brokerData/getAllToObject`, formData,{
        return http.post(`/` + objecttype + `/getAllObjects`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }

}

const GetRealestateObjectSubData = async (guid, theRealm, token) => {
    try {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("guid", guid);


        // return http.post(`/brokerData/getAllToRealestateObject`, formData,{
        return http.post(`/realestateobject/getAllObjects`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }

}

const GetObjectData = async (theRealm, token) => {
    try {
        console.log('Hello GetObjectData');
        let formData = new FormData();
        formData.append("realm", theRealm);
        // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
        return http.post(`/administrativeobject/economicunit/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }
};

const GetObjectDataByType = async (obejcttype, type, theRealm, token) => {
    try {
        console.log('Hello GetObjectDataByType');
        let formData = new FormData();
        formData.append("realm", theRealm);
        // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
        return http.post(`/` +obejcttype+`/` + type + `/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }
};


function DataobjectsListTable({dataObjects, openDetailView, openRealestateDetailView, handleObjecttypeChange, theRealm, token, setDataObjects}) {
    const [, setDataobjectsDialogOpen] = useContext(DataobjectsDialogOpenContext);
    const [dataObjectSelected, setDataObjectSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [DataComponentEditDialogOpen, setDataComponentEditDialogOpen] = useState(false);
    const [DataComponentFormDialogOpen, setDataComponentFormDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const [filtering, setFiltering] = useState(false);
    const [tableDataId, setTableDataId] = useState(null);

    const tableRef = useRef();

    let navigate = useNavigate();


    const location = useLocation();

    const objectType = location?.state?.objectType;
    useEffect(() => {
        if(objectType) {
            putChangesInMotion(objectType)
        }
    }, [objectType])



    const handleEditClick = (object) => {
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            DataobjectService.getAdministrativeObject(token, theRealm, object.guid)
                .then((result) => {
                    console.log(result)
                    setDataObjectSelected(result.data);
                    setDataobjectsDialogOpen(true);
                    setIsLoading(false);
                });
        }else if(object.realestateobjectTypeGuid) {
            DataobjectService.getRealestateObject(token, theRealm, object.guid)
                .then((result) => {
                    console.log(result)
                    setDataObjectSelected(result.data);
                    setDataobjectsDialogOpen(true);
                    setIsLoading(false);
                });
        }
    }

    const handleDeactivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            DataobjectService.deactivateAdministrativeobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectData(theRealm, token).then((bla) => {
                        setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }else if(object.realestateobjectTypeGuid) {
            DataobjectService.deactivateRealestateobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectData(theRealm, token).then((bla) => {
                        setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }
    }

    const handleActivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            DataobjectService.activateAdministrativeobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectData(theRealm, token).then((bla) => {
                        setDataObjects([...bla.data]);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }else if(object.realestateobjectTypeGuid) {
            DataobjectService.activateRealestateobject(token, theRealm, object.guid)
                .then((result) => {
                    GetObjectData(theRealm, token).then((bla) => {
                        setDataObjects([...bla.data]);
                        // setDataObjectSelected(result.data);
                        setIsLoading(false);
                    });
                    // reloadObjects();
                    // setIsLoading(false);
                });
        }
    }

    const handleComponentClick = (event, rowData) => {
        setDataObjectSelected(rowData);
        setDataComponentEditDialogOpen(true);
    }

    const handleUploadClick = (event, item) => {
        console.log(item);
        event.preventDefault();
        setDataObjectSelected(item);
        setDataobjectsDialogOpen(false);
        setDataComponentEditDialogOpen(false);
        setUploadDialogOpen(true);
    }

    let bauteilActionRO = {
        icon: 'hardware',
        tooltip: 'Bauteile',
        onClick: (event, rowData) => handleComponentClick(event, rowData),
        // disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
    }
    let bauteilActionAO = '';

    const options = [
        {value: '', text: '-- Bitte wählen Sie einen Objekttypen --'},
        {value: 'territorialdivision', text: 'Gebietsgliederung'},
        {value: 'site', text: 'Liegenschaft'},
        {value: 'economicunit', text: 'Wirtschaftseinheit'},
        {value: 'parcel', text: 'Flurstück'},
        {value: 'structure', text: 'Bauwerk'},
        {value: 'building', text: 'Gebäude'},
        {value: 'storey', text: 'Geschoß'},
        {value: 'buildingentrance', text: 'Hauseingang'},
        {value: 'rentalunit', text: 'Mieteinheit'},
        {value: 'commercialunit', text: 'Gewerbeeinheit'},
        {value: 'apartment', text: 'Wohnung'},
        {value: 'room', text: 'Raum'},
        {value: 'playground', text: 'Spielplatz'},
        {value: 'parkingspace', text: 'Stellplatz'},
    ];

    const [selected, setSelected] = useState(options[0].value);
    const [title, setTitle] = useState('Bitte wählen Sie einen Objekttypen');
    const [fieldType, setFieldType] = useState('administrativeobjectType');
    const [bauteilAction, setBauteilAction] = useState('');

    let hideDeactivatedColumn = true


    const handleChange = (event) => {
        putChangesInMotion(event.target.value);
    }

    const putChangesInMotion = (val) => {
        console.log(val);
        setSelected(val);
        let objecttype, type
        switch (val) {
            case 'site':
                objecttype = 'realestateobject';
                type = 'site';
                setTitle('Liegenschaften');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'structure':
                objecttype = 'realestateobject';
                type = 'structure';
                setTitle('Bauwerke');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'building':
                objecttype = 'realestateobject';
                type = 'building';
                setTitle('Gebäude');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'room':
                objecttype = 'realestateobject';
                type = 'room';
                setTitle('Räume');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'storey':
                objecttype = 'realestateobject';
                type = 'storey';
                setTitle('Etagen');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'playground':
                objecttype = 'realestateobject';
                type = 'playground';
                setTitle('Spielplätze');
                setFieldType('realestateobjectType');
                setBauteilAction(bauteilActionRO);
                break;
            case 'economicunit':
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschafteinheiten')
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'apartment':
                objecttype = 'administrativeobject';
                type = 'apartment';
                setTitle('Wohnungen');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'rentalunit':
                objecttype = 'administrativeobject';
                type = 'rentalunit';
                setTitle('Mieteinheiten');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'commercialunit':
                objecttype = 'administrativeobject';
                type = 'commercialunit';
                setTitle('Gewerbeeinheiten');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'parkingspace':
                objecttype = 'administrativeobject';
                type = 'parkingspace';
                setTitle('Stellplatzanlagen');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'location':
                objecttype = 'administrativeobject';
                type = 'location';
                setTitle('Belegenheiten');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'buildingentrance':
                objecttype = 'administrativeobject';
                type = 'buildingentrance';
                setTitle('Hauseingänge');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'parcel':
                objecttype = 'administrativeobject';
                type = 'parcel';
                setTitle('Flurstücke');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'territorialdivision':
                objecttype = 'administrativeobject';
                type = 'territorialdivision';
                setTitle('Gebietsgliederungen');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            case 'washhouse':
                objecttype = 'administrativeobject';
                type = 'washhouse';
                setTitle('Waschhäuser');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
                break;
            default:
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschaftseinheiten');
                setFieldType('administrativeobjectType');
                setBauteilAction(bauteilActionAO);
        }


        return handleObjecttypeChange(objecttype, type)
    };

    const getColumnType = () => {
        return fieldType;
    }


    if(filtering) {
        hideDeactivatedColumn = false;
    }


    const reloadObjects = () => {
        putChangesInMotion(selected)
            .then(() => {
                const theData = tableRef.current?.state?.renderData;
                if (theData instanceof Array) {
                    if (tableDataId !== null) {
                        const _data = [...dataObjects];
                        // console.log('hey ho lets go' + tableDataId)
                        _data.forEach((item) => {
                            if (item.tableData.showDetailPanel) {
                                item.tableData.showDetailPanel = '';
                            }
                        });
                        if (_data[tableDataId]) {
                            _data[tableDataId].tableData = {
                                ..._data[tableDataId].tableData,
                                showDetailPanel: tableRef.current.props.detailPanel[0].render
                            }

                            setDataObjects(_data);
                        }
                    }
                }
            })
    }

    const tableHeight =(window.innerHeight -88 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>
            <List style={{backgroundColor: "#FFFFFF"}}>
                <ListItem>
                <ObjecttypeSelectlist
                    options={options}
                    selected={selected}
                    handleChange={handleChange}
                />
                    <div style={{width: "100px"}}></div>
                    <Button variant="outlined" onClick={() => {setFiltering(currentFilter => !currentFilter)}}>nach Spalten filtern</Button>
                </ListItem>
            </List>

        <MaterialTable
            tableRef={tableRef}
            title={title}
            columns={[
                { title: 'Bezeichnung', field: 'name' },
                { title: 'Nummer', field: 'number'},
                { title: 'Kennzeichen', field: 'mark' },
                { title: 'Typ', field: getColumnType()},
                { title: 'deaktiviert', field: 'deactivated', lookup: { 0: 'nein', 1: 'ja' }, hidden: hideDeactivatedColumn }
            ]}
            data={dataObjects}

            onRowClick={(event, rowData) => {
                    navigate('/Dataobject', {state: {subObjects: rowData, title: rowData.name, objectType: selected}})
                }
            }
            options={{
                headerStyle: header,
                rowStyle: rowData => ({
                    backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF',
                    display: (hideDeactivatedColumn && rowData.deactivated) ? 'none': ''
                }),
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                actionsColumnIndex: -1,
                filtering,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
            }}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editieren',
                    onClick: (event, rowData) => handleEditClick(rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                },
                rowData => ({
                    icon:  rowData.deactivated ? 'taskalt' : 'cancel',
                    tooltip: rowData.deactivated ? 'Aktivieren': 'Deaktivieren',
                    onClick: rowData.deactivated ? (event, rowData) => handleActivateClick(rowData, event) :(event, rowData) => handleDeactivateClick(rowData, event),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                }),
                bauteilAction,
                {
                    icon: 'upload',
                    tooltip: 'Upload',
                    onClick: (event, rowData) => handleUploadClick(event, rowData),
                    disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
                }

            ]}
            isLoading={isLoading}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} von {count}',
                    labelRowsSelect: 'Zeilen',
                    labelRowsPerPage: 'Zeilen pro Seite'
                },
                toolbar: {
                    nRowsSelected: '{0} Zeile(n) ausgewählt',
                    searchTooltip: 'Suchen',
                    searchPlaceholder: 'Suchbegriff eingeben'
                },
                header: {
                    actions: 'Aktionen'
                },
                body: {
                    emptyDataSourceMessage: 'Keine Einträge gefunden',
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        />
            <DataObjectsEdit dataObjectSelected={dataObjectSelected}
                             handleDeactivateClick={handleDeactivateClick}
                             handleActivateClick={handleActivateClick}
                             reloadObjects={reloadObjects}
            />
            <DataComponentEdit
                dataObjectSelected={dataObjectSelected}
                setDataComponentEditDialogOpen={setDataComponentEditDialogOpen}
                DataComponentEditDialogOpen={DataComponentEditDialogOpen}
                handleUploadClick={handleUploadClick}
                setDataComponentFormDialogOpen={setDataComponentFormDialogOpen}
                DataComponentFormDialogOpen={DataComponentFormDialogOpen}
            />
            <UploadDialog
                dataObjectSelected={dataObjectSelected}
                setUploadDialogOpen={setUploadDialogOpen}
                uploadDialogOpen={uploadDialogOpen}
            />

        </>
    )
}

function DataobjectsList({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);

    const [dataObjects, setDataObjects] = useState([]);
    const [, setDataObject] = useState([]);
    const [, setIsDetailViewOpen] = useState(false);
    const [, setIsLoading] = useState(false);

    let navigate = useNavigate();
    // const classes = useStyles();

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);

    useEffect(()=>{
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
            navigate('/');
        }
    }, []);


    const openDetailView = async (guid) => {
        await trackPromise(
            GetObjectSubData(guid, theRealm, token).then(
                (blup) => {
                    setDataObject(blup.data);
                    setIsDetailViewOpen(true);
                }
            )
        );
    }

    const openRealestateDetailView = async (guid) => {
        await trackPromise(
            GetRealestateObjectSubData(guid, theRealm, token).then(
                (blup) => {
                    setDataObject(blup.data);
                    setIsDetailViewOpen(true);
                }
            )
        );
    }

    const handleObjecttypeChange = (objecttype, type) => {
        setIsLoading(true);
        return trackPromise(
            GetObjectDataByType(objecttype, type, theRealm, token).then((bla) => {
                // console.log(bla);
                setDataObjects(bla.data);
                setIsLoading(false);
            }));
    };

    return (
        <div>
            <div className={styles.katalogView}>
                <LoadingIndicator/>
                <div className={styles.btListe}>

                        <DataobjectsListTable
                            dataObjects={dataObjects}
                            openDetailView={openDetailView}
                            openRealestateDetailView={openRealestateDetailView}
                            setIsDetailViewOpen={setIsDetailViewOpen}
                            theRealm={theRealm}
                            token={token}
                            setDataObjects={setDataObjects}
                            handleObjecttypeChange={handleObjecttypeChange}
                        />

                </div>

            </div>

        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataobjectsList);