import UserService from "../services/UserService";
import { useEffect, useState} from "react";
import { INCREMENT } from './start/reducer';
import {connect} from 'react-redux';


import {SET_THE_REALM} from "./start/actions";

import {useParams} from "react-router";


const Redirect = ({theRealm, setTheRealm}) => {
    const [, setMyRealm] = useState(theRealm);



    const {realm} = useParams();

    const loginRealm = async (realm) => {
        switch (realm) {
            case 'de.altoba':
                realm = 'altoba';
                break;
            case 'de.bds-hamburg':
                realm = 'bds';
                break;
            case 'de.wiederaufbau':
                realm = 'wiederaufbau';
                break;
            case 'hamburg.dhu':
                realm = 'dhu';
                break;
            case 'de.kaifu':
                realm = 'kaifu';
                break;
            case 'de.fluwog':
                realm = 'fluwog';
                break;
        }
        setMyRealm(realm);
        await UserService.automaticLogin(realm);
        setTheRealm(realm)
    }
    useEffect(() => {
        if(realm) {
            loginRealm(realm)
        }

    }, [])


    return (
        null
    )
}

function mapStateToProps({requestId, theRealm}){
    return {
        requestId: requestId,
        theRealm: theRealm,
    }
}

function mapDispatchToProps(dispatch){
    return {
        increment(){
            dispatch({ type: INCREMENT });
        },
        setTheRealm(theRealm){
            dispatch({type: SET_THE_REALM, action: theRealm});
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Redirect)