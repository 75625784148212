import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    TokenContext,
    // BimProfileSelectedContext,
    // BimProfileSelectedDataContext,
    BIMDialogOpenContext,
    // BimProfContext,
    SiteColorContext, // TreeItemsStateContext,
    BimUserProjectsContext,

} from "../../Store";
import { useTheme } from 'styled-components'
import {makeStyles} from "@mui/styles";

import {connect} from "react-redux";

import MaterialTable from "material-table";

import ProjectService from "../../../services/ProjectService";
import BimUserProjectObjects from "./BimUserProjectObjects";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import UserService from "../../../services/UserService";
import { header } from'../../../shared/headerStyle';

// Style für DataTreeView
// const useStyles = makeStyles({
const styles = {
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        width: "100%",
        height: "100%",
        /*margin: "15px",
        padding: "20px",*/
        float: "left",
        /*border: "2px solid #B4AF03",
        borderRadius: "25px",*/
    },
    btListe: {
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

    table: {
        minWidth: 700,
    },
};
//});


// User projektliste
function DataListView({projectList, setUserProject}) {
    // const classes = useStyles();
    const tableRef = React.createRef();
    // const [, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    // const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);

    const handleClickOnRow = (project) => {
        setUserProject(project);
        // setBIMDialogOpen(true);
    }

    const theme = useTheme()

    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;

    return (

    <MaterialTable
        tableRef={tableRef}
        theme={theme}
        title="Projekte"
        columns={[
            { title: 'Bezeichnung', field: 'name' },
            { title: 'Beschreibung', field: 'description'},
            { title: 'lesen', field: 'read',  lookup: { false: 'nein', true: 'ja' } },
            { title: 'schreiben', field: 'write',  lookup: { false: 'nein', true: 'ja' } },
            { title: 'Profil', field: 'bimProfileName'},
        ]}
        data={projectList}
        options={{
            headerStyle: header,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            exportButton: true,
            exportAllData: true,
            maxBodyHeight: `${tableHeight}vh`,
            minBodyHeight: `${tableHeight}vh`,
        }}
        actions={[
            {
                icon: 'wysiwyg',
                tooltip: 'Daten erfassen',
                onClick: (event, rowData) => handleClickOnRow(rowData)
            },
        ]}
        onRowClick={(event, rowData) => handleClickOnRow(rowData)}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} von {count}',
                labelRowsSelect: 'Zeilen',
                labelRowsPerPage: 'Zeilen pro Seite'
            },
            toolbar: {
                nRowsSelected: '{0} Zeile(n) ausgewählt',
                searchTooltip: 'Suchen',
                searchPlaceholder: 'Suchbegriff eingeben'
            },
            header: {
                actions: ''
            },
            body: {
                emptyDataSourceMessage: 'Keine Einträge gefunden',
                filterRow: {
                    filterTooltip: 'Filter'
                },
                editRow: {
                    deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                    saveTooltip: 'Speichern',
                    cancelTooltip: 'Abbrechen'
                }
            }
        }}

    />
    );
}

function BimUserProjects({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    // const [bimProf /*, setBimProf*/] = useContext(BimProfContext);
    // const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [SiteColor] = useContext(SiteColorContext);
    // const [, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);
    const [bimUserProjects, setBimUserProjects] = useContext(BimUserProjectsContext);
    const [userProject, setUserProject] = useState(null);

    //const classes = useStyles();

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])
    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);

    const reloadProjects = useCallback(async () => {
        ProjectService.listmyprojects(token, theRealm)
            .then((result) => {
                console.dir(result.data);
                setBimUserProjects(result.data);
            });
    }, [setBimUserProjects, theRealm, token]);

    useEffect( () => {
        console.log('Halloooo');
        async function fetchData() {
            // if (!bimUserProjects) {
            ProjectService.listmyprojects(token, theRealm)
                .then((result) => {
                    // console.dir(result);
                    setBimUserProjects(result.data);
                });
            // }
        }
        trackPromise(
            fetchData().then(()=>{
                // jo
            })
        )
    }, []);

    return (
        <div>
            <LoadingIndicator />
            <div sx={styles.katalogView}>
                <div sx={styles.btListe}>
                    {!userProject?.guid ? (
                    <DataListView projectList={bimUserProjects} setUserProject={setUserProject}/>
                        ) : (
                    <BimUserProjectObjects userProject={userProject} setUserProject={setUserProject} projectGuid={userProject?.guid} realm={theRealm} projectId={userProject.id}/>
                        )
                    }
                </div>

            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimUserProjects);