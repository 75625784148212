import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import UserAdminService from "../../../services/UserAdminService";
// import {MyTextInput} from "../../components/MyTextInput";
import {MyUserForm} from "../../MyUserForm";
// import {MyCheckbox} from "../../components/MyCheckbox";
// import {Link} from "react-router-dom";
import {fetchRealmGroupById, fetchRealmGroups, fetchRealmRoles, fetchUserById, fetchUserRoles} from "../../../app/user/api";
// import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import {Box, Card, CardContent, Typography} from "@mui/material";

export const BROKER_TENANT_GROUP = 'BrokerTenant';

const initialValues = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    theRole: [],
    enableUser: false,
    // realm: ''
}

const validationSchema = Yup.object({
    username: Yup.string()
        .required('Pflichtfeld'),
    email: Yup.string()
        .email('Ungültige E-Mail-Adresse')
        .required('Pflichtfeld'),
    password: Yup.string(),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')

});

const MandantUserForm = ({realmId, userId}) => {
    const [formValues, setFormValues] = useState(null);
    const [,setUsername] = useState('');
    const [roles, setRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [, setBenutzer] = useState(null);
    const [groups, setGroups] = useState([]);
    const [, setGroup] = useState(null);
    const [tenantId, setTenantId] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        console.log(realmId)
        async function fetchData() {
            const bla = await fetchUserById(userId, realmId);
            const rollen = await fetchRealmRoles(realmId);
            const userRollen = await fetchUserRoles(userId, realmId);

            const rollenKrams = [];
            for (const role of rollen) {
                for (const urole of userRollen) {
                    if (role.id === urole.id) {
                        rollenKrams.push(role.id);
                    }
                }
            }

            const savedValues = {
                username: bla.username,
                email: bla.email,
                firstname: bla.firstName ? bla.firstName : '',
                lastname: bla.lastName ? bla.lastName : '',
                theRole: rollenKrams,
                enableUser: bla.enabled
                // realm: ''
            }
            setFormValues(savedValues);

            const gruppen = await fetchRealmGroups(realmId);
            if (gruppen && gruppen.length > 0) {
                for (const gruppe of gruppen) {
                    if (gruppe.name === BROKER_TENANT_GROUP) {
                        let id = gruppe.id;
                        const group = await fetchRealmGroupById(id, realmId);
                        setGroup(group);
                        const tenantId = group?.attributes?.tenantId[0];
                        setTenantId(tenantId);
                        break;
                    }
                }
            }
            setBenutzer(bla);
            setUsername(bla.username);
            setUserRoles(userRollen);
            setRoles(rollen);
            setGroups(gruppen);
        }
        fetchData();

    }, [realmId, userId]);


    return (
        <React.Fragment>
            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validationSchema}
                onSubmit= {
                    async (values, { setSubmitting}) => {
                        console.dir(values);

                        const rolesToSave = [];
                        const rolesToDelete = [];
                        for (const role of roles) {
                            for(const urole of values.theRole) {
                                console.log(role.id, urole);
                                if(role.id === urole) {
                                    rolesToSave.push({id: role.id, name: role.name});
                                    break;
                                }
                            }
                        }

                        for (const role of userRoles) {
                            let toDelete = true;
                            for(const urole of values.theRole) {
                                if(role.id === urole) {
                                    toDelete = false;
                                    break;
                                }
                            }
                            if(toDelete) {
                                rolesToDelete.push({id: role.id, name: role.name});
                            }
                        }

                        let user = {};
                        if(values.password) {
                            user = {
                                // realm: realmId,
                                username: values.username,
                                email: values.email,
                                firstName: values.firstname,
                                lastName: values.lastname,
                                enabled: values.enableUser,
                                credentials: [{
                                    type: 'password',
                                    value: values.password,
                                    temporary: false
                                }],
                                attributes:{tenantId: tenantId},
                                realmRoles: values.theRole
                            }
                        } else {
                            user = {
                                // realm: realmId,
                                username: values.username,
                                email: values.email,
                                firstName: values.firstname,
                                lastName: values.lastname,
                                enabled: values.enableUser,
                                attributes:{tenantId: tenantId},
                                realmRoles: values.theRole
                            }
                        }
                        if(!userId) {
                            console.log('Hello',userId,realmId);
                            const newUserId = await UserAdminService.addUser(realmId, user, rolesToSave);
                            console.log('userId: ' + newUserId);
                        } else {
                            // console.log(userId,realmId);
                            console.dir(user);
                            await UserAdminService.editUser(userId, realmId, user, rolesToSave, rolesToDelete);
                        }
                        setSubmitting(false);
                        //dispatch(history)
                        navigate('/MandantUserList/' + realmId);
                    }
                }
                enableReinitialize
            >
                <div >
                    <Box m="0 0 1em 1em">
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Benutzer-Daten
                                </Typography>
                                <MyUserForm roles={roles} realmId={realmId} groups={groups} />
                            </CardContent>
                        </Card>
                    </Box>
                </div>
            </Formik>
        </React.Fragment>
    );
}

export default MandantUserForm;