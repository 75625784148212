import React, {useEffect} from 'react'
import { useMapEvents, TileLayer, LayersControl, LayerGroup,
    GeoJSON, } from 'react-leaflet'
import { useState } from 'react'
import * as L from 'leaflet/dist/leaflet';

function Layers({featureCollection}) {
    //const [borderData, setBorderData] = useState(featureCollection)
    // Call useMapEvents:
    const map = useMapEvents({
        // Use leaflet map event as the key and a call back with the
        // map method as the value:
        zoomend: () => {
            // Get the zoom level once zoom ended:
            console.log(map.getZoom())
        },
        moveend: () => {
            // Get bounds once move has ended:
            console.log(map.getBounds())
        }
    })

    // useEffect(() => {
    //     let layer = L.geoJSON(featureCollection).addTo(map);
    //     setTimeout(() => {
    //         console.dir(layer);
    //         // map.fitBounds(layer.getBounds());
    //     }, 3000);
    // }, featureCollection, map)



    console.log(featureCollection)

    return (
        <>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Basic Map" key="1">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Topo Map" key="2">
                    <TileLayer
                        attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                        url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                {featureCollection && featureCollection.features?.length>0 && featureCollection.features?.map((data) => {
                    console.log('in Layers');
                    console.log(data);
                    const geojson = data.geometry.coordinates[0][0]
                    const state_name = data.properties.name
                    const myKey = data.properties.guid;
                    return (
                        <>
                            <LayersControl.Overlay checked name={state_name} key={data.properties.id}>
                                <LayerGroup>
                                   {/* <GeoJSON
                                        key={myKey}
                                        data={geojson}
                                        pathOptions={{ color: 'blue', fillOpacity: 0 }}
                                        eventHandlers={{
                                            mouseover: (event) => event.target.setStyle({ fillOpacity: 0.5 }),
                                            mouseout: (event) => event.target.setStyle({ fillOpacity: 0.0 }),
                                        }}
                                    >

                                    </GeoJSON>*/}
                                </LayerGroup>
                            </LayersControl.Overlay>
                        </>
                    )
                })}
            </LayersControl>
        </>
    )
}

export default Layers