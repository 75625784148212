import React, { useContext } from "react";
import {makeStyles} from "@mui/styles"
import { SiteColorContext } from "../Store";
import TwinRoutes from "../../TwinRoutes";

// Darstellung des MainViews (des Hauptteils) neben der Sidebar

const useStyles = makeStyles((theme) => ({
  Light: {
    width: "100%",
    textAlign: "center",
    background: "#fffef5",
    color: "black",
    overflowX: "hidden",
    overflowY: "auto",
  },
  Dark: {
    width: "100%",
    textAlign: "center",
    background: "#191919",
    color: "#e5e5e5",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

const MainView = (props) => {
  const classes = useStyles();
  const [SiteColor] = useContext(SiteColorContext);

  let color;
  SiteColor ? color = classes.Light : color = classes.Dark;
  return (
      <div className={color}>
        <TwinRoutes {...props}/>
      </div>
  )
};

export default MainView;