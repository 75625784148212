import React, { useContext } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
// import TreeItem from "@mui/lab/TreeItem";
import {
  TreeSelectedContext,
  TreeSelectedItemContext,
  AttrIsSelectedContext,
  CompIsSelectedContext,
  BTNameContext,
  BTCodeContext,
  BTDescContext,
  TreeParentContext
} from "../../../Store";

// Elemente für Baum-Darstellung definieren wenn Suchfeld nicht benutzt wird (Aufruf über SearchList.js)

export const TreeItemsFromData = (treeItems, pa) => {
    const [, setTreeSelected] = useContext(TreeSelectedContext);
    const [, setTreeSelectedItem] = useContext(TreeSelectedItemContext);
    const [, setAttrIsSelected] = useContext(AttrIsSelectedContext);
    const [, setCompIsSelected] = useContext(CompIsSelectedContext);
    const [, setTreeParent] = useContext(TreeParentContext);
    const [, setBTName] = useContext(BTNameContext);
    const [, setBTCode] = useContext(BTCodeContext);
    const [, setBTDesc] = useContext(BTDescContext);
    // Unterkomponenten definieren
    if (treeItems) {
        // console.dir(treeItems);
        return treeItems.map((treeItemData) => {
            let itemName = treeItemData.name;
            let children = undefined;

            // Untergeordnete Elemente children_objecttypes --> childrens TreeItem gelesen werden zu können
            if (
                treeItemData.children_objecttypes &&
                treeItemData.children_objecttypes.length > 0
            ) {
                children = TreeItemsFromData(
                    treeItemData.children_objecttypes
                );
            }

            return (
                <>
                    <TreeItem
                        key={treeItemData.global_id}
                        nodeId={itemName}
                        label={itemName}
                        children={children}
                        onLabelClick={() => {
                            // Speicherung der Werte des ausgewählten Elementes
                            setTreeSelected(treeItemData);
                            setTreeSelectedItem(treeItemData.attribute);
                            // Attribut-Auswahl reseten
                            setAttrIsSelected(false);
                            // Bauteil-Auswahl festlegen
                            setCompIsSelected(true);
                            // Bauteil-Update Reset
                            setBTName("");
                            setBTCode("");
                            setBTDesc("");
                            setTreeParent(pa)
                        }}
                    />
                </>
            );
        });
    }
};