import React, { useEffect} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {Icon, ListItemText, Tooltip, IconButton} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import ComponentTypeAutoComplete from "../../../shared/ComponentTypeAutoComplete";
import {MySelectInput} from "../../MySelectInput";
import MenuItem from "@mui/material/MenuItem";
import {MyTextInput} from "../../MyTextInput";
import {MyCheckbox} from "../../MyCheckbox";
import {connect} from "react-redux";
import {makeStyles} from "@mui/styles";
import styled from "styled-components";
import InfoIcon from '@mui/icons-material/Info';
import {createTheme} from "@mui/material/styles";


const useStyles = makeStyles((theme) => ({

    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));

const Label = styled.label`
  left: 26px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;
  background-color: #ffffff;
  color: #888888;  
  padding-left: 4px;
  padding-right: 4px;
  top: ${(props) => (props.isFloating ? `-2px` : `35%`)};
  font-size: ${(props) => (props.isFloating ? `0.75rem` : `1rem`)};
`;

function DataComponentList({componentSelected, attributeList, treeItemsState, setComponentTypeValue, value, liegenschaftSubFlatList, setRealestateobjectSubValues, realestateobjectSubValue, componentFlatListValues, setParentComponentValue, parentComponentValue, userProject, theRealm}) {
    const classes = useStyles();

    const theme = createTheme({
        typography: {
            fontFamily: "poppins",
        }
    })

    useEffect(() => {
        if(value && !componentSelected?.componentType) {
            setComponentTypeValue(value);
        }
    },[]);

    componentSelected?.componentAttributeList?.forEach(item => {

        attributeList?.forEach(attr => {

            if(item.attributeType === attr.attributeType) {

                attr.value = item.value;
                console.dir(attr)
            }
        })
    })

    console.log('userproject');
    console.dir(userProject);
    console.dir(attributeList);

    const treeFlatList = [];
    if(userProject && userProject.componenttypes?.length > 0) {
        userProject.componenttypes.forEach(item => {
            convert(item)
        });
    } else {
        treeItemsState.forEach(item => {
            convert(item)
        });
    }
    function convert(item) {
        const myItem = {};
        myItem.name = item.name;
        myItem.id = item.objecttype_id;
        myItem.code = item.code;
        myItem.title = item.code + ' - ' + item.name;
        myItem.cafmId = item.cafm_id;
        myItem.globalId = item.global_id;
        myItem.key = item.global_id;
        treeFlatList.push(myItem);
        item.children_objecttypes?.forEach(item2 => {
            convert(item2)
        });
    }

    return (
        <>
            <List>

                {componentSelected.componentType ? (
                    <>
                        <ListItem key="1">
                            <ListItemText key="7">{componentSelected.componentType?.mmsCode} - {componentSelected.componentType?.name}</ListItemText>
                        </ListItem>
                        <ListItem key="2">
                            <InputLabel key="5" className={classes.InputLabel}>Guid</InputLabel>
                            <ListItemText key="6">{componentSelected.guid}</ListItemText>
                        </ListItem>
                    </>
                ) : (
                    <>
                        <ListItem key="3">
                            <ListItemText key="8">Bauteiltyp auswählen</ListItemText>
                        </ListItem>
                        <ListItem key="4">
                            <ComponentTypeAutoComplete
                                key="9"
                                flatList={treeFlatList}
                                setValue={setComponentTypeValue}
                                value={value}
                                label="Bauteiltypen wählen oder Text eingeben"
                            />
                        </ListItem>

                    </>
                )}

                <ListItem key={componentSelected.guid + '-1'}>
                    <MyTextInput
                        key={'mti' + componentSelected.guid + '-1'}
                        margin="dense"
                        name="name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        label="Name"
                        InputLabelProps={{ shrink: "Name"?true:false }}
                        required
                    />
                </ListItem>
                <ListItem key={componentSelected.guid + '-2'}>
                    <MyTextInput
                        key={'mti' + componentSelected.guid + '-2'}
                        margin="dense"
                        name="number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        label="Nummer"
                        InputLabelProps={{ shrink: "Nummer"?true:false }}
                    />
                </ListItem>
                <ListItem key={componentSelected.guid + '-3'}>
                    <MyTextInput
                        key={'mti' + componentSelected.guid + '-3'}
                        margin="dense"
                        name="mark"
                        type="text"
                        fullWidth
                        variant="outlined"
                        label="Kennzeichen"
                        InputLabelProps={{ shrink: "Kennzeichen"?true:false }}
                    />
                </ListItem>
                <ListItem>
                    <ComponentTypeAutoComplete
                        flatList={liegenschaftSubFlatList}
                        setValue={setRealestateobjectSubValues}
                        value={realestateobjectSubValue}
                        label="Ort (zugehöriges Immobilienobjekt)"
                    />
                    <div style={{width: "11px"}}></div>
                    <ComponentTypeAutoComplete
                        key="9"
                        flatList={componentFlatListValues}
                        setValue={setParentComponentValue}
                        value={parentComponentValue}
                        label="übergeordnetes Bauteil"
                    />
                </ListItem>


                {attributeList && attributeList.sort((a, b) => a.label.localeCompare(b.label)).map((attr, index)=> (

                    <ListItem key={'li' +index}>

                        {attr.enumerators ? (
                            <>
                                <Label key={'lab' + attr.id} isFloating={true}>{attr.label}{attr.value}</Label>
                                <MySelectInput
                                    key={attr.key}
                                    margin="dense"
                                    name={attr.name}
                                    label={attr.label}
                                    fullWidth
                                    variant="outlined"
                                    native={true}
                                    displayEmpty={true}
                                    sx={{fontFamily: 'sans-serif', color: '#333'}}
                                    // defaultValue={attr.value ? attr.value : ''}
                                    // onChange={(e) => handleChange(e)}
                                >

                                    {attr.enumerators?.map((item, index) => {
                                        let itemValue = ''
                                        let selected = ''
                                        if(item instanceof Object) {
                                            itemValue = item.wert
                                        } else {
                                            itemValue = item;
                                        }

                                        return (
                                            <option key={'m' + index} value={itemValue} >{itemValue}</option>
                                        )}
                                    )}

                                </MySelectInput>
                                {(attr.dimension || attr.definition_de || attr.description || attr.example_de) ? (
                                    <Tooltip title={
                                        <>
                                            {attr.dimension && (
                                                <div>{attr.dimension}</div>
                                            )}
                                            {attr.definition_de && (
                                                <div>{attr.definition_de}</div>
                                            )}
                                            {(attr.description && attr.definition_de !== attr.description)&& (
                                                <div>{attr.description}</div>
                                            )}
                                            {attr.example_de && (
                                                <div>{attr.example_de}</div>
                                            )}
                                        </>
                                    } data-html="true" arrow>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : null
                                }

                            </>
                        ): (
                            <>
                                {attr.identifier === 'IfcDate' || attr.identifiert ==='IfcDateTime' ? (
                                    <>
                                        <MyTextInput
                                            key={'mti' + attr.key}
                                            margin="dense"
                                            name={attr.name}
                                            type="date"
                                            fullWidth
                                            variant="outlined"
                                            label={attr.label}
                                            InputLabelProps={{ shrink: attr.label?true:false }}
                                        />
                                        {(attr.dimension || attr.definition_de || attr.description || attr.example_de) ? (
                                            <Tooltip title={
                                                <>
                                                    {attr.dimension && (
                                                        <div>{attr.dimension}</div>
                                                    )}
                                                    {attr.definition_de && (
                                                        <div>{attr.definition_de}</div>
                                                    )}
                                                    {(attr.description && attr.definition_de !== attr.description)&& (
                                                        <div>{attr.description}</div>
                                                    )}
                                                    {attr.example_de && (
                                                        <div>{attr.example_de}</div>
                                                    )}
                                                </>
                                            } data-html="true" arrow>
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null
                                        }
                                    </>
                                ): (
                                    <>
                                        {attr.data_type === 'Reelle Zahl' || attr.identifier ==='IfcReal' || attr.identifier === 'IfcInteger' || attr.identifier ==='IfcLengthMeasure' || attr.data_type == 'Ganzzahl' ? (
                                            <>
                                                <MyTextInput
                                                    key={'mti' + attr.key}
                                                    margin="dense"
                                                    name={attr.name}
                                                    type="number"
                                                    fullWidth
                                                    variant="outlined"
                                                    label={attr.label + (attr.unit ? ' [' + attr.unit + ']' : '')}
                                                    InputLabelProps={{ shrink: attr.label?true:false }}
                                                />
                                                {(attr.dimension || attr.definition_de || attr.description || attr.example_de) ? (
                                                    <Tooltip title={
                                                        <>
                                                            {attr.dimension && (
                                                                <div>{attr.dimension}</div>
                                                            )}
                                                            {attr.definition_de && (
                                                                <div>{attr.definition_de}</div>
                                                            )}
                                                            {(attr.description && attr.definition_de !== attr.description)&& (
                                                                <div>{attr.description}</div>
                                                            )}
                                                            {attr.example_de && (
                                                                <div>{attr.example_de}</div>
                                                            )}
                                                        </>
                                                    } data-html="true" arrow>
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : null
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {attr.identifier === 'IfcBoolean' || attr.identifier === 'IfcLogical' || attr.attributeType ==='IfcBoolean' ? (
                                                    <>
                                                        <MyCheckbox
                                                            name={attr.name}
                                                        >{attr.label}</MyCheckbox>
                                                        {(attr.dimension || attr.definition_de || attr.description || attr.example_de) ? (
                                                            <Tooltip title={
                                                                <>
                                                                    {attr.dimension && (
                                                                        <div>{attr.dimension}</div>
                                                                    )}
                                                                    {attr.definition_de && (
                                                                        <div>{attr.definition_de}</div>
                                                                    )}
                                                                    {(attr.description && attr.definition_de !== attr.description)&& (
                                                                        <div>{attr.description}</div>
                                                                    )}
                                                                    {attr.example_de && (
                                                                        <div>{attr.example_de}</div>
                                                                    )}
                                                                </>
                                                            } data-html="true" arrow>
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {attr.reference_field === 'realestateobject' && (attr.attributeType ==='IfcExternalReference' || attr.type ==='IfcExternalReference') ? (
                                                            <>
                                                                <ComponentTypeAutoComplete
                                                                    flatList={liegenschaftSubFlatList}
                                                                    setValue={setRealestateobjectSubValues}
                                                                    value={realestateobjectSubValue}
                                                                    label="Ort (zugehöriges Immobilienobjekt)"
                                                                />
                                                            </>
                                                        ): (
                                                            <>
                                                                {attr.reference_field === 'component' && (attr.attributeType ==='IfcExternalReference' || attr.type ==='IfcExternalReference') ? (
                                                                    <>
                                                                        {/* Bauteile des ROs */}

                                                                        <ComponentTypeAutoComplete
                                                                            key="9"
                                                                            flatList={componentFlatListValues}
                                                                            setValue={setParentComponentValue}
                                                                            value={parentComponentValue}
                                                                            label="übergeordnetes Bauteil"
                                                                        />
                                                                    </>
                                                                ): (
                                                                    <>
                                                                        <MyTextInput
                                                                            key={'mti' + attr.key}
                                                                            margin="dense"
                                                                            name={attr.name}
                                                                            type="text"
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={attr.label + (attr.unit ? ' [' + attr.unit + ']' : '')}
                                                                            InputLabelProps={{ shrink: attr.label?true:false }}
                                                                        />
                                                                        {(attr.dimension || attr.definition_de || attr.description || attr.example_de) ? (
                                                                            <Tooltip title={
                                                                                <>
                                                                                {attr.dimension && (
                                                                                    <div>{attr.dimension}</div>
                                                                                )}
                                                                                {attr.definition_de && (
                                                                                    <div>{attr.definition_de}</div>
                                                                                )}
                                                                                {(attr.description && attr.definition_de !== attr.description)&& (
                                                                                    <div>{attr.description}</div>
                                                                                )}
                                                                                {attr.example_de && (
                                                                                    <div>{attr.example_de}</div>
                                                                                )}
                                                                                </>
                                                                            } data-html="true" arrow>
                                                                            <IconButton>
                                                                                <InfoIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                    </ListItem>

                ))}

                <ListItem key={componentSelected.guid + '-4'}>
                    <MyTextInput
                        key={'mti' + componentSelected.guid + '-4'}
                        margin="dense"
                        name="geom"
                        type="text"
                        fullWidth
                        variant="outlined"
                        label="Geometrie"
                        InputLabelProps={{ shrink: "Geometrie"?true:false }}
                    />
                </ListItem>

                {componentSelected && componentSelected.id ? (
                    <ListItem key="2">
                        <InputLabel  key="10" className={classes.InputLabel}>deaktiviert</InputLabel>
                        <ListItemText  key="11">{componentSelected.deactivated ? 'ja': 'nein'}</ListItemText>
                    </ListItem>
                ): null}

            </List>
        </>
    )
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataComponentList);