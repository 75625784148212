import React, {useContext, useEffect, useState, useCallback} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import UserAdminService from "../../../services/UserAdminService";
// import {MyTextInput} from "../../components/MyTextInput";
import {MyUserForm} from "../../MyUserForm";
// import {MyCheckbox} from "../../components/MyCheckbox";
// import {Link} from "react-router-dom";
import {fetchRealmGroupById, fetchRealmGroups, fetchRealmRoles} from "../../../app/user/api";
// import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { BROKER_TENANT_GROUP } from "../../../modules/config";
import UserService from "../../../services/UserService";
import {TokenContext} from "../../Store";

const initialValues = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    theRole: [],
    enableUser: false,
    // realm: ''
}

const validationSchema = Yup.object({
        username: Yup.string()
            .required('Pflichtfeld'),
        email: Yup.string()
            .email('Ungültige E-Mail-Adresse')
            .required('Pflichtfeld'),
        password: Yup.string(),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')

    })

const MandantUserFormCreate = ({realmId}) => {
    const [token] = useContext(TokenContext);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [, setGroup] = useState(null);
    const [tenantId, setTenantId] = useState(0);
    let navigate = useNavigate();


    const fetchData = useCallback(async () => {
        if(UserService.hasRole(['ADMIN_MANDANT', 'BROKER_ADMIN', 'USER_ADMIN_MANDANT'])) {
            const rollen = await fetchRealmRoles(realmId);
            setRoles(rollen);
        }
        const gruppen = await fetchRealmGroups(realmId);
        console.log(gruppen)
        if (gruppen && gruppen.length > 0) {
            for (const gruppe of gruppen) {
                if (gruppe.name === BROKER_TENANT_GROUP) {
                    let id = gruppe.id;
                    const group = await fetchRealmGroupById(id, realmId);
                    console.log(group);
                    setGroup(group);
                    if(group?.attributes && group?.attributes?.tenantId instanceof Array) {
                        const tenantId = group?.attributes?.tenantId[0];
                        setTenantId(tenantId);
                    }
                    break;
                }
            }
        }
        setGroups(gruppen);
    })

    useEffect(() => {
        console.log(realmId)

        fetchData();

    }, [realmId]);


    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit= {
                    async (values, { setSubmitting}) => {
                        console.dir(values);

                        const rolesToSave = [];
                        for (const role of roles) {
                            for(const urole of values.theRole) {
                                // console.log(role.id, urole);
                                if(role.id === urole) {
                                    rolesToSave.push({id: role.id, name: role.name});
                                    break;
                                }
                            }
                        }


                        let user = {};
                        if(values.password) {
                            user = {
                                // realm: realmId,
                                username: values.username,
                                email: values.email,
                                firstName: values.firstname,
                                lastName: values.lastname,
                                enabled: values.enableUser,
                                credentials: [{
                                    type: 'password',
                                    value: values.password,
                                    temporary: false
                                }],
                                attributes:{tenantId: tenantId},
                                realmRoles: values.theRole
                            }
                        } else {
                            user = {
                                // realm: realmId,
                                username: values.username,
                                email: values.email,
                                firstName: values.firstname,
                                lastName: values.lastname,
                                enabled: values.enableUser,
                                attributes:{tenantId: tenantId},
                                realmRoles: values.theRole
                            }
                        }
                        UserAdminService.setRealm(realmId);
                        UserAdminService.setToken(token);
                        const newUserId = await UserAdminService.addUser(realmId, user, rolesToSave);
                        console.log('userId: ' + newUserId);

                        setSubmitting(false);
                        //dispatch(history)
                        navigate('/MandantUserList/' + realmId);
                    }
                }
                enableReinitialize
            >
                <MyUserForm roles={roles} realmId={realmId} groups={groups}/>


            </Formik>
        </React.Fragment>
    );
}

export default MandantUserFormCreate;