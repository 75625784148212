import UserAdminService from "../../services/UserAdminService";
import RealmAdminService from "../../services/RealmAdminService";

export async function fetchUserList(realmId) {

    const token = sessionStorage.getItem('kctoken');
    // console.log(token);
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
   //  console.log('hello');
    return await UserAdminService.getUsersList();

}

export async function fetchUserById(userId, realmId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
    // console.log('fetchUserById: ' +realmId);
    const user = {id: userId, realm: realmId};
    return await UserAdminService.getUserById(user);
}

export async function fetchUserRoles(userId, realmId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
    const user = {id: userId, realm: realmId};

    return await UserAdminService.getUserRoles(user);
}

export async function fetchUserGroupRoles(userId, realmId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
    const user = {id: userId, realm: realmId};

    return await UserAdminService.getUserGroupRoles(user);
}

export async function fetchRealmRoles(realmId) {
    const token = sessionStorage.getItem('kctoken');
    RealmAdminService.setToken(token);
    RealmAdminService.setRealm(realmId);
    return await RealmAdminService.getRolesList(realmId);
}

export async function fetchRealmGroups(realmId) {
    const token = sessionStorage.getItem('kctoken');
    RealmAdminService.setToken(token);
    RealmAdminService.setRealm(realmId);
    return await RealmAdminService.getGroups(realmId);
}

export async function fetchRealmGroupById(groupId, realmId) {
    const token = sessionStorage.getItem('kctoken');
    RealmAdminService.setToken(token);
    RealmAdminService.setRealm(realmId);
    return await RealmAdminService.getGroupById(groupId, realmId);
}

export async function fetchUserGroups(realmId, userId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
    const user = {id: userId, realm: realmId};
    return await UserAdminService.getUserGroups(user);
}

export async function addUserToGroup(realmId, userId, groupId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);

    return await UserAdminService.addUserToGroup(userId, groupId, realmId);
}

export async function removeUserFromGroup(realmId, userId, groupId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);

    return await UserAdminService.removeUserFromGroup(userId, groupId, realmId);
}

export async function fetchUserClientRoles(realmId, userId, clientId) {
    const token = sessionStorage.getItem('kctoken');
    UserAdminService.setToken(token);
    UserAdminService.setRealm(realmId);
    return await UserAdminService.getUserClientRoles(realmId, userId, clientId);
}

export async function fetchRealmClients(realmId) {
    const token = sessionStorage.getItem('kctoken');
    RealmAdminService.setToken(token);
    RealmAdminService.setRealm(realmId);
    return await RealmAdminService.getClients(realmId);
}