import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, {useEffect, useState} from "react";
import ListDisplay from "./ListDisplay";
// import EditComp from "../TreeComponent/EditComp";
import {makeStyles} from "@mui/styles";
import ListComponenttype from "./ListComponenttype";
import ListDisplayAttributes from "./ListDisplayAttributes";

const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    selected: {
        backgroundColor: "rgba(240,178,175)",
        fontWeight: "bold",
    }
});

function ItemComponenttype({data, index, labelId, currentObjectTypeId, setCurrentObjectTypeId, setCurrentObjectType, handleClick, showAttributes, setShowAttributes, showAttributesSub, setShowAttributesSub, checkedAttributes, setCheckedAttributes, theRealm}) {
    const classes = useStyles();
    // const [showAttributesSub, setShowAttributesSub] = useState(false);

    const [currentAttributeId, setCurrentAttributeId] = useState(null);
    // const [checkedAttributes, setCheckedAttributes] = useState([]);

    useEffect(()=>{
        console.log(currentObjectTypeId, index)
        if(currentObjectTypeId === index) {
            setShowAttributes(true);
        } else {
            // setShowAttributes(false);
        }
    },[index, currentObjectTypeId, setShowAttributes])


    const handleToggleAttributes = (value) => {
        console.log(value)

        const currentIndex = checkedAttributes.indexOf(value);
        if (currentIndex === -1) {
            checkedAttributes.push(value);
            // attributes.push(componentTypeId + '_' + value);
            setCurrentAttributeId(value);
        } else {
            checkedAttributes.splice(currentIndex, 1);
            // attributes.splice(currentIndex, 1);
            setCurrentAttributeId(new Date());
        }
        setCheckedAttributes(checkedAttributes);
        // setCurrentRightObjectTypeId(currentRightObjectTypeId)
        // setCheckedRight(checkedRight);

        // setAttributes(attributes);
    }
    // console.log('ItemDisplay');
    // console.log('ItemDisplay: ' + checkedItems + ' - ' + index);
    let showChildren = data?.children_objecttypes?.length > 0;
    // let checkedAttributes = [];
    // let currentAttributeId = null;
    return (
        <>
            <ListItem key={index} role="listitem" button onClick={(e) => handleClick(index, data)}>

                <ListItemIcon>
                    <Checkbox
                        checked={currentObjectTypeId === data?.objecttype_id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={<span >{`${data?.code} - ${data?.name}`}</span>}/>


            </ListItem>
            {/*{(showAttributes && currentObjectTypeId === data?.objecttype_id) ?
                <ListItem>
                    <ListDisplayAttributes
                        items={data.attribute}
                        componentTypeId={index}
                        handleToggleAttributes={handleToggleAttributes}
                        checkedAttributes={checkedAttributes}
                        currentAttributeId={currentAttributeId}/></ListItem> : null}*/}
            {showChildren? <ListComponenttype
                items={data?.children_objecttypes}
                currentObjectTypeId={currentObjectTypeId}
                setCurrentObjectTypeId={setCurrentObjectTypeId}
                showAttributes={showAttributesSub}
                setShowAttributes={setShowAttributesSub}
                setCurrentObjectType={setCurrentObjectType}
                checkedAttributes={checkedAttributes}
                setCheckedAttributes={setCheckedAttributes}
                theRealm={theRealm}/> : null}
        </>
    );
}

export default ItemComponenttype;