import UserService from "../services/UserService";
import React, {useCallback, useEffect, useRef, useState} from "react";
import { INCREMENT } from './start/reducer';
import {connect} from 'react-redux';
import {fetchUserRealms} from "../services/BrokerUserService";
// import {MyRealmSelect} from "../components/MyRealmSelect";
// import * as actions from "./start/actions";
import {SET_THE_REALM} from "./start/actions";
// import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {EXTERN_USERADMINISTRATION_URL} from "../modules/config";
import {useParams} from "react-router";


const Welcome = ({requestId, theRealm, increment, setTheRealm}) => {
    const [myRealm, setMyRealm] = useState(theRealm);
    const [isSending, setIsSending] = useState(false);
    const isMounted = useRef(true);
    const [username] = useState('');
    const [, setMessage] = useState('');
    const [, setRealms] = useState([]);


    const {realm} = useParams();



    // set isMounted to false when we unmount the component
    useEffect(() => {

        return () => {
            isMounted.current = false
        }
    }, [])

    /*const sendRequest = useCallback(async () => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        console.log(username)
        if(username !== '') {
            increment();
            const realms = await fetchUserRealms(username, requestId);
            console.log(realms)
            // let sel = '';
            if(realms && realms.length > 0) {
                if(realms.length === 1) {
                    setRealm(realms[0]);
                    UserService.automaticLogin(realms[0]);
                    setTheRealm(realms[0])
                } else {
                    setRealms(realms);
                }
            } else {
                setMessage('kein Mandant gefunden');
            }
        }
        //await API.sendRequest()
        // once the request is sent, update state again
        // if (isMounted.current) // only update if we are still mounted
        setIsSending(false)
    }, [isSending, username, increment, requestId, setTheRealm]) // update the callback if the state changes
*/
/*
    const onChangeSelect = (e) => {
        setRealm(e.target.value);
        setTheRealm(e.target.value);
    }
*/

    const loginRealm = async (realm) => {
        switch (realm) {
            case 'de.altoba':
                realm = 'altoba';
                break;
            case 'de.bds-hamburg':
                realm = 'bds';
                break;
            case 'de.wiederaufbau':
                realm = 'wiederaufbau';
                break;
            case 'hamburg.dhu':
                realm = 'dhu';
                break;
            case 'de.kaifu':
                realm = 'kaifu';
                break;
            case 'de.fluwog':
                realm = 'fluwog';
                break;
        }
        setMyRealm(realm);
        await UserService.automaticLogin(realm);
        setTheRealm(realm)
    }
    useEffect(() => {
        if(realm) {
            loginRealm(realm)
        }

    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your form submission logic here
        loginRealm(myRealm)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // event.preventDefault();
        }
    };

    return (
        <div className="container">
            <div className="jumbotron">
                <h1>TWIN Login</h1>


                <form autoComplete="on" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                    <p></p>
                    <p>
                        Falls sie die entsprechende URL zum Einloggen bei Ihrem Mandanten nicht zur Hand haben<br/>
                        können Sie hier seinen Namen eingeben (bitte nicht mit Ihrem Benutzernamen verwechseln)<br/>

                    </p>
                    <p>
                        <input
                            type="text"
                            name="myRealm"
                            autoFocus
                            placeholder="Mandanten-Name eingeben"
                            value={realm}
                            onChange={e => setMyRealm(e.target.value)}
                            style={{width: "200px"}}
                        />

                    </p>
                    {/*<p><MyRealmSelect realms={realms} realm={realm} onChangeSelect={onChangeSelect} /></p>
                    <p>{message}</p>*/}

                </form>
                <p>
                    <Button type="button" color="primary" variant="outlined" size="large" disabled={isSending} onClick={()=> {loginRealm(myRealm)}}>Login</Button>

                </p>
                {/*<button className="btn btn-lg btn-warning" onClick={() => UserService.doLogin()}>Admin Login</button>*/}

                {/*<p>
                    <Button type="button" color="primary" variant="outlined" size="large"  onClick={() => loginRealm('Billetest')}>Einloggen in Twin - Billetest</Button>
                </p>
                <p>
                    <Button type="button" color="primary" variant="outlined" size="large"  onClick={() => loginRealm('wiederaufbau')}>Einloggen in Twin - Wiederaufbau</Button>
                </p>
                <p>
                    <Button type="button" color="primary" variant="outlined" size="large"  onClick={() => loginRealm('bds')}>Einloggen in Twin - BDS</Button>
                </p>*/}
                <p>
                    <a href={`${EXTERN_USERADMINISTRATION_URL}/`+myRealm} target="extUV"><Button type="button" color="primary" variant="outlined" size="large" >Benutzerverwaltung</Button></a>
                </p>

            </div>
        </div>
    )
}

function mapStateToProps({requestId, theRealm}){
    return {
        requestId: requestId,
        theRealm: theRealm,
    }
}

function mapDispatchToProps(dispatch){
    return {
        increment(){
            dispatch({ type: INCREMENT });
        },
        setTheRealm(theRealm){
            dispatch({type: SET_THE_REALM, action: theRealm});
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Welcome)