import {connect} from "react-redux";
import React from "react";
// import ItemDisplayRight from "./ItemDisplayRight";
import ItemDisplayAttribute from "./ItemDisplayAttribute";


function ListDisplayAttributes({items, componentTypeId, handleToggleAttributes, checkedAttributes, currentAttributeId, theRealm}) {
    // console.log('ListDisplayAttributes: ' +checkedAttributes);
    return (
        <div style={{marginLeft: "2em"}}>
            {items.map((data) => {
                // console.log("attr");
                // console.log(data);
                const labelId = `transfer-list-item-${data.attribute_id}-label`;
                return (
                    <ItemDisplayAttribute
                        key={data.attribute_id}
                        data={data}
                        index={data.attribute_id}
                        labelId={labelId}
                        handleToggleAttributes={handleToggleAttributes}
                        checkedAttributes={checkedAttributes}
                        componentTypeId={componentTypeId}
                        />
                );
            })}
        </div>
    );

}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListDisplayAttributes);