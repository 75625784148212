import React, {useState, useContext, useEffect, useCallback, useLayoutEffect} from "react";
import {DataobjectsDialogOpenContext, TokenContext} from "../../Store";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {Form, Formik} from "formik";
import http from "../../../shared/http-common";
import DataObjectFormItems from "../Dataobjects/DataobjectFormItems";
import DataobjectService from "../../../services/DataobjectService";
import {connect} from "react-redux";
import UploadDialog from "./UploadDialog";
import UploadService from "../../../services/upload-files.service";
import {confirmWrapper} from "../../../shared/confirm";

import {useStylesDialog} from "../../../shared/useStyleDialog";

// Fenster zum Anlegen eines Profils

const useStyles = makeStyles((theme) => ({
/*    dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "70%",
        marginRight: 0,
    },
    appBar: {
        position: "relative",
        background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },*/
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));

const initialValues = {
    name: '',
    description: '',
    componenttypes: [],
    guid: '',
    id: null,
    instanceTypeId: null,
    deactivated: 0
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function DataObjectsEdit({dataObjectSelected, handleDeactivateClick, handleActivateClick, reloadObjects, theRealm}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();
    const [DataobjectsDialogOpen, setDataobjectsDialogOpen] = useContext(DataobjectsDialogOpenContext);
    const [token] = useContext(TokenContext);
    // const [realm] = useContext(RealmContext);
    const [, setName] = useState("");
    const [, setNumber] = useState("");
    const [, setMark] = useState("");
    const [, setDescription] = useState("");
    const [, setDeactivated] = useState(0);
    const [instanceTypeId, setInstanceTypeId] = useState(null);
    const [instanceTypeList, setInstanceTypeList] = useState([]);
    const [guid, setGuid] = useState("");
    const [id, setId] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [, setComponenttypes] = useState([]);
    const [, setComponenttypeItems] = useState([]);
    const [, setAttributes] = useState([]);
    // const [bimProf /*, setBimProf*/] = useContext(BimProfContext);
    const [options, setOptions] = useState([]);
    const [attributeList, setAttributeList] = useState([]);

    const [message, setMessage] = useState("");
    const [fileInfos, setFileInfos] = useState([]);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const [realestateobjectParentValue, setRealestateobjectParentValue] = useState(null);
    const [administrativeobjectParentValue, setAdministrativeobjectParentValue] = useState(null);

    const GetInstanceTypeList = useCallback(async () => {
        try {
            let formData = new FormData();
            formData.append("realm", theRealm);
            return http.post(`/lookupTable/getInstanceTypeLookup`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    }, [theRealm, token]);

    useEffect(() => {
        if(dataObjectSelected.administrativeobjectTypeGuid) {
            DataobjectService.getAdministrativeobjectTypeAttributes(token, theRealm, dataObjectSelected.administrativeobjectTypeGuid)
                .then((result) => {
                    console.log(result);
                    setAttributeList(result.data);
                });
            if(dataObjectSelected.parentAdministrativeobjectGuid) {
                const myItem = {};
                myItem.name = dataObjectSelected.parentAdministrativeobjectName;
                myItem.id = dataObjectSelected.parentAdministrativeobjectId;
                myItem.guid = dataObjectSelected.parentAdministrativeobjectGuid;
                myItem.title = dataObjectSelected.parentAdministrativeobjectName;
                myItem.key = dataObjectSelected.parentAdministrativeobjectGuid;
                setAdministrativeobjectParentValue(myItem);
            } else {
                setAdministrativeobjectParentValue(null);
            }
        } else if(dataObjectSelected.realestateobjectTypeGuid) {
            DataobjectService.getRealestateobjectTypeAttributes(token, theRealm, dataObjectSelected.realestateobjectTypeGuid)
                .then((result) => {
                    console.log(result);
                    setAttributeList(result.data);
                });

            if(dataObjectSelected.parentRealestateobjectGuid) {
                const myItem = {};
                myItem.name = dataObjectSelected.parentRealestateobjectName;
                myItem.id = dataObjectSelected.parentRealestateobjectId;
                myItem.guid = dataObjectSelected.parentRealestateobjectGuid;
                myItem.title = dataObjectSelected.parentRealestateobjectName;
                myItem.key = dataObjectSelected.parentRealestateobjectGuid;
                setRealestateobjectParentValue(myItem);
            } else {
                setRealestateobjectParentValue(null);
            }
        }
    }, [dataObjectSelected, theRealm, token, setRealestateobjectParentValue]);

    useLayoutEffect(()=>{
        console.log('hello DataObject');
        console.dir(dataObjectSelected);
        setComponenttypes([]);
        setAttributes([]);
        setComponenttypeItems([]);
        if(dataObjectSelected &&  dataObjectSelected.id) {
            setName(dataObjectSelected.name);
            setNumber(dataObjectSelected.number);
            setMark(dataObjectSelected.mark);
            setDescription(dataObjectSelected.description);
            setGuid(dataObjectSelected.guid);
            setId(dataObjectSelected.id);
            setInstanceTypeId(dataObjectSelected.instanceTypeId);
            setDeactivated(dataObjectSelected.deactivated)

            const savedValues = {
                name: dataObjectSelected.name,
                number: dataObjectSelected.number,
                mark: dataObjectSelected.mark,
                description: dataObjectSelected.description,
                guid: dataObjectSelected.guid,
                id: dataObjectSelected.id,
                instanceTypeId: dataObjectSelected.instanceTypeId,
                deactivated: dataObjectSelected.deactivated
            }

            if(dataObjectSelected.administrativeobjectAttributeList) {
                dataObjectSelected.administrativeobjectAttributeList.forEach((item, index) => {
                    savedValues['attr_'+item.attributeTypeGuid] = item.value;
                });
            } else if(dataObjectSelected.realestateobjectAttributeList) {
                dataObjectSelected.realestateobjectAttributeList.forEach((item, index) => {
                    console.log(item)
                    let myItemValue = item.value
                    attributeList.forEach((attr) => {
                        console.log(attr)
                        if(attr.guid === item.attributeTypeGuid) {
                            if(attr.type === 'boolean') {
                                if(item.value === true || item.value === "true" || item.value === 1 || item.value === "1") {
                                    myItemValue = true
                                } else {
                                    myItemValue = false
                                }
                            }
                        }
                    })
                    savedValues['attr_'+item.attributeTypeGuid] = myItemValue;
                });
            }

            // setDataobjectsDialogOpen(true);

            setFormValues(savedValues);

            setMessage('');
            async function doSomething() {
                await afterUploadWork();
            }
            doSomething();
        } else {
            setMessage('');
            setFileInfos([]);
            setName("");
            setNumber("");
            setMark("");
            setDescription("");
            setGuid("");
            setId(null);
            setInstanceTypeId(null);
            setComponenttypes([]);
            setAttributes([]);
            setDeactivated(0)
            const savedValues = {
                name: "",
                number: "",
                mark: "",
                description: "",
                guid: "",
                id: null,
                instanceTypeId: null,
            }
            setFormValues(savedValues);
        }

        const instanceOptions = [];
        if(token && token !== 'Kein Token generiert!') {
            GetInstanceTypeList().then((result) => {
                    console.log('GetInstanceTypeList');
                    console.log(result.data)
                    setInstanceTypeList(result.data);
                    if (instanceTypeList && instanceTypeList.length > 0) {
                        instanceTypeList.forEach((prof, index) => {
                            instanceOptions.push({value: prof.id, label: prof.label})
                        });
                        setOptions(instanceOptions);
                    }
                }
            )
        }

    }, [dataObjectSelected, theRealm, token, GetInstanceTypeList]);

    const onChangeInstanceType = (selectedOption) => {
        console.log(selectedOption);
        setInstanceTypeId(selectedOption.value);
    }

    const deactivateClick = async(event) => {
        if (await confirmWrapper('Soll das Objekt wirklich deaktiviert werden?')) {
            setDataobjectsDialogOpen(false);
            handleDeactivateClick(dataObjectSelected, event);
        }
    }

    const activateClick = (event) => {
        setDataobjectsDialogOpen(false);
        handleActivateClick(dataObjectSelected, event);
    }

    const saveObject = async (formData, attributeData) => {
        if(dataObjectSelected.administrativeobjectTypeGuid) {
            DataobjectService.saveAdministrativeobject(token, theRealm, dataObjectSelected.administrativeobjectTypeGuid, formData, attributeData)
                .then(async(result) => {
                    console.log(result);
                    // setAttributeList(result.data);
                    await reloadObjects();
                });
        } else if(dataObjectSelected.realestateobjectTypeGuid) {
            DataobjectService.saveRealestateobject(token, theRealm, dataObjectSelected.realestateobjectTypeGuid, formData, attributeData)
                .then(async (result) => {
                    console.log(result);
                    // setAttributeList(result.data);
                    await reloadObjects();
                });
        }
        // await reloadProjects();
        setDataobjectsDialogOpen(false);
    }

    const afterUploadWork = async () => {
        let type = '';
        if(dataObjectSelected.administrativeobjectTypeGuid) {
            type = 'administrativeobject';
        } else {
            type = 'realestateobject';
        }
        UploadService.getFiles(dataObjectSelected.id, token, theRealm, type)
            .then(async(files) => {
                console.log('hallo Dateien:')
                console.dir(files);
                setFileInfos(files.data);
            })
    }

    const deleteAttachment = useCallback(async(guid) => {
        if (await confirmWrapper('Soll die Datei wirklich gelöscht werden?')) {
            let type = '';
            if (dataObjectSelected.administrativeobjectTypeGuid) {
                type = 'administrativeobject';
            } else {
                type = 'realestateobject';
            }
            UploadService.deleteAttachment(guid, token, theRealm, type)
                .then((response) => {
                    console.dir(response);
                    if (response.data?.message) {
                        setMessage('Die Datei wurde gelöscht.');
                    } else if (response.data?.message === 'not found') {
                        setMessage('Die Datei wurde nicht gefunden.');
                    } else {
                        setMessage(response.data.message);
                    }
                    console.log(dataObjectSelected.id)
                    afterUploadWork()
                })
                .catch(() => {

                });
        }
    },[token, dataObjectSelected, theRealm]);

    const downloadFile = async (file) => {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("attachmentId", file.id);
        formData.append("filename", file.name);
        let tableName = 'bauteil'
        let type = '';
        if(dataObjectSelected.administrativeobjectTypeGuid) {
            type = 'administrativeobject';
        } else {
            type = 'realestateobject';
        }
        formData.append("attachmentTable", type);

        const fetchData = await http.post("/attachment/download", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        );
        // console.dir(fetchData);
        let fetchDataModified = `data:${file.mime_type};base64,${fetchData.data.base64FileContent}`;
        console.log(fetchDataModified)
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = file.name;
        a.click();
    }



    return (
        <>
        <Dialog
            className={classesDialog.dialog}
            open={DataobjectsDialogOpen}
            onClose={() => setDataobjectsDialogOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <Formik
                initialValues={formValues || initialValues}

                onSubmit={async (values, { setSubmitting }) => {
                    console.dir(values);

                    const data = {
                        name: values.name,
                        description: values.description,
                        instanceTypeId: instanceTypeId,
                        // componenttypes: componenttypesToSave,
                        guid: guid,
                        id: id,
                        mark: values.mark,
                        number: values.number,
                        deactivated: values.deactivated,
                        parentRealestateobject: realestateobjectParentValue,
                        parentAdministrativeobject: administrativeobjectParentValue,
                    };
                    const objectAttributes = [];
                    Object.entries(values).map(([key, value], i) => {
                        console.log(key + ' - '+ value + ' - ' + key.substring(0, 5));
                        if(key.substring(0, 5) === 'attr_') {
                            const arr = key.split('_');
                            const attributeTypeGuid = arr[1];
                            const attr = {};
                            attr.attributeTypeGuid = attributeTypeGuid;
                            attr.value = value;
                            attributeList.map((item) => {
                                if(item.guid === attributeTypeGuid) {
                                    attr.name = item.label;
                                }
                            })
                            console.log(attr);
                            objectAttributes.push(attr);
                        }
                    })

                    await saveObject(data, objectAttributes);
                    setSubmitting(false);

                }}

            >
                <Form>

                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setDataobjectsDialogOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                {dataObjectSelected?.id ? `Objekt bearbeiten`: `Objekt hinzufügen`}
                            </Typography>
                            {/*{name != "" && name != null ? (*/}
                                <Button
                                    type="submit"
                                    color="inherit"
                                >
                                    Speichern
                                </Button>
                        </Toolbar>
                    </AppBar>

                    <DataObjectFormItems
                        dataObjectSelected={dataObjectSelected}
                        options={options}
                        attributeList={attributeList}
                        instanceTypeId={instanceTypeId}
                        onChangeInstanceType={onChangeInstanceType}
                        realestateobjectParentValue={realestateobjectParentValue}
                        setRealestateobjectParentValue={setRealestateobjectParentValue}
                        administrativeobjectParentValue={administrativeobjectParentValue}
                        setAdministrativeobjectParentValue={setAdministrativeobjectParentValue}
                        />
                    <Toolbar>
                        {dataObjectSelected?.deactivated ? (
                            <Button onClick={(event) => activateClick(event)} color="inherit" variant="outlined" size="medium">Aktivieren</Button>
                        ) : null}
                        {dataObjectSelected && !dataObjectSelected.deactivated ? (
                            <Button onClick={(event) => deactivateClick(event)} color="inherit" variant="outlined" size="medium">Deaktivieren</Button>
                        ) : null}
                        {/*) : null}*/}
                        <Button
                            variant="outlined"
                            onClick={()=>setUploadDialogOpen(true)}
                            color="inherit"
                            size="medium"
                        >Upload</Button>
                    </Toolbar>
                    {fileInfos.length > 0 ? (
                        <div className="card">
                            <div className="card-header">Liste der Dateien auf m Server</div>
                            <ul className="list-group list-group-flush">
                                {fileInfos &&
                                    fileInfos.map((file, index) => (
                                        <li className="list-group-item" key={index}>
                                            <img src={`data:${file.mime_type};base64,${file.previewBase64content}`} alt=""/>
                                            <div>{file.name}</div>
                                            <Button onClick={()=>downloadFile(file)}>Download</Button>
                                            <Button onClick={()=>deleteAttachment(file.guid)}>Löschen</Button>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    ) : null }
                    <div className="alert alert-light" role="alert">
                        {message}
                    </div>

                </Form>
            </Formik>
        </Dialog>
        <UploadDialog
            dataObjectSelected={dataObjectSelected}
            setUploadDialogOpen={setUploadDialogOpen}
            uploadDialogOpen={uploadDialogOpen}
            callbackFunc={afterUploadWork}
        />

        </>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(DataObjectsEdit);