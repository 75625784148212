import React, {useCallback, useContext, useEffect} from "react";
import {
    TokenContext,
    BimProfileSelectedContext,
    BimProfileSelectedDataContext,
    BIMDialogOpenContext,
    BimProfContext,
} from "../../Store";

import { makeStyles } from "@mui/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShortTextIcon from "@mui/icons-material/ShortText";

import ProfilesListView from "./ProfilesListView";


import {connect} from "react-redux";
import BimProfileService from "../../../services/BimProfileService";

import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import UserService from "../../../services/UserService";
import {useNavigate} from "react-router-dom";


// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        /*width: "95%",
        height: "90%",
        margin: "15px",
        padding: "20px",
        float: "left",
        border: "2px solid #B4AF03",
        borderRadius: "25px",*/
        width: "100%",
        height: "100%",
        float: "left",
    },
    btListe: {
        /*width: "20vw",
        maxHeight: "65vh",*/
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

});

// Baumdarstellung der Profile

const TreeItemsFromData = (treeItems) => {
    const [, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);
    // const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);

    return treeItems.map((treeItemData) => {
        let children_objecttypes = undefined;
        if (
            treeItemData.children_objecttypes &&
            treeItemData.children_objecttypes.length > 0
        ) {
            children_objecttypes = TreeItemsFromData(
                treeItemData.children_objecttypes
            );
        }
        return (
            <TreeItem
                key={treeItemData.objecttype_id}
                nodeId={treeItemData.name}
                label={treeItemData.name}
                children={children_objecttypes}
                onLabelClick={() => {
                    setBimProfileSelected(treeItemData.name);
                    setBimProfileSelectedData(treeItemData);
                    // onClickAufItem(treeItemData.objecttype_id);
                }}
            />
        );
    });
};

// Baumdarstellung Komponentenliste
function DataTreeView({ treeItems }) {
    const classes = useStyles();


    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<ShortTextIcon />}
        >
            {TreeItemsFromData(treeItems)}
        </TreeView>
    );
}

function BimProfiles({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [bimProf , setBimProf] = useContext(BimProfContext);
    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [SiteColor] = useContext(SiteColorContext);
    const [bimProfileSelected, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);

    const classes = useStyles();
    // console.dir(bimProf);

    let navigate  = useNavigate();

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);


    const GetProfilesData = useCallback(async () => {
        try {

            BimProfileService.getBimProfiles(token, theRealm)
                .then((res) => {
                    if(res.data.result) {
                        setBimProf(res.data.result);
                    } else {
                        setBimProf(res.data);
                    }
                    setBimProfileSelected('');
                    setBimProfileSelectedData([]);
                })

        } catch (e) {
            console.log(e);
        }
    }, [setBimProf, setBimProfileSelected, setBimProfileSelectedData, theRealm, token]);

    useEffect( () => {
        async function fetchData() {
            if (!bimProf) {
                await GetProfilesData();
            }
        }
        trackPromise (
            fetchData()
        )
    }, []);

    const addNew = () => {
        console.log('hello new profile')
        setBimProfileSelected("");
        setBimProfileSelectedData([]);
        setBIMDialogOpen(true);
    }

    const showBimetaProfiles = () => {
        navigate('/BimProfile/bimetaprofile');
    }

    return (
        <div>
            <LoadingIndicator />
            <div className={classes.katalogView}>
                <div className={classes.btListe}>
                    {/*<DataTreeView treeItems={bimProf}  />*/}
                    <ProfilesListView
                        profileList={bimProf}
                        GetProfilesData={GetProfilesData}
                        setBimProfileSelected={setBimProfileSelected}
                        bimProfileSelected={bimProfileSelected}
                        addNew={addNew}
                        showBimetaProfiles={showBimetaProfiles}
                    />
                </div>
                {/*<div className={classes.editView}>
                    <BimEdits GetProfilesData={GetProfilesData}/>
                </div>*/}

            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimProfiles);