import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Dialog from "@mui/material/Dialog";

import Button from "@mui/material/Button";
import Alert from "@mui/lab/Alert";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const styles = {
    dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "75%",
        marginRight: 0,
    },
    dialog50: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },
    dialogAlert: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "30%",
    },
    dialogConfirm: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },
    appBar: {
        position: "inherit",
        background: "linear-gradient(45deg, #0B0E10 50%, #D5CD7E 90%)",
    },

    highlight: {
        display: "inline",
        color: "#D5CD7E",
    },
    formControl: {
        width: "100%",
    },
}

const YourDialog = ({show, proceed, confirmation, options}) => {
    // const classes = useStyles();
    // const classesDialog = useStylesDialog();
    return (
        <Dialog
            sx={styles.dialog50}
            onHide={() => proceed(false)} show={show} open={show}>
            <DialogContent>
                <Alert variant="outlined" severity="error">
                    <b>{confirmation}</b>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => proceed(false)} color="primary">CANCEL</Button>
                <Button onClick={() => proceed(true)} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    )
}

YourDialog.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);

