import React, { useContext } from "react";
// import axios from "axios";
// import { BROKER_URL } from "../../../../../modules/config";
import {
    TokenContext,
    RealmContext,
    DeleteCompDialogOpenContext,
    TreeSelectedContext,
    TreeItemsStateContext, CompIsSelectedContext
} from "../../../../Store";

import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@material-ui/lab/Alert";
import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import {trackPromise} from "react-promise-tracker";
import LoadingIndicator from "../../../../../shared/LoadingIndicator";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Fenster zum Löschen des ausgewählten Bauteils

// Transition legt fest, wie das Fenster erscheint
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function DeleteCompDialog() {
    const classesDialog = useStylesDialog();

    const [DeleteCompDialogOpen, setDeleteCompDialogOpen] = useContext(
        DeleteCompDialogOpenContext
    );
    const [TreeSelected, setTreeSelected] = useContext(TreeSelectedContext);
    const [, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [, setCompIsSelected] = useContext(CompIsSelectedContext);

    const Comp_Delete = async () => {
        try {
            await trackPromise(
                TreeComponentTypeService.deleteComponentType(token, realm, TreeSelected.global_id)
                    .then((res) => {
                        TreeComponentTypeService.getTreeData(token, realm)
                            .then((result) => {
                                setTreeItemsState(result.data);
                                console.log(res);
                                setCompIsSelected(false);

                            });
                    })
            );

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <LoadingIndicator/>
            <Dialog
                className={classesDialog.dialog50}
                open={DeleteCompDialogOpen}
                onClose={() => setDeleteCompDialogOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setDeleteCompDialogOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Bauteil <b>{TreeSelected.name}</b> löschen
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Alert variant="outlined" severity="error">
                        <b>Möchten Sie das Bauteil{" "}
                            <div className={classesDialog.highlight}>{TreeSelected.name}</div>{" "}
                            wirklich löschen?</b>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteCompDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            Comp_Delete();
                            setDeleteCompDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
