import http from "../shared/http-common";

const TreeComponentTypeService = {
    getTreeData(token, realm) {
        try {
            if(token && token !== 'Kein Token generiert!') {
                let formData = new FormData();
                formData.append("realm", realm);

                return http.post(`/componentType/list`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });
            }
        } catch (e) {
            console.log(e);
        }
    },

    saveComponentType(token, realm, data) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("data", JSON.stringify(data));

        return http.post(`/componentType/saveComponentType`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteComponentTypeAttribute(token, realm, globalId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("globalId", globalId);

        return http.post(`/componentType/deleteComponentTypeAttribute`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteComponentType(token, realm, globalId) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("globalId", globalId);

        return http.post(`/componentType/deleteComponentType`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getTreeComponent(token, realm, code) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("code", code);

        return http.post(`/componentType/get`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    downloadComponenttypeTemplate(token, realm, guid) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("guid", guid);

        return http.get(`/componentType/exportComponenttypeTemplate`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }

}

export default TreeComponentTypeService;