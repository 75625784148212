import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import ListDisplay from "./ListDisplay";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    selected: {
        backgroundColor: "rgba(240,178,175)",
        fontWeight: "bold",
    }
});

function ItemDisplay({data, index, checkedItems, labelId, handleToggle, currentObjectTypeId, idPath}) {
    const classes = useStyles();

    const handleClick = (index, idPath, data) => {
        handleToggle(index, idPath, data)
        // childObjects = data.children_objecttypes;
    }
    // console.log('ItemDisplay');
    // console.log('ItemDisplay: ' + checkedItems + ' - ' + index);
    let showChildren = checkedItems.indexOf(index) > -1;

    return (
        <>
            <ListItem key={index} role="listitem" button onClick={(e) => handleClick(index, idPath, data)}>
                <ListItemIcon>
                    <Checkbox
                        checked={currentObjectTypeId === data.objecttype_id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={<span className={checkedItems.indexOf(index) > -1 ? classes.selected: ""}>{`${data.code} - ${data.name}`}</span>} className={checkedItems.indexOf(index) > -1 ? classes.selected: ""}/>
            </ListItem>
            {showChildren? <ListDisplay items={data.children_objecttypes} checkedItems={checkedItems} handleToggle={handleToggle} currentObjectTypeId={currentObjectTypeId} idPath={idPath}/> : null}
        </>
    );
}

export default ItemDisplay;