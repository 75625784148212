import http from "../shared/http-common";

const DataobjectService = {

    getAdministrativeObject(token, realm, guid) {
        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append("guid", guid);
            return http.post(`/administrativeobject/get`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getRealestateObject(token, theRealm, guid) {
        try {
            let formData = new FormData();
            formData.append("realm", theRealm);
            formData.append("guid", guid);
            return http.post(`/realestateobject/get`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });
        } catch (e) {
            console.log(e);
        }
    },

    getAdministrativeobjectTypeAttributes(token, theRealm, administrativeobjectTypeGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("administrativeobjectTypeGuid", administrativeobjectTypeGuid);

        return http.post(`/administrativeobjectType/listAttributes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getRealestateobjectTypeAttributes(token, theRealm, realestateobjectTypeGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectTypeGuid", realestateobjectTypeGuid);

        return http.post(`/realestateobjectType/listAttributes`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveRealestateobject(token, theRealm, realestateobjectTypeGuid, properties, attributes) {
        properties['realestateobjectType'] = realestateobjectTypeGuid;
        const data = {type: 'realestateobject', properties: properties, attributes: attributes}
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("data", JSON.stringify(data));
        console.log(data)

        return http.post(`/realestateobject/update`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    saveAdministrativeobject(token, theRealm, administrativeobjectTypeGuid, properties, attributes) {
        properties['administrativeobjectType'] = administrativeobjectTypeGuid;
        const data = {type: 'administrativeobject', properties: properties, attributes: attributes}
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("data", JSON.stringify(data));
        console.log(data)
        return http.post(`/administrativeobject/update`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deactivateAdministrativeobject(token, theRealm, administrativeobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        const data = {administrativeobjectGuid: administrativeobjectGuid};
        formData.append("data", JSON.stringify(data));

        return http.post(`/administrativeobject/deactivate`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deactivateRealestateobject(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        const data = {realestateobjectGuid: realestateobjectGuid};
        formData.append("data", JSON.stringify(data));

        return http.post(`/realestateobject/deactivate`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    activateAdministrativeobject(token, theRealm, administrativeobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        const data = {administrativeobjectGuid: administrativeobjectGuid};
        formData.append("data", JSON.stringify(data));

        return http.post(`/administrativeobject/activate`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    activateRealestateobject(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        const data = {realestateobjectGuid: realestateobjectGuid};
        formData.append("data", JSON.stringify(data));

        return http.post(`/realestateobject/activate`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listrealestateobjectchildren(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectGuid", realestateobjectGuid);
        return http.post(`/realestateobject/listrealestateobjectchildren`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listrealestateobjectparents(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectGuid", realestateobjectGuid);
        return http.post(`/realestateobject/listrealestateobjectparents`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listrealestateobjectsiblings(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectGuid", realestateobjectGuid);
        return http.post(`/realestateobject/listrealestateobjectsiblings`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listrealestateobjectstorealestateobject(token, theRealm, realestateobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectGuid", realestateobjectGuid);
        return http.post(`/realestateobject/listrealestateobjectstorealestateobject`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listrealestateobjectstorealestateobjectwithtypelevel(token, theRealm, realestateobjectGuid, realestateobjectTypeGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("realestateobjectGuid", realestateobjectGuid);
        formData.append("realestateobjectTypeGuid", realestateobjectTypeGuid);
        return http.post(`/realestateobject/listrealestateobjectstorealestateobjectwithtypelevel`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },


    listadministrativeobjectparents(token, theRealm, administrativeobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("administrativeobjectGuid", administrativeobjectGuid);
        return http.post(`/administrativeobject/listadministrativeobjectparents`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listadministrativeobjectchildren(token, theRealm, administrativeobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("administrativeobjectGuid", administrativeobjectGuid);
        return http.post(`/administrativeobject/listadministrativeobjectchildren`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listadministrativeobjectstoadministrative(token, theRealm, administrativeobjectGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("administrativeobjectGuid", administrativeobjectGuid);
        return http.post(`/administrativeobject/listadministrativeobjectstoadministrative`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    listadministrativeobjectstoadministrativewithtypelevel(token, theRealm, administrativeobjectGuid, administrativeobjectTypeGuid) {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("administrativeobjectGuid", administrativeobjectGuid);
        formData.append("administrativeobjectTypeGuid", administrativeobjectTypeGuid);
        return http.post(`/administrativeobject/listadministrativeobjectstoadministrativewithtypelevel`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }

}

export default DataobjectService;