// import KcAdminClient from '@keycloak/keycloak-admin-client';
import KeycloakAdminClient from "@keycloak/keycloak-admin-client";
import { KEYCLOAK_SERVER_URL } from "../modules/config";


const kcAdminClient = new KeycloakAdminClient({
        baseUrl: KEYCLOAK_SERVER_URL,
        realmName: ''
    });

const setToken = (token) => {
    kcAdminClient.setAccessToken(token);
};

const setRealm = (realm) => {
    kcAdminClient.realmName = realm;
};

const getUsersList = () => {
    return kcAdminClient.users.find()
        .then((users) => {
            return users;
        });
};

const addUser = (realm, user, rolesToSave) => {
    console.log(user,realm);
    // const u = kcAdminClient.users.create()
    return kcAdminClient.users.create(user)
        .then(async (res) => {
            console.log(res);
            if(rolesToSave && rolesToSave.length > 0) {
                console.dir(rolesToSave);
                await kcAdminClient.users.addRealmRoleMappings({id: res.id, roles:rolesToSave, realm:realm})

            }
            return res.id;
        });
}

const editUser = (id, realm, user, rolesToSave, rolesToDelete) => {
    console.log(id,realm);
    return kcAdminClient.users.update({id: id, realm: realm}, user)
        .then(async (res) => {
            if(rolesToSave && rolesToSave.length > 0) {
                // console.dir(rolesToSave);
                await kcAdminClient.users.addRealmRoleMappings({id: id, roles:rolesToSave, realm:realm})
            }
            if(rolesToDelete && rolesToDelete.length > 0) {
                console.dir(rolesToDelete);
                await kcAdminClient.users.delRealmRoleMappings({id: id, roles:rolesToDelete, realm:realm})
            }

            return res.id;

        })
        .catch(err => {
            console.dir(err);
        });
}

const getUserById = (user) => {
    return kcAdminClient.users.findOne(user)
        .then((res) => {
            console.log(res)
            return res;
        });
}

const getUserRoles = (user) => {
    return kcAdminClient.users.listRealmRoleMappings(user)
        .then((res) => {
            console.log(res);
            return res;
        });
}

const getUserGroupRoles = (user) => {
    return kcAdminClient.users.listCompositeRealmRoleMappings(user)
        .then((res) => {
            console.log(res);
            return res;
        });
}

const getUserClientRoles = async (realm, userId, clientId) => {
    // return kcAdminClient.users.listClientRoleMappings({id: userId, clientUniqueId: clientId, realm: realm})
    return kcAdminClient.users.listCompositeClientRoleMappings({id: userId, clientUniqueId: clientId, realm: realm})

}

const deleteUser = (user) => {
    return kcAdminClient.users.del(user)
        .then((res) => {
            return res;
        });
}

const getUserGroups = (user) => {

    return kcAdminClient.users.listGroups(user)
        .then((res) => {
            console.log(res);
            return res;
        });
}

const addUserToGroup = (userId, groupId, realm) => {
    return kcAdminClient.users.addToGroup({id: userId, groupId:groupId, realm:realm});

}

const removeUserFromGroup = (userId, groupId, realm) => {
    return kcAdminClient.users.delFromGroup({id: userId, groupId:groupId, realm:realm});

}



const UserAdminService = {
    setToken,
    getUsersList,
    setRealm,
    addUser,
    getUserById,
    getUserRoles,
    deleteUser,
    editUser,
    getUserGroups,
    addUserToGroup,
    removeUserFromGroup,
    getUserClientRoles,
    getUserGroupRoles
};

export default UserAdminService;