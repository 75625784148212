import {useParams} from "react-router";
import React, {useEffect, useState, useCallback} from "react";
// import {fetchUser} from './operations';
// import {Link} from "react-router-dom";
import MandantUserForm from "./MandantUserForm";
// import connect from '../../shared/connectWithOnLoad';
import {addUserToGroup, fetchRealmGroupById, fetchRealmGroups, fetchUserGroups, removeUserFromGroup} from "../../../app/user/api";
import {Box, Button, Card, CardContent, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {BROKER_TENANT_GROUP} from "../../../modules/config";
import {makeStyles} from "@mui/styles"

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

function MandantGruppe({realm, userId, getRealmRollen}) {
    const classes = useStyles();
    // const {realmId, userId} = useParams();
    // const [, setBenutzer] = useState(null);
    // const [username, setUsername] = useState('');
    const [, setTenantId] = useState(0);
    const [groups, setGroups] = useState([]);
    const [assigned, setAssigned] = useState([]);

    const getRealmGroups = useCallback((realm) => {
        fetchRealmGroups(realm)
            .then(gruppen => {
                console.log(gruppen)
                setGroups(gruppen);
                if (gruppen && gruppen.length > 0) {
                    for (const gruppe of gruppen) {
                        if (gruppe.name === BROKER_TENANT_GROUP) {
                            let id = gruppe.id;
                            fetchRealmGroupById(id, realm)
                                .then(group => {
                                    console.log(group);
                                    // setGroup(group);
                                    if(group?.attributes && group?.attributes?.tenantId instanceof Array) {
                                        const tenantId = group?.attributes?.tenantId[0];
                                        setTenantId(tenantId);
                                    }
                                });

                        }
                    }
                }
                getUserGroups(userId, realm)
                    .then(() => {
                        console.log('Hol die Rollen')
                        getRealmRollen(realm, userId);
                    }) ;
            });

    })

    const getUserGroups = useCallback((userId, realm) => {
        return fetchUserGroups(realm, userId)
            .then(result => {
                console.log(result);
                setAssigned(result)
            })
    });

    useEffect(() => {
        getRealmGroups(realm);

    }, [realm, userId]);

    const addToGroup = async (groupId) => {
        await addUserToGroup(realm, userId, groupId);
        getRealmGroups(realm);
    }

    const leaveGroup = async (groupId) => {
        await removeUserFromGroup(realm, userId, groupId);
        getRealmGroups(realm);
    }

    return (
        <div >
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            verfügbare Gruppen in Keycloak
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {groups.map(function (group, index) {
                                    for (let i = 0; i < assigned.length; i++) {
                                        if (assigned[i].id === group.id) {
                                            return null;
                                        }
                                    }
                                    return <TableRow key={group.id}>
                                        <TableCell>{group.name} </TableCell>
                                        <TableCell align="right"><Button onClick={() => addToGroup(group.id)} size="small"
                                                                         className={classes.link}
                                                                         color="primary">hinzufügen</Button></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <Typography variant="h6" gutterBottom>
                            Zugewiesene Gruppen
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {assigned.map(function (group, index) {
                                    return <TableRow key={group.id}>
                                        <TableCell>{group.name} </TableCell>
                                        <TableCell align="right"><Button onClick={() => leaveGroup(group.id)} size="small"
                                                                         className={classes.link}
                                                                         color="primary">Entfernen</Button></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default MandantGruppe;
