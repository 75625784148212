import React, {useState, useContext, useEffect, useCallback, useLayoutEffect} from "react";
import {BIMDialogOpenContext, BimProfContext, BimProjectSelectedContext, RealmContext, TokenContext} from "../../Store";


import {makeStyles, withStyles} from "@mui/styles";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import {Form, Formik} from "formik";

import http from "../../../shared/http-common";

import ProjectService from "../../../services/ProjectService";

import Checkbox from '@mui/material/Checkbox';

import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import LoadingIndicator from "../../../shared/LoadingIndicator";

import {TableCell, TableRow} from "@mui/material";

import { header } from'../../../shared/headerStyle';
import {useStylesDialog} from "../../../shared/useStyleDialog";

import ObjecttypeService from "../../../services/ObjecttypeService";
import BimProjectAppBar from "./BimProjectAppBar";
import BimProjectFormList from "./BimProjectFormList";
import BimProjectTabs from "./BimProjectTabs";

// Fenster zum Anlegen eines Profils


const useStyles = makeStyles((theme) => ({
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "120px"
    },
    selectbox: {
        width: "100%"
    },
    TabBorderStyle: {
        padding: "3px",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    }
}));


const StyledTableCell = withStyles((theme) => ({
    head: header,
    body: {
        fontSize: 12,
    },
}))(TableCell);

const initialValues = {
    name: '',
    description: '',
    componenttypes: [],
    guid: '',
    id: null,
    profileId: null,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function BimProjectCreate({bimProjectData, reloadProjects, setBimProjectSelected}) {
    //const classes = useStyles();
    const classesDialog = useStylesDialog();
    const [BIMDialogOpen, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [, setName] = useState("");
    const [, setDescription] = useState("");
    const [profileId, setProfileId] = useState(null);
    // const [category, setCategory] = useState("");
    const [guid, setGuid] = useState("");
    const [, setId] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [, setComponenttypes] = useState([]);
    const [, setComponenttypeItems] = useState([]);
    const [, setAttributes] = useState([]);

    // const [options, setOptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [checkedRights, setCheckedRights] = useState([]);
    const [, setIsLoading] = useState(false);

    // const [BimProjectSelected, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    const [optionsRealestateobject, setOptionsRealestateobject] = useState([]);

    const getRealestateobjectTypes = useCallback(async (token, theRealm) => {
        const bla = await ObjecttypeService.getRealestateobjecttypes(token, theRealm);
        const myOptions = [];

        if(bla.data && bla.data instanceof Array) {
            myOptions[0] = {value: '', text: ' - Bitte wählen -'}
            bla.data?.forEach((val) => {
                const item = {value: val.guid, text: val.label}
                myOptions.push(item);

            })
        }
        setOptionsRealestateobject(myOptions)

    },[]);

    useEffect(()=> {
            getRealestateobjectTypes(token, realm);
    },[getRealestateobjectTypes, token, realm]);

    let userRights = [];
    let rights = [];
    // let formValues = []
    const handleChange = (event) => {
        // console.log(event.target.name);
        // console.log(event.target.value);
        // console.log(event.target.checked);
        console.log(checkedRights)
        rights = checkedRights.rights;
        rights?.forEach((r) => {
            if(r.username === event.target.name) {
                if(event.target.value === 'read') {
                    r.read = event.target.checked;
                } else if(event.target.value === 'write') {
                    r.write = event.target.checked;
                }
            }
        })

        setCheckedRights({
            rights,
        });
    };

    useLayoutEffect(()=>{
        if(bimProjectData &&  bimProjectData.id) {
            const savedValues = {
                name: bimProjectData.name,
                description: bimProjectData.description,
                guid: bimProjectData.guid,
                id: bimProjectData.id,
                profileId: bimProjectData.bimProfileId,
                realestateobjectLevelGuid: bimProjectData.realestateobjectLevelGuid
            }
            console.dir(savedValues)
            // formValues = savedValues;
            setFormValues(savedValues);
        } else {
            const savedValues = {
                name: "",
                description: "",
                guid: "",
                id: null,
                profileId: null,
                realestateobjectLevelGuid: null
            }
            console.dir(savedValues)
            setFormValues(savedValues);
        }
        // console.dir(formValues);
    }, [bimProjectData]);

    useEffect(()=> {
        console.log('hello BimCreate');
        console.dir(bimProjectData);
        setComponenttypes([]);
        setAttributes([]);
        setCheckedRights([]);
        setComponenttypeItems([]);
        if(bimProjectData &&  bimProjectData.id) {
            setName(bimProjectData.name);
            setDescription(bimProjectData.description);
            setGuid(bimProjectData.guid);
            setId(bimProjectData.id);
            setProfileId(bimProjectData.bimProfileId);
            // setSelectedRealestateobject(bimProjectData.realestateobjectLevelGuid)
            if(bimProjectData.componenttypes) {
                const comTypes = [];
                const comTypeItems = [];
                const attributts = [];
                bimProjectData.componenttypes.forEach((item, index) => {
                    comTypes.push(item.objecttype_id);
                    if(item.attribute) {
                        item.attribute.forEach((attr, idx) => {
                            attributts.push(item.objecttype_id + '_' + attr.attribute_id);
                        })
                    }
                    comTypeItems.push(item)
                });
                setComponenttypes(comTypes);
                setAttributes(attributts);
                setComponenttypeItems(comTypeItems);
            }
            // setBIMDialogOpen(true);


            setIsLoading(true);
            ProjectService.getKeycloakUser(token, realm)
                .then((result) => {
                    console.dir(result);
                    setUsers(result.data);
                    const tempUsers = result.data;
                    rights = [];
                    if(tempUsers) {
                        tempUsers.forEach((u)=>{
                            rights.push({username: u.username, read: false, write:false, userId: u.id});
                        })
                        // console.log(rights);
                        setCheckedRights({rights});
                    }
                    // console.log(checkedRights)
                    ProjectService.getProjectUserRights(token, realm, bimProjectData.id, bimProjectData.guid)
                        .then((result) => {
                            // console.log(result);
                            if(result?.data && result.data instanceof Array) {
                                // console.log(result.data);
                                userRights = result.data;
                                //userRights = [];
                                userRights.forEach((r) => {
                                    rights.forEach((u) => {
                                        if(r.username === u.username) {
                                            u.read = r.read;
                                            u.write = r.write;
                                        }
                                    })
                                })
                                setCheckedRights({
                                    rights
                                });
                            }
                            setIsLoading(false);
                        });
                })
                .finally(()=>{
                    setIsLoading(false);
                })



        } else {
            setName("");
            setDescription("");
            setGuid("");
            setId(null);
            setProfileId(null);
            setComponenttypes([]);
            setAttributes([]);
            setCheckedRights([]);


            // formValues = savedValues;
        }
        // console.log('Hallo');


    }, [bimProjectData]); // bimProjectData, bimProf, setBIMDialogOpen, realm, token

    const saveProject = async (formData) => {
        await http.post(`/project/save`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(async (res) => {
            console.log('nach projekt save')
            console.dir(res.data);
            // bimProjectData = res.data
            // setBimProjectSelected(res.data)
            await reloadProjects(res.data);
        });
        // console.dir(retVal)

        // setBimProjectSelected(retVal);
        // setBIMDialogOpen(false);
    }

    return (
        <Dialog
            className={classesDialog.dialog}
            open={BIMDialogOpen}
            onClose={() => setBIMDialogOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <BimProjectForm
                formValues={formValues}
                initialValues={initialValues}
                bimProjectData={bimProjectData}
                realm={realm}
                setProfileId={setProfileId}
                profileId={profileId}
                optionsRealestateobject={optionsRealestateobject}
                users={users}
                handleChange={handleChange}
                guid={guid}
                saveProject={saveProject}
                checkedRights={checkedRights}
                />

        </Dialog>
    );
}

export default BimProjectCreate;

function FormRow({username, handleChange, checkedRights}) {
    console.dir(checkedRights);
    let checkRead = false;
    let checkWrite = false;
    checkedRights.rights?.forEach((r) => {
        if(r.username === username) {
            checkRead = r.read;
            checkWrite = r.write;
        }
    })

    return (
        <React.Fragment>

            <TableRow>
                <StyledTableCell>{username}</StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="read" onChange={handleChange} checked={checkRead} /></StyledTableCell>
                <StyledTableCell><Checkbox name={username} value="write" onChange={handleChange} checked={checkWrite}/></StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function BimProjectForm({formValues, initialValues, bimProjectData, realm, setProfileId, profileId, optionsRealestateobject, users, handleChange, guid, saveProject, checkedRights}) {

    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    // const [checkedRights, setCheckedRights] = useState([]);
    const [selectedRealestateobject, setSelectedRealestateobject] = useState('');
    const [hideSaveButton, setHideSaveButton] = useState(false);
    console.dir(formValues);
    return (
        <Formik
            initialValues={formValues || initialValues}

            onSubmit={async (values, { setSubmitting }) => {
                console.dir(values);
                // console.log(checkedRights);
                const projectUserRights = [];
                users.forEach((u) => {
                    checkedRights.rights?.forEach((r) => {
                        if(u.username === r.username) {
                            projectUserRights.push({
                                username: u.username,
                                read: r.read,
                                write: r.write,
                                userId: r.userId
                            })
                        }
                    })
                })

                const project = {
                    name: values.name,
                    description: values.description,
                    profileId: profileId,
                    // componenttypes: componenttypesToSave,
                    guid: guid,
                    realestateobjectLevelGuid: selectedRealestateobject
                };
                // console.dir(project);
                let formData = new FormData();
                formData.append("realm", realm);
                formData.append("data", JSON.stringify(project));
                // formData.append("data", project);
                formData.append("projectUserRights", JSON.stringify(projectUserRights));
                // formData.append("projectUserRights", projectUserRights);

                await saveProject(formData);



                setSubmitting(false);

            }}

        >
            <Form>
                <BimProjectAppBar setBIMDialogOpen={setBIMDialogOpen}
                                  objectId={bimProjectData?.id}
                                  hideSaveButton={hideSaveButton}
                />
                <LoadingIndicator/>
                {/*<BimProjectFormList
                    bimProjectData={bimProjectData}
                    setProfileId={setProfileId}
                    profileId={profileId}
                    optionsRealestateobject={optionsRealestateobject}
                    setSelectedRealestateobject={setSelectedRealestateobject}
                    selectedRealestateobject={selectedRealestateobject}
                />*/}

                <BimProjectTabs
                    users={users}
                    bimProjectData={bimProjectData}
                    checkedRights={checkedRights}
                    handleChange={handleChange}
                    realm={realm}
                    setProfileId={setProfileId}
                    profileId={profileId}
                    optionsRealestateobject={optionsRealestateobject}
                    setSelectedRealestateobject={setSelectedRealestateobject}
                    selectedRealestateobject={selectedRealestateobject}
                    setHideSaveButton={setHideSaveButton}
                />
            </Form>
        </Formik>
    )
}