export const BROKER_TENANT_GROUP = 'BrokerTenant';
// export const BROKER_URL = 'http://localhost:8081/dataAcquisitionApi';
// export const TWIN_SERVER_URL = 'http://localhost:8085';
// export const TWIN_SERVER_URL = 'https://twin-backend.immospector.com';
// export const BROKER_URL = 'https://broker-dev.immospector.com/dataAcquisitionApi';
// export const KEYCLOAK_SERVER_URL = 'https://auth-broker.immospector.com/';
// export const KEYCLOAK_SERVER_URL = 'https://auth2.immospector.com/';
// export const KEYCLOAK_CLIENT_ID = 'twin-broker-client';
// export const EXTERN_USERADMINISTRATION_URL = 'http://localhost:3006/twinusers';
// export const EXTERN_USERADMINISTRATION_URL = 'https://useradministration.immospector.com/twinusers';

export const TWIN_SERVER_URL = window.app && window.app.env.TWIN_SERVER_URL;
export const BASE_SERVER_URL = window.app && window.app.env.BASE_SERVER_URL;
export const BROKER_URL = window.app && window.app.env.BROKER_URL;
export const KEYCLOAK_SERVER_URL = window.app && window.app.env.KEYCLOAK_SERVER_URL;
export const KEYCLOAK_CLIENT_ID = window.app && window.app.env.KEYCLOAK_CLIENT_ID;
export const EXTERN_USERADMINISTRATION_URL = window.app && window.app.env.EXTERN_USERADMINISTRATION_URL;
export const KEYCLOAK_ALLOWED_REALMS = window.app && window.app.env.KEYCLOAK_ALLOWED_REALMS;

export const MENU1 = "Home";
export const MENU2 = "Katalog";
export const MENU3 = "Profile";
export const MENU4 = "Projekte";
export const MENU6 = "Daten-Objekte";
export const MENU7 = "Bauteile";
export const MENU5 = "Ausloggen";
export const MENU8 = "Meine Projekte";
export const MENU9 = "BiMeta-Daten";
export const MENU10 = "Felder";

export const JSON_RPC_VERSION = '2.0';
