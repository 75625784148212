import React, {useEffect, useState} from 'react';
import {SET_THE_REALM, SET_THE_TOKEN} from "../app/start/actions";
//import {connect} from "react-redux";
import connect from '../shared/connectWithOnLoad';

export const TokenContext = React.createContext('');
export const RealmContext = React.createContext('');
export const SiteColorContext = React.createContext('');
export const TreeItemsStateContext = React.createContext('');
export const TreeSelectedContext = React.createContext('');
export const TreeParentContext = React.createContext('');
export const BimProfileSelectedContext = React.createContext('');
export const BimProfileSelectedDataContext = React.createContext([]);
export const BimProfContext = React.createContext('');
export const BimProjectsContext = React.createContext([]);
export const BimUserProjectsContext = React.createContext([]);
export const BimProjectSelectedContext = React.createContext([]);
export const TreeSelectedItemContext = React.createContext('');
export const AttrSelectedContext = React.createContext('');
export const AttrSelectDescContext = React.createContext('');
export const AttrSelectIdContext = React.createContext('');
export const AttrSelectIfcTypeContext = React.createContext('');
export const SearchValueContext = React.createContext('');
export const DialogOpenContext = React.createContext('');
export const UpdateCompDialogOpenContext = React.createContext('');
export const DeleteCompDialogOpenContext = React.createContext('');
export const UpdateAttrDialogOpenContext = React.createContext('');
export const DeleteAttrDialogOpenContext = React.createContext('');
export const SubDialogOpenContext = React.createContext('');
export const SubDialogConfirmOpenContext = React.createContext('');
export const AttrDialogOpenContext = React.createContext('');
export const BIMDialogOpenContext = React.createContext('');
export const TreeClickedContext = React.createContext('');
export const TreeIsLoadedContext = React.createContext('');
export const ProfilClickedContext = React.createContext('');
export const ProfilIsLoadedContext = React.createContext('');
export const ProjectClickedContext = React.createContext('');
export const ProjectIsLoadedContext = React.createContext('');
export const UserProjectIsLoadedContext = React.createContext('');
export const DataobjectsClickedContext = React.createContext('');
export const DataobjectsIsLoadedContext = React.createContext('');
export const DataobjectsDialogOpenContext = React.createContext('');
export const DataobjectsContext = React.createContext([]);
export const DatacomponentsContext = React.createContext([]);
export const DatacomponentsClickedContext = React.createContext('');
export const CompIsSelectedContext = React.createContext('');
export const AttrIsSelectedContext = React.createContext('');
export const CatalogParentIdContext = React.createContext('');
export const BTNameContext = React.createContext('');
export const BTCodeContext = React.createContext('');
export const BTDescContext = React.createContext('');

const Store = ({children, theRealm, setTheRealm, keycloakToken, setKeycloakToken}) => {
    // Token
    const [token, setToken] = useState('Kein Token generiert!');
    // Realm
    const [realm, setRealm] = useState(theRealm);
    // Farb-Thema (hell/dunkel)
    const [SiteColor, setSiteColor] = useState(true);
    // Ausgewählter Knoten
    const [TreeSelected, setTreeSelected] = useState('');
    // Ausgewählter Knoten-Parent
    const [TreeParent, setTreeParent] = useState([]);
    // Ausgewähltes BIM-Profil - Name
    const [BimProfileSelected, setBimProfileSelected] = useState('');
    // Ausgewähltes BIM-Profil - Objekt
    const [BimProfileSelectedData, setBimProfileSelectedData] = useState([]);
    // Bim-Profile
    const [BimProf, setBimProf] = useState([]);
    // Bim-Projects
    const [BimProjects, setBimProjects] = useState([]);
    const [BimUserProjects, setBimUserProjects] = useState([]);
    // Bim-Projects
    const [BimProjectSelected, setBimProjectSelected] = useState([]);
    // Knoten
    const [TreeItemsState, setTreeItemsState] = useState([]);
    // Ausgewählter Knoten
    const [TreeSelectedItem, setTreeSelectedItem] = useState([]);
    // Ausgewähltes Attribut
    const [AttrSelected, setAttrSelected] = useState('');
    // Ausgewähltes Attribut - Beschreibung
    const [AttrSelect_Desc, setAttrSelect_Desc] = useState('');
    // Ausgewähltes Attribut - ID
    const [AttrSelect_Id, setAttrSelect_Id] = useState('');
    // Ausgewähltes Attribut - IFC-Typ
    const [AttrSelect_IfcType, setAttrSelect_IfcType] = useState('');
    // Suchbegriff
    const [SearchValue, setSearchValue] = useState('');
    // Komponenten-Fenster offen?
    const [DialogOpen, setDialogOpen] = useState(false);
    // Update Komponente-Fenster offen?
    const [UpdateCompDialogOpen, setUpdateCompDialogOpen] = useState(false);
    // Delete Komponente-Fenster offen?
    const [DeleteCompDialogOpen, setDeleteCompDialogOpen] = useState(false);
    // Update Attribut-Fenster offen?
    const [UpdateAttrDialogOpen, setUpdateAttrDialogOpen] = useState(false);
    // Delete Attribut-Fenster offen?
    const [DeleteAttrDialogOpen, setDeleteAttrDialogOpen] = useState(false);
    // Unterkomponenten-Fenster offen?
    const [SubDialogOpen, setSubDialogOpen] = useState(false);
    // Unterkomponenten-Bestätiguns-Fenster offen?
    const [SubDialogConfirmOpen, setSubDialogConfirmOpen] = useState(false);
    // Attribut-Fenster offen?
    const [AttrDialogOpen, setAttrDialogOpen] = useState(false);
    // BIM-Fenster offen?
    const [BIMDialogOpen, setBIMDialogOpen] = useState(false);
    // TreeView angeklickt?
    const [TreeClicked, setTreeClicked] = useState(false);
    // TreeView geladen?
    const [TreeIsLoaded, setTreeIsLoaded] = useState(false);
    // Bim-Profile angeklickt?
    const [ProfilClicked, setProfilClicked] = useState(false);
    // Bim-Profile geladen?
    const [ProfilIsLoaded, setProfilIsLoaded] = useState(false);
    // Bim-Profile angeklickt?
    const [ProjectClicked, setProjectClicked] = useState(false);
    // Bim-Profile geladen?
    const [ProjectIsLoaded, setProjectIsLoaded] = useState(false);
    const [UserProjectIsLoaded, setUserProjectIsLoaded] = useState(false);
    // Bauteil ausgewählt?
    const [CompIsSelected, setCompIsSelected] = useState(false);
    // Attribut ausgewählt?
    const [AttrIsSelected, setAttrIsSelected] = useState(false);
    // ParentId des gefilterten Knoten
    const [CatalogParentId,setCatalogParentId] = useState([]);
    // Bauteil Name (für UpdateComp)
    const [BTName, setBTName] = useState('');
    // Bauteil Code (für UpdateComp)
    const [BTCode, setBTCode] = useState('');
    // Bauteil Beschreibung (für UpdateComp)
    const [BTDesc, setBTDesc] = useState('');

    // Dataobjects angeklickt?
    const [DataobjectsClicked, setDataobjectsClicked] = useState(false);
    // Dataobjects geladen?
    const [DataobjectsIsLoaded, setDataobjectsIsLoaded] = useState(false);
    const [DataobjectsDialogOpen, setDataobjectsDialogOpen] = useState(false);
    const [Dataobjects, setDataobjects] = useState([]);
    const [Datacomponents, setDatacomponents] = useState([]);
    const [DatacomponentsClicked, setDatacomponentsClicked] = useState(false);

    const myToken = sessionStorage.getItem('kctoken');
    if(myToken !== '' && myToken !== 'undefined') {
        // console.log('Store: set the kctoken aus sessionStorage: ' + sessionStorage.getItem('kctoken'));
        keycloakToken = sessionStorage.getItem('kctoken');
        // setKeycloakToken(keycloakToken);
    }

    if(theRealm === '' && sessionStorage.getItem('realm_stuff')) {
        // console.log('Store: set the realm aus sessionStorage');
        theRealm = sessionStorage.getItem('realm_stuff');
        setTheRealm(theRealm);
    }

    useEffect(() => {
        setToken(keycloakToken);
        setRealm(theRealm);
        setKeycloakToken(keycloakToken);
    }, [keycloakToken, theRealm, setKeycloakToken])

    return (
        <TokenContext.Provider value={[token,setToken]}>
            <RealmContext.Provider value={[realm, setRealm]}>
            <SiteColorContext.Provider value={[SiteColor, setSiteColor]}>
            <CompIsSelectedContext.Provider value={[CompIsSelected,setCompIsSelected]}>
            <AttrIsSelectedContext.Provider value={[AttrIsSelected,setAttrIsSelected]}>
            <TreeIsLoadedContext.Provider value={[TreeIsLoaded,setTreeIsLoaded]}>
            <TreeClickedContext.Provider value={[TreeClicked,setTreeClicked]}>
            <ProfilClickedContext.Provider value={[ProfilClicked,setProfilClicked]}>
            <ProfilIsLoadedContext.Provider value={[ProfilIsLoaded,setProfilIsLoaded]}>
            <ProjectClickedContext.Provider value={[ProjectClicked,setProjectClicked]}>
            <ProjectIsLoadedContext.Provider value={[ProjectIsLoaded,setProjectIsLoaded]}>
            <UserProjectIsLoadedContext.Provider value={[UserProjectIsLoaded,setUserProjectIsLoaded]}>
            <DataobjectsClickedContext.Provider value={[DataobjectsClicked,setDataobjectsClicked]}>
            <DatacomponentsClickedContext.Provider value={[DatacomponentsClicked,setDatacomponentsClicked]}>
            <DataobjectsIsLoadedContext.Provider value={[DataobjectsIsLoaded,setDataobjectsIsLoaded]}>
            <DataobjectsDialogOpenContext.Provider value={[DataobjectsDialogOpen, setDataobjectsDialogOpen]}>
            <DataobjectsContext.Provider value={[Dataobjects, setDataobjects]}>
            <DatacomponentsContext.Provider value={[Datacomponents, setDatacomponents]}>
            <DialogOpenContext.Provider value={[DialogOpen,setDialogOpen]}>
            <SubDialogOpenContext.Provider value={[SubDialogOpen,setSubDialogOpen]}>
            <SubDialogConfirmOpenContext.Provider value={[SubDialogConfirmOpen,setSubDialogConfirmOpen]}>
            <UpdateCompDialogOpenContext.Provider value={[UpdateCompDialogOpen,setUpdateCompDialogOpen]}>
            <DeleteCompDialogOpenContext.Provider value={[DeleteCompDialogOpen,setDeleteCompDialogOpen]}>
            <UpdateAttrDialogOpenContext.Provider value={[UpdateAttrDialogOpen,setUpdateAttrDialogOpen]}>
            <DeleteAttrDialogOpenContext.Provider value={[DeleteAttrDialogOpen,setDeleteAttrDialogOpen]}>
            <AttrDialogOpenContext.Provider value={[AttrDialogOpen,setAttrDialogOpen]}>
            <BIMDialogOpenContext.Provider value={[BIMDialogOpen,setBIMDialogOpen]}>
            <TreeItemsStateContext.Provider value={[TreeItemsState,setTreeItemsState]}>
            <TreeSelectedContext.Provider value={[TreeSelected,setTreeSelected]}>
            <TreeParentContext.Provider value={[TreeParent,setTreeParent]}>
            <BimProfContext.Provider value={[BimProf,setBimProf]}>
            <BimProfileSelectedContext.Provider value={[BimProfileSelected,setBimProfileSelected]}>
            <BimProfileSelectedDataContext.Provider value={[BimProfileSelectedData,setBimProfileSelectedData]}>
            <BimProjectsContext.Provider value={[BimProjects, setBimProjects]}>
            <BimProjectSelectedContext.Provider value={[BimProjectSelected, setBimProjectSelected]}>
            <BimUserProjectsContext.Provider value={[BimUserProjects, setBimUserProjects]}>
            <TreeSelectedItemContext.Provider value={[TreeSelectedItem,setTreeSelectedItem]}>
            <AttrSelectedContext.Provider value={[AttrSelected,setAttrSelected]}>
            <AttrSelectDescContext.Provider value={[AttrSelect_Desc,setAttrSelect_Desc]}>
            <AttrSelectIdContext.Provider value={[AttrSelect_Id,setAttrSelect_Id]}>
            <AttrSelectIfcTypeContext.Provider value={[AttrSelect_IfcType,setAttrSelect_IfcType]}>
            <CatalogParentIdContext.Provider value={[CatalogParentId,setCatalogParentId]}>
            <SearchValueContext.Provider value={[SearchValue,setSearchValue]}>
            <BTNameContext.Provider value={[BTName,setBTName]}>
            <BTCodeContext.Provider value={[BTCode,setBTCode]}>
            <BTDescContext.Provider value={[BTDesc,setBTDesc]}>
                {children}
            </BTDescContext.Provider>
            </BTCodeContext.Provider>
            </BTNameContext.Provider>
            </SearchValueContext.Provider>
            </CatalogParentIdContext.Provider>
            </AttrSelectIfcTypeContext.Provider>
            </AttrSelectIdContext.Provider>
            </AttrSelectDescContext.Provider>
            </AttrSelectedContext.Provider>
            </TreeSelectedItemContext.Provider>
            </BimUserProjectsContext.Provider>
            </BimProjectSelectedContext.Provider>
            </BimProjectsContext.Provider>
            </BimProfileSelectedDataContext.Provider>
            </BimProfileSelectedContext.Provider>
            </BimProfContext.Provider>
            </TreeParentContext.Provider>
            </TreeSelectedContext.Provider>
            </TreeItemsStateContext.Provider>
            </BIMDialogOpenContext.Provider>
            </AttrDialogOpenContext.Provider>
            </DeleteAttrDialogOpenContext.Provider>
            </UpdateAttrDialogOpenContext.Provider>
            </DeleteCompDialogOpenContext.Provider>
            </UpdateCompDialogOpenContext.Provider>
            </SubDialogConfirmOpenContext.Provider>
            </SubDialogOpenContext.Provider>
            </DialogOpenContext.Provider>
            </DatacomponentsContext.Provider>
            </DataobjectsContext.Provider>
            </DataobjectsDialogOpenContext.Provider>
            </DataobjectsIsLoadedContext.Provider>
            </DatacomponentsClickedContext.Provider>
            </DataobjectsClickedContext.Provider>
            </UserProjectIsLoadedContext.Provider>
            </ProjectIsLoadedContext.Provider>
            </ProjectClickedContext.Provider>
            </ProfilIsLoadedContext.Provider>
            </ProfilClickedContext.Provider>
            </TreeClickedContext.Provider>
            </TreeIsLoadedContext.Provider>       
            </AttrIsSelectedContext.Provider>
            </CompIsSelectedContext.Provider>
            </SiteColorContext.Provider>
            </RealmContext.Provider>
        </TokenContext.Provider>
    )
}
// export default Store;

function mapStateToProps({theRealm, keycloakToken}){
    return {
        theRealm: theRealm,
        keycloakToken: keycloakToken
    }
}

function mapDispatchToProps(dispatch){
    return {
        setTheRealm(theRealm) {
            dispatch({type: SET_THE_REALM, action: theRealm});
        },
        setKeycloakToken(keycloakToken) {
            dispatch({type: SET_THE_TOKEN, action: keycloakToken})
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Store)