import http from "../shared/http-common";

const MandantUserAdminService = {
    getKeycloakUser(token, realm) {

        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/keycloakUser/getUserToRealm/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }
}

export default MandantUserAdminService;