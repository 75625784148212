import {useParams} from "react-router";
import React, {useEffect, useState, useCallback} from "react";
// import {fetchUser} from './operations';
// import {Link} from "react-router-dom";
import MandantUserForm from "./MandantUserForm";
import MandantGruppe from "./MandantGruppe";
// import UserProjects from "./UserProjects";
// import connect from '../../shared/connectWithOnLoad';
import {
    fetchRealmClients,
    fetchRealmRoles,
    fetchUserById, fetchUserClientRoles, fetchUserGroupRoles,
    fetchUserGroups,
    fetchUserRoles
} from "../../../app/user/api";
import { makeStyles } from '@mui/styles';
import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    List,
    ListButton,
    RefreshButton,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar
} from 'react-admin';
import MandantRolle from "./MandantRolle";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserProjects from "./UserProjects";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import UserObjects from "./UserObjects";


const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        float: "left",
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    formular: {
        width: 600,
        float: 'left'
    },

    ButtonBorderStyle: {
        border: "1px solid rgba(25, 118, 210, 0.5)",
        borderRadius: "4px",
        padding: "5px 15px",
        color: "#1976d2"
    }
}));

const styles = {
    TabBorderStyle: {
        padding: "3px",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    ButtonBorderStyle: {
        border: "1px solid rgba(25, 118, 210, 0.5)",
        borderRadius: "4px",
        padding: "5px 15px",
        color: "#1976d2"
    },

}

function Aside ({realm, userId, getRealmRollen, roles}){
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            <MandantGruppe realm={realm} userId={userId} getRealmRollen={getRealmRollen}/>
            <MandantRolle realm={realm} userId={userId} roles={roles}/>
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MandantUser = () => {
    const {realmId, userId} = useParams();
    const [, setBenutzer] = useState(null);
    const [, setGroups] = useState(null);
    const [username, setUsername] = useState('');
    const [roles, setRoles] = useState([]);
    const classes = useAsideStyles();
    const fetchData = useCallback((userId, realmId) => {
        fetchUserById(userId, realmId)
            .then(bla => {
                fetchUserGroups(realmId, userId)
                    .then(groups => {
                        console.log(groups)
                        setGroups(groups)
                    })
                setBenutzer(bla);
                setUsername(bla.username)
            })
    });

    const getRealmRollen = useCallback((realm, userId) => {
        fetchRealmRoles(realm)
            .then(async(rollen) => {
                console.log(rollen)
                const rollenKrams = [];
                fetchUserRoles(userId, realm)
                    .then(async (userRollen) => {
                        // console.log(userRollen)
                        for (const urole of userRollen) {
                            if(!urole.description) {
                                rollenKrams.push(urole);
                            }
                        }
                        // for (const role of rollen) {
                        //     for (const urole of userRollen) {
                        //         if (role.id === urole.id) {
                        //             rollenKrams.push(role.id);
                        //         }
                        //     }
                        // }

                        const clients = await fetchRealmClients(realm);
                        // console.log(clients)
                        let clientRollen = []
                        for (const c of clients) {
                            const croles = await fetchUserClientRoles(realm, userId, c.id);
                            // console.log(croles)
                            for(let i=0; i < croles.length; i++) {
                                if(!croles[i].description) {
                                    clientRollen.push(croles[i]);
                                    rollenKrams.push(croles[i]);
                                }
                            }

                        }
                        const tmpRoles = []
                        rollenKrams.forEach(i=> {
                            tmpRoles.push(i.id);
                        })

                        const ugr = await fetchUserGroupRoles(userId, realm);
                        for (const ugrole of ugr) {
                            if(!ugrole.description) {
                                if(!tmpRoles.includes(ugrole.id)) {
                                    rollenKrams.push(ugrole);
                                }
                            }
                        }

                        console.log(rollenKrams);
                        setRoles(rollenKrams);

                    });

            });

    });





    useEffect(() => {
        fetchData(userId, realmId);
    }, [realmId, userId]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (

        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{marginTop:'2px', marginLeft: '2px'}}>
                    <Tab label={username} {...a11yProps(0)} sx={styles.TabBorderStyle}/>
                    <Tab label="Projekte" {...a11yProps(1)} sx={styles.TabBorderStyle} />
                    <Tab label="Objekte" {...a11yProps(2)} sx={styles.TabBorderStyle} />
                    <Link to={`/MandantUserList/${realmId}`} ><Button type="button" color="primary" variant="outlined" size="medium" sx={styles.ButtonBorderStyle} style={{marginTop: "3px", marginLeft: "20px"}}>Benutzer-Liste</Button></Link>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div style={{ width: "600px", float: 'left'}}>

                    <MandantUserForm realmId={realmId} userId={userId} />
                </div>
                <Aside realm={realmId} userId={userId} getRealmRollen={getRealmRollen} roles={roles}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserProjects username={username} userId={userId} realm={realmId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <UserObjects username={username} userId={userId} realm={realmId} />
            </TabPanel>
        </div>
    );
}

export default MandantUser;
