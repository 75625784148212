import {connect} from "react-redux";
import React from "react";
import ItemDisplay from "./ItemDisplay";


function ListDisplay({items, checkedItems, handleToggle, currentObjectTypeId, idPath, theRealm}) {

    return (
        <div>
            {items.map((data) => {
                // console.log("WTF");
                // console.log(data);
                const idPathLocal = idPath ? idPath + '.' + data.objecttype_id : data.objecttype_id;
                const labelId = `transfer-list-item-${data.objecttype_id}-label`;
                return (
                    <ItemDisplay
                        key={data.objecttype_id}
                        data={data}
                        index={data.objecttype_id}
                        labelId={labelId}
                        checkedItems={checkedItems}
                        handleToggle={handleToggle}
                        currentObjectTypeId={currentObjectTypeId}
                        idPath={idPathLocal} />
                );
            })}
        </div>
    );

}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(ListDisplay);