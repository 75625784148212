import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {TreeItemsStateContext} from '../../Store';

import ListDisplay from "./ListDisplay";
import ListDisplayRight from "./ListDisplayRight";

// Auswahl-Menü bei Profil-Erstellung

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 400,
        height: 300,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

// function not(a, b) {
//     return a.filter((value) => b.indexOf(value) === -1);
// }

// function intersection(a, b) {
//     return a.filter((value) => b.indexOf(value) !== -1);
// }

export default function AttrListComp({setComponenttypes, setAttributes, attributes, componenttypes, componenttypeItems}) {
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [checkedRight, setCheckedRight] = useState([]);
    const [, setLeft] = useState([]);

    const [right, setRight] = useState([]);
    const [treeItemsState] = useContext(TreeItemsStateContext);
    // const [BimProfileSelectedData] = useContext(BimProfileSelectedDataContext);
    const [currentObjectTypeId, setCurrentObjectTypeId] = useState(null);
    const [currentRightObjectTypeId, setCurrentRightObjectTypeId] = useState(null);
    const [currentAttributeId, setCurrentAttributeId] = useState(null);
    const [selectedItemLeft, setSelectedItemLeft] = useState(null);
    const [rightItems, setRightItems] = useState([]);
    const [checkedAttributes, setCheckedAttributes] = useState([]);


    useEffect(()=>{
        const mainItems = []
        treeItemsState.forEach((item, index) => {
            mainItems.push(item.objecttype_id);
            // const bla = getChildren(item);
            // const myKey = "" + item.objecttype_id;
            // blup.push({[myKey]: item.objecttype_id});
        });
        setRight([]);
        setRightItems([]);
        setCheckedAttributes([]);
        // setBlup(blup);
        setLeft(mainItems);
        setRight(componenttypes);
        setRightItems(componenttypeItems);
        const chdAttr = [];
        if(attributes && attributes.length > 0) {
            attributes.forEach((attr) => {
                const arr = attr.split('_');
                if(arr[1]) {
                    chdAttr.push(parseFloat(arr[1]));
                }
            });

        }
        setCheckedRight(componenttypes);
        setCheckedAttributes(chdAttr);
        setAttributes(attributes);
        console.log(componenttypeItems);
        console.log(componenttypes);
        console.log(attributes);

    }, [attributes, componenttypeItems, componenttypes, treeItemsState, setAttributes]);

    // const leftChecked = intersection(checked, left);
    const leftChecked = currentObjectTypeId;
    // const rightChecked = intersection(checked, right);

    const handleToggle = (value, idPath, item) => {
        const currentIndex = checked.indexOf(value);
        idPath = "" + idPath;
        const pathArray = idPath ? idPath.split('.'): [];
        const pathIndex = pathArray.indexOf(value);

        console.log(value);
        console.log(idPath)

        checked.forEach((val, index) => {
            const cIdx = pathArray.indexOf("" +  val);
            if(cIdx === -1) {
                checked.splice(index, 1);
            }
        });

        // wir klicken auf ein item der aktuelle Wert landet in checked
        // wenn in checked -> unchecked
        // wert dem Pfad zufügen oder eben wieder raus hauen.
        // wenn es parent zum Value gibt soll der nicht in checked sein.
        // wenn man wieder im Pfad hochclickt item und alles drunter löschen
        if (currentIndex === -1) {
            checked.push(value);
            setCurrentObjectTypeId(value);
            console.log(currentObjectTypeId);
            setSelectedItemLeft(item);
        } else {
            const checkedLength = checked.length;
            if(currentIndex < checkedLength-1) {
                checked.splice(currentIndex, checkedLength-currentIndex);
            } else {
                checked.splice(currentIndex, 1);
            }
            setCurrentObjectTypeId(null);
            const pathLength = pathArray.length
            pathArray.splice(pathIndex, pathLength-pathIndex);
            setSelectedItemLeft(null)
        }

        setChecked(checked);
        setLeft(pathArray);
    };

    const handleToggleRight = (value) => {
        console.log(value)
        const currentIndex = checkedRight.indexOf(value);
        let myVal = null;
        if (currentIndex === -1) {
            checkedRight.push(value);
            myVal = value;
        } else {
            checkedRight.splice(currentIndex, 1);
            myVal = null;
        }
        console.log('handleToggleRight: ' + value);
        console.log('handleToggleRight: ' + currentIndex);
        console.log('handleToggleRight: ' + checkedRight);
        setCheckedRight(checkedRight);
        setCurrentRightObjectTypeId(myVal);
        setComponenttypes(checkedRight);
    }

    // const handleAllRight = () => {
    //     setRight(right.concat(left));
    //     setLeft([]);
    // };

    const handleCheckedRight = () => {
        right.push(leftChecked);
        setChecked([])
        setRight(right);

        rightItems.push(selectedItemLeft);
        setRightItems(rightItems);
        setSelectedItemLeft(null)
        setCurrentObjectTypeId(null)
        setCurrentRightObjectTypeId(null);

    };

    const handleCheckedLeft = () => {
        console.log(rightItems);
        console.log(checkedRight);
        const keep = [];
        rightItems.forEach((item, index) => {
            if(checkedRight.includes(item.objecttype_id)) {

            } else {
               keep.push(item);
            }
        });

        setRightItems(keep);
        setCheckedRight([]);
        setCheckedAttributes([]);
        //setLeft(left.concat(rightChecked));
        //setRight(not(right, rightChecked));
        //setChecked(not(checked, rightChecked));
    };

    // const handleAllLeft = () => {
    //     setLeft(left.concat(right));
    //     setRight([]);
    // };

    const handleToggleAttributes = (value, componentTypeId) => {
        console.log(value)

        const currentIndex = checkedAttributes.indexOf(value);
        if (currentIndex === -1) {
            checkedAttributes.push(value);
            attributes.push(componentTypeId + '_' + value);
            setCurrentAttributeId(value);
        } else {
            checkedAttributes.splice(currentIndex, 1);
            attributes.splice(currentIndex, 1);
            setCurrentAttributeId(new Date());
        }
        setCheckedAttributes(checkedAttributes);
        setCurrentRightObjectTypeId(currentRightObjectTypeId)
        setCheckedRight(checkedRight);

        setAttributes(attributes);
     }

    // const getChildren = (data) => {
    //     const arr = [];
    //     if(data.children_objecttypes && data.children_objecttypes.length > 0) {
    //         data.children_objecttypes.forEach((item) => {
    //             arr.push(item.objecttype_id);
    //             const childArr = getChildren(item);
    //             arr[item.objecttype_id] = childArr;
    //         })
    //     }
    //     return arr;
    // }

    const customList = (items) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                <ListDisplayRight
                    items={items}
                    currentRightObjectTypeId={currentRightObjectTypeId}
                    handleToggleRight={handleToggleRight}
                    checkedItems={checkedRight}
                    handleToggleAttributes={handleToggleAttributes}
                    checkedAttributes={checkedAttributes}
                    currentAttributeId={currentAttributeId}
                />
                <ListItem />
            </List>
        </Paper>
    );

    const customList2 = (items) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                <ListDisplay
                    items={items}
                    checkedItems={checked}
                    handleToggle={handleToggle}
                    currentObjectTypeId={currentObjectTypeId}
                    idPath=""
                />
            </List>
        </Paper>
    );




    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item>{customList2(treeItemsState)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    {/*<Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>*/}
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={!leftChecked}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={checkedRight.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    {/*<Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>*/}
                </Grid>
            </Grid>
            <Grid item>{customList(rightItems)}</Grid>
        </Grid>
    );
}
