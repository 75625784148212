
import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import ComponentTypeAutoComplete from "./ComponentTypeAutoComplete";
import {makeStyles} from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));


export default function ParentObjectEditComponent ({name, guid, flatList, setValue, value, dataObjectSelected}) {
    const classes = useStyles();
    const [hiddenEditMode, setHiddenEditMode] = useState(true);

    const [defaultValue, setDefaultValue] = useState(value);
    useEffect(() => {
        setDefaultValue(value);
    }, [value]);

    const handleClick = () => {
        setHiddenEditMode(!hiddenEditMode)
        if(hiddenEditMode) {
            setValue(defaultValue);
        }
    }

    return (
        <>

                <ListItem>
                    <InputLabel className={classes.InputLabel}>Übergeordnetes Objekt</InputLabel>
                    {hiddenEditMode && (guid || name) ? (
                        <InputLabel>{name} ({guid})</InputLabel>
                    ) : (
                        <>
                        {!hiddenEditMode ? (
                        <ComponentTypeAutoComplete
                        flatList={flatList}
                        setValue={setValue}
                        value={value}
                        label="Übergeordnetes Objekt"
                        />
                        ) : (
                        null
                        )
                    }
                        </>
                    )}

                    <div style={{width: '25px'}}></div>
                    <EditIcon onClick={() => {handleClick()}}/>
                </ListItem>


        </>
    )
}