import React, { useEffect, useState} from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import {Form, Formik} from "formik";


import {connect} from "react-redux";

import {makeStyles} from "@mui/styles";
import UploadForm from "./UploadForm";
import {useStylesDialog} from "../../../shared/useStyleDialog";
import UploadDialogAppBar from "./UploadDialogAppBar";

const useStyles = makeStyles((theme) => ({

    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "200px"
    },
    selectbox: {
        width: "100%"
    },
}));

const initialValues = {
    name: '',
    description: '',
    componenttypes: [],
    guid: '',
    id: null,
    instanceTypeId: null,
    deactivated: 0
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function UploadDialog({dataObjectSelected, uploadDialogOpen, setUploadDialogOpen, callbackFunc, theRealm}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();
    // const [token] = useContext(TokenContext);
    const [formValues] = useState(null);
    // const [guid] = useState("");
    // const [id] = useState(dataObjectSelected?.id);
    // const [object, setObject] = useState(dataObjectSelected)

    useEffect(()=> {
        let type = 'realestateobject';
        console.log('UploadDialog');
        console.log(dataObjectSelected);
        if(dataObjectSelected.administrativeobjectTyp) {
            type = 'administrativeobject';
        } else if(dataObjectSelected.componentTypeName) {
            type = 'component';
        } else {
            type = 'realestateobject';
        }
        console.log(type);
    }, [dataObjectSelected])




    return (
        <Dialog
            className={classesDialog.dialog}
            open={uploadDialogOpen}
            onClose={() => setUploadDialogOpen(false)}
            TransitionComponent={Transition}
            fullScreen
        >
            <Formik
                initialValues={formValues || initialValues}

                onSubmit={async (values, { setSubmitting }) => {
                    // await saveObject(data, objectAttributes);
                    setSubmitting(false);

                }}

            >
                <Form>

                    <UploadDialogAppBar
                        setUploadDialogOpen={setUploadDialogOpen}
                    />

                    <UploadForm
                        dataObjectSelected={dataObjectSelected}
                        id={dataObjectSelected.id}
                        type={dataObjectSelected.administrativeobjectType ? 'administrativeobject': dataObjectSelected.realestateobjectType ? 'realestateobject' : 'component'}
                        callbackFunc={callbackFunc}
                    />


                </Form>
            </Formik>
        </Dialog>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(UploadDialog);