import React, {useEffect, useState, useContext} from "react";
import http from "../../../shared/http-common";
import {TokenContext} from "../../Store";
import MaterialTable from "material-table";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import ProjectService from "../../../services/ProjectService";
import UserService from "../../../services/UserService";

import { header } from'../../../shared/headerStyle';

const GetObjectDataByType = async (objecttype, type, realm, token) => {
    try {
        console.log('Hello GetObjectDataByType');
        let formData = new FormData();
        formData.append("realm", realm);
        // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
        return http.post(`/` +objecttype+`/` + type + `/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }
};

function ProjectObjects({projectGuid, realm, projectId}) {
    const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataObjects, setDataObjects] = useState([]);
    const tableRef = React.createRef();


    const [title] = useState('Zugeordnete Objekte');

    useEffect(()=>{
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, []);

    useEffect(()=> {
        setIsLoading(true);
        ProjectService.listprojectobjects(token, realm, projectId, projectGuid)
            .then(result => {
                setDataObjects(result.data);
                setIsLoading(false);
            })
    }, [token, realm, projectId, projectGuid]);


    const getColumnType = (data) => {
        let type = '';
        if(data.realestateobjectType) {
            type = data.realestateobjectType
        } else if(data.administrativeobjectType) {
            type = data.administrativeobjectType
        }
        return type;
    }


    const handleDeleteClick = async(object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object instanceof Array) {
            for (const obj of object) {
                await ProjectService.deleteProjectObject(token, realm, projectId, projectGuid, obj.guid)
            }
        } else {
            console.log(object);
            await ProjectService.deleteProjectObject(token, realm, projectId, projectGuid, object.guid)

        }
        ProjectService.listprojectobjects(token, realm, projectId, projectGuid)
            .then(result => {
                setDataObjects(result.data);
                setIsLoading(false);
            });
    }
    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>

            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: 'type', render: (rowData) => {
                        return getColumnType(rowData)}
                    },
                ]}
                data={dataObjects}

                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF'
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    selection: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}

                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: 'entfernen'
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                onRowClick={(event, rowData) => {
                    // Copy row data and set checked state
                    const rowDataCopy = { ...rowData };
                    rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
                    // Copy data so we can modify it
                    const dataCopy = [...dataObjects];
                    // Find the row we clicked and update it with `checked` prop
                    dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
                    setDataObjects(dataCopy);
                }}
                actions={[
                    rowData => ({
                        icon: () => <DeleteOutline />,
                        tooltip: 'aus Projekt entfernen',
                        onClick: (event, rowData) => handleDeleteClick(rowData, event)
                    }),
                ]}
            />

        </>
    )
}

export default ProjectObjects;
