import React, { useState, useContext, useEffect } from "react";

import {
    TokenContext,
    RealmContext,
    AttrDialogOpenContext,
    TreeSelectedContext,
    TreeSelectedItemContext, AttrSelectedContext, TreeItemsStateContext, AttrIsSelectedContext,
} from "../../../../Store";

import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import http from "../../../../../shared/http-common";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Fenster zum Anlegen eines Attributes

const useStyles = makeStyles((theme) => ({
    /*dialog: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "70%",
        marginRight: 0,
    },
    appBar: {
        position: "relative",
        background: "linear-gradient(45deg, #633437 30%, #B4AF03 90%)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
        formControl: {
        width: "100%",
    },
    dialogConfirm: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
    },*/
    multipleSelect: {
        width: "100%",
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const GetUnitList = async (token, realm) => {

    try {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/componentType/getComponentTypeAttributeUnitList`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

    } catch (e) {
        console.log(e);
    }

};

const GetDatatypeList = async (token, realm) => {

    try {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/componentType/getComponentTypeAttributeDatatypeList`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

    } catch (e) {
        console.log(e);
    }

};


export default function CreateAttrDialog({reloadTreeItem}) {
    const classes = useStyles();
    const classesDialog = useStylesDialog();
    const [AttrDialogOpen, setAttrDialogOpen] = useContext(AttrDialogOpenContext);
    const [TreeSelected, setTreeSelected] = useContext(TreeSelectedContext);
    const [TreeSelectedItem] = useContext(TreeSelectedItemContext);
    const [, setAttrSelected] = useContext(AttrSelectedContext);
    const [, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [, setAttrIsSelected] = useContext(AttrIsSelectedContext);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [AttrName, setAttrName] = useState("");
    const [AttrType, setAttrType] = useState("");
    const [AttrDataType, setAttrDataType] = useState("");
    const [AttrDesc, setAttrDesc] = useState("");
    const [AttrGlobalId, setAttrGlobalId] = useState("");
    const [AttrUnit] = useState("");
    const [AttrUnitId, setAttrUnitId] = useState("");
    const [AttrEnumerator, setAttrEnumerator] = useState([]);
    const [, setPostArray] = useState([]);
    const [AttrConfirm, setAttrConfirm] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const [datatypeList, setDatatypeList] = useState([]);

    const [enumEdit, setEnumEdit] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await GetUnitList(token, realm)
                .then((result) => {
                    // console.log(result)
                    setUnitList(result.data);
                });

        }
        async function fetchDatatype() {
            await GetDatatypeList(token, realm)
                .then((result) => {
                    console.log(result.data)
                    setDatatypeList(result.data);
                });

        }
        fetchData();
        fetchDatatype();
    }, [token, realm, setUnitList, setDatatypeList]);

    const AddAttr = async () => {
        const newArray = {
            name: AttrName,
            globalId: AttrGlobalId,
            datatype: AttrDataType,
            attributeType: AttrType,
            //pos: 0,
            unit: AttrUnit,
            unitId: AttrUnitId,
            description: AttrDesc,
            enumerators: AttrEnumerator,
        };

        const combArray = TreeSelectedItem;

        setPostArray(combArray.push(newArray));
        await Attr_Create();
    };

    const Attr_Create = async () => {
        try {

            const data = {
                name: TreeSelected.name,
                description: TreeSelected.description,
                cafmId: TreeSelected.cafm_id,
                globalId: TreeSelected.global_id,
                code: TreeSelected.code,
                //sortNumber: 0,
                parentComponent: TreeSelected.parent_objecttype_id,
                parentCafmId: TreeSelected.parent_cafm_id,
                parentGlobalId: TreeSelected.parent_global_id,
                //cafmConnectVersion: "v3",
                //applicableEntity: "IfcWall",
                //location: "",
                attributes: TreeSelectedItem,
            };

                 TreeComponentTypeService.saveComponentType(token, realm, data)
                    .then((res) => {
                        TreeComponentTypeService.getTreeData(token, realm)
                            .then((result) => {
                                setTreeItemsState(result.data);
                                console.log(res);

                                reloadTreeItem().then((newItem) => {
                                    if (newItem instanceof Array && newItem.length > 0) {
                                        console.log(newItem[0])
                                        setTreeSelected(newItem[0]);
                                        if(newItem[0].attribute) {
                                            const arr = newItem[0].attribute
                                            arr.forEach((attr) => {
                                                if(attr.name === AttrName) {
                                                    setAttrSelected(attr);
                                                }
                                            })
                                        }
                                        setAttrIsSelected(true);
                                    }
                                })
                            })

                    });


        } catch (e) {
            console.log(e);
        }
    };

    const handleEnumChange = (event) => {
        const enumString = event.target.value
        const enumArray = enumString.split(',');
        setAttrEnumerator(enumArray);
    }

    const handleEnumChange2 = (event, index, ele) => {
        console.dir(event);
        const enumString = event.target.value
        /// const enumArray = enumString.split(',');
        if(index === AttrEnumerator?.length) {
            event.target.value = ''
        }
        console.log(AttrEnumerator)
        const bums: Array = AttrEnumerator;
        console.log(enumString, index)
        bums[index] = enumString
        console.log(index ,AttrEnumerator?.length)

        const newArray = [];
        bums.forEach(value =>{
            if(value !== '') {
                newArray.push(value)
            }
        })
        ;
        setAttrEnumerator(newArray);
        console.log(AttrEnumerator)

    }

    const handleUnitChange = (val) => {
        // const enumString = event.target.value
        console.dir(val)
        setAttrUnitId(val.toString());
    }

    const lastEnumIndex = AttrEnumerator?.length;

    return (
        <div>
            <Dialog
                className={classesDialog.dialog}
                open={AttrDialogOpen}
                onClose={() => setAttrDialogOpen(false)}
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setAttrDialogOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Merkmal hinzufügen
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => {
                                setAttrConfirm(true);
                            }}
                        >
                            Bestätigen
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Attr_name"
                            label="Name"
                            type="text"
                            fullWidth
                            onChange={(e) => setAttrName(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Attr_globalid"
                            label="GUID"
                            type="text"
                            fullWidth
                            onChange={(e) => setAttrGlobalId(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_type_title">Daten-Typ</InputLabel>
                            <Select
                                labelId="AttrTypeLabel"
                                value={AttrType}
                                id="Attr_type"
                                required
                                fullWidth
                                onChange={(e) => setAttrType(e.target.value)}

                            >
                                {datatypeList.map((item) => (
                                    <MenuItem value={item.guid}>{item.name} - {item.identifier}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </ListItem>
                    {/*<ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_type_title">Typ</InputLabel>
                            <Select
                                labelId="AttrTypeLabel"
                                value={AttrType}
                                id="Attr_type"
                                onChange={(e) => setAttrType(e.target.value)}
                            >
                                <MenuItem value={"IfcLabel"}>IfcLabel</MenuItem>
                                <MenuItem value={"IfcText"}>IfcText</MenuItem>
                                <MenuItem value={"IfcBoolean"}>IfcBoolean</MenuItem>
                                <MenuItem value={"IfcInteger"}>IfcInteger</MenuItem>
                                <MenuItem value={"IfcIdentifier"}>IfcIdentifier</MenuItem>
                                <MenuItem value={"IfcDate"}>IfcDate</MenuItem>
                                <MenuItem value={"IfcReal"}>IfcReal</MenuItem>
                                <MenuItem value={"IfcCountMeasure"}>IfcCountMeasure</MenuItem>
                                <MenuItem value={"IfcMassMeasure"}>IfcMassMeasure</MenuItem>
                                <MenuItem value={"IfcVolumeMeasure"}>IfcVolumeMeasure</MenuItem>
                                <MenuItem value={"IfcLengthMeasure"}>IfcLengthMeasure</MenuItem>
                                <MenuItem value={"IfcAreaMeasure"}>IfcAreaMeasure</MenuItem>
                                <MenuItem value={"IfcPositiveLengthMeasure"}>IfcPositiveLengthMeasure</MenuItem>
                                <MenuItem value={"IfcElectricVoltageMeasure"}>IfcElectricVoltageMeasure</MenuItem>
                                <MenuItem value={"IfcPowerMeasure"}>IfcPowerMeasure</MenuItem>
                                <MenuItem value={"IfcPressureMeasure"}>IfcPressureMeasure</MenuItem>
                                <MenuItem value={"IfcMassDensityMeasure"}>IfcMassDensityMeasure</MenuItem>
                                <MenuItem value={"IfcVolumetricFlowRateMeasure"}>IfcVolumetricFlowRateMeasure</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>*/}
                    <ListItem>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Attr_description"
                            label="Hinweis"
                            type="text"
                            fullWidth
                            onChange={(e) => setAttrDesc(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_unit_title">Unit</InputLabel>
                            <Select
                                labelId="AttrUnitLabel"
                                value={AttrUnitId}
                                id="Attr_unit"
                                onChange={(e) => handleUnitChange(e.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {unitList.map((item) => (
                                    <MenuItem value={item.guid}>{item.name} [{item.symbol}]</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button onClick={()=>setEnumEdit(true)}>Enumerator-Werte eingeben</Button>
                    </ListItem>
                    <ListItem>
                        <TextField
                            margin="dense"
                            id="Attr_enums"

                            type="text"
                            fullWidth
                            onChange={(e) => handleEnumChange(e)}
                            value={AttrEnumerator}
                            disabled
                            defaultValue={AttrEnumerator}
                        />

                    </ListItem>

                    <Divider />
                </List>
            </Dialog>
            {AttrName !== "" ?
                <Dialog
                    className={classesDialog.dialogConfirm}
                    open={AttrConfirm}
                    onClose={() => setAttrConfirm(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setAttrConfirm(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                Merkmal <b>{AttrName}</b> anlegen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Alert variant="outlined" severity="success">
                            Das Merkmal {AttrName} kann angelegt werden! Das zugehörige Bauteil ist {TreeSelected.name}.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setAttrConfirm(false);
                            }}
                            color="primary"
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={async() => {
                                await AddAttr();
                                setAttrDialogOpen(false);
                                setAttrConfirm(false);
                            }}
                            color="primary"
                        >
                            Bestätigen
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                <Dialog
                    className={classesDialog.dialogConfirm}
                    open={AttrConfirm}
                    onClose={() => setAttrConfirm(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classesDialog.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setAttrConfirm(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classesDialog.title}>
                                Merkmal <b>{AttrName}</b> anlegen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Alert variant="outlined" severity="warning">
                            Keinen Namen vergeben!
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setAttrConfirm(false);
                            }}
                            color="primary"
                        >
                            Eingaben ändern
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Dialog
                className={classesDialog.dialogConfirm}
                open={enumEdit}
                onClose={() => setEnumEdit(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setEnumEdit(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Enumerator bearbeiten für <b>{AttrName}</b>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div>

                        {AttrEnumerator?.map((item, index) => (
                                <TextField
                                    autoFocus
                                    defaultValue={item}
                                    margin="dense"
                                    id={`enum_${index}`}
                                    label={`Enum-Wert ${index + 1}`}
                                    type="text"
                                    fullWidth
                                    onChange={(e) => handleEnumChange2(e, index, this)}
                                />
                            )
                        )}
                        <TextField
                            autoFocus
                            defaultValue=""
                            margin="dense"
                            id={`enum_${lastEnumIndex}`}
                            label={`Enum-Wert neu`}
                            type="text"
                            fullWidth
                            onChange={(e) => handleEnumChange2(e, lastEnumIndex)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEnumEdit(false);
                        }}
                        color="primary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={async() => {
                            // await AddAttr();

                            setEnumEdit(false);
                        }}
                        color="primary"
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
