// import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useCallback, useContext, useEffect, useState} from "react";
import UploadService from "../../../services/upload-files.service";
import {TokenContext} from "../../Store";
import {connect} from "react-redux";
import http from "../../../shared/http-common";
import {confirmWrapper} from "../../../shared/confirm";

function UploadForm({dataObjectSelected, id, type, callbackFunc, theRealm}) {
    const [token] = useContext(TokenContext);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [fileInfosLocale, setFileInfosLocal] = useState([]);
    const [fileInfos, setFileInfos] = useState([]);
    // const classes = useStyles();

    // const token = sessionStorage.getItem('kctoken');


    useEffect(() => {
        console.log(type);
        fetchFileData();
        console.log('UploadForm');
        console.log(dataObjectSelected.id);
        console.log(id);
        setCurrentFile(selectedFiles[0]);
    }, [selectedFiles, dataObjectSelected, id, token, theRealm, type]);

    const fetchFileData = async() => {
        await UploadService.getLocaleFiles(token, theRealm)
            .then((files) => {
                console.log('localfiles')
                console.dir(files)
                setFileInfosLocal(files.data)
            });
        await UploadService.getFiles(id, token, theRealm, type)
            .then((files) => {
                console.log('files')
                console.log(files);
                setFileInfos(files.data);
                // if(callbackFunc instanceof Function) {
                //     callbackFunc();
                // }
            });
    }

    function initFiles(files) {
        setSelectedFiles(files);
        // setCurrentFile(selectedFiles[0]);
    }

    const upload = useCallback(async() => {
        // console.log('hello upload');
        // console.dir(selectedFiles);
        // setCurrentFile(selectedFiles[0]);
        setProgress(0);
        // console.log('hello currentFile');
        // console.dir(selectedFiles[0]);
        // console.log('hello currentFile 2');
        // console.dir(currentFile);
        UploadService.upload(currentFile, token, theRealm, (event) => {
            console.log(currentFile)
            setProgress(Math.round((100 * event.loaded) / event.total))

        })
            .then(async(response) => {
                console.dir(dataObjectSelected)
                // Hier müssen wir die Dateien jetzt auf den Server hochladen
                setMessage(response.data.message)
                const res = await UploadService.insertAttachment(id ,currentFile, token, theRealm, type, dataObjectSelected.guid);
                console.log(res);
                const re2 = await UploadService.countAttachmentByObject(id ,token, theRealm, type)
                console.log(re2);
                setMessage(re2.data?.count)
                const result = await UploadService.getFiles(id, token, theRealm, type);
                setFileInfos(result.data);
                initFiles([]);
                if(callbackFunc instanceof Function) {
                    callbackFunc();
                }
                deleteLocaleFile(currentFile.name)
            })
            .catch((err) => {
                console.dir(err)
                setProgress(0);
                setMessage("Fehler: die Datei konnte nicht geladen werden!");
                setCurrentFile(undefined)
            });
    }, [currentFile, token, dataObjectSelected, id, theRealm, type]);

    const deleteLocaleFile = useCallback(async(fileName) => {
        UploadService.deleteLocaleFile(fileName, token, theRealm)
            .then((response) => {
                // console.dir(response)
                setMessage(response.data.message)
                return UploadService.getLocaleFiles(token, theRealm);
            })
            .then((files) => {
                // console.dir(files)
                setFileInfosLocal(files.data);
            })
            .catch(() => {
                setProgress(0);
                setMessage("Fehler: die Datei konnte nicht geladen werden!");
                setCurrentFile(undefined);
            });
    },[token, theRealm]);

    // const deleteFile = useCallback(async(fileName) => {
    //     UploadService.deleteLocaleFile(fileName, token)
    //         .then((response) => {
    //             // console.dir(response)
    //             setMessage(response.data.message)
    //             return UploadService.getLocaleFiles(token, theRealm);
    //         })
    //         .then((files) => {
    //             // console.dir(files)
    //             setFileInfosLocal(files.data);
    //         })
    //         .catch(() => {
    //             setProgress(0);
    //             setMessage("Fehler: die Datei konnte nicht geladen werden!");
    //             setCurrentFile(undefined)
    //         });
    // },[token, theRealm]);

    const deleteAttachment = useCallback(async(guid) => {
        if (await confirmWrapper('Soll die Datei wirklich gelöscht werden?')) {
            UploadService.deleteAttachment(guid, token, theRealm, type)
                .then((response) => {
                    if (response.data?.message) {
                        setMessage('Die Datei wurde gelöscht.');
                    } else if (response.data?.message === 'not found') {
                        setMessage('Die Datei wurde nicht gefunden.');
                    } else {
                        setMessage(response.data.message);
                    }
                    return UploadService.getFiles(id, token, theRealm, type);
                })
                .then((files) => {
                    // console.dir(files)
                    setFileInfos(files.data)
                    if (callbackFunc instanceof Function) {
                        callbackFunc();
                    }
                })
                .catch(() => {
                    setProgress(0);
                    setMessage("Fehler: die Datei konnte nicht geladen werden!");
                    setCurrentFile(undefined)
                });
        }
    },[token, id, theRealm, type]);

    const downloadFile = async (file) => {
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("attachmentId", file.id);
        formData.append("filename", file.name);
        let tableName = ''
        if(type === 'component') {
            tableName ='bauteil'
        } else {
            tableName = type;
        }
        formData.append("attachmentTable", tableName);
        console.log(type);
        const fetchData = await http.post("/attachment/download", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        );
        // console.dir(fetchData);
        let fetchDataModified = `data:${file.mime_type};base64,${fetchData.data.base64FileContent}`;
        console.log(fetchDataModified)
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = file.name;
        a.click();
    }

    return (
        <div>
            {currentFile && (
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                    >
                        {progress}%
                    </div>
                </div>
            )}

            <label className="btn btn-default">
                <input type="file" onChange={(e) => initFiles(e.target.files)} />
            </label>

            <button className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={() => upload()}
            >
                Upload
            </button>

            <div className="alert alert-light" role="alert">
                {message}
            </div>

            {/*<div className="card">
                <div className="card-header">Liste der lokalen Dateien</div>
                <ul className="list-group list-group-flush">
                    {fileInfosLocale &&
                        fileInfosLocale.map((file, index) => (
                            <li className="list-group-item" key={index}>
                                {file.name}
                                <Button onClick={()=>deleteLocaleFile(file.name)}>Löschen</Button>
                            </li>
                        ))}
                </ul>
            </div>*/}
            <div className="card">
                <div className="card-header">Liste der Dateien auf dem Server</div>
                <ul className="list-group list-group-flush">
                    {fileInfos &&
                        fileInfos.map((file, index) => (
                            <li className="list-group-item" key={index}>
                                <img src={`data:${file.mime_type};base64,${file.previewBase64content}`} alt=""/>
                                <div>{file.name} ({file.sizeFormatted})</div>
                                <Button onClick={()=>downloadFile(file)}>{file.name}</Button>
                                <Button onClick={()=>deleteAttachment(file.guid)}>Löschen</Button>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(UploadForm);