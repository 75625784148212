import {useParams} from "react-router";
import React, {useEffect, useState, useCallback} from "react";
// import {fetchUser} from './operations';
// import {Link} from "react-router-dom";
import MandantUserForm from "./MandantUserForm";
// import connect from '../../shared/connectWithOnLoad';
import {
    fetchRealmGroupById,
    fetchRealmGroups,
    fetchRealmRoles,
    fetchUserClientRoles,
    fetchUserRoles,
    fetchRealmClients
} from "../../../app/user/api";
import {Box, Button, Card, CardContent, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {BROKER_TENANT_GROUP} from "../../../modules/config";
import {makeStyles} from "@mui/styles"

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

function MandantRolle({roles}) {
    const classes = useStyles();


    return (
        <div >
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Zugewiesene Rollen in Keycloak
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {roles.map(function (role, index) {

                                    return <TableRow key={role.id}>
                                        <TableCell>{role.name} </TableCell>
                                        {/*<TableCell align="right"><Button onClick={() => alert(role)} size="small"
                                                                         className={classes.link}
                                                                         color="primary">hinzufügen</Button></TableCell>*/}
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default MandantRolle;
