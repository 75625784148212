import React, {useEffect, useState, useContext} from "react";
import http from "../../../shared/http-common";

import {TokenContext} from "../../Store";
import Button from "@mui/material/Button";

import MaterialTable from "material-table";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ProjectService from "../../../services/ProjectService";
import DataobjectService from "../../../services/DataobjectService";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";
import DataComponentEdit from "../Dataobjects/DataComponentEdit";
import UploadDialog from "../Dataobjects/UploadDialog";

import { header } from'../../../shared/headerStyle';

const GetObjectDataByType = async (obejcttype, type, theRealm, token) => {
    try {
        console.log('Hello GetObjectDataByType');
        let formData = new FormData();
        formData.append("realm", theRealm);
        // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
        return http.post(`/` +obejcttype+`/` + type + `/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }
};

const getColumnType = (data) => {
    let type = '';
    if(data.realestateobjectType) {
        type = data.realestateobjectType
    } else if(data.administrativeobjectType) {
        type = data.administrativeobjectType
    }

    let fieldtype = ''
    switch (type) {
        case 'site':
        case 'structure':
        case 'building':
        case 'room':
        case 'storey':
        case 'playground':
            fieldtype = 'realestateobjectType';
            break;
        case 'economicunit':
        case 'apartment':
        case 'rentalunit':
        case 'commercialunit':
        case 'parkingspace':
        case 'location':
        case 'buildingentrance':
        case 'parcel':
        case 'territorialdivision':
        case 'washhouse':
            fieldtype = 'administrativeobjectType';
            break;
    }


    return type;
}


function BimUserProjectObjects({userProject, setUserProject, projectGuid, realm, projectId, }) {
    const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataObjects, setDataObjects] = useState([]);
    const [subDataObjects, setSubDataObjects] = useState([]);
    const tableRef = React.createRef();

    const [showSubList, setShowSubList] = useState(false);

    const [title] = useState('Zugeordnete Objekte');

    useEffect(()=> {
        setIsLoading(true);
        trackPromise(
        ProjectService.listprojectobjects(token, realm, projectId, projectGuid)
            .then(result => {
                setDataObjects(result.data);
                setIsLoading(false);
            })
        );
    }, [token, realm, projectId, projectGuid]);



    return (
        <>
            <LoadingIndicator/>
            {!showSubList ? (
                <ObjectList userProject={userProject} setUserProject={setUserProject} dataObjects={dataObjects} setDataObjects={setDataObjects} subDataObjects={subDataObjects} setSubDataObject={setSubDataObjects} setShowSubList={setShowSubList}  realm={realm}/>
                ) : (
                <ObjectSubList subDataObjects={subDataObjects} setSubDataObject={setSubDataObjects} setShowSubList={setShowSubList} userProject={userProject} realm={realm}/>
                )
            }

        </>
    )
}

function ObjectList({userProject, setUserProject, dataObjects, setDataObjects, subDataObjects, setSubDataObject, setShowSubList, realm}) {
    const tableRef = React.createRef();
    const [title] = useState(userProject.name + ' - Zugeordnete Objekte');
    const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);
    // const [filtering, setFiltering] = useState(false);

    const goBack = () => {
        setUserProject(null);
    }

    const handleRowClick = async(event, object) => {
        event.preventDefault();
        setIsLoading(true);
        console.log(object)
        console.log(userProject)

        if(object.administrativeobjectTypeGuid) {

            await trackPromise(
                DataobjectService.listadministrativeobjectstoadministrative(token, realm, object.guid)
                    .then((result) => {
                        setSubDataObject(result.data);
                        setShowSubList(true);
                        // setIsLoading(false);
                    })
            )
        }else if(object.realestateobjectTypeGuid) {
            if(userProject.realestateobjectLevelGuid) {
                await trackPromise(
                    DataobjectService.listrealestateobjectstorealestateobjectwithtypelevel(token, realm, object.guid, userProject.realestateobjectLevelGuid)
                        .then((result) => {
                            console.log(result.data);
                            setSubDataObject(result.data);
                            setShowSubList(true);
                            // setIsLoading(false);
                        })
                )
            } else {
                await trackPromise(
                    DataobjectService.listrealestateobjectstorealestateobject(token, realm, object.guid)
                        .then((result) => {
                            console.log(result.data);
                            setSubDataObject(result.data);
                            setShowSubList(true);
                            // setIsLoading(false);
                        })
                )
            }
        }


    }
    const tableHeight =(window.innerHeight -37 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>
            <div onClick={goBack} className="backline">
                <Button style={{color: '#ffffff'}}><ChevronLeftIcon />Zurück</Button>
            </div>
            <LoadingIndicator />
            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: 'type', render: (rowData) => {
                            return getColumnType(rowData)
                        }
                    },
                ]}
                data={dataObjects}

                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF'
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    selection: false,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}

                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                onRowClick={handleRowClick}
                actions={[
                    rowData => ({
                        icon: 'wysiwyg',
                        tooltip: 'Alle zugehörigen Objekte anzeigen',
                        onClick: (event, rowData) => handleRowClick(event, rowData)
                    }),
                ]}
            />

        </>
    )
}


function ObjectSubList({subDataObjects, setSubDataObject, setShowSubList, userProject, realm}) {
    const tableRef = React.createRef();
    const [title] = useState(userProject.name + ' - Objekt zur Bauteil-Erfassung auswählen');
    // const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);

    const [dataObjectSelected, setDataObjectSelected] = useState([]);

    const [DataComponentEditDialogOpen, setDataComponentEditDialogOpen] = useState(false);
    const [DataComponentFormDialogOpen, setDataComponentFormDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);


    // const [componentType, setComponentType] = useState(null);
    const [, setComponentSelected] = useState([]);
    // const [attributeList, setAttributeList] = useState([]);

    const [, setFormValues] = useState(null)
    // const[realestateobjectGuid, setRealestateobjectGuid] = useState('');



    const goBack = () => {
        setShowSubList(false)
    }

    const handleUploadClick = (event, item) => {
        console.log(item);
        event.preventDefault();
        setDataObjectSelected(item);
        // setDataobjectsDialogOpen(false);
        setDataComponentEditDialogOpen(false);
        setUploadDialogOpen(true);
    }

    const handleEditClick = async (event, rowData) => {
        event.preventDefault();
        console.log(rowData);
        setFormValues([]);
        // setDataObjectSelected([]);
        setComponentSelected([])
        setDataObjectSelected(rowData);
        setDataComponentEditDialogOpen(true);
    }
    const tableHeight =(window.innerHeight -37 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>
            <div onClick={goBack} className="backline" >
                <Button style={{color: '#ffffff'}}><ChevronLeftIcon />Zurück</Button>
            </div>
            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: 'type'},

                ]}
                data={subDataObjects}

                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF'
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    selection: false,
                    exportButton: true,
                    exportAllData: true,
                    filtering: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}

                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                onRowClick={(event, rowData) => handleEditClick(event, rowData)}
/*                onRowClick={(event, rowData) => {
                    // Copy row data and set checked state
                    const rowDataCopy = { ...rowData };
                    rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
                    // Copy data so we can modify it
                    const dataCopy = [...subDataObjects];
                    // Find the row we clicked and update it with `checked` prop
                    dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
                    setSubDataObject(dataCopy);
                }}*/
                actions={[
                    // rowData => ({
                    //     icon: 'wysiwyg',
                    //     tooltip: 'Alle zugehörigen Objekte anzeigen',
                    //     onClick: (event, rowData) => handleRowClick(rowData, event)
                    // }),
                ]}
            />
           <DataComponentEdit
                dataObjectSelected={dataObjectSelected}
                setDataComponentEditDialogOpen={setDataComponentEditDialogOpen}
                DataComponentEditDialogOpen={DataComponentEditDialogOpen}
                handleUploadClick={handleUploadClick}
                setDataComponentFormDialogOpen={setDataComponentFormDialogOpen}
                DataComponentFormDialogOpen={DataComponentFormDialogOpen}
                userProject={userProject}
            />
            <UploadDialog
                dataObjectSelected={dataObjectSelected}
                setUploadDialogOpen={setUploadDialogOpen}
                uploadDialogOpen={uploadDialogOpen}
            />

        </>
    )
}

export default BimUserProjectObjects;
