import {SET_THE_REALM, SET_THE_TOKEN} from "./actions";

const INCREMENT = 'INCREMENT';
const DECREMENT = 'DECREMENT';


const initialState = 0;
const initialRealm = '';
const initialToken = '';

function reducer(requestId = initialState, action){
    switch(action.type){
        case INCREMENT:
            return requestId + 1;
        case DECREMENT:
            return requestId - 1;
        default:
            return requestId;
    }
}

export function theRealmReducer(theRealm= initialRealm, action) {
    switch(action.type) {
        case SET_THE_REALM:
            return action.action
        default:
            return theRealm;
    }
}

export function keycloakTokenReducer(keycloakToken= initialToken, action) {
    switch(action.type) {
        case SET_THE_TOKEN:
            return action.action
        default:
            return keycloakToken;
    }
}

export { INCREMENT, DECREMENT };
export default reducer;

