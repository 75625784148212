import React, { useContext } from "react";
import {makeStyles} from "@mui/styles"
import {
  AttrSelectedContext,
  UpdateAttrDialogOpenContext,
  DeleteAttrDialogOpenContext,
  SiteColorContext,
} from "../../Store";

import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import RenderOnRole from "../../RenderOnRole";

// Bearbeiten-Fenster der Attribute des gewählten Bauteils

const useStyles = makeStyles(() => ({
  treeView: {
    textAlign: "left",
  },
  attrEdit: {
    minHeight: "40vh",
    maxHeight: "fit-content",
    width: "80%",
    overflowY: "scroll",
    margin: "15px",
    padding: "20px",
    float: "right",
    border: "1px solid #D5CD7E",
    borderRadius: "10px",
  },
  ueberAttr: {
    float: "left",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "bold",
    width: "100%",
  },
  attrEditBtn: {
    float: "right",
    textAlign: "right",
  },
  attrMeta: {
    textAlign: "left",
    marginLeft: "5px",
  },
  lightIcons: {
    color: "grey",
  },
  darkIcons: {
    color: "#e5e5e5",
  },
}));

// Darstellung der Enumeratoren, sofern vorhanden
function DisplayEnumsTree({ enums }) {
  const classes = useStyles();

  if (enums !== null) {
    return (
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<ArrowRightIcon />}
        key={1}
      >
        {DisplayEnumsItem(enums)}
      </TreeView>
    );
  } else {
    return " ";
  }
}

// Enum-Item mapping
const DisplayEnumsItem = (enums) => {
  // Enums definieren
  return enums.map((e) => {
    return (
      <div>
        <TreeItem key={e} nodeId={e} label={e} />
      </div>
    );
  });
};

function EditAttr() {
  const classes = useStyles();
  const [AttrSelected] = useContext(AttrSelectedContext);
  const [, setUpdateAttrDialogOpen] = useContext(UpdateAttrDialogOpenContext);
  const [, setDeleteAttrDialogOpen] = useContext(DeleteAttrDialogOpenContext);
  const [SiteColor] = useContext(SiteColorContext);

  return (
    <div className={classes.attrEdit}>
      <div className={classes.ueberAttr}>{AttrSelected.name}</div>
      <RenderOnRole roles={['MAP_ADMIN', 'ADMIN_MAP', 'TWIN_DATA_ADMIN']}>

      {AttrSelected.twin_only ? SiteColor ? (

        <div className={classes.attrEditBtn}>
          <IconButton
            className={classes.lightIcons}
            onClick={() => {
              setUpdateAttrDialogOpen(true);
              console.log(AttrSelected);
            }}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            className={classes.lightIcons}
            onClick={() => {
              setDeleteAttrDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>

      ) : (
        <div className={classes.attrEditBtn}>
          <IconButton
            className={classes.darkIcons}
            onClick={() => {
              setUpdateAttrDialogOpen(true);
              console.log(AttrSelected);
            }}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            className={classes.darkIcons}
            onClick={() => {
              setDeleteAttrDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : null }

      </RenderOnRole>
      <div>
        <div className={classes.attrMeta}>
          Merkmal-ID: {AttrSelected.guid}
        </div>
        <div className={classes.attrMeta}>
          System Name: {AttrSelected.system_name}
        </div>
        <div className={classes.attrMeta}>
          Physikal. Größe: {AttrSelected.dimension}
        </div>
        <div className={classes.attrMeta}>
          Daten-Typ: {AttrSelected.data_type}
        </div>
        <div className={classes.attrMeta}>
          Idenitfier: {AttrSelected.identifier}
        </div>
        <div className={classes.attrMeta}>
          Hinweis: {AttrSelected.description}
        </div>
        <div className={classes.attrMeta}>
          Definition: {AttrSelected.definition_de}
        </div>
        <div className={classes.attrMeta}>
          Einheit: {AttrSelected.unit}
        </div>

        <div className={classes.attrMeta}>
          Enumeratoren: <DisplayEnumsTree enums={AttrSelected.enumerators} />
        </div>
        <div className={classes.attrMeta}>
          Beispiel: {AttrSelected.example_de}
        </div>
      </div>
    </div>
  );
}

export default EditAttr;