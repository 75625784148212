import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    TokenContext,
    // BimProfileSelectedContext,
    // BimProfileSelectedDataContext,
    BIMDialogOpenContext,
    // BimProfContext,
    SiteColorContext, // TreeItemsStateContext,
    BimProjectsContext,
    BimProjectSelectedContext
} from "../../Store";
import { useTheme } from 'styled-components'
import {makeStyles} from "@mui/styles";
import {connect} from "react-redux";

import http from "../../../shared/http-common";
import MaterialTable from "material-table";
import BimProjectCreate from "./BimProjectCreate";
import {confirmWrapper} from "../../../shared/confirm";
import UserService from "../../../services/UserService";

import { header } from'../../../shared/headerStyle';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BimProjectFormList from "./BimProjectFormList";
import PropTypes from "prop-types";
import BimUserProjects from '../BimUserProjects/BimUserProjects';
// Style für DataTreeView
const useStyles = makeStyles({
    root: {
        textAlign: "left",
        fontFamily: "poppins",
    },
    lightButton: {
        float: "right",
        fontWeight: "bold",
    },
    darkButton: {
        float: "right",
        color: "#e5e5e5",
        fontWeight: "bold",
    },
    katalogView: {
        width: "100%",
        height: "100%",
        /*margin: "15px",
        padding: "20px",*/
        float: "left",
        /*border: "2px solid #B4AF03",
        borderRadius: "25px",*/
    },
    btListe: {
        width: "100%",
        maxHeight: "100vh",
        /*overflowY: "scroll",*/
        /*margin: "15px",
        padding: "10px",*/
        float: "left",
    },

    table: {
        minWidth: 700,
    },
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Baumdarstellung Komponentenliste
function DataListView({ projectList, deleteProject, handleDeactivateClick, addNew, handleClickOnRow }) {
    // const classes = useStyles();
    const tableRef = React.createRef();
    // const [, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
    }, [])

    /*const handleClickOnRow = (project) => {
        console.dir(project)
        setBimProjectSelected(project);
        setBIMDialogOpen(true);
    }*/

    const theme = useTheme()

    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;

    return (

    <MaterialTable
        tableRef={tableRef}
        theme={theme}
        title="Projekte"
        columns={[
            { title: 'Bezeichnung', field: 'name' },
            { title: 'Beschreibung', field: 'description'},
            { title: 'Profil', field: 'bimProfileName'},
            { title: 'deaktiviert', field: 'deactivated',  lookup: { 0: 'nein', 1: 'ja' } },
        ]}
        data={projectList}
        options={{
            headerStyle: header,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            exportButton: true,
            exportAllData: true,
            maxBodyHeight: `${tableHeight}vh`,
            minBodyHeight: `${tableHeight}vh`,
        }}
        actions={[
            {
                icon: 'wysiwyg',
                tooltip: 'Details',
                onClick: (event, rowData) => handleClickOnRow(rowData)
            },{
                icon: 'delete',
                tooltip: 'löschen',
                onClick: (event, rowData) => deleteProject(rowData),
                disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
            },
            rowData => ({
                icon:  rowData.deactivated ? 'taskalt' : 'cancel',
                tooltip: rowData.deactivated ? 'Aktivieren': 'Deaktivieren',
                onClick: (event, rowData) => handleDeactivateClick(rowData),
                disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
            }),
            {
                icon: 'add',
                tooltip: 'Neues Projekt anlegen',
                isFreeAction: true,
                onClick: (event) => addNew(),
                disabled: !UserService.hasRole(['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN'])
            }
        ]}
        onRowClick={(event, rowData) => handleClickOnRow(rowData)}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} von {count}',
                labelRowsSelect: 'Zeilen',
                labelRowsPerPage: 'Zeilen pro Seite'
            },
            toolbar: {
                nRowsSelected: '{0} Zeile(n) ausgewählt',
                searchTooltip: 'Suchen',
                searchPlaceholder: 'Suchbegriff eingeben'
            },
            header: {
                actions: ''
            },
            body: {
                emptyDataSourceMessage: 'Keine Einträge gefunden',
                filterRow: {
                    filterTooltip: 'Filter'
                },
                editRow: {
                    deleteText: 'Soll der Datensatz wirklich gelöscht werden?',
                    saveTooltip: 'Speichern',
                    cancelTooltip: 'Abbrechen'
                }
            }
        }}

    />
    );
}

function BimProjects({theRealm}) {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    // const [bimProf /*, setBimProf*/] = useContext(BimProfContext);
    const [, setBIMDialogOpen] = useContext(BIMDialogOpenContext);
    const [SiteColor] = useContext(SiteColorContext);
    // const [, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    // const [, setBimProfileSelectedData] = useContext(BimProfileSelectedDataContext);
    const [bimProjects, setBimProjects] = useContext(BimProjectsContext);
    const [BimProjectSelected, setBimProjectSelected] = useContext(BimProjectSelectedContext);
    // const [formValues, setFormValues] = useState(null);
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState();

    // const kctoken = sessionStorage.getItem('kctoken');
    // setToken(kctoken);

    const reloadProjects = useCallback(async (project=null) => {
        const GetProjectsData = async () => {
            try {
                let formData = new FormData();
                formData.append("realm", theRealm);

                return http.post(`/project/listAll`, formData,{
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });


            } catch (e) {
                console.log(e);
            }

        };
        GetProjectsData().then((result) => {
            console.log(result.data);
            setBimProjects(result.data);
            if(project) {
                handleClickOnRow(project)
            }
        });
    }, [setBimProjects, theRealm, token]);

    useEffect(() => {
        if(!UserService.isLoggedIn()) {
            UserService.doLogout();
        }
        UserService.userInfo().then(c => {
            setUserInfo(c);
        })
    }, []);

    useEffect( () => {
        async function fetchData() {
            if (!bimProjects) {
                await reloadProjects();
            }
        }
        fetchData();
    }, [bimProjects, reloadProjects]);

    const handleDeactivateClick = (project) => {
        if(project.deactivated) {
            Project_activate(project);
        } else {
            Project_Deactivate(project);
        }
    }

    // Gewähltes Bim-Project löschen
    const Project_Delete = async (project) => {
        try {
            if (await confirmWrapper('Soll das Projekt wirklich gelöscht werden?')) {
                console.log(BimProjectSelected);
                let formData = new FormData();
                formData.append("realm", theRealm);
                formData.append("projectId", project.id);
                formData.append("guid", project.guid);
                // await http.post(`/brokerProfile/deleteProject`, formData,{
                await http.post(`/project/delete`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                });

                await reloadProjects();
                setBimProjectSelected([]);
            }

        } catch (e) {
            console.log(e);
        }
    };

    // Gewähltes Bim-Project deaktivieren
    const Project_Deactivate = async (project) => {
        try {

            console.log(BimProjectSelected);
            let formData = new FormData();
            formData.append("realm", theRealm);
            formData.append("projectId", project.id);
            formData.append("guid", project.guid);
            // await http.post(`/brokerProfile/deactivateProject`, formData,{
            await http.post(`/project/deactivate`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });

            await reloadProjects();
            setBimProjectSelected([]);


        } catch (e) {
            console.log(e);
        }
    };

    // Gewähltes Bim-Project aktivieren
    const Project_activate = async (project) => {
        try {

            console.log(BimProjectSelected);
            let formData = new FormData();
            formData.append("realm", theRealm);
            formData.append("projectId", project.id);
            formData.append("guid", project.guid);
            // await http.post(`/brokerProfile/activateProject`, formData,{
            await http.post(`/project/activate`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });

            await reloadProjects();
            setBimProjectSelected([]);


        } catch (e) {
            console.log(e);
        }
    };

    const addNew = () => {
        setBimProjectSelected([]);
        setBIMDialogOpen(true);
    }

    const handleClickOnRow = (project) => {
        console.dir(project)
        setBimProjectSelected(project);
        setBIMDialogOpen(true);
    }

    // Tab Krams
    const [value, setValue] = React.useState(0);

    const styles = {
        TabBorderStyle: {
            padding: "3px",
            borderRight: "1px solid black",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
        }
    }
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    let hasRole = false
    if(userInfo){
        console.dir(userInfo);

        userInfo.realm_access?.roles?.forEach(item => {
            if(item === 'MAP_ADMIN') {
                hasRole = true;
            }
        });
        if(!hasRole) {
            userInfo.resource_access["twin-broker-client"]?.roles?.forEach(item => {
                if(item === 'TWIN_DATA_WRITER' || item === 'TWIN_DATA_ADMIN') {
                    hasRole = true;
                }
            });
        }
    }

    return (
        <div>
            <div className={classes.katalogView}>
                <div className={classes.btListe}>
                    {hasRole ? (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px'}}>
                            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" sx={{marginLeft: '5px' }}>
                                <Tab label="Projekt" {...a11yProps(0)} sx={styles.TabBorderStyle}/>
                                <Tab label="Eigene Projekte" {...a11yProps(1)} sx={styles.TabBorderStyle}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <DataListView projectList={bimProjects} deleteProject={Project_Delete} handleDeactivateClick={handleDeactivateClick} addNew={addNew} handleClickOnRow={handleClickOnRow}/>

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <BimUserProjects theRealm={theRealm} />
                        </TabPanel>
                    </>
                    ) : (
                    <BimUserProjects theRealm={theRealm} />
                )}
                </div>
               {/* <div className={classes.editView}>
                    <BimProjectEdits reloadProjects={reloadProjects}/>
                </div>*/}
                <BimProjectCreate
                    bimProjectData={BimProjectSelected}
                    reloadProjects={reloadProjects}
                    setBimProjectSelected={setBimProjectSelected}
                />
            </div>
        </div>
    );
};

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(BimProjects);