import Keycloak from "keycloak-js";
import { KEYCLOAK_SERVER_URL } from "../modules/config";
import { KEYCLOAK_CLIENT_ID } from "../modules/config";
import { BASE_SERVER_URL } from "../modules/config";

let realm = 'master';
if(sessionStorage.getItem('realm_stuff')) {
    realm = sessionStorage.getItem('realm_stuff');
}

let intervallId;


/*const keycloakConfigJson3 = {
    "realm": realm,
    "url": "https://auth.immospector.com/auth/",
    "clientId": "immomatik-broker-client",
}*/

const keycloakConfigJson2 = {
    "realm": realm,
    "url": KEYCLOAK_SERVER_URL,
    "clientId": KEYCLOAK_CLIENT_ID,
}

/*const keycloakConfigJsonLocal = {
    "realm": 'Billetest',
    "url": "http://localhost:8080/",
    "clientId": "twin-broker-client",
}*/


// const _kc = new Keycloak('/keycloak.json');
const _kc = new Keycloak(keycloakConfigJson2);

// const _kc = new Keycloak();

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso', //'login-required', //'check-sso', //login-required
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'


    })
        .then((authenticated) => {
            // if (authenticated) {
                //store authentication tokens in sessionStorage for usage in app
                sessionStorage.setItem('kctoken', _kc.token);
                sessionStorage.setItem('refreshToken', _kc.refreshToken);

                onAuthenticatedCallback();
            // } else {
            //   doLogin();
            // }
            //to regenerate token on expiry
            //
            intervallId = setInterval(() => {
                _kc.updateToken(180).then((refreshed) => {
                    if (refreshed) {
                        // console.debug('Token refreshed' + refreshed);
                        sessionStorage.setItem('kctoken', _kc.token);
                        sessionStorage.setItem('refreshToken', _kc.refreshToken);
                    } else {
                        console.warn('Token not refreshed 1, valid for '
                            + Math.round(_kc.tokenParsed.exp + _kc.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    }
                },
                    (e) => {
                    console.dir(e)
                        console.error('Failed to refresh token');
                });


            }, 60 * 1000)
        },
            (err) => {
            console.dir(err)
                console.error("Authenticated Failed");
        })
        .catch((e)=>{
            console.dir(e)
        });

    _kc.onTokenExpired = () => {
        console.log('expired '+new Date());
        _kc.updateToken(180).then((refreshed) => {
                if (refreshed) {
                    // console.debug('Token refreshed' + refreshed);
                    sessionStorage.setItem('kctoken', _kc.token);
                    sessionStorage.setItem('refreshToken', _kc.refreshToken);
                } else {
                    console.warn('Token not refreshed 2, valid for '
                        + Math.round(_kc.tokenParsed.exp + _kc.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }
            },
            () => {
                console.error('Failed to refresh token 2');
            });

    }
};

const doLogin = _kc.login;

const doLogout = async () => {
    sessionStorage.setItem('kctoken','');
    sessionStorage.setItem('refreshToken', '');
    clearInterval(intervallId);
    sessionStorage.setItem('realm_stuff', '');
    sessionStorage.clear();
    await _kc.logout({redirectUri: BASE_SERVER_URL});
}

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const userTenant = () => _kc.tokenParsed?.tenantName;
const userTenantId = () => _kc.tokenParsed?.tenantId;

const updateToken = (successCallback) =>
    _kc.updateToken(180)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => {
    let checkClientRolle = false;
    let checkRealmRolle = false;
    checkClientRolle = _kc.hasResourceRole(role, _kc.clientId)
    checkRealmRolle = _kc.hasRealmRole(role)
    return checkClientRolle || checkRealmRolle;
});

const automaticLogin = async (realmId) => {
    realm = realmId
    _kc.realm = realmId;
    // _kc.clientId = '';
    // console.log(realm);
    sessionStorage.setItem('realm_stuff', realmId);
    await doLogin();
}

const setRealm = (realmId) => {
    _kc.realm = realmId;
}

const userProfile = () => {
    return _kc.loadUserProfile()
        .then((res) => {
            console.log('userProfile: ' + JSON.stringify(res));
            return res
        })
}

const userInfo = () => {
    // console.log('in UserInfo')
    return _kc.loadUserInfo()
        .then((res) => {
            // console.log('userInfo: ' + JSON.stringify(res));
            return res;
        })
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    setRealm,
    automaticLogin,
    userTenant,
    userTenantId,
    userInfo,
    userProfile
};

export default UserService;


