import {useParams} from "react-router";
import React from "react";

import MandantUserFormCreate from "./MandantUserFormCreate";

function MandantUserCreate() {
    const {realmId} = useParams();


    return (
        <div className="row">
            <div className="col-sm-12">
                <h3>{realmId} - neuen Benutzer anlegen</h3>
                <MandantUserFormCreate realmId={realmId}  />
            </div>
        </div>
    );
}

export default MandantUserCreate;
