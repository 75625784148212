// import React from 'react';
// import { KcAdminClient } from "@keycloak/keycloak-admin-client";
// import KcAdminClient from '@keycloak/keycloak-admin-client';
import KeycloakAdminClient from "@keycloak/keycloak-admin-client";

import { KEYCLOAK_SERVER_URL } from "../modules/config";


const _kcAdminClient = new KeycloakAdminClient({
    baseUrl: KEYCLOAK_SERVER_URL,
    realmName: 'master',
});

const setToken = (token) => {
    _kcAdminClient.setAccessToken(token);
}

const setRealm = (realm) => {
    _kcAdminClient.realmName = realm;
};

const getRealmsList = () => {
    return _kcAdminClient.realms.find()
        .then((realms) => {
            return realms;
        })
};

const getRolesList = (realm) => {
    // console.log(realm);
    _kcAdminClient.setConfig({realmName: realm});
    return _kcAdminClient.roles.find({realm: realm})
        .then((result) => {
            return result;
        });
}

const getGroups = (realm) => {
    _kcAdminClient.setConfig({realmName: realm});
    return _kcAdminClient.groups.find()
        .then((result) => {
            return result;
        });
}

const getGroupById = (groupId, realm) => {
    _kcAdminClient.setConfig({realmName: realm});
    return _kcAdminClient.groups.findOne({id: groupId, realm: realm})
        .then((result) => {
            return result;
        });
}

const getClients = (realm) => {
    _kcAdminClient.setConfig({realmName: realm});
    return _kcAdminClient.clients.find(realm);
}

const RealmAdminService = {
    setToken,
    getRealmsList,
    getRolesList,
    getGroups,
    getGroupById,
    setRealm,
    getClients
};

export default RealmAdminService;