import { handleActions } from 'redux-actions';
import * as actions from './userAction';

const initialState = [];

function setUsers(state, action) {
    const users = action.payload;
    return users;
}

function setBenutzer(state, action) {
    const benutzer = action.payload;
    return benutzer;
}

export default handleActions(
    {
        [actions.setUserList]: setUsers,
        [actions.setBenutzer]: setBenutzer
    },
    initialState
);