import React, {useContext} from 'react';
import {Route, Routes} from 'react-router-dom';

// Components
import Home from './components/MainView/Home/Home';
// import TokenGenerator from './components/MainView/TokenGenerator/TokenGenerator';
import BIMProfiles from './components/MainView/BimProfiles/BimProfiles';
import BimProjects from './components/MainView/BimProjects/BimProjects';
import BimUserProjects from './components/MainView/BimUserProjects/BimUserProjects';
import TreeComponent from './components/MainView/TreeComponent/TreeComponent';
import LoadingScreen from './components/MainView/LoadingScreen/LoadingScreen';
import DataobjectsList from './components/MainView/Dataobjects/DataobjectsList';
import Datacomponents from './components/MainView/Datacomponents/Datacomponents';
import MandantUserList from './components/MainView/MandantUserList/MandantUserList';
import MandantUserCreate from './components/MainView/MandantUserList/MandantUserCreate';
import ObjectFields from './components/MainView/Administration/ObjectFields';
import MapObjects from './components/MainView/Karte/MapObjects';
// import App from './components/MainView/Administration/User/App';

// Store
import {
    TreeIsLoadedContext,
    ProfilIsLoadedContext,
    ProjectIsLoadedContext,
    UserProjectIsLoadedContext,
} from './components/Store';
// import Mandanten from "./app/mandant/Mandanten";
import RolesRoute from "./components/RolesRoute";
import NoMatch from "./components/NoMatch";
// import UserCreate from "../trash/UserCreate";
// import Users from "../trash/Users";
import RenderOnRole from "./components/RenderOnRole";
// import User from "../trash/User";
import UploadFiles from "./components/MainView/Import/upload-files.component";
import FileMapping from "./components/FileMapping";
import MandantUser from "./components/MainView/MandantUserList/MandantUser";
import BimetaProfiles from "./components/MainView/BimProfiles/BimetaProfiles";
import WuDbData from "./components/MainView/Import/WuDbData";
import BimetaData from "./components/MainView/BimetaData/BimetaData";
import Dataobject from "./components/MainView/Dataobjects/Dataobject";

import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Welcome from "./app/Welcome";
import DokuObject from "./components/MainView/DokuObject";

const TwinRoutes = (props) => {
    const [TreeIsLoaded] = useContext(TreeIsLoadedContext);
    const [ProfilIsLoaded] = useContext(ProfilIsLoadedContext);
    const [ProjectIsLoaded] = useContext(ProjectIsLoadedContext);
    const [UserProjectIsLoaded] = useContext(UserProjectIsLoadedContext);
    // const [DataobjectsIsLoaded] = useContext(DataobjectsIsLoadedContext);

    return (
        <Routes>
            <Route exact path='/' element={ <RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><Home/></RolesRoute>}
            />
            {/*<!-- Meine Projekte-->*/}
            {UserProjectIsLoaded ? <Route exact path='/BimUserProjects'
                element={ <RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><BimUserProjects/></RolesRoute>}
            /> : <Route exact path='/BimUserProjects'
                        element={ <RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}
            />}
            {/*<!-- Projekte-->*/}
            {ProjectIsLoaded ? <Route exact path='/BimProjects'
                element={ <RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><BimProjects/></RolesRoute>}
                /> : <Route exact path='/BimProjects' element={
                <RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}
            />}
            {/*<!-- Katalog-->*/}
            {TreeIsLoaded ? <Route exact path='/treecomponent'
                    element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><TreeComponent/></RolesRoute>}/>
                : <Route exact path='/treecomponent'
                         element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}/>}
            {/*<!-- Profile-->*/}
            {ProfilIsLoaded ? <Route exact path='/BimProfile'
                                     element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><BIMProfiles/></RolesRoute>}/>
                : <Route exact path='/BimProfile'
                         element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}/>}
            {/*<!-- Upload --> */}
            <Route exact path='/Import/uploadfiles/:realmId'
                   element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_ADMIN']}><UploadFiles/></RolesRoute>} />

            <Route exact path='/MandantUserList/:realmId'
                   element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN']}><MandantUserList/></RolesRoute>}/>

            <Route exact path="/mandantuseredit/:realmId/:userId"
                   element={<RolesRoute  roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN']}><MandantUser/></RolesRoute>} />

            <Route exact path='/MandantUserCreate/:realmId'
                element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN']}><MandantUserCreate/></RolesRoute>} />

            <Route exact path='/filemapping/:file'
                   element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><FileMapping/></RolesRoute>} />

            <Route exact path='/BimProfile/bimetaprofile'
                element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}><BimetaProfiles/></RolesRoute>}/>
            : <Route exact path='/BimProfile/bimetaprofile'
                     element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}/>
            <Route exact path='/Import/wuimport'
                element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_DATA_ADMIN']}><WuDbData/></RolesRoute>}/>
            : <Route exact path='/Import/wuimport'
                     element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}/>
            <Route exact path='/Import/wudaten'
                element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_DATA_ADMIN']}><WuDbData/></RolesRoute>}/>
            : <Route exact path='/Import/wudaten'
                     element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_DATA_ADMIN']}><LoadingScreen/></RolesRoute>}/>
            <Route exact path='/Import/BimetaData'
                element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN']}><BimetaData/></RolesRoute>}/>
            : <Route exact path='/Import/BimetaData'
                     element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN']}><LoadingScreen/></RolesRoute>}/>

            <Route exact path='/DataobjectsList/:realmId'
                   element={<RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}  >
                <DataobjectsList />
            </RenderOnRole>}/>
            <Route exact path='/Dataobject'
                   element={<RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}  >
                       <Dataobject />
                   </RenderOnRole>}/>
            {/*<Route exact path='/DataobjectsList/:realmId' component={DataobjectsList}/>*/}
            <Route exact path='/Datacomponents/:realmId'
                   element={<RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']} >
                <Datacomponents />
            </RenderOnRole>}/>

            <Route exact path='/ObjectFields/:realmId'
                   element={<RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']} >
                <ObjectFields />
            </RenderOnRole>}/>

                <Route exact path='/startimport'
                    element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_ADMIN']}>
                    <FileMapping />
                </RolesRoute>}/>
                <Route exact path="/mandantusercreate/:realmId/:userId"
                    element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN']} >
                    <MandantUserCreate/>
                </RolesRoute>}/>
                {/*<RolesRoute roles={['BROKER_ADMIN','ADMIN_MANDANT', 'ADMIN_MAP', 'USER_MANDANT']} exact path='/uploadfiles/:realmId'>
                    <UploadFiles />
                </RolesRoute>*/}

                <Route exact path='/MapObjects/:realmId'
                       element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_READER', 'TWIN_DATA_ADMIN']}><MapObjects/></RolesRoute>}/>

            <Route exact path='/SwaggerDoku/:realmId'
                   element={<RolesRoute roles={['ADMIN_MAP', 'MAP_ADMIN']}><DokuObject /></RolesRoute>}/>

{/*            <Route exact path='/mandantlist' element={<RenderOnRole  roles={['BROKER_ADMIN']}>
                <Mandanten/>
            </RenderOnRole>}/>
            <Route exact path='/benutzeradd/:realmId'
                element={<RolesRoute roles={['BROKER_ADMIN', 'USER_ADMIN_MANDANT', 'ADMIN_MANDANT']}>
                    <UserCreate/></RolesRoute>}
            />
            <Route exact path='/benutzer/:realmId' element={<RolesRoute roles={['BROKER_ADMIN', 'USER_ADMIN_MANDANT', 'ADMIN_MANDANT']}>
                <Users/>
            </RolesRoute>}/>
            <Route exact path="/benutzeredit/:realmId/:userId" element={<RolesRoute  roles={['BROKER_ADMIN', 'USER_ADMIN_MANDANT', 'ADMIN_MANDANT']}>
                <User/>
            </RolesRoute>}/>*/}
            <Route path="/:realm" element={<Home />}/>
            <Route path="*" element={<NoMatch/>}/>
        </Routes>
    )
}

export default TwinRoutes