import http from "../shared/http-common";

const BimProfileService = {
    getBimProfile(token, theRealm, profileId) {
        console.log(theRealm, profileId);
        let formData = new FormData();
        formData.append("realm", theRealm);
        formData.append("profileId", profileId);
        // formData.append("guid", project.guid);
        // return http.get(`/getprofile/${theRealm}/${profileId}`,{
        return http.post(`/bimProfile/get/`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getBimProfiles(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/bimProfile/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    deleteBimProfile(token, realm, BimProfileSelectedData) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("profileId", BimProfileSelectedData.id);
        formData.append("guid", BimProfileSelectedData.guid);
        return http.post(`/bimProfile/delete`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    getBimetaProfiles(token, realm) {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/bimetaData/profiles`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    },

    importBimetaProfiles(token, realm, id) {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("id", id);

        return http.post(`/bimetaData/saveprofile`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }
}

export default BimProfileService;