import { handleActions } from 'redux-actions';
import * as actions from './mandantAction';

const initialState = [];

function setRealms(state, action) {
    const realms = action.payload;
    return realms;
}

export default handleActions(
    {
        [actions.setMandantList]: setRealms
    },
    initialState
);