import {useStylesDialog} from "../../../shared/useStyleDialog";
import {makeStyles} from "@mui/styles";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import {MyTextInput} from "../../MyTextInput";
import InputLabel from "@mui/material/InputLabel";
import {ErrorMessage} from "formik";
import Select from 'react-select';
import ObjecttypeSelectlist from "../../../shared/ObjecttypeSelectlist";
import {useContext, useEffect, useLayoutEffect, useState} from "react";
import {BimProfContext, RealmContext, TokenContext} from "../../Store";
// import ObjecttypeService from "../../../services/ObjecttypeService";



const styles = {
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "150px"
    },
    selectbox: {
        width: "300px"
    },
    TabBorderStyle: {
        padding: "3px",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    }
}

function BimProjectFormList({bimProjectData, setProfileId, profileId, optionsRealestateobject, setSelectedRealestateobject, selectedRealestateobject}) {
    // const classesDialog = useStylesDialog();
    // const classes = useStyles();
    const [bimProf /*, setBimProf*/] = useContext(BimProfContext);
    const [options, setOptions] = useState([]);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);

    const onChangeProfile = (selectedOption) => {
        // console.log(selectedOption);
        setProfileId(selectedOption.value);
    }


    useLayoutEffect(()=>{
        const profileOptions = [];
        if(bimProf && bimProf.length > 0) {
            bimProf.forEach((prof, index)=>{
                // console.log(index)
                profileOptions.push({value: prof.id, label: prof.name})
            });
            setOptions(profileOptions);
        }
    }, [bimProf]);

    // const [optionsRealestateobject, setOptionsRealestateobject] = useState('');


    useEffect(() => {
        if(bimProjectData) {
            setSelectedRealestateobject(bimProjectData.realestateobjectLevelGuid)
        } else {
            setSelectedRealestateobject('')
        }
    }, [bimProjectData]);

    const handleChangeRealestateobject = event => {
        console.log(event.target.value);
        setSelectedRealestateobject(event.target.value);
        // handleObjecttypeChange(objecttype, type)
    };


    return (
        <List>
            <ListItem>

                <MyTextInput
                    autoFocus
                    margin="dense"
                    name="name"
                    type="text"
                    fullWidth
                    required
                    label="Name"
                />
                <ErrorMessage name="name"/>
            </ListItem>

            <ListItem>

                <MyTextInput
                    margin="dense"
                    name="description"
                    type="text"
                    fullWidth
                    label="Beschreibung"
                />
            </ListItem>
            <ListItem>
                <InputLabel sx={styles.InputLabel}>Profil</InputLabel>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '300px',
                        }),
                    }}
                    isDisabled={profileId>0}
                    value={
                        options?.filter(option =>
                            option.value === profileId)
                    }
                    onChange={(option) => onChangeProfile(option)}
                    options={options}

                />
            </ListItem>
            <ListItem>
                <InputLabel sx={styles.InputLabel}>Objekt-Ebene</InputLabel>
                <ObjecttypeSelectlist
                    options={optionsRealestateobject}
                    selected={selectedRealestateobject}
                    handleChange={handleChangeRealestateobject}
                    style={styles.selectbox}
                />
            </ListItem>
            <ListItem>
                <InputLabel>Status:&nbsp;</InputLabel>
                <InputLabel>{bimProjectData.status}</InputLabel>
            </ListItem>
            <ListItem>
                <div>Bauteiltypen, die dem Profil zugeordnet sind:</div>

            </ListItem>
            <ListItem style={{display: "block"}}>
                {bimProjectData?.componenttypes?.map((ct) => (
                    <div>{ct.mmsCode} - {ct.name}</div>
                ))}
            </ListItem>


        </List>
    )
}
export default BimProjectFormList;