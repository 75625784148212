import React, {useState, useContext, useEffect} from "react";

import {
    TokenContext,
    RealmContext,
    UpdateAttrDialogOpenContext,
    TreeSelectedContext,
    TreeSelectedItemContext,
    AttrSelectedContext,
    TreeItemsStateContext, AttrIsSelectedContext,
} from "../../../../Store";

// import {makeStyles} from "@mui/styles"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TreeComponentTypeService from "../../../../../services/TreeComponentTypeService";
import {trackPromise} from "react-promise-tracker";
import http from "../../../../../shared/http-common";

import {useStylesDialog} from "../../../../../shared/useStyleDialog";

// Fenster zum Bearbeiten des ausgewählten Attributes

// Transition legt fest, wie das Fenster erscheint
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const GetUnitList = async (token, realm) => {

    try {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/componentType/getComponentTypeAttributeUnitList`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

    } catch (e) {
        console.log(e);
    }

};

const GetDatatypeList = async (token, realm) => {

    try {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/componentType/getComponentTypeAttributeDatatypeList`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });

    } catch (e) {
        console.log(e);
    }

};

export default function UpdateAttrDialog({reloadTreeItem}) {
    const classesDialog = useStylesDialog();
    const [UpdateAttrDialogOpen, setUpdateAttrDialogOpen] = useContext(
        UpdateAttrDialogOpenContext
    );
    const [TreeSelected, setTreeSelected] = useContext(TreeSelectedContext);
    const [TreeSelectedItem] = useContext(TreeSelectedItemContext);
    const [, setTreeItemsState] = useContext(TreeItemsStateContext);
    const [, setAttrIsSelected] = useContext(AttrIsSelectedContext);
    const [AttrSelected, setAttrSelected] = useContext(AttrSelectedContext);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [AttrName, setAttrName] = useState(AttrSelected.name);
    const [AttrType, setAttrType] = useState(AttrSelected.attribute_type);
    const [AttrDataType, setAttrDataType] = useState(AttrSelected.data_type);
    const [AttrDesc, setAttrDesc] = useState(AttrSelected.description);
    const [AttrId, setAttrId] = useState(AttrSelected.attribute_id);
    const [AttrGlobalId, setAttrGlobalId] = useState(AttrSelected.global_id);
    const [AttrUnit, setAttrUnit] = useState(AttrSelected.unit);
    const [AttrUnitId, setAttrUnitId] = useState(AttrSelected.unit_id);
    const [AttrEnums, setAttrEnums] = useState(AttrSelected.enumerators);

    const [alertOpen, setAlertOpen] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const [datatypeList, setDatatypeList] = useState([]);
    const [enumEdit, setEnumEdit] = useState(false);

   // console.log(TreeSelectedItem)

    let myUnit = AttrSelected.unit;
    let myUnitId = AttrSelected.unit_id;

    useEffect(() => {
        setAttrName(AttrSelected.name)
        setAttrDataType(AttrSelected.data_type);
        setAttrEnums(AttrSelected.enumerators);
        setAttrUnit(AttrSelected.unit);
        setAttrUnitId(AttrSelected.unit_id);
        setAttrDesc(AttrSelected.description);
        setAttrGlobalId(AttrSelected.global_id);
        setAttrId(AttrSelected.attribute_id);
        setAttrType(AttrSelected.attribute_type)
    }, [AttrSelected, setAttrName, setAttrDataType, setAttrEnums, setAttrUnit, setAttrUnitId, setAttrDesc, setAttrGlobalId, setAttrId, setAttrType]);

    useEffect(() => {
        async function fetchData() {
            await GetUnitList(token, realm)
                .then((result) => {
                    setUnitList(result.data);
                });

        }
        async function fetchDatatype() {
            await GetDatatypeList(token, realm)
                .then((result) => {
                    console.log(result.data)
                    setDatatypeList(result.data);
                });

        }
        fetchData();
        fetchDatatype();
    }, [token, realm, setUnitList, setDatatypeList]);


    var updateArray = [];
    const updateAttr = (items) => {
        // Nicht verwendete Arrays ermitteln und anfügen
        for (let y = 0; y <= items.length - 1; y++) {
            if (items[y].name !== AttrSelected.name) {
                updateArray.push(items[y]);
            }
        }

        // verändertes Array anfügen
        updateArray.push({
            attribute_id: AttrId,
            name: AttrName,
            //cafmBauteil: "",
            //cafmId: "",
            datatype: AttrDataType,
            attributeType: AttrType,
            //pos: 0,
            unit: AttrUnit,
            unitId: AttrUnitId,
            enumerators: AttrEnums,
            description: AttrDesc,
            globalId: AttrGlobalId
        });
    };

    const Attr_Update = async () => {
        updateAttr(TreeSelectedItem);

        // Überprüfung ob Änderungen vorgenommen wurden (noch ergänzen)
        try {

            const data = {
                name: TreeSelected.name, //!!
                    description: TreeSelected.description,
                    cafmId: TreeSelected.cafm_id,
                    globalId: TreeSelected.global_id,
                    code: TreeSelected.code, //!!
                    attributes: updateArray,
            };
            await trackPromise(
                TreeComponentTypeService.saveComponentType(token, realm, data)
                    .then((res) => {
                        TreeComponentTypeService.getTreeData(token, realm)
                            .then((result) => {
                                setTreeItemsState(result.data);

                                reloadTreeItem().then((newItem) => {
                                    if (newItem instanceof Array && newItem.length > 0) {
                                        console.log(newItem[0])
                                        setTreeSelected(newItem[0]);
                                        if(newItem[0].attribute) {
                                            const arr = newItem[0].attribute
                                            arr.forEach((attr) => {
                                                if(attr.attribute_id === AttrId) {
                                                    setAttrSelected(attr);
                                                }
                                            })
                                        }
                                        setAttrIsSelected(true);
                                    }
                                })
                            })

                    })
            );
        } catch (e) {
            console.log(e);
        }
    };

/*    const handleEnumChange = (event) => {
        const enumString = event.target.value
        const enumArray = enumString.split(',');
        setAttrEnums(enumArray);
    }*/

    const handleEnumChange2 = (event, index, ele) => {
        console.dir(event);
        const enumString = event.target.value
        /// const enumArray = enumString.split(',');
        if(index === AttrEnums?.length) {
            event.target.value = ''
        }
        console.log(AttrEnums)
        let bums: Array
        if(!AttrEnums) {
            bums = []
        } else {
            bums = AttrEnums;
        }
        console.log(enumString, index)
        bums[index] = enumString
        console.log(index ,AttrEnums?.length)

        const newArray = [];
        bums.forEach(value =>{
            if(value !== '') {
                newArray.push(value)
            }
        })
        ;
        setAttrEnums(newArray);
        console.log(AttrEnums)

    }

    const handleUnitChange = (val) => {
        setAttrUnitId(val.toString());
        myUnitId = val;
        if(unitList && unitList.length > 0) {
            unitList.forEach((u) => {
                if(u.id.toString() === val.toString()) {
                    setAttrUnit(u.prefix?u.prefix  + u.name :"" + u.name);
                    myUnit = u.prefix?u.prefix  + u.name :"" + u.name;
                }
            })
        }
        if(!val) {
            setAttrUnit("");
            myUnit = "";
        }
    }


    const lastEnumIndex = AttrEnums?.length

    return (
        <div>
            <Dialog
                className={classesDialog.dialog}
                open={UpdateAttrDialogOpen}
                onClose={() => setUpdateAttrDialogOpen(false)}
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setUpdateAttrDialogOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Merkmal <b> {AttrSelected.name} </b> bearbeiten
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => {
                                setAlertOpen(true);

                            }}
                        >
                            Bestätigen
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <TextField
                            autoFocus
                            required
                            defaultValue={AttrSelected.name}
                            margin="dense"
                            id="Attr_name"
                            label="Name"
                            type="text"
                            fullWidth
                            onChange={(e) => setAttrName(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            autoFocus
                            required
                            defaultValue={AttrSelected.attribute_id}
                            margin="dense"
                            id="Attr_id"
                            label="Merkmal-ID"
                            type="text"
                            fullWidth
                            disabled
                            onChange={(e) => setAttrId(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            autoFocus
                            required
                            defaultValue={AttrSelected.global_id}
                            margin="dense"
                            id="Attr_globalid"
                            label="Global-ID"
                            type="text"
                            fullWidth
                            disabled
                            onChange={(e) => setAttrGlobalId(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_type_title">Typ</InputLabel>
                            <Select
                                labelId="AttrTypeLabel"
                                value={AttrType}
                                id="Attr_type"
                                required
                                fullWidth
                                onChange={(e) => setAttrType(e.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {datatypeList.map((item) => (
                                        <MenuItem value={item.guid}>{item.name} - {item.identifier}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <InputLabel>Daten-Typ: {AttrDataType}</InputLabel>
                        {/*<FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_data_type_title">Daten-Typ: {AttrDataType}</InputLabel>

                            <Select
                                labelId="AttrDataTypLabel"
                                value={AttrDataType}
                                id="Attr_datatype"
                                onChange={(e) => setAttrDataType(e.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {datatypeList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>*/}
                    </ListItem>
                    <ListItem>
                        <TextField
                            autoFocus
                            defaultValue={AttrSelected.description}
                            margin="dense"
                            id="Attr_description"
                            label="Beschreibung"
                            type="text"
                            fullWidth
                            onChange={(e) => setAttrDesc(e.target.value)}
                        />
                    </ListItem>

                    <ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_unit_title">Unit</InputLabel>
                            <Select
                                labelId="AttrUnitLabel"
                                value={AttrUnitId}
                                id="Attr_unit"
                                onChange={(e) => handleUnitChange(e.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {unitList.map((item) => (
                                    <MenuItem value={item.guid}>{item.name} [{item.symbol}]</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl className={classesDialog.formControl}>
                            <InputLabel id="Attr_enumerator_label">Enumerator {AttrSelected?.enumerator}</InputLabel>
                            <Select
                                labelId="AttrEnumeratorLabel"
                                value={AttrEnums}
                                id="Attr_enumerator"
                                onChange={(e) => setAttrEnums(e.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                {AttrSelected.enumerators?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}

                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        {/*<TextField
                            autoFocus
                            defaultValue={AttrSelected.enumerators}
                            margin="dense"
                            id="Attr_enums"
                            label="Enumerator-Wert hinzufügen"
                            type="text"
                            fullWidth
                            onChange={(e) => handleEnumChange(e)}
                        />*/}
                        <Button onClick={()=>setEnumEdit(true)}>Enumerator-Werte bearbeiten</Button>
                    </ListItem>
                    <Divider />
                </List>
            </Dialog>
            <Dialog
                open={alertOpen}
                className={classesDialog.dialogAlert}
                onClose={() => setAlertOpen(false)}
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setAlertOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Merkmal <b>{AttrSelected.name}</b> updaten
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <DialogContentText>{"Sind die Eingaben korrekt?"}</DialogContentText>
                    <Alert variant="outlined" severity="info">
                        <DialogContentText>Name: {AttrName}</DialogContentText>
                        <DialogContentText>Merkmal-ID: {AttrId}</DialogContentText>
                        <DialogContentText>Global-ID: {AttrGlobalId}</DialogContentText>
                        <DialogContentText>Data-Typ: {AttrDataType}</DialogContentText>
                        <DialogContentText>Typ: {AttrType}</DialogContentText>
                        <DialogContentText>Unit: {AttrUnit}</DialogContentText>
                        <DialogContentText>Enumeratoren: {AttrEnums}</DialogContentText>
                        <DialogContentText>Beschreibung: {AttrDesc}</DialogContentText>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAlertOpen(false)} color="primary">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={async () => {
                            await Attr_Update();
                            setAlertOpen(false);
                            setUpdateAttrDialogOpen(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className={classesDialog.dialogConfirm}
                open={enumEdit}
                onClose={() => setEnumEdit(false)}
                TransitionComponent={Transition}
            >
                <AppBar className={classesDialog.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setEnumEdit(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classesDialog.title}>
                            Enumerator bearbeiten für <b>{AttrName}</b>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                   <div>

                       {AttrEnums?.map((item, index) => (
                               <TextField
                               autoFocus
                               defaultValue={item}
                               margin="dense"
                               id={`enum_${index}`}
                               label={`Enum-Wert ${index + 1}`}
                               type="text"
                               fullWidth
                               onChange={(e) => handleEnumChange2(e, index, this)}
                               />
                           )
                       )}
                       <TextField
                           autoFocus
                           defaultValue=""
                           margin="dense"
                           id={`enum_${lastEnumIndex}`}
                           label={`Enum-Wert neu`}
                           type="text"
                           fullWidth
                           onChange={(e) => handleEnumChange2(e, lastEnumIndex)}
                       />
                   </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEnumEdit(false);
                        }}
                        color="primary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={async() => {
                            // await AddAttr();

                            setEnumEdit(false);
                        }}
                        color="primary"
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
