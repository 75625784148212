import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";


function ItemDisplayAttribute({data, index, labelId, handleToggleAttributes, checkedAttributes, componentTypeId}) {
    // const classes = useStyles();

    const handleClick = (index) => {
        handleToggleAttributes(index, componentTypeId);
        // childObjects = data.children_objecttypes;
    }
    console.log('ItemDisplayAttribute: '+ index +checkedAttributes.indexOf(index));
    return (
        <>
            <ListItem key={index} role="listitem" button onClick={()=>handleClick(index)}>
                <ListItemIcon>
                    <Checkbox
                        key={index}
                        value={index}
                        checked={checkedAttributes.indexOf(index) > -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    >{data.name}</Checkbox>
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${data.name}`} />
            </ListItem>
        </>
    );
}

export default ItemDisplayAttribute;