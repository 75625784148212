import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Typography} from "@mui/material";
import RenderOnRole from "../../RenderOnRole";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import React from "react";
import {useStylesDialog} from "../../../shared/useStyleDialog";


function BimProjectAppBar({setBIMDialogOpen, objectId, hideSaveButton}) {
    const classesDialog = useStylesDialog();

    return (
        <AppBar className={classesDialog.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setBIMDialogOpen(false)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classesDialog.title}>
                    {objectId ? `Projekt bearbeiten`: `Projekt hinzufügen`}
                </Typography>
                <RenderOnRole roles={['ADMIN_MAP', 'MAP_ADMIN', 'TWIN_MANDANT_ADMIN', 'TWIN_DATA_WRITER', 'TWIN_DATA_ADMIN']}>
                    {!hideSaveButton ? (
                    <Button
                        type="submit"
                        color="inherit"
                    >
                        Speichern
                    </Button>
                        ) : null }
                </RenderOnRole>
            </Toolbar>
        </AppBar>
    )
}
export default BimProjectAppBar;