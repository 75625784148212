import React, { useState, useContext} from "react";
import http from "../../../shared/http-common";
import {TokenContext} from "../../Store";

import {trackPromise} from "react-promise-tracker";
import {List, ListItem} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ObjecttypeSelectlist from "../../../shared/ObjecttypeSelectlist";
import MaterialTable from "material-table";
import SaveIcon from "@mui/icons-material/Save";
import ProjectService from "../../../services/ProjectService";

import { header } from'../../../shared/headerStyle';

const GetObjectDataByType = async (obejcttype, type, theRealm, token) => {
    try {
        console.log('Hello GetObjectDataByType');
        let formData = new FormData();
        formData.append("realm", theRealm);
        // return http.post(`/brokerData/getAdministrativeobjects`, formData,{
        return http.post(`/` +obejcttype+`/` + type + `/list`, formData,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    } catch (e) {
        console.log(e);
    }
};

function ProjectObjectsSelect({projectGuid, realm, projectId}) {
    const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataObjects, setDataObjects] = useState([]);
    const tableRef = React.createRef();
    const options = [
        {value: '', text: '-- Bitte wählen Sie einen Objekttypen --'},
        {value: 'territorialdivision', text: 'Gebietsgliederung'},
        {value: 'site', text: 'Liegenschaft'},
        {value: 'economicunit', text: 'Wirtschaftseinheit'},
        {value: 'structure', text: 'Bauwerk'},
        {value: 'building', text: 'Gebäude'},
        {value: 'storey', text: 'Geschoß'},
        {value: 'buildingentrance', text: 'Hauseingang'},
        {value: 'rentalunit', text: 'Mieteinheit'},
        {value: 'commercialunit', text: 'Gewerbeeinheit'},
        {value: 'apartment', text: 'Wohnung'},
        {value: 'room', text: 'Raum'},
        {value: 'playground', text: 'Spielplatz'},
        {value: 'parkingspace', text: 'Stellplatz'},
    ];

    const [selected, setSelected] = useState(options[0].value);
    const [title, setTitle] = useState('Bitte wählen Sie einen Objekttypen');
    const [fieldType, setFieldType] = useState('administrativeobjectType');

    const handleObjecttypeChange = async(objecttype, type) => {
        setIsLoading(true);
        await trackPromise(
            GetObjectDataByType(objecttype, type, realm, token).then((bla) => {
                // console.log(bla);
                setDataObjects(bla.data);
                setIsLoading(false);
            }));
    };

    const handleChange = async(event) => {
        console.log(event.target.value);
        setSelected(event.target.value);
        let objecttype, type
        switch (event.target.value) {
            case 'site':
                objecttype = 'realestateobject';
                type = 'site';
                setTitle('Liegenschaften');
                setFieldType('realestateobjectType');
                break;
            case 'structure':
                objecttype = 'realestateobject';
                type = 'structure';
                setTitle('Bauwerke');
                setFieldType('realestateobjectType');
                break;
            case 'building':
                objecttype = 'realestateobject';
                type = 'building';
                setTitle('Gebäude');
                setFieldType('realestateobjectType');
                break;
            case 'room':
                objecttype = 'realestateobject';
                type = 'room';
                setTitle('Räume');
                setFieldType('realestateobjectType');
                break;
            case 'storey':
                objecttype = 'realestateobject';
                type = 'storey';
                setTitle('Etagen');
                setFieldType('realestateobjectType');
                break;
            case 'playground':
                objecttype = 'realestateobject';
                type = 'playground';
                setTitle('Spielplätze');
                setFieldType('realestateobjectType');
                break;
            case 'economicunit':
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschafteinheiten')
                setFieldType('administrativeobjectType');
                break;
            case 'apartment':
                objecttype = 'administrativeobject';
                type = 'apartment';
                setTitle('Wohnungen');
                setFieldType('administrativeobjectType');
                break;
            case 'rentalunit':
                objecttype = 'administrativeobject';
                type = 'rentalunit';
                setTitle('Mieteinheiten');
                setFieldType('administrativeobjectType');
                break;
            case 'commercialunit':
                objecttype = 'administrativeobject';
                type = 'commercialunit';
                setTitle('Gewerbeeinheiten');
                setFieldType('administrativeobjectType');
                break;
            case 'parkingspace':
                objecttype = 'administrativeobject';
                type = 'parkingspace';
                setTitle('Stellplatzanlagen');
                setFieldType('administrativeobjectType');
                break;
            case 'location':
                objecttype = 'administrativeobject';
                type = 'location';
                setTitle('Belegenheiten');
                setFieldType('administrativeobjectType');
                break;
            case 'buildingentrance':
                objecttype = 'administrativeobject';
                type = 'buildingentrance';
                setTitle('Hauseingänge');
                setFieldType('administrativeobjectType');
                break;
            case 'parcel':
                objecttype = 'administrativeobject';
                type = 'parcel';
                setTitle('Flurstücke');
                setFieldType('administrativeobjectType');
                break;
            case 'territorialdivision':
                objecttype = 'administrativeobject';
                type = 'territorialdivision';
                setTitle('Gebietsgliederungen');
                setFieldType('administrativeobjectType');
                break;
            case 'washhouse':
                objecttype = 'administrativeobject';
                type = 'washhouse';
                setTitle('Waschhäuser');
                setFieldType('administrativeobjectType');
                break;
            default:
                objecttype = 'administrativeobject';
                type = 'economicunit';
                setTitle('Wirtschaftseinheiten');
                setFieldType('administrativeobjectType');
        }


        await handleObjecttypeChange(objecttype, type)
    };

    const getColumnType = () => {
        return fieldType;
    }

    const handleAssignClick = async(object, event) => {

        event.preventDefault();
        setIsLoading(true);
        if(object instanceof Array) {
            console.log(object);
            for (const obj of object) {
                if (obj.administrativeobjectTypeGuid) {
                    await ProjectService.saveProjectObject(token, realm, projectId, projectGuid, obj.id, obj.guid, 'administrativeobject')
                } else if (obj.realestateobjectTypeGuid) {
                    await ProjectService.saveProjectObject(token, realm, projectId, projectGuid, obj.id, obj.guid, 'realestateobject')
                }
            }
            setIsLoading(false);
        } else {
            console.log(object);
            if (object.administrativeobjectTypeGuid) {
                await ProjectService.saveProjectObject(token, realm, projectId, projectGuid, object.id, object.guid, 'administrativeobject')
                setIsLoading(false);
            } else if (object.realestateobjectTypeGuid) {
                await ProjectService.saveProjectObject(token, realm, projectId, projectGuid, object.id, object.guid, 'realestateobject')
                setIsLoading(false);
            }
        }
    }

    const tableHeight =(window.innerHeight - 74 - 52 - 1) / window.innerHeight * 100;

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#2222ff',
            },

        },
        components: { //bringt nix
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        "&.MuiTablePagination-toolbar": {
                            width: 'auto'
                        }
                    }
                }

            }
        }

    });


    return (
        <>
            <List style={{backgroundColor: "#FFFFFF"}}>
                <ListItem>
                    <ObjecttypeSelectlist
                        options={options}
                        selected={selected}
                        handleChange={handleChange}
                    />
                </ListItem>
            </List>
            <ThemeProvider theme={theme}>
            <MaterialTable

                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: getColumnType()},
                ]}
                data={dataObjects}

                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF'
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    selection: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}

                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: 'zuweisen'
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                onRowClick={(event, rowData) => {
                    // Copy row data and set checked state
                    const rowDataCopy = { ...rowData };
                    rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
                    // Copy data so we can modify it
                    const dataCopy = [...dataObjects];
                    // Find the row we clicked and update it with `checked` prop
                    dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
                    setDataObjects(dataCopy);
                }}
                actions={[
                    rowData => ({
                        icon: () => <SaveIcon />,
                        tooltip: 'dem Projekt zuweisen',
                        onClick: (event, rowData) => handleAssignClick(rowData, event)
                    }),
                ]}

                />
            </ThemeProvider>
        </>
    )
}

export default ProjectObjectsSelect;
