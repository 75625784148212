import React, {useEffect, useState, useCallback, useContext} from "react";
import http from "../../../shared/http-common";

import { TokenContext} from "../../Store";

import MaterialTable from "material-table";
import { header } from'../../../shared/headerStyle';


function UserObjects({username, userId, realm}) {

    const [token] = useContext(TokenContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataObjects, setDataObjects] = useState([]);
    const tableRef = React.createRef();

    const [title] = useState('Objekte des Benutzers');

    const getUserObjects = useCallback(async () => {

        try {
            let formData = new FormData();
            formData.append("realm", realm);
            formData.append('username', username)
            formData.append('userId', userId)

            return http.post(`/project/listUserObjects`, formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            });


        } catch (e) {
            console.log(e);
        }

    }, [realm, token, username, userId]);

    useEffect(()=> {
        setIsLoading(true)
        getUserObjects()
            .then(result => {
                console.log(result);
                setDataObjects(result.data);
                setIsLoading(false)
            })
    }, []);

    const handleDeactivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            setIsLoading(false);
        }else if(object.realestateobjectTypeGuid) {
            setIsLoading(false);
        }
    }

    const handleActivateClick = (object, event) => {
        event.preventDefault();
        setIsLoading(true);
        if(object.administrativeobjectTypeGuid) {
            setIsLoading(false);
        }else if(object.realestateobjectTypeGuid) {
            setIsLoading(false);
        }
    }

    const getColumnType = (data) => {
        let type = '';
        if(data.realestateobjectType) {
            type = data.realestateobjectType
        } else if(data.administrativeobjectType) {
            type = data.administrativeobjectType
        }
        return type;
    }

    const tableHeight =(window.innerHeight - 66 - 74 - 52 - 1) / window.innerHeight * 100;

    return (
        <>

            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={[
                    { title: 'Bezeichnung', field: 'name' },
                    { title: 'Nummer', field: 'number'},
                    { title: 'Kennzeichen', field: 'mark' },
                    { title: 'Typ', field: 'type', render: (rowData) => {
                            return getColumnType(rowData)}
                    }
                ]}
                data={dataObjects}

                options={{
                    headerStyle: header,
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.deactivated) ? '#EEE' : '#FFF'
                    }),
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    actionsColumnIndex: -1,
                    // selection: false,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: `${tableHeight}vh`,
                    minBodyHeight: `${tableHeight}vh`,
                }}

                isLoading={isLoading}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsSelect: 'Zeilen',
                        labelRowsPerPage: 'Zeilen pro Seite'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Zeile(n) ausgewählt',
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchbegriff eingeben'
                    },
                    header: {
                        actions: 'verantworlich'
                    },
                    body: {
                        emptyDataSourceMessage: 'Keine Einträge gefunden',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                /*onRowClick={(event, rowData) => {
                    // Copy row data and set checked state
                    const rowDataCopy = { ...rowData };
                    rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
                    // Copy data so we can modify it
                    const dataCopy = [...dataObjects];
                    // Find the row we clicked and update it with `checked` prop
                    dataCopy[rowDataCopy.tableData.id] = rowDataCopy;
                    setDataObjects(dataCopy);
                }}*/

            />

        </>
    )
}

export default UserObjects;
